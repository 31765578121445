import axiosInstance from "../../../utils/axios";

const path = "admin/style-files";


export const Details = async (fileName) => {
    return await axiosInstance().get(`${path}/details?&file_name=${fileName}`);
}

export const Edit = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}

export const ResetLatest = async (values) => {
    return await axiosInstance().post(`${path}/reset-latest`, values);
}

export const Reset = async (values) => {
    return await axiosInstance().post(`${path}/reset`, values);
}