import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import toHtml from 'html-react-parser'
import Breadcrums from '../../common/breadcrumbs';
import Loader from '../../common/loader'
import { formateDate, showStatus } from '../../../../utils/commonfunction';
import * as announcementsService from '../../services/announcements.services'
import { ANNOUNCEMENT_TYPES } from '../../../../utils/Constants';


const AnnouncementsView = () => {
    const navigate = useNavigate();
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Announcements", url: "/admin/announcements/list/1" }, { title: "View", url: "" },]

    useEffect(() => {
        announcementsService.Details(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    const decideType = (announcement_type) => {
        if (announcement_type === ANNOUNCEMENT_TYPES.GENERAL) {
            return <span className="badge badge-success">General</span>
        } else if (announcement_type === ANNOUNCEMENT_TYPES.EVENT) {
            return <span className="badge badge-danger">Event</span>
        }
    }
    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="main-content-label mb-3">View Announcement</h6>
                            </div>
                            {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table  table-bordered table-hover">
                                        <tbody>

                                            <tr>
                                                <th> Title</th>
                                                <td>{showdefault && showdefault?.title ? showdefault.title : "N/A"}</td>
                                            </tr>

                                            <tr>
                                                <th> Title In German</th>
                                                <td>{showdefault && showdefault?.title_de ? showdefault.title_de : "N/A"}</td>
                                            </tr>

                                            <tr>
                                                <th>Announcement Type</th>
                                                <td>{decideType(showdefault?.announcement_type)}</td>
                                            </tr>
                                            <tr>
                                                <th>Event Name</th>
                                                {/* <td>{showdefault && showdefault?.event_data?.title ? showdefault.event_data.title : "N/A"}</td> */}
                                                <td>{showdefault && showdefault?.event_data?.title ? <Link to={`/admin/event-management/events/view/event/${showdefault.event_data?._id}`}>{showdefault.event_data?.title}</Link>: "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{showdefault && showdefault?.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault.status))}</td>
                                            </tr>
                                            <tr>
                                                <th>Created Date</th>
                                                <td>{showdefault && showdefault?.createdAt ? formateDate(showdefault.createdAt) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Created By</th>

                                                <td>{showdefault && showdefault?.createdBy?.name ? <Link to={`/admin/staff/view/${showdefault.createdBy._id}`}>{showdefault.createdBy.name}</Link> : "N/A"}</td>
                                            </tr>

                                            <tr>
                                                <th>Updated By</th>

                                                <td>{showdefault && showdefault?.updatedBy?.name ? <Link to={`/admin/staff/view/${showdefault.updatedBy._id}`}>{showdefault.updatedBy.name}</Link> : "N/A"}</td>

                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault?.description ? showdefault.description : "N/A" }}></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault?.description_de ? showdefault.description_de : "N/A" }}></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="mt-5">
                                        <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                                            <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div> : <Loader />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnnouncementsView;