import React from 'react'
import { URL_REGEX } from '../../../utils/Constants'

export default function VideoPlayer({src, type="video"}) {
    function isFile(source){
        return !URL_REGEX.test(source);
    }
  return (
    <div>
        {isFile(src)?
            <h4>Invalid video URL {src}</h4>
            :
              type==="video"?
              <video className='w-100 rounded-2' src={src} controls={true} autoPlay={true}></video>
              :<audio className='w-100 rounded-2' src={src} controls={true} autoPlay={true}></audio>
        }
    </div>
  )
}
