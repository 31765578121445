import React, { useEffect, useState } from "react";
import { Field, FieldArray, Formik } from "formik";
import Swal from "sweetalert2";
import * as globalSettingsService from "../../../services/globalsetting.services";
import Breadcrums from "../../../common/breadcrumbs";
import {
  blockInvalidChar,
  getNameFromList,
  handleServerValidations,
  hasPermission,
} from "../../../../../utils/commonfunction";
import {
  SWAL_SETTINGS,
  isProfileRequired,
} from "../../../../../utils/Constants";
import { TagPicker, Toggle } from "rsuite";
import CustomCkeditor from "../../../common/customeditor";
import ReactCountryFlag from "react-country-flag";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import Loader from "../../../common/loader";

const GlobalSettings = (props) => {
  const [showdefault, setShowDefault] = useState({});
  const [contactNumber, setContactNumber] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Global Settings", url: "" },
  ];
  const [previewimage, setPreviewImage] = useState("");
  const [previewImageGerman, setPreviewImageGerman] = useState("");

  useEffect(() => {
    globalSettingsService
      .Details()
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
        setPreviewImage(response.data.login_bg_image);
        setPreviewImageGerman(response.data.login_bg_image_de);
        setContactNumber(JSON.parse(response?.data?.contact_no));
        setTimeout(() => {
          setDataLoaded(true);
        }, 100);
      })
      .catch((error) => {
        console.log("error=====>", error);
        setDataLoaded(true);
      });
  }, []);

  //   useEffect(() => {
  //     /**DEV MODE >>>> */
  //     document.addEventListener('keydown',function(e){
  //         checkDevModeSession();
  //     });
  //     document.addEventListener('mousemove',function(e){
  //         checkDevModeSession();
  //     });
  //     checkDevModeSession();
  //     /**DEV MODE <<<< */
  // }, [])

  // const checkDevModeSession = ()=>{
  //     try{
  //         let sKey = getSessionKey('devmodetime');
  //         let oldDate = new Date(sKey);
  //         let oldTime = oldDate.getTime();
  //         let newDate = new Date();
  //         let newTime = newDate.getTime();
  //         let diff = newTime - oldTime;
  //         if(diff <= DEVMODE.SESSION_TIME){

  //         }else{
  //             logoutDevMode();
  //         }
  //     }catch(err){
  //         logoutDevMode();
  //     }
  // }

  // const logoutDevMode = ()=>{
  //     removeSessionKey('devmode');
  //     removeSessionKey('devmodetime');
  //     if(window.location.href.includes('/email-templates/add')){
  //         navigate('/email-templates/1');
  //     }
  // }

  const showDropDown = (country) => {
    return (
      <label>
        {"   " + getNameFromList(country)} {" +"}
        {getCountryCallingCode(country)}
      </label>
    );
  };

  const data =
    isProfileRequired &&
    isProfileRequired?.length > 0 &&
    isProfileRequired.map((item) => ({ label: item?.label, value: item?.value }));


  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
        <Formik
          validateOnChange={true}
          enableReinitialize
          initialValues={{
            Address:
              showdefault && showdefault.address ? showdefault.address : "",
            smtp_username:
              showdefault && showdefault.smtp_username
                ? showdefault.smtp_username
                : "",
            AdminEmail:
              showdefault && showdefault.admin_email
                ? showdefault.admin_email
                : "",
            Contact:
              contactNumber && contactNumber?.number
                ? contactNumber?.number
                : "",
            countryCodePhone:
              contactNumber && contactNumber?.countryCode
                ? contactNumber?.countryCode
                : "",
            dialCodePhone:
              contactNumber && contactNumber?.code
                ? contactNumber?.code
                : "" + getCountryCallingCode(getCountries()[0]),
            TermsAndConditionTwo:
              showdefault && showdefault.terms_and_condition_two
                ? showdefault.terms_and_condition_two
                : "",
            TermsAndConditionOne:
              showdefault && showdefault.terms_and_condition_one
                ? showdefault.terms_and_condition_one
                : "",
            TermsAndConditionTwoDe:
              showdefault && showdefault.terms_and_condition_two_de
                ? showdefault.terms_and_condition_two_de
                : "",
            TermsAndConditionOneDe:
              showdefault && showdefault.terms_and_condition_one_de
                ? showdefault.terms_and_condition_one_de
                : "",
            richtext:
              showdefault && showdefault.login_content
                ? showdefault.login_content
                : "",
            richtext_de:
              showdefault && showdefault.login_content_de
                ? showdefault.login_content_de
                : "",
            footer_text:
              showdefault && showdefault.footer_text
                ? showdefault.footer_text
                : "",
            CopyRighttxt:
              showdefault && showdefault.copy_right_text
                ? showdefault.copy_right_text
                : "",
            activity_forum:
              showdefault && showdefault.activity_forum
                ? showdefault.activity_forum
                : false,
            surveys:
              showdefault && showdefault.surveys ? showdefault.surveys : false,
            internal_chat:
              showdefault && showdefault.internal_chat
                ? showdefault.internal_chat
                : false,
            comments:
              showdefault && showdefault.comments
                ? showdefault.comments
                : false,
            login_bg_image:
              showdefault && showdefault.login_bg_image
                ? showdefault.login_bg_image
                : "",
            login_bg_image_de:
                showdefault && showdefault.login_bg_image_de
                  ? showdefault.login_bg_image_de
                  : "", 
            socialMedia:
              showdefault && showdefault.social_media
                ? showdefault.social_media
                : [],
            invoice_footer:
              showdefault && showdefault.invoice_footer
                ? showdefault.invoice_footer
                : "",
            web: showdefault && showdefault.website ? showdefault.website : "",
            ticket_prefix_text:
              showdefault && showdefault.ticket_prefix_text
                ? showdefault.ticket_prefix_text
                : "",
            ticket_prefix_number:
              showdefault && showdefault.ticket_prefix_number
                ? showdefault.ticket_prefix_number
                : "",
            ticket_footer_text:
              showdefault && showdefault.ticket_footer_text
                ? showdefault.ticket_footer_text
                : "",
            cookies_content:
              showdefault && showdefault.cookies_content
                ? showdefault.cookies_content
                : "",
            cookies_content_de:
              showdefault && showdefault.cookies_content_de
                ? showdefault.cookies_content_de
                : "",
            default_language:
              showdefault && showdefault.default_language
                ? showdefault.default_language
                : "",
            profile_required_fields:
              showdefault && showdefault.profile_required_fields
                ? showdefault.profile_required_fields
                : "",
            pay_by_invoice_content:
              showdefault && showdefault.pay_by_invoice_content
                ? showdefault.pay_by_invoice_content
                : "",
          }}
          validate={(values) => {
            let error = { socialMedia: [] };
            if (values.socialMedia.length > 0) {
              values.socialMedia.forEach((element, i) => {
                error.socialMedia[i] = {};
                if (!element.title) {
                  error.socialMedia[i]["title"] = "Title is required";
                }
                if (!element.url) {
                  error.socialMedia[i]["url"] = "URL is required";
                }
                if (!element.icon) {
                  error.socialMedia[i]["icon"] = "Icon is required";
                }
              });
            }
            if (!values.Address || !values.Address.trim())
              error.Address = "Address is required";

            if (!values.smtp_username || !values.smtp_username.trim())
              error.smtp_username = "SMTP Username is required";
            if (!values.AdminEmail) {
              error.AdminEmail = "Email is required";
            }
            // else if (
            //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.AdminEmail)
            // ) {
            //   error.AdminEmail = "Invalid email address";
            // }
            else if (
              !/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                values.AdminEmail
              )
            ) {
              error.AdminEmail = "Invalid email address";
            }
            if (!values.Contact) {
              error.Contact = "Contact is required";
            }
            if (!values.CopyRighttxt || !values.CopyRighttxt.trim()) {
              error.CopyRighttxt = "Copy right text is required";
            }
            if (
              !values.TermsAndConditionTwo ||
              !values.TermsAndConditionTwo.trim()
            ) {
              error.TermsAndConditionTwo =
                "Terms and condition text is required";
            }
            if (
              !values.TermsAndConditionOne ||
              !values.TermsAndConditionOne.trim()
            ) {
              error.TermsAndConditionOne =
                "Terms and condition text is required";
            }
            if (!values.richtext || !values.richtext.trim()) {
              error.richtext = "Login banner page text is required";
            }
            if (!values.footer_text || !values.footer_text.trim()) {
              error.footer_text = "Footer text is required";
            }
            if (!values.invoice_footer || !values.invoice_footer.trim()) {
              error.invoice_footer = "Invoice footer is required";
            }
            if (
              !values.pay_by_invoice_content ||
              !values.pay_by_invoice_content.trim()
            ) {
              error.pay_by_invoice_content =
                "Pay by invoice content is required";
            }
            if (
              !values.ticket_prefix_text ||
              !values.ticket_prefix_text.trim()
            ) {
              error.ticket_prefix_text = "Ticket prefix text is required";
            }
            if (!values.ticket_prefix_number) {
              error.ticket_prefix_number = "Ticket prefix number is required";
            }
            if (!values.ticket_footer_text) {
              error.ticket_footer_text = "Ticket footer text is required";
            }
            if (!values.cookies_content) {
              error.cookies_content = "Cookies content is required";
            }
            if (!values.default_language) {
              error.default_language = "Default language is required";
            }
            // if (!values.profile_required_fields) {
            //   error.profile_required_fields = "This filed is required";
            // }
            if (!values.web) {
              error.web = "Website is required";
            }
            if (values.socialMedia.length > 0) {
              values.socialMedia.forEach((element, i) => {
                if (element.title && element.url && element.icon) {
                  if (i === values.socialMedia.length - 1) {
                    delete error.socialMedia;
                  }
                } else {
                  return error;
                }
              });
            } else {
              delete error.socialMedia;
            }
            return error;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitted(true);
            let formData = new FormData();
            formData.append("address", values.Address);
            formData.append("website", values.web);
            formData.append("smtp_username", values.smtp_username);
            formData.append("admin_email", values.AdminEmail);
            if (values.Contact && values.dialCodePhone) {
              formData.append(
                "contact_no",
                JSON.stringify({
                  code: values.dialCodePhone,
                  number: values.Contact,
                  countryCode: values.countryCodePhone,
                })
              );
            }
            formData.append("copy_right_text", values.CopyRighttxt);
            formData.append(
              "terms_and_condition_one",
              values.TermsAndConditionOne
            );
            formData.append(
              "terms_and_condition_two",
              values.TermsAndConditionTwo
            );
            formData.append(
              "terms_and_condition_one_de",
              values.TermsAndConditionOneDe
            );
            formData.append(
              "terms_and_condition_two_de",
              values.TermsAndConditionTwoDe
            );
            formData.append("login_content", values.richtext);
            formData.append("login_bg_image", values.login_bg_image);
            formData.append("login_bg_image_de", values.login_bg_image_de);
            formData.append("footer_text", values.footer_text);
            formData.append("social_media", JSON.stringify(values.socialMedia));
            formData.append("activity_forum", values.activity_forum);
            formData.append("surveys", values.surveys);
            formData.append("internal_chat", values.internal_chat);
            formData.append("comments", values.comments);
            formData.append("invoice_footer", values.invoice_footer);
            formData.append("ticket_prefix_text", values.ticket_prefix_text);
            formData.append(
              "ticket_prefix_number",
              values.ticket_prefix_number
            );
            formData.append("ticket_footer_text", values.ticket_footer_text);
            formData.append("cookies_content", values.cookies_content);
            formData.append("cookies_content_de", values.cookies_content_de);
            formData.append("default_language", values.default_language);
            formData.append(
              "profile_required_fields",
              JSON.stringify(values.profile_required_fields)
            );
            formData.append(
              "pay_by_invoice_content",
              values.pay_by_invoice_content
            );
            formData.append("login_content_de", values.richtext_de);
            globalSettingsService
              .Add(formData)
              .then((response) => {
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                  setTimeout(() => {
                    setSubmitted(false);
                  }, 2000);
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                  setSubmitted(false);
                }
              })
              .catch((error) => {
                console.log("error ====> ", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">
                          Global Settings
                        </h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="smtp_username"
                            className="text-left d-flex"
                          >
                            SMTP Username:<span className="requirestar">*</span>
                          </label>
                          <input
                            name="smtp_username"
                            type="text"
                            id="smtp_username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.smtp_username}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.smtp_username &&
                              touched.smtp_username &&
                              errors.smtp_username}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="AdminEmail"
                            className="text-left d-flex"
                          >
                            Admin Email:<span className="requirestar">*</span>
                          </label>
                          <input
                            name="AdminEmail"
                            type="text"
                            id="AdminEmail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.AdminEmail}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.AdminEmail &&
                              touched.AdminEmail &&
                              errors.AdminEmail}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="Contact" className="text-left d-flex">
                            Contact No:<span className="requirestar">*</span>{" "}
                          </label>
                          <div className=" form-control d-flex">
                            <select
                              className="selectInput"
                              style={{
                                paddingLeft: 0,
                                paddingTop: 0,
                                marginTop: 0,
                                border: 0,
                                width: 40,
                                backgroundColor: "white",
                              }}
                              value={values.countryCodePhone}
                              onChange={(event) => {
                                setFieldValue(
                                  "countryCodePhone",
                                  event.target.value
                                );
                                setFieldValue(
                                  "dialCodePhone",
                                  "+" +
                                    getCountryCallingCode(event.target.value)
                                );
                              }}
                            >
                              {getCountries().map((country) => (
                                <>
                                  <option key={country} value={country}>
                                    <ReactCountryFlag countryCode={country} />
                                    {showDropDown(country)}
                                  </option>
                                </>
                              ))}
                            </select>
                            <label
                              style={{ padding: 0, margin: 0, paddingTop: 2 }}
                            >
                              {values.dialCodePhone}
                            </label>
                            <input
                              style={{
                                width: "100%",
                                height: "100%",
                                border: "white",
                              }}
                              className="input_contact"
                              name="Contact"
                              id="Contact"
                              onChange={(event) => {
                                setFieldValue(`Contact`, event.target.value);
                              }}
                              value={values.Contact}
                              onKeyDown={blockInvalidChar}
                            />
                          </div>
                          {/* <input
                          type="text"
                          name="Contact"
                          id="Contact"
                          className="form-control"
                          placeholder="Enter Contact"
                          value={values.Contact}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onKeyDown={blockInvalidChar}
                        /> */}
                          <span className="text-danger d-flex text-left">
                            {errors.Contact &&
                              touched.Contact &&
                              errors.Contact}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="Address" className="text-left d-flex">
                            Address:<span className="requirestar">*</span>{" "}
                          </label>
                          <textarea
                            name="Address"
                            id="Address"
                            type="text"
                            style={{ minHeight: "100px" }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Address}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.Address &&
                              touched.Address &&
                              errors.Address}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="web" className="text-left d-flex">
                            Website:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="web"
                            id="web"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.web}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.web && touched.web && errors.web}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="CopyRighttxt"
                            className="text-left d-flex"
                          >
                            Copy Right Text:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="CopyRighttxt"
                            type="text"
                            id="CopyRighttxt"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.CopyRighttxt}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.CopyRighttxt &&
                              touched.CopyRighttxt &&
                              errors.CopyRighttxt}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="TermsAndConditionOne"
                            className="text-left d-flex"
                          >
                            Complete Profile Terms and Condition One:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"TermsAndConditionOne"}
                              setFieldValue={setFieldValue}
                              value={values.TermsAndConditionOne}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            <></>
                          )}
                          <span className="text-danger d-flex text-left">
                            {errors.TermsAndConditionOne &&
                              touched.TermsAndConditionOne &&
                              errors.TermsAndConditionOne}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="TermsAndConditionTwo"
                            className="text-left d-flex"
                          >
                            Complete Profile Terms And Condition Two:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"TermsAndConditionTwo"}
                              setFieldValue={setFieldValue}
                              value={values.TermsAndConditionTwo}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            <></>
                          )}
                          <span className="text-danger d-flex text-left">
                            {errors.TermsAndConditionTwo &&
                              touched.TermsAndConditionTwo &&
                              errors.TermsAndConditionTwo}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="TermsAndConditionOneDe"
                            className="text-left d-flex"
                          >
                            Complete Profile Terms and Condition One German:
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"TermsAndConditionOneDe"}
                              setFieldValue={setFieldValue}
                              value={values.TermsAndConditionOneDe}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="TermsAndConditionTwoDe"
                            className="text-left d-flex"
                          >
                            Complete Profile Terms And Condition Two German:
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"TermsAndConditionTwoDe"}
                              setFieldValue={setFieldValue}
                              value={values.TermsAndConditionTwoDe}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="footer_text"
                            className="text-left d-flex"
                          >
                            Footer Text:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="footer_text"
                            type="text"
                            id="footer_text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.footer_text}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.footer_text &&
                              touched.footer_text &&
                              errors.footer_text}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="login_bg_image"
                            className="text-left d-flex"
                          >
                            Login BG Image:{" "}
                          </label>
                          <input
                            className="form-control imgInput"
                            id="login_bg_image"
                            name="login_bg_image"
                            accept="image/*"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "login_bg_image",
                                event.currentTarget.files[0] || ""
                              );
                              event.currentTarget.files.length === 1
                                ? setPreviewImage(
                                    URL.createObjectURL(
                                      event.currentTarget.files[0]
                                    )
                                  )
                                : setPreviewImage("");
                            }}
                          />
                        </div>
                        {previewimage ? (
                          <div className="mb-4">
                            <img
                              src={previewimage}
                              alt="Preview"
                              style={{ height: "100px" }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                          <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="login_bg_image_de"
                            className="text-left d-flex"
                          >
                            Login BG Image German:{" "}
                          </label>
                          <input
                            className="form-control imgInput"
                            id="login_bg_image_de"
                            name="login_bg_image_de"
                            accept="image/*"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "login_bg_image_de",
                                event.currentTarget.files[0] || ""
                              );
                              event.currentTarget.files.length === 1
                                ? setPreviewImageGerman(
                                    URL.createObjectURL(
                                      event.currentTarget.files[0]
                                    )
                                  )
                                : setPreviewImageGerman("");
                            }}
                          />
                        </div>
                        {previewImageGerman ? (
                          <div className="mb-4">
                            <img
                              src={previewImageGerman}
                              alt="Preview"
                              style={{ height: "100px" }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="richtext"
                            className="text-left d-flex"
                          >
                            Login Page Content:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"richtext"}
                              setFieldValue={setFieldValue}
                              value={values.richtext}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            <></>
                          )}
                          <span className="text-danger d-flex text-left">
                            {errors.richtext &&
                              touched.richtext &&
                              errors.richtext}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="richtext_de"
                            className="text-left d-flex"
                          >
                            Login Page Content German:
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"richtext_de"}
                              setFieldValue={setFieldValue}
                              value={values.richtext_de}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-12">
                          <div
                            className={
                              "d-flex justify-content-between " +
                              (hasPermission(
                                "/admin/cms/settings/toggle/activity-forum"
                              )
                                ? ""
                                : "disable-input")
                            }
                            style={{ maxWidth: "200px" }}
                          >
                            <label
                              htmlFor="activity_forum"
                              className="text-left cp"
                            >
                              Activity forum
                            </label>

                            <Toggle
                              className="ms-3"
                              id="activity_forum"
                              name="activity_forum"
                              checked={values.activity_forum}
                              onChange={(e) => {
                                setFieldValue("activity_forum", e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className={
                              "d-flex justify-content-between " +
                              (hasPermission(
                                "/admin/cms/settings/toggle/activity-forum"
                              )
                                ? ""
                                : "disable-input")
                            }
                            style={{ maxWidth: "200px" }}
                          >
                            <label htmlFor="surveys" className="text-left cp">
                              Surveys
                            </label>
                            <Toggle
                              className="ms-3"
                              id="surveys"
                              name="surveys"
                              checked={values.surveys}
                              onChange={(e) => {
                                setFieldValue("surveys", e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className={
                              "d-flex justify-content-between " +
                              (hasPermission(
                                "/admin/cms/settings/toggle/activity-forum"
                              )
                                ? ""
                                : "disable-input")
                            }
                            style={{ maxWidth: "200px" }}
                          >
                            <label
                              htmlFor="internal_chat"
                              className="text-left cp"
                            >
                              Internal chat
                            </label>
                            <Toggle
                              className="ms-3"
                              id="internal_chat"
                              name="internal_chat"
                              checked={values.internal_chat}
                              onChange={(e) => {
                                setFieldValue("internal_chat", e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className={
                              "d-flex justify-content-between " +
                              (hasPermission(
                                "/admin/cms/settings/toggle/activity-forum"
                              )
                                ? ""
                                : "disable-input")
                            }
                            style={{ maxWidth: "200px" }}
                          >
                            <label htmlFor="comments" className="text-left cp">
                              Comments
                            </label>
                            <Toggle
                              className="ms-3"
                              id="comments"
                              name="comments"
                              checked={values.comments}
                              onChange={(e) => {
                                setFieldValue("comments", e);
                              }}
                            />
                          </div>
                        </div>
                        <label className="text-left d-flex">
                          Social Media:
                        </label>
                        <FieldArray
                          name="socialMedia"
                          render={(arrayHelper) => (
                            <div className="col-12 mb-4">
                              <div className="row">
                                {values.socialMedia &&
                                values.socialMedia.length > 0 ? (
                                  values.socialMedia.map((item, index) => (
                                    <div className="col-12 " key={index}>
                                      <div className="w-100 p-2 bg-group">
                                        <div className="row">
                                          <div className="col-md-3 text-center form-group mb-0">
                                            <Field
                                              className="form-control"
                                              id="title"
                                              name={`socialMedia[${index}].title`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={item.title}
                                              placeholder="Title"
                                            />
                                            <span className="text-danger d-flex text-left">
                                              {((touched.socialMedia &&
                                                touched.socialMedia[index] &&
                                                touched.socialMedia[index]
                                                  .title) ||
                                                submitClicked) &&
                                                errors.socialMedia &&
                                                errors.socialMedia[index] &&
                                                errors.socialMedia[index].title}
                                            </span>
                                          </div>
                                          <div className="col-md-4 text-center form-group mb-0">
                                            <Field
                                              className="form-control"
                                              id="url"
                                              name={`socialMedia[${index}].url`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={item.url}
                                              placeholder="URL"
                                            />
                                            <span className="text-danger d-flex text-left">
                                              {((touched.socialMedia &&
                                                touched.socialMedia[index] &&
                                                touched.socialMedia[index]
                                                  .url) ||
                                                submitClicked) &&
                                                errors.socialMedia &&
                                                errors.socialMedia[index] &&
                                                errors.socialMedia[index].url}
                                            </span>
                                          </div>
                                          <div className="col-md-3 text-center form-group mb-0">
                                            <Field
                                              className="form-control"
                                              id="icon"
                                              name={`socialMedia[${index}].icon`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={item.icon}
                                              placeholder="Icon"
                                            />
                                            <span className="text-danger d-flex text-left">
                                              {((touched.socialMedia &&
                                                touched.socialMedia[index] &&
                                                touched.socialMedia[index]
                                                  .icon) ||
                                                submitClicked) &&
                                                errors.socialMedia &&
                                                errors.socialMedia[index] &&
                                                errors.socialMedia[index].icon}
                                            </span>
                                          </div>
                                          <div className="col-md-2">
                                            <div className="social-media-action">
                                              <button
                                                className="btn btn-danger "
                                                type="button"
                                                onClick={() =>
                                                  arrayHelper.remove(index)
                                                } // remove a friend from the list
                                              >
                                                Remove
                                              </button>
                                              {index ===
                                                values?.socialMedia?.length -
                                                  1 && (
                                                <button
                                                  className="btn btn-main-primary signbtn ms-2 "
                                                  type="button"
                                                  onClick={() =>
                                                    arrayHelper.insert(
                                                      values?.socialMedia
                                                        ?.length,
                                                      {
                                                        title: "",
                                                        url: "",
                                                        icon: "",
                                                      }
                                                    )
                                                  }
                                                >
                                                  Add
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <button
                                    className="btn btn-main-primary signbtn mr-2 ms-2 mb-3"
                                    type="button"
                                    onClick={() => arrayHelper.push({})}
                                  >
                                    Add a social media
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        />
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="invoice_footer"
                            className="text-left d-flex"
                          >
                            Invoice Footer:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"invoice_footer"}
                              setFieldValue={setFieldValue}
                              value={values.invoice_footer}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            <></>
                          )}
                          <span className="text-danger d-flex text-left">
                            {errors.invoice_footer &&
                              touched.invoice_footer &&
                              errors.invoice_footer}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="pay_by_invoice_content"
                            className="text-left d-flex"
                          >
                            Pay By Invoice Content:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"pay_by_invoice_content"}
                              setFieldValue={setFieldValue}
                              value={values.pay_by_invoice_content}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            <></>
                          )}
                          <span className="text-danger d-flex text-left">
                            {errors.pay_by_invoice_content &&
                              touched.pay_by_invoice_content &&
                              errors.pay_by_invoice_content}
                          </span>
                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label
                            htmlFor="ticket_prefix_text"
                            className="text-left d-flex"
                          >
                            Ticket Prefix Text:
                            <span className="requirestar">*</span>
                          </label>
                          <input
                            name="ticket_prefix_text"
                            type="text"
                            id="ticket_prefix_text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ticket_prefix_text}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.ticket_prefix_text &&
                              touched.ticket_prefix_text &&
                              errors.ticket_prefix_text}
                          </span>
                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label
                            htmlFor="ticket_prefix_number"
                            className="text-left d-flex"
                          >
                            Ticket Prefix Number:
                            <span className="requirestar">*</span>
                          </label>
                          <input
                            name="ticket_prefix_number"
                            type="number"
                            id="ticket_prefix_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ticket_prefix_number}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.ticket_prefix_number &&
                              touched.ticket_prefix_number &&
                              errors.ticket_prefix_number}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="ticket_footer_text"
                            className="text-left d-flex"
                          >
                            Ticket Footer Text:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"ticket_footer_text"}
                              setFieldValue={setFieldValue}
                              value={values.ticket_footer_text}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            <></>
                          )}
                          <span className="text-danger d-flex text-left">
                            {errors.ticket_footer_text &&
                              touched.ticket_footer_text &&
                              errors.ticket_footer_text}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="cookies_content"
                            className="text-left d-flex"
                          >
                            Cookies Content:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"cookies_content"}
                              setFieldValue={setFieldValue}
                              value={values.cookies_content}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            <></>
                          )}
                          <span className="text-danger d-flex text-left">
                            {errors.cookies_content &&
                              touched.cookies_content &&
                              errors.cookies_content}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="cookies_content_de"
                            className="text-left d-flex"
                          >
                            Cookies Content German:
                           {" "}
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"cookies_content_de"}
                              setFieldValue={setFieldValue}
                              value={values.cookies_content_de}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="col-lg-12 text-center form-group">
                          <label
                            htmlFor="default_language"
                            className="text-left d-flex"
                          >
                            Default Language:
                            <span className="requirestar">*</span>
                          </label>
                          <div className="select-down-arrow ">
                            <Field
                              as="select"
                              id="default_language"
                              name={"default_language"}
                              className="form-control select2"
                            >
                              <option value="" label="Select">
                                Select{" "}
                              </option>
                              <option className="text-capitalize" value="en">
                                English
                              </option>
                              <option className="text-capitalize" value="de">
                                Deutsch
                              </option>
                            </Field>
                          </div>
                          <span className="text-danger d-flex text-left">
                            {errors.default_language &&
                              touched.default_language &&
                              errors.default_language}
                          </span>
                        </div>
                        <div className="col-md-12 text-center form-group">
                          <label
                            htmlFor="profile_required_fields"
                            className="text-left d-flex"
                          >
                           Profile Required Fields:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          <div className="select-down-arrow text-left">
                            <TagPicker
                              data={data}
                              defaultValue={values.profile_required_fields}
                              className="w-100 rsuite-input"
                              onChange={(e) =>
                                setFieldValue("profile_required_fields", e)
                              }
                              cleanable={false}
                            />
                          </div>
                          {/* <span className="text-danger d-flex text-left">
                            {errors.profile_required_fields &&
                              touched.profile_required_fields &&
                              errors.profile_required_fields}
                          </span> */}
                        </div>
                        {hasPermission("/admin/cms/settings/submit") ? (
                          <div className="">
                            <button
                              className="btn btn-main-primary signbtn mr-2"
                              type="submit"
                              disabled={submitted ? true : null}
                              onClick={() => {
                                setSubmitClicked(true);
                              }}
                            >
                              <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                              Submit
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default GlobalSettings;
