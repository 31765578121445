import { Navigate, createBrowserRouter } from "react-router-dom";

/** USER ROUTE START */
import Userlayout from "./modules/user/shared/userlayout";
import Home from "./modules/user/pages/home";
import UserLogin from "./modules/user/pages/login";
import UserProfile from "./modules/user/pages/profile";
import UserForgetPassword from "./modules/user/pages/forgetpassword";
import UserOtpVerification from "./modules/user/pages/otpverification";
import UserPublicRoute from "./modules/user/shared/userpublicroute";
import UserPrivateRoute from "./modules/user/shared/userprivateroute";
import ResetPassword from "./modules/user/pages/resetpwd";
import EventDetailsPage from "./modules/user/pages/EventDetails/eventDetailspage";
import UserTopics from "./modules/user/pages/activityform/topics";
import UserActivity from "./modules/user/pages/activityform/activityform";
import UserTopicsView from "./modules/user/pages/activityform/topicsView";
import SurveyTable from "./modules/user/pages/surve/surveytable";
import SurveyQuestions from "./modules/user/pages/surve/questionset";
import UserAnnouncements from "./modules/user/pages/announcements/announcements";
import UserNotifications from "./modules/user/pages/notification";


/** USER ROUTE END */

/**ADMIN ROUTE START */
import Adminlayout from "./modules/admin/shared/adminlayout";
import Login from "./modules/admin/pages/login";
import PrivateRoute from "./modules/admin/shared/privateRoute";
import PublicRoute from "./modules/admin/shared/publicRoute";
import Profile from "./modules/admin/pages/profile";
import EditProfile from "./modules/admin/pages/editprofile";
import Changepassword from "./modules/admin/pages/changepassword";
import OtpVerificationPage from "./modules/admin/pages/otpverification";
import ForgotPwdPage from "./modules/admin/pages/forgotpassword";
import ResetPwdPage from "./modules/admin/pages/resetpwd";
import Error404 from "./modules/admin/pages/404";
import Dashboard from "./modules/admin/pages/dashboard";
import PortalDesign from "./modules/admin/pages/portaldesign";
import EmailTemplatesTable from "./modules/admin/pages/cms/automaticemailtemplates/list";
import EmailTempAdd from "./modules/admin/pages/cms/automaticemailtemplates/add";
import EmailTempEdit from "./modules/admin/pages/cms/automaticemailtemplates/edit";
import ViewEmailTemp from "./modules/admin/pages/cms/automaticemailtemplates/view";
import PageTable from "./modules/admin/pages/cms/pages/list";
import PageAdd from "./modules/admin/pages/cms/pages/add";
import PageEdit from "./modules/admin/pages/cms/pages/edit";
import PageView from "./modules/admin/pages/cms/pages/view";
import GlobalSettings from "./modules/admin/pages/cms/settings/settings";
import QuestionSetTable from "./modules/admin/pages/questionset/list";
import QuestionSetAdd from "./modules/admin/pages/questionset/add";
import QuestionSetEdit from "./modules/admin/pages/questionset/edit";
import QuestionSetView from "./modules/admin/pages/questionset/view";
import QuestionAdd from "./modules/admin/pages/question/add";
import QuestionEdit from "./modules/admin/pages/question/edit";
import QuestionView from "./modules/admin/pages/question/view";
import TicketAdd from "./modules/admin/pages/ticket/add";
import TicketEdit from "./modules/admin/pages/ticket/edit";
import TicketView from "./modules/admin/pages/ticket/view";
import TicketTable from "./modules/admin/pages/ticket/list";
import EventsTable from "./modules/admin/pages/events/list";
import EventsAdd from "./modules/admin/pages/events/add";
import EventsEdit from "./modules/admin/pages/events/edit";
import View from "./modules/admin/pages/events/view";
import ViewEvent from "./modules/admin/pages/events/viewEvent";
import ViewHotels from "./modules/admin/pages/events/viewHotels";
import ViewAgenda from "./modules/admin/pages/events/viewAgenda";
import ViewRegistrations from "./modules/admin/pages/events/viewRegistrations";
import ViewInvitations from "./modules/admin/pages/events/viewInvitations";
import ViewAttendees from "./modules/admin/pages/events/viewAttendees";
import ViewRegistrationDetails from "./modules/admin/pages/events/viewRegistrationDetails";
import ViewInvoiceDetails from "./modules/admin/pages/events/viewInvoice";

import Event from "./modules/admin/pages/events/event";
import Hotels from "./modules/admin/pages/events/hotels";
import Agenda from "./modules/admin/pages/events/agenda";
import ActivitySetTable from "./modules/admin/pages/activityset/list";
import ActivitySetAdd from "./modules/admin/pages/activityset/add";
import ActivitySetEdit from "./modules/admin/pages/activityset/edit";
import ActivitySetView from "./modules/admin/pages/activityset/view";
import TopicAdd from "./modules/admin/pages/topic/add";
import TopicEdit from "./modules/admin/pages/topic/edit";
import TopicView from "./modules/admin/pages/topic/view";
import BannerTable from "./modules/admin/pages/cms/banners/list";
import BannerAdd from "./modules/admin/pages/cms/banners/add";
import BannerEdit from "./modules/admin/pages/cms/banners/edit";
import BannerView from "./modules/admin/pages/cms/banners/view";
import NewsLettersTable from "./modules/admin/pages/newsletters/list";
import NewsLettersAdd from "./modules/admin/pages/newsletters/add";
import NewsLettersEdit from "./modules/admin/pages/newsletters/edit";
import NewsLettersView from "./modules/admin/pages/newsletters/view";
import Galary from "./modules/admin/pages/galary/galary";
import GalaryDetails from "./modules/admin/pages/galary/galarydetails";
import UsersTable from "./modules/admin/pages/usermgmt/list";
import UsersView from "./modules/admin/pages/usermgmt/view";
import GalleryAdd from "./modules/admin/pages/galary/add";
import FrontRoot from "./modules/user/shared/frontroot";
import FAQ from "./modules/user/pages/faq";
import PrivacyPolicy from "./modules/user/pages/privacypolicy";
import TermsCondition from "./modules/user/pages/termscondition";
import PromotionsList from "./modules/admin/pages/newsletters/promotions/list";
import PromotionsView from "./modules/admin/pages/newsletters/promotions/view";
import InvitationsList from "./modules/admin/pages/events/Invitations/list";
import InvitationView from "./modules/admin/pages/events/Invitations/view";
import EmailLogs from "./modules/admin/pages/emaillogs/list";
import StaffTable from "./modules/admin/pages/staff/list";
import StaffAdd from "./modules/admin/pages/staff/add";
import StaffsEdit from "./modules/admin/pages/staff/edit";
import StaffView from "./modules/admin/pages/staff/view";
import EmailLogsView from "./modules/admin/pages/emaillogs/view";
import EmailTempAddNews from "./modules/admin/pages/cms/emailTemplate/add";
import EmailTempEditNews from "./modules/admin/pages/cms/emailTemplate/edit";
import EmailTemplatesTableNews from "./modules/admin/pages/cms/emailTemplate/list";
import ViewEmailTempNews from "./modules/admin/pages/cms/emailTemplate/view";
import CompleteProfile from "./modules/user/pages/completeprofile";
import DynamicPages from "./modules/user/pages/dynamicPages";
import Registration from "./modules/user/pages/Registration";
import RegistrationStepTwo from "./modules/user/pages/RegistrationStepTwo";
import UserChangePassword from "./modules/user/pages/changepassword";
import SharedFiles from "./modules/user/pages/sharedFiles";
import EventRegistrationList from "./modules/user/pages/eventRegistrationList";
import Error404User from "./modules/user/pages/user404";
import Settings from "./modules/user/pages/settings";
import UsersAdd from "./modules/admin/pages/usermgmt/add";
import UserEdit from "./modules/admin/pages/usermgmt/edit";
import CompleteAdminProfile from "./modules/admin/pages/completeAdminProfile";
import EventRegistrationView from "./modules/user/pages/eventRegistrationView";
import InvitationList from "./modules/user/pages/invitationList";
import RegistrationThanksPage from "./modules/user/pages/registrationThanksPage";
import InvitationRejectPage from "./modules/user/pages/invitationRejectPage";
import InvoiceDesign from "./modules/admin/pages/invoiceDesign";
import EditRegistrationDetail from "./modules/admin/pages/events/editRegistrationDetails";
import ViewTicketDetails from "./modules/admin/pages/events/checkIn-checkOut";
import ViewAttendeesDetails from "./modules/admin/pages/events/attendeesView";
import EditRegistrationDetailStepTwo from "./modules/admin/pages/events/EditRegistrationDetailStepTwo";
import Chat from './modules/user/pages/chatComponents/Chat/Chat';
import Join from './modules/user/pages/chatComponents/Join/Join';
import TransactionsTable from "./modules/admin/pages/Transactions/list";
import TransactionsView from "./modules/admin/pages/Transactions/view";
import TransactionList from "./modules/user/pages/transaction";
import EventTransactions from "./modules/admin/pages/events/eventTransactions";
import PushNotificationTable from "./modules/admin/pages/cms/pushNotificationTemplate/list";
import PushNotificationAdd from "./modules/admin/pages/cms/pushNotificationTemplate/add";
import ViewPushNotification from "./modules/admin/pages/cms/pushNotificationTemplate/view";
import PushNotificationEdit from "./modules/admin/pages/cms/pushNotificationTemplate/edit";
import LanguageFiles from "./modules/admin/pages/cms/languageFiles/languageFiles";
import FrontendFiles from "./modules/admin/pages/cms/languageFiles/frontendFiles";
import BackendFiles from "./modules/admin/pages/cms/languageFiles/backendFiles";
import EditLanguageFiles from "./modules/admin/pages/cms/languageFiles/editlanguageFiles";
import EventDetailsPreviewAdmin from "./modules/user/pages/EventDetails/eventDetailsPreviewAdmin";
import StyleCssFile from "./modules/admin/pages/cms/rootStyles/StyleCssFile";
import InvoicePaymentPage from "./modules/user/pages/inovicepaymentPage";
import UserViewAnnouncements from "./modules/user/pages/announcements/announcementsView";
import DistributionListTable from "./modules/admin/pages/newsletters/distributionlIst/list";
import DistributionListView from "./modules/admin/pages/newsletters/distributionlIst/view";
import DistributionAdd from "./modules/admin/pages/newsletters/distributionlIst/add";
import DistributionEdit from "./modules/admin/pages/newsletters/distributionlIst/edit";
import DistributionUsersList from "./modules/admin/pages/newsletters/distributionlIst/usersList";
import AnnouncementsTable from "./modules/admin/pages/Announcements/list";
import AnnouncementsAdd from "./modules/admin/pages/Announcements/add";
import AnnouncementsEdit from "./modules/admin/pages/Announcements/edit";
import AnnouncementsView from "./modules/admin/pages/Announcements/view";
import ViewAnnouncement from "./modules/admin/pages/events/viewAnnouncement";
import SurveyThanks from "./modules/user/pages/surve/SurveyThanks";


const router = createBrowserRouter([
  /** USER ROUTE START */
  {
    path: "/",
    element: <FrontRoot />,
    children: [
      {
        path: "/",
        element: <Userlayout />,

        children: [
          {
            path: "",
            element: <UserPrivateRoute component={Home} />,
          },
          {
            path: "org-info/:slug",
            element: <DynamicPages />,
          },
          {
            path: "dynamic-pages/:slug",
            element: <DynamicPages />,
          },
          {
            path: "settings",
            element: <UserPrivateRoute component={Settings} />,
            children: [
              {
                path: "",
                element: <UserPrivateRoute component={UserProfile} />,
              },
              {
                path: "profile",
                element: <UserPrivateRoute component={UserProfile} />,
              },
              {
                path: "change-password",
                element: <UserPrivateRoute component={UserChangePassword} />,
              },
              {
                path: "event-registrations/list/:pgno",
                element: <UserPrivateRoute component={EventRegistrationList} />,
              },
              {
                path: "event-registrations/view/:id",
                element: <UserPrivateRoute component={EventRegistrationView} />,
              },
              {
                path: "shared-files/:pgno",
                element: <UserPrivateRoute component={SharedFiles} />,
              },
              {
                path: "invitation-list/:pgno",
                element: <UserPrivateRoute component={InvitationList} />,
              },
              {
                path: "transaction-list/:pgno",
                element: <UserPrivateRoute component={TransactionList} />,
              }
            ]
          },
          {
            path: "activity-forum/:pgno",
            element: <UserPrivateRoute component={UserActivity} />
          },
          {
            path: "activity-forum/topic/:id",
            element: <UserPrivateRoute component={UserTopics} />,
          },
          {
            path: "activity-forum/topicView/:id",
            element: <UserPrivateRoute component={UserTopicsView} />,
          },
          {
            path: "survey/:pgno",
            element: (
              <UserPrivateRoute component={SurveyTable} />
            ),
          },
          {
            path: "survey/question/:id",
            element: <UserPrivateRoute component={SurveyQuestions} />,
          },
          {
            path: "survey/thanks",
            element: <UserPrivateRoute component={SurveyThanks} />,
          },
          {
            path: "event-detail/:id",
            element: <UserPrivateRoute component={EventDetailsPage} />,
          },
          {
            path: "event-preview/:slug",
            element: <EventDetailsPreviewAdmin />,
          },
          {
            path: "event-registration",
            element: <UserPrivateRoute component={Registration} />,
          },
          {
            path: "registration-thanks-page",
            element: <UserPrivateRoute component={RegistrationThanksPage} />,
          },
          {
            path: "invoice-payment-page",
            element: <UserPrivateRoute component={InvoicePaymentPage} />,
          },
          {
            path: "invitation-reject-page",
            element: <UserPrivateRoute component={InvitationRejectPage} />,
          },
          {
            path: "event-registration-step-2",
            element: <UserPrivateRoute component={RegistrationStepTwo} />,
          },
          {
            path: "announcements/list/:pgno",
            element: <UserPrivateRoute component={UserAnnouncements} />
          },
          {
            path: "announcements/details/:id",
            element: <UserPrivateRoute component={UserViewAnnouncements} />
          },
          {
            path: "notifications/:pgno",
            element: <UserPrivateRoute component={UserNotifications} />
          },
          {
            path: "faq",
            element: <FAQ />,
          },
          {
            path: "privacy-policy",
            element: <PrivacyPolicy />,
          },
          {
            path: "users/:pgno",
            element: <UserPrivateRoute component={Join}/>,
          },
          {
            path: "chat",
            element: <UserPrivateRoute component={Chat}/>,
          },
          {
            path: "terms-condition",
            element: <TermsCondition />,
          },
          {
            path: "/login",
            element: <UserLogin />
          },
          {
            path: "/complete-profile",
            element: <UserPublicRoute component={CompleteProfile} />
          },
          {
            path: "/forget-password",
            element: <UserPublicRoute component={UserForgetPassword} />,
          },
          {
            path: "/otp-verificaiton",
            element: <UserOtpVerification />,
          },
          {
            path: "/reset-password",
            element: <UserPublicRoute component={ResetPassword} />,
          },
          { path: "/404", element: <Error404User /> },
          { path: "*", element: <Error404User /> },
        ],
      },
    ],
    /* For User section */
  },

  /**USER ROUTE END */

  /**ADMIN ROUTE START */
  {
    path: "admin",
    element: <Adminlayout />,
    children: [
      {
        path: "",
        element: <Navigate to={"/admin/dashboard"} />,
      },
      {
        path: "dashboard",
        element: <PrivateRoute component={Dashboard} route={'/admin/dashboard'} />,
      },
      {
        path: "invoice-design",
        element: <PrivateRoute route={"/admin/invoice-design"} component={InvoiceDesign} />,
      },
      {
        path: "profile",
        element: <PrivateRoute component={Profile} route={'/admin/profile'} />,
        children: [
          {
            path: "edit/:id",
            element: <PrivateRoute component={EditProfile} route={'/admin/profile/edit/*'} />,
          },
          {
            path: "change-password/:id",
            element: <PrivateRoute component={Changepassword} route={'/admin/profile/change-password/*'} />,
          },
        ],
      },

      {/**user management route start */ },
      {
        path: "user-management/users/list/:pgno",
        element: <PrivateRoute component={UsersTable} route={'/admin/user-management/users/list/*'} />,
      },
      {
        path: "user-management/users/view/:id",
        element: <PrivateRoute component={UsersView} route={'/admin/user-management/users/view/*'} />,
      },
      {
        path: "user-management/users/add",
        element: <PrivateRoute component={UsersAdd} route={'/admin/user-management/users/add'} />,
      },
      {
        path: "user-management/users/:pgno/edit/:id",
        element: <PrivateRoute component={UserEdit} route={'/admin/user-management/users/*/edit/*'} />,
      },
      {/**user management route end */ },


      {/**staff route start */ },
      {
        path: "staff/list/:pgno",
        element: <PrivateRoute component={StaffTable} route={'/admin/staff/list/*'} />,
      },
      {
        path: "staff/add",
        element: <PrivateRoute component={StaffAdd} route={'/admin/staff/add'} />,
      },
      {
        path: "staff/:pgno/edit/:id",
        element: <PrivateRoute component={StaffsEdit} route={'/admin/staff/*/edit/*'} />,
      },
      {
        path: "staff/view/:id",
        element: <PrivateRoute component={StaffView} route={'/admin/staff/view/*'} />,
      },
      {/**staff route end */ },



      {/**Event management route start */ },
      {
        path: "event-management/events/list/:pgno",
        element: <PrivateRoute component={EventsTable} route={'/admin/event-management/events/list/*'} />,
      },
      {
        path: "event-management/events/add",
        element: <PrivateRoute component={EventsAdd} route={'/admin/event-management/events/add'} />,
        children: [
          {
            path: "",
            element: <PrivateRoute component={Event} route={'/admin/event-management/events/add'} />,
          },
          {
            path: ":id",
            element: <PrivateRoute component={Event} route={'/admin/event-management/events/add/*'} />,
          },
          {
            path: "hotels/:id",
            element: <PrivateRoute component={Hotels} route={'/admin/event-management/events/add/hotels/*'} />,
          },
          {
            path: "agenda/:id",
            element: <PrivateRoute component={Agenda} route={'/admin/event-management/events/add/agenda/*'} />,
          },
        ],
      },
      {
        path: "event-management/events/edit",
        element: <PrivateRoute component={EventsEdit} route={'/admin/event-management/events/edit'} />,
        children: [
          {
            path: ":id",
            element: <PrivateRoute component={Event} route={'/admin/event-management/events/edit/*'} />,
          },
          {
            path: "hotels/:id/:pgno?",
            element: <PrivateRoute component={Hotels} route={'/admin/event-management/events/edit/hotels/*/*'} />,
          },
          {
            path: "agenda/:id/:pgno?",
            element: <PrivateRoute component={Agenda} route={'/admin/event-management/events/edit/agenda/*/*'} />,
          },
        ],
      },
      {
        path: "event-management/events/view",
        element: <PrivateRoute component={View} route={'/admin/event-management/events/view'} />,
        children: [
          {
            path: "event/:id",
            element: <PrivateRoute component={ViewEvent} route={'/admin/event-management/events/view/event/*'} />,
          },
          {
            path: "hotels/:id/:pgno",
            element: <PrivateRoute component={ViewHotels} route={'/admin/event-management/events/view/hotels/*/*'} />,
          },
          {
            path: "agenda/:id/:pgno",
            element: <PrivateRoute component={ViewAgenda} route={'/admin/event-management/events/view/agenda/*/*'} />,
          },
          {
            path: "registrations/:id/:pgno",
            element: <PrivateRoute component={ViewRegistrations} route={'/admin/event-management/events/view/registrations/*/*'} />,
          },
          {
            path: "invitations/:id",
            element: <PrivateRoute component={ViewInvitations} route={'/admin/event-management/events/view/invitations/*'} />,
          },
          {
            path: "event-transactions/:id/:pgno",
            element: <PrivateRoute component={EventTransactions} route={'/admin/event-management/events/view/event-transactions/*/*'} />,
          },
          {
            path: "attendees/:id/:pgno",
            element: <PrivateRoute component={ViewAttendees} route={'/admin/event-management/events/view/attendees/*/*'} />,
          },
          {
            path: "event-announcements/:id/:pgno",
            element: <PrivateRoute component={ViewAnnouncement} route={'/admin/event-management/events/view/event-announcements/*/*'} />,
          },
          {
            path: "checkIn-checkOut/:id/:pgno",
            element: <PrivateRoute component={ViewTicketDetails} route={'/admin/event-management/events/view/checkIn-checkOut/*/*'} />,
          },
        ],
      },
      {
        path: "event-management/events/view/:registrationId",
        element: <PrivateRoute component={ViewRegistrationDetails} route={'/admin/event-management/events/view/*'} />,
      },
      {
        path: "event-management/events/view/invoice/:registrationId",
        element: <PrivateRoute component={ViewInvoiceDetails} route={'/admin/event-management/events/view/invoice/*'} />,
      },
      {
        path: "event-management/events/registration-edit/:registrationId",
        element: <PrivateRoute component={EditRegistrationDetail} route={'/admin/event-management/events/registration-edit/*'} />,
      },
      {
        path: "event-management/events/view/attendees/:attendeesId",
        element: <PrivateRoute component={ViewAttendeesDetails} route={'/admin/event-management/events/attendees/*'} />,
      },


      
      {
        path: "event-management/events/registration-edit-step-two",
        element: <PrivateRoute component={EditRegistrationDetailStepTwo} route={'/admin/event-management/events/registration-edit-step-two'} />,
      },
      {
        path: "event-management/tickets/list/:pgno",
        element: <PrivateRoute component={TicketTable} />,
      },
      {
        path: "event-management/tickets/add",
        element: <PrivateRoute component={TicketAdd} />,
      },
      {
        path: "event-management/tickets/:pgno/edit/:id",
        element: <PrivateRoute component={TicketEdit} />,
      },
      {
        path: "event-management/tickets/view/:id",
        element: <PrivateRoute component={TicketView} />,
      },
      {
        path: "event-management/invitations/list/:pgno",
        element: <PrivateRoute component={InvitationsList} />,
      },
      {
        path: "event-management/invitations/view/:id",
        element: <PrivateRoute component={InvitationView} />,
      },
      {
      /**Event Management Route End */
      },

      {
      /**Cms Route Start */
      },
      {
        path: "cms/pages/list/:pgno",
        element: <PrivateRoute component={PageTable} route={'/admin/cms/pages/list/*'} />,
      },
      {
        path: "cms/pages/add",
        element: <PrivateRoute component={PageAdd} route={'/admin/cms/pages/add'} />
      },
      {
        path: "cms/pages/:pgno/edit/:id",
        element: <PrivateRoute component={PageEdit} route={'/admin/cms/pages/*/edit/*'} />,
      },
      {
        path: "cms/pages/view/:id",
        element: <PrivateRoute component={PageView} route={'/admin/cms/pages/view/*'} />,
      },

      {
        path: "cms/banner/list/:pgno",
        element: <PrivateRoute component={BannerTable} route={'/admin/cms/banner/list/*'} />,
      },
      {
        path: "cms/banner/add",
        element: <PrivateRoute component={BannerAdd} route={'/admin/cms/banner/add'} />,
      },
      {
        path: "cms/banner/:pgno/edit/:id",
        element: <PrivateRoute component={BannerEdit} route={'/admin/cms/banner/*/edit/*'} />,
      },
      {
        path: "cms/banner/view/:id",
        element: <PrivateRoute component={BannerView} route={'/admin/cms/banner/view/*'} />,
      },

      {
        path: "cms/settings",
        element: <PrivateRoute component={GlobalSettings} route={'/admin/cms/settings'} />,
      },
      {
        path: "cms/language-files",
        element: <PrivateRoute component={LanguageFiles} route={'/admin/cms/language-files'} />,
        children: [
          {
            path: "frontend",
            element: <PrivateRoute component={FrontendFiles} route={'/admin/cms/language-files/frontend'} />,
          },
          {
            path: "backend",
            element: <PrivateRoute component={BackendFiles} route={'/admin/cms/language-files/backend'} />,
          },
          {
            path: "edit",
            element: <PrivateRoute component={EditLanguageFiles} route={'/admin/cms/language-files/edit'} />,
          },
        ],
      },
      {
        path: "cms/style-files",
        element: <PrivateRoute component={StyleCssFile} route={'/admin/cms/style-files'} />,
      },
      {
        path: "cms/portal-design",
        element: <PrivateRoute component={PortalDesign} route={'/admin/cms/portal-design'} />,
      },

      {
        path: "cms/default-email-template/list/:pgno",
        element: <PrivateRoute component={EmailTemplatesTable} route={'/admin/cms/default-email-template/list/*'} />,
      },
      {
        path: "cms/default-email-template/add",
        element: <PrivateRoute component={EmailTempAdd} route={'/admin/cms/default-email-template/add'} />,
      },
      {
        path: "cms/default-email-template/:pgno/edit/:id",
        element: <PrivateRoute component={EmailTempEdit} route={'/admin/cms/default-email-template/*/edit/*'} />,
      },
      {
        path: "cms/default-email-template/view/:id",
        element: <PrivateRoute component={ViewEmailTemp} route={'/admin/cms/default-email-template/view/*'} />,
      },

      {
        path: "cms/push-notification-template/list/:pgno",
        element: <PrivateRoute component={PushNotificationTable} route={'/admin/cms/push-notification-template/list/*'} />,
      },
      {
        path: "cms/push-notification-template/add",
        element: <PrivateRoute component={PushNotificationAdd} route={'/admin/cms/push-notification-template/add'} />,
      },
      {
        path: "cms/push-notification-template/:pgno/edit/:id",
        element: <PrivateRoute component={PushNotificationEdit} route={'/admin/cms/push-notification-template/*/edit/*'} />,
      },
      {
        path: "cms/push-notification-template/view/:id",
        element: <PrivateRoute component={ViewPushNotification} route={'/admin/cms/push-notification-template/view/*'} />,
      },

      {
        path: "cms/promotional-email-templates/list/:pgno",
        element: <PrivateRoute component={EmailTemplatesTableNews} route={'/admin/cms/promotional-email-templates/list/*'} />,
      },
      {
        path: "cms/promotional-email-templates/add",
        element: <PrivateRoute component={EmailTempAddNews} route={'/admin/cms/promotional-email-templates/add'} />,
      },
      {
        path: "cms/promotional-email-templates/:pgno/edit/:id",
        element: <PrivateRoute component={EmailTempEditNews} route={'/admin/cms/promotional-email-templates/*/edit/*'} />,
      },
      {
        path: "cms/promotional-email-templates/view/:id",
        element: <PrivateRoute component={ViewEmailTempNews} route={'/admin/cms/promotional-email-templates/view/*'} />,
      },
      {
      /* cms route end */
      },

      {
      /**Question Route start */
      },
      {
        path: "question-settings/sets/list/:pgno",
        element: <PrivateRoute component={QuestionSetTable} route={'/admin/question-settings/sets/list/*'} />,
      },
      {
        path: "question-settings/sets/add",
        element: <PrivateRoute component={QuestionSetAdd} route={'/admin/question-settings/sets/add'} />,
      },
      {
        path: "question-settings/sets/:pgno/edit/:id",
        element: <PrivateRoute component={QuestionSetEdit} route={'/admin/question-settings/sets/*/edit/*'} />,
      },
      {
        path: "question-settings/sets/view/:id",
        element: <PrivateRoute component={QuestionSetView} route={'/admin/question-settings/sets/view/*'} />,
      },
      {
        path: "question-settings/questions/add",
        element: <PrivateRoute component={QuestionAdd} route={'/admin/question-settings/questions/add'} />,
      },
      {
        path: "question-settings/questions/:pgno/edit/:id",
        element: <PrivateRoute component={QuestionEdit} route={'/admin/question-settings/questions/*/edit/*'} />,
      },
      {
        path: "question-settings/questions/view/:id",
        element: <PrivateRoute component={QuestionView} route={'/admin/question-settings/questions/view/*'} />,
      },
      {
      /** Question route end */
      },

      {
      /** Activities Route start */
      },
      {
        path: "activity-forum/activities/list/:pgno",
        element: <PrivateRoute component={ActivitySetTable} route={'/admin/activity-forum/activities/list/*'} />,
      },
      {
        path: "activity-forum/activities/add",
        element: <PrivateRoute component={ActivitySetAdd} route={'/admin/activity-forum/activities/add'} />,
      },
      {
        path: "activity-forum/activities/:pgno/edit/:id",
        element: <PrivateRoute component={ActivitySetEdit} route={'/admin/activity-forum/activities/*/edit/*'} />,
      },
      {
        path: "activity-forum/activities/view/:id",
        element: <PrivateRoute component={ActivitySetView} route={'/admin/activity-forum/activities/view/*'} />,
      },
      {
        path: "activity-forum/topics/add",
        element: <PrivateRoute component={TopicAdd} route={'/admin/activity-forum/topics/add'} />,
      },
      {
        path: "activity-forum/topics/:pgno/edit/:id",
        element: <PrivateRoute component={TopicEdit} route={'/admin/activity-forum/topics/*/edit/*'} />,
      },
      {
        path: "activity-forum/topics/view/:id",
        element: <PrivateRoute component={TopicView} route={'/admin/activity-forum/topics/view/*'} />,
      },
      {
      /**Activities Route End */
      },
      {
      /* News-letter Route Start */
      },
      {
        path: "news-letter/list/:pgno",
        element: <PrivateRoute component={NewsLettersTable} route={'/admin/news-letter/list/*'} />,
      },
      {
        path: "news-letter/add",
        element: <PrivateRoute component={NewsLettersAdd} route={'/admin/news-letter/add'} />,
      },
      {
        path: "news-letter/:pgno/edit/:id",
        element: <PrivateRoute component={NewsLettersEdit} route={'/admin/news-letter/*/edit/*'} />,
      },
      {
        path: "news-letter/view/:id/:pgno",
        element: <PrivateRoute component={NewsLettersView} route={'/admin/news-letter/view/*/*'} />,
      },

      {
        path: "news-letter/promotions/:pgno",
        element: <PrivateRoute component={PromotionsList} />,
      },
      {
        path: "news-letter/promotions/view/:id",
        element: <PrivateRoute component={PromotionsView} />,
      },
      /* Distribution List Route Start */
      {
        path: "distribution-list/list/:pgno",
        element: <PrivateRoute component={DistributionListTable} route={'/admin/distribution-list/list/*'} />,
      },
      {
        path: "distribution-list/add",
        element: <PrivateRoute component={DistributionAdd} route={'/admin/distribution-list/add'} />,
      },
      {
        path: "distribution-list/:pgno/edit/:id",
        element: <PrivateRoute component={DistributionEdit} route={'/admin/distribution-list/*/edit/*'} />,
      },
      {
        path: "distribution-list/view/:id",
        element: <PrivateRoute component={DistributionListView} route={'/admin/distribution-list/view/*'} />,
      },
      {
        path: "distribution-list/users/list/:id/:pgno",
        element: <PrivateRoute component={DistributionUsersList} route={'/admin/distribution-list/view/*/*'} />,
      },
      /* Distribution List Route End */
      {
      /* News-letter Route End */
      },

      /** Announcements Routing */
      {
        path: "announcements/list/:pgno",
        element: <PrivateRoute component={AnnouncementsTable} route={'/admin/announcements/list/*'} />,
      },
      {
        path: "announcements/add",
        element: <PrivateRoute component={AnnouncementsAdd} route={'/admin/announcements/add'} />,
      },
      {
        path: "announcements/:pgno/edit/:id",
        element: <PrivateRoute component={AnnouncementsEdit} route={'/admin/announcements/*/edit/*'} />,
      },
      {
        path: "announcements/view/:id",
        element: <PrivateRoute component={AnnouncementsView} route={'/admin/announcements/view/*'} />,
      },
      /** Announcements Routing End*/

      /* Transactions Routing Start */

      {
        path: "transactions/list/:pgno",
        element: <PrivateRoute component={TransactionsTable} route={'/admin/transactions/list/*'} />,
      },

      { path: "transactions/view/:id", element: <PrivateRoute component={TransactionsView} route={'/admin/transactions/view/*'} /> },

      /* Transactions Routing End */

      /** Media Routing Start*/
      { path: "media", element: <PrivateRoute component={Galary} route={'/admin/media'} /> },

      { path: "media/add", element: <PrivateRoute component={GalleryAdd} route={'/admin/media/add'} /> },

      {
        path: "media/details",
        element: <PrivateRoute component={GalaryDetails} route={'/admin/media/details'} />,
      },

      /** Media Routing End*/

      /** Email Logs Routing Start*/
      { path: "email-logs/list/:pgno", element: <PrivateRoute component={EmailLogs} route={'/admin/email-logs/list/*'} /> },

      { path: "email-logs/view/:id", element: <PrivateRoute component={EmailLogsView} route={'/admin/email-logs/view/*'} /> },

      /** Email Logs Routing End*/
    ],


  },
  { path: "admin/login", element: <PublicRoute component={Login} /> },
  { path: "admin/complete-profile/:validate_string", element: <PublicRoute component={CompleteAdminProfile} /> },
  {
    path: "admin/forget-password",
    element: <PublicRoute component={ForgotPwdPage} />,
  },
  {
    path: "admin/otp-verification/:email",
    element: <PublicRoute component={OtpVerificationPage} />,
  },
  {
    path: "admin/reset-password",
    element: <PublicRoute component={ResetPwdPage} />,
  },
  { path: "admin/*", element: <Error404 /> },


  /**ADMIN ROUTE END */



]);

export default router;
