import axios from "axios";
import { getUser, logOutUser, setSessionKey, getLocalKey } from '../utils/commonfunction';
import { SWAL_SETTINGS } from '../utils/Constants';
import Swal from "sweetalert2";

const Axios = (history = null) => {
  const baseURL = process.env.REACT_APP_API_URL
  let headers = {
    language : getLocalKey('system_language') ? getLocalKey('system_language'): 'en'
  };
  
  try{
    if(getUser() && getUser().token){
      headers.Authorization = `Bearer ${getUser().token}`;
    }
  }catch(err){
    console.log(err);
  }

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers
  });

  axiosInstance.interceptors.response.use((response) =>
    new Promise((resolve, reject) => {
      resolve(response.data);
    }), (error) => {
      console.error("erroree -----> ", error);
      if (error && error.response && error.response.status && error.response.status === 401) {
        setSessionKey("session_expired", "true");
        localStorage.setItem("landingpage", window.location.pathname);
        localStorage.setItem("landingpagesearch", window.location.search);
        Swal.fire({
          icon: "error",
          text: error?.response?.data?.message || "Session is expired please login again..." ,
          ...SWAL_SETTINGS,
        });
        if(document.getElementById("logout-functionality")){
          document.getElementById("logout-functionality").click();
        }
      }
    }
  );

  return axiosInstance;
};

export default Axios;