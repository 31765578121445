import React, { useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
import Swal from "sweetalert2"
import {  useNavigate } from 'react-router-dom'
import * as galaryService from '../../services/galary.services';
import Breadcrums from '../../common/breadcrumbs';
import { SWAL_SETTINGS } from '../../../../utils/Constants';
import { handleServerValidations } from '../../../../utils/commonfunction';
import * as Yup from 'yup';

const GalleryAdd = () => {
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Media", url: "/admin/media" }, { title: "Add", url: "" },]

    const validationSchema = Yup.object().shape({
        imagesfiles: Yup.mixed().required('Files is required').test(
            'fileType',
            'Files is required',
            (value) => {
                if (value.length === 0) {
                    return false
                } else {
                    return true;
                }
            }
        ),
    });



    // Handle multiple image deletion
    const handleImageDelete = (arr, name, setFieldValue) => {
        const remainingimages = Object.values(arr).filter((i) => i.name !== name)
        setFieldValue('imagesfiles', remainingimages)
    };


    function showfilesIcons(file) {
        const fileName = file.name
        const fileNameArr = fileName.split('.');
        const fileExt = fileNameArr[fileNameArr.length - 1]
        if (fileExt === "pdf") {
            return <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
        } else if (fileExt === "zip" || fileExt === "tar" || fileExt === "rar") {
            return <i className="fa fa-file-archive-o" aria-hidden="true"></i>
        }
        else if (fileExt === "docx") {
            return <i className="fa fa-file-word-o" aria-hidden="true"></i>
        } else if (fileExt === "xls" || fileExt === "xlsx") {
            return <i className="fa fa-file-excel-o" aria-hidden="true"></i>
        } else if (fileExt === "mp3" || fileExt === "wav") {
            return <i className="fa fa-file-audio-o" aria-hidden="true"></i>
        } else if (fileExt === "video") {
            return <i className="fa fa-file-video-o" aria-hidden="true"></i>
        } else if (fileExt === 'gif') {
            return <i className="fa fa-gift" aria-hidden="true"></i>
        } else {
            return <i className="fa fa-file" aria-hidden="true"></i>
        }
    }


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    imagesfiles: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitted(true)
                    let formData = new FormData();
                    for (let i = 0; i < values.imagesfiles.length; i++) {
                        formData.append('gallery_images', values.imagesfiles[i])
                    }
                    galaryService.Add(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                resetForm({ values: '' });
                                navigate(`/admin/media`)
                            }, 2000);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                            setSubmitted(false)
                        }
                    }).catch(error => {
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Add Files</h6>
                                    </div>
                                    <div className="row row-sm">
                                        {/* multiple files */}
                                        <div className="ff_fileupload_wrap position-relative">
                                            <input
                                                className='multiimgstyle cp'
                                                id="imagesfiles"
                                                multiple name="imagesfiles"
                                                type="file"
                                                onChange={(event) => {
                                                    setFieldValue("imagesfiles", event.currentTarget.files)
                                                }} />
                                            <div className="ff_fileupload_dropzone_wrap">
                                                <label className='text-left d-flex' htmlFor="newimagesfiles">Multiple files:</label>
                                                <button type='button' className='ff_fileupload_dropzone cp'></button>
                                            </div>
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"imagesfiles"} /></span>
                                        </div>
                                        <div className='mt-3 file_preview_container'>
                                            {values.imagesfiles.length > 0 &&
                                                Object.values(values.imagesfiles).map((file) => (
                                                    file.type.includes("image") ?
                                                        <div className='file_preview_wrapper' key={file.name}>
                                                            <span className='file_preview_close' onClick={() => handleImageDelete(values.imagesfiles, file.name, setFieldValue)}>
                                                                <i className="fa fa-times" aria-hidden="true"></i>
                                                            </span>
                                                            <img
                                                                src={URL.createObjectURL(file)}
                                                                alt={file.name}
                                                                style={{ height: "100px" }}
                                                                className="preview-image mx-2"
                                                            />
                                                        </div>
                                                        :
                                                        <div className='file_preview_wrapper' key={file.name}>
                                                            <div className='file_icon_preview'>
                                                                {showfilesIcons(file)}
                                                            </div>
                                                            <span className='file_preview_close' onClick={() => handleImageDelete(values.imagesfiles, file.name, setFieldValue)}>
                                                                <i className="fa fa-times" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                ))}
                                        </div>
                                        {/* multiple files end */}
                                        <div className="mt-5" >
                                            <button className="btn btn-main-primary signbtn mr-2" type="submit" disabled={submitted ? true : null}>
                                                {submitted ? <span aria-hidden="true" className="spinner-border spinner-border-sm mx-1" role="status" /> : <i className="ace-icon fa fa-check bigger-110 mx-1"></i>}
                                                Submit
                                            </button>
                                            <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>
    )
}

export default GalleryAdd