import React, { useEffect, useState } from 'react'
import { Field, Formik } from 'formik'
import Swal from "sweetalert2"
import { useLocation, useNavigate } from 'react-router-dom'
import * as questionSetServices from '../../services/questionset';
import Breadcrums from '../../common/breadcrumbs';
import CustomCkeditor from '../../common/customeditor';
import {  SURVEY_TYPES, SWAL_SETTINGS } from '../../../../utils/Constants';
import { handleServerValidations, hasPermission, removeSpecialCharacter } from '../../../../utils/commonfunction';
import { useSelector } from 'react-redux';


const QuestionSetAdd = (props) => {
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const location = useLocation();
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Question Set", url: "/admin/question-settings/sets/list/1" }, { title: "Add", url: "" },]
    const [initialValues, setInitialValues] = useState({
        title: "",
        title_de: "",
        event: "",
        type: "",
        richtext: "",
        richtext_de: ""
    });

    let globalData = useSelector((state) => state.globaldetail);

    const myGlobalData = useSelector((state) => state.globalData);
    const [getGlobalData, setGlobalData] = useState({});
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        setGlobalData(JSON.parse(myGlobalData?.data));
      }
    },[myGlobalData]);

    useEffect(() => {
        setInitialValues({
            title: location?.state?.title ? location?.state?.title : "",
            title_de: location?.state?.title_de ? location?.state?.title_de : "",
            type: location?.state?.type ? location?.state?.type : "",
            richtext: location?.state?.description ? location?.state?.description : "",
            richtext_de: location?.state?.description_de ? location?.state?.description_de : ""
        })
        setDataLoaded(true);
    }, []);

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            {dataLoaded ? (
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validateOnBlur={false}
                    validate={values => {
                        const error = {};
                        if (!values.title || !values.title.trim()) error.title = "Title is required";
                        if (!values.type) error.type = "Type is required";
                        if (!values.richtext || !values.richtext.trim()) error.richtext = "Description is required";
                        return error;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitted(true)
                        let formData = new FormData();
                        formData.append('title', values.title);
                        formData.append('title_de', values.title_de);
                        formData.append('type', values.type);
                        formData.append('description', values.richtext);
                        formData.append('description_de', values.richtext_de);
                        questionSetServices.Add(formData).then(response => {
                            setSubmitting(false);
                            if (response.success) {
                                Swal.fire({
                                    icon: 'success',
                                    text: response.message,
                                    ...SWAL_SETTINGS
                                })
                                setTimeout(() => {
                                    resetForm({ values: '' });
                                    navigate(`/admin/question-settings/questions/add`, { state: response.data._id })
                                }, 2000);

                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    text: handleServerValidations(response),
                                    ...SWAL_SETTINGS
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    }}

                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row row-sm">
                            <div className="col-lg-12 col-md-12 animation_fade">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div>
                                            <h6 className="main-content-label mb-3">Add Question Type</h6>
                                        </div>
                                        <div className="row row-sm">
                                            <div className='col-md-6 text-center form-group'>
                                                <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span> </label>
                                                <input name='title' id='title' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.title && touched.title && errors.title}</span>
                                            </div>
                                            <div className='col-md-6 text-center form-group'>
                                                <label htmlFor='title_de' className='text-left d-flex'>Title In German:</label>
                                                <input name='title_de' id='title_de' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title_de} className='form-control' />
                                                {/* <span className='text-danger d-flex text-left'>{errors.title_de && touched.title_de && errors.title_de}</span> */}
                                            </div>
                                            <div className="col-lg-6 text-center form-group">
                                                <label htmlFor='type' className='text-left d-flex'>Type:<span className="requirestar">*</span> </label>
                                                <div className="form-group select-down-arrow">
                                                    <Field as='select' id="type" name={"type"} className="form-control select2 text-capitalize">
                                                        <option value="" label="Select Type">Select{" "}</option>
                                                        {getGlobalData?.settings?.surveys === false ? (
                                                            <option value={SURVEY_TYPES.QUESTION_SET}>
                                                                {removeSpecialCharacter('Question-Set')}
                                                            </option>
                                                        ) : (
                                                            <>
                                                                {hasPermission('/admin/question-settings/sets/question-set') && (
                                                                    <option value={SURVEY_TYPES.QUESTION_SET}>
                                                                        {removeSpecialCharacter('Question-Set')}
                                                                    </option>
                                                                )}
                                                                {hasPermission('/admin/question-settings/sets/survey') && (
                                                                    <option value={SURVEY_TYPES.SURVEY}>
                                                                        {removeSpecialCharacter('Survey')}
                                                                    </option>
                                                                )}
                                                            </>
                                                        )}
                                                    </Field>
                                                </div>
                                                <span className='text-danger d-flex text-left'>{errors.type && touched.type && errors.type}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                                                <CustomCkeditor
                                                    fieldname={"richtext"}
                                                    setFieldValue={setFieldValue}
                                                    value={values.richtext}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                                <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='subject' className='text-left d-flex'>Description In German:</label>
                                                <CustomCkeditor
                                                    fieldname={"richtext_de"}
                                                    setFieldValue={setFieldValue}
                                                    value={values.richtext_de}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                                {/* <span className='text-danger d-flex text-left'>{errors.richtext_de && touched.richtext_de && errors.richtext_de}</span> */}
                                            </div>
                                            <div className="">
                                                <button className="btn btn-main-primary signbtn mr-2" type="submit" disabled={submitted ? true : null}>
                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                    Submit
                                                </button>
                                                <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                    <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
                </Formik>
            ) : (
                <></>
            )}
        </>
    )
}

export default QuestionSetAdd