import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { globalSetting } from "../../modules/user/services/common.services";

export const fetchDetailsApi = createAsyncThunk('data/fetchDetailsApi', async () => {
    // let res = await globalSetting();
    // return res?.data;
  });

export const GlobalDetailsSlice = createSlice({
    name:'globaldetail',
    initialState:{
        loading: false,
        data: null,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDetailsApi.pending,(state) => {
            state.loading = true
            state.data = null
            state.error = null
        })
        .addCase(fetchDetailsApi.fulfilled, (state,action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
        .addCase(fetchDetailsApi.rejected, (state,action) => {
            state.loading = false
            state.data = null
            state.error = action.error.message
        })
    }
})

export default GlobalDetailsSlice.reducer;