import axiosInstance from "../../../utils/axios";

const path = "admin/user"

export const List = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const Add = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}

export const Edit = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}

export const Delete = async (values) => {
    return await axiosInstance().post(`${path}/delete`, values)
}

export const Details = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
}

export const Status = async (values) => {
    return await axiosInstance().post(`${path}/change-status`, values)
}

export const Subscribe = async (values) => {
    return await axiosInstance().post(`${path}/news-letter-toggle`, values)
}

export const AssignMedia = async (values) => {
    return await axiosInstance().post(`${path}/assign-media`, values);
}

export const ListByIds = async (values) => {
    return await axiosInstance().post(`${path}/list-by-ids`, values)
}

export const Transactions = async (values) => {
    return await axiosInstance().post(`${path}/transaction-list`, values)
}

export const ListChats = async (values) => {
    return await axiosInstance().post(`${path}/chat-list`, values);
}

export const ChatDelete = async (values) => {
    return await axiosInstance().post(`${path}/messages-delete`, values)
}

export const ChatDetails = async (id) => {
    return await axiosInstance().get(`${path}/get-messages?room=${id}`)
}