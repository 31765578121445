import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Swal from "sweetalert2";
import * as authService from '../services/auth.services';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { handleServerValidations } from '../../../utils/commonfunction';
import {
  SWAL_SETTINGS,
  ATLEAST_ONE_CAPITAL_REGEX,
  ATLEAST_ONE_NUMBER_REGEX,
  ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  ATLEAST_ONE_SMALL_REGEX
} from '../../../utils/Constants';
import { useSelector } from 'react-redux';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState('false');
  const [conformshow, setConformShow] = useState('false');
  const { t } = useTranslation();
  const [getGlobalData, setGlobalData] = useState({});
  const myGlobalData = useSelector((state) => state.globalData);
  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  },[myGlobalData]);


  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('web_login_err_password_required'))
      .matches(ATLEAST_ONE_SMALL_REGEX, t('web_lbl_err_password_lower'))
      .matches(ATLEAST_ONE_CAPITAL_REGEX, t('web_lbl_err_password_capital'))
      .matches(ATLEAST_ONE_NUMBER_REGEX, t('web_lbl_err_password_number'))
      .matches(ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, t('web_lbl_err_password_special_character'))
      .min(PASSWORD_MIN_LENGTH, t('web_lbl_err_password_8_character_long'))
      .max(PASSWORD_MAX_LENGTH, t('web_lbl_err_password_more_than')),
    confirmPassword: Yup.string().required(t('web_lbl_err_confirm_password')).oneOf([Yup.ref("password"), null], t('web_lbl_err_password_and_confirm')),
  })

  const handleshow = (event) => {
    if (event === "pwd") {
      setShow(!show)
    }
    if (event === "confirepwd") {
      setConformShow(!conformshow)
    }
  }

  return (

    <>
      {/* <Formik
                initialValues={{ password: '', confirmPassword: "" }}
                validate={values => {
                    const errors = {};
                    if (!values.password) {
                        errors.password = 'Password is required';
                    }
                    if (!values.confirmPassword) {
                        errors.confirmPassword = 'Confirm Password is required';
                    }
                    if (values.password != values.confirmPassword) errors.confirmPassword = "Password does not match"
                    return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm}) => {
                    setLoader(true)
                    let formData = new FormData();
                    formData.append("new_password", values.password);
					formData.append("c_password", values.confirmPassword)
                    formData.append("validate_string", location.state)
                    authService.resetPassword(formData).then(response => {
						if (response.success) {
							Swal.fire({
								icon: 'success',
								text: response.message,
								...SWAL_SETTINGS
							})
							setTimeout(() => {
								resetForm()
								navigate("/login");
							}, 2000);
						} else {
							Swal.fire({
								icon: 'error',
								text: handleServerValidations(response),
								...SWAL_SETTINGS
							})
						}
					}).catch(error => {
						console.log("error: ", error);
					})
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    // and other goodies
                }) => (
                    <div className='page user-main-signin-wrapper'>
                        <div className='innerbody'>
                            <div className="row signpages text-center">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="row">
                                            <div className='col-12'>
                                                <div className="card-body mt-2 mb-2">
                                                    <div className="clearfix"></div>
                                                    <form onSubmit={handleSubmit}>
                                                        <h5 className="text-center mb-2">RESET PASSWORD</h5>
                                                        <div className="form-group text-start mb-3">
                                                            <label>Password</label>
                                                            <div className="input-group">
                                                                <input className={"form-control inputwidth" + (errors.password && touched.password ? " is-invalid state-invalid" : "")}
                                                                    placeholder="Enter your password"
                                                                    type={!show === true ? "text" : "password"}
                                                                    name="password"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.password}
                                                                    autoComplete="off" />
                                                                <span className='input-group-text' onClick={() => { handleshow("pwd") }}>{(!show === true ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>)}</span>
                                                            </div>
                                                            <span className='text-danger text-start d-block err-spn'>{errors.password && touched.password && errors.password}</span>
                                                        </div>
                                                        <div className="form-group text-start mb-3">
                                                            <label>Confirm Password</label>
                                                            <div className="input-group">
                                                                <input className={"form-control inputwidth" + (errors.confirmPassword && touched.confirmPassword ? " is-invalid state-invalid" : "")}
                                                                    placeholder="Enter your confirmPassword"
                                                                    type={!conformshow === true ? "text" : "password"}
                                                                    name="confirmPassword"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.confirmPassword}
                                                                    autoComplete="off" />
                                                                <span className='input-group-text' onClick={() => { handleshow("confirepwd") }}>{(!conformshow === true ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>)}</span>
                                                            </div>
                                                            <span className='text-danger text-start d-block err-spn'>{errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}</span>
                                                        </div>
                                                        <div className='text-start'>
                                                            <button className={"btn btn-primary mt-2" + (loader === true ? " disabled" : "")} type="submit">Reset</button>
                                                        </div>
                                                        {loader === true ?
                                                            <div className="spinner-border text-primary mt-2" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> : ""}
                                                    </form>
                                                    <p className="text-muted tx-13 ml-0 text-center mt-3">{"Already have an account"}</p>
                                                    <div className="text-center ml-0">
                                                        <div className="mb-1"><Link to="/login">Log in</Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik> */}

      <div className="wraper-inner cpt">
        <section className="accounts-page section-padding">
          <div className="container">
            <div className="accounts-page-box row">
              <div className="accounts-page-box-left col-md-7">
                <figure>
                  {getGlobalData?.settings?.login_bg_image ?
                    <img src={getGlobalData?.settings?.login_bg_image} alt="LoginImage" /> :
                    <Skeleton count={1} style={{ height: '500px' }} />
                  }
                </figure>
                <div className="account-content">
                  <div dangerouslySetInnerHTML={{ __html: `${getGlobalData?.settings?.login_content ? getGlobalData.settings.login_content : "N/A"}` }}></div>
                </div>
              </div>
              <div className="accounts-page-box-right col-md-5">
                <Formik
                  initialValues={{ password: '', confirmPassword: "" }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    let formData = new FormData();
                    formData.append("new_password", values.password);
                    formData.append("c_password", values.confirmPassword)
                    formData.append("validate_string", location.state)
                    authService.resetPassword(formData).then(response => {
                      if (response.success) {
                        Swal.fire({
                          icon: 'success',
                          text: response.message,
                          ...SWAL_SETTINGS
                        })
                        setTimeout(() => {
                          resetForm()
                          navigate("/login");
                        }, 2000);
                      } else {
                        Swal.fire({
                          icon: 'error',
                          text: handleServerValidations(response),
                          ...SWAL_SETTINGS
                        })
                      }
                    }).catch(error => {
                      console.log("error: ", error);
                    })
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <div className="accounts-page-box-inner-L">
                      <div className="common-bg-form">
                        <div className="top-from-headning text-left mb-4">
                          <figure>
                            <img src={getGlobalData?.portal_design?.logo_transparent} alt="Logo" />
                          </figure>
                          <h3>{t('web_reset_password')}</h3>
                          <p>{t('web_reset_password_message')} </p>
                        </div>
                        <form className="form-box login-form" onSubmit={handleSubmit}>
                          <div className="form-group">
                            <div className="with-icon">
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_password')}
                                type={!show === true ? "text" : "password"}
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                autoComplete="off"
                              />
                              <div className="icon-box" onClick={() => handleshow("pwd")}>
                                {(!show === true ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>)}
                              </div>
                            </div>
                            <span className="text-danger text-start d-blockerr-spn">
                              {errors.password && touched.password && errors.password}
                            </span>
                          </div>
                          <div className="form-group mt-4">
                            <div className="with-icon">
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_confirm_password')}
                                type={!conformshow === true ? "text" : "password"}
                                name="confirmPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                                autoComplete="off"
                              />
                              <div className="icon-box" onClick={() => handleshow("confirepwd")}>
                                {(!conformshow === true ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>)}
                              </div>
                            </div>
                            <span className='text-danger text-start d-block err-spn'>{errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}</span>
                          </div>
                          <div className="btn-box text-center mt-4 ">
                            <button className="btn btn-primary w-100 h-50" type="submit">
                              {t('web_lbl_submit')}
                            </button>
                          </div>
                          <div className="bottom-text mt-4 text-center">
                            {t('web_reset_password_back')} <Link to="/login">{t('web_reset_password_login')}</Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default ResetPassword;