import React,{useState,useEffect} from 'react';
import * as commonServices from '../services/common.services';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link,useLocation,useParams } from "react-router-dom";
import {createMeta, setTitle} from '../../../utils/commonfunction';
import { useTranslation } from 'react-i18next';


const DynamicPages = () => {

    const params = useParams();
    const loc = useLocation();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState({});
    const { t } = useTranslation();

  
    useEffect(() => {
      setLoading(true);
      commonServices.cmsDetails(params?.slug).then((response) => {
        setData(response?.data);
        setTitle(response?.data?.title);
        createMeta(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error of terms&conditions",error);
      })
    },[loc,params?.slug])

  return (
    <div className="wraper-inner cpt">
        <section className="inner-banner">
          <div className="container">
            <div className="row ">
                <div className="banner-inner-card">
                  <h1>{data?.title}</h1>
                  <nav className="breadcrumb">
                      <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                                    <Link to={`/`}>{t('web_header_heading_home')}</Link>
                                </li>
                        <li className="breadcrumb-item active" aria-current="page">{data?.name}</li>
                      </ol>
                  </nav>
                </div>
            </div>
          </div>
        </section>
        {loading ? (
          <section className="content-box-main section-padding">
              <div className="container" style={{minHeight:'48vh'}}>
                <Skeleton count={10} style={{height:'50px'}}/>
              </div>
          </section>
        ) : (
          <div style={{minHeight:'70vh'}} dangerouslySetInnerHTML={{ __html: `${data && data?.description ? data?.description : "N/A"}` }}></div>
        )}
    </div>
  )
}

export default DynamicPages;