import React from 'react';

import './Message.css';

import ReactEmoji from 'react-emoji';
import { formateDate, getFileExt, handleServerValidations, isApp } from '../../../../../../utils/commonfunction';
import { MESSAGE_TYPES, SWAL_SETTINGS } from '../../../../../../utils/Constants';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lg-autoplay.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import 'lightgallery/css/lg-rotate.css';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import Swal from 'sweetalert2';
import { DeleteMessage } from '../../../../services/common.services';
import { useSelector } from 'react-redux';

const Message = ({ setEdit, setMessageData, setMessage, setMessages, message, senderData, receiverData,loaderPercentage }) => {
	let isSentByCurrentUser = false;

	const leadData = useSelector((state) => state.designportal);

	if (message.sender_id === senderData._id) {
		isSentByCurrentUser = true;
	}
	/* For download files */
	const handleDownloadClick = async (e, fileURL) => {
		e.preventDefault();
        let fileName = "";
        if(fileURL){
			fileName = fileURL.replace(`${process.env.REACT_APP_SERVER_BASE_URL}/uploads/chat/`, "");
			if(isApp()){
				const appData = {action:'download-file', data:{
					file_path:fileURL,
					file_type:getFileExt(fileName),
					file_name:fileName
				}};
				window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
			}else{
				try {
				const response = await fetch(fileURL);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const blob = await response.blob();
				const blobURL = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = blobURL;
				a.download = fileName;
			
				document.body.appendChild(a);
				a.click();
			
				document.body.removeChild(a);
				URL.revokeObjectURL(blobURL);
				} catch (error) {
				console.error('Error downloading the file:', error);
				Swal.fire({
					title: 'Download Error',
					text: `There was an error while downloading the file "${fileName}".`,
					icon: 'error',
				});
				}
			}
        }else{
            console.log("Error in file downloading")
        }
      };

	const handleDeleteMessage = (data) => {
		Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You want to delete this comment!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: leadData?.data?.background_colors?.primary_active_color,
            cancelButtonColor: leadData?.data?.colors?.danger,
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                let formData = new FormData();
                formData.append('_id',data?._id);
                DeleteMessage(formData).then((response) => {
                    if(response?.success){
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        });
						setMessages((prevState) => prevState && prevState.filter((item) => item?._id !== data?._id))
                    }else{
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        });
                    }
                }).catch((err) => {
                    Swal.fire({
                        icon: 'error',
                        text: handleServerValidations(err),
                        ...SWAL_SETTINGS
                    });
                })
            }
        })
	} 

	const handleEditMessage = (data) => {
		setMessage(data?.message);
		setMessageData(data);
		setEdit(true);
	}

	function prepareMessage(data){
		if(data.type === MESSAGE_TYPES.TEXT){
			return <p style={{wordBreak:'break-all'}}>{ReactEmoji.emojify(data?.message)}</p>;
		}else if(data.type === MESSAGE_TYPES.IMAGE){
			return <LightGallery speed={500} plugins={[lgThumbnail, lgZoom, lgFullscreen, lgRotate]}>
				<a href={data?.file_url}>
					<img className="message-image cp" src={data?.file_url} alt={data?.file_name}/>
				</a>
			</LightGallery>
		}else if(data.type === MESSAGE_TYPES.EMOJI){
			return <span style={{wordBreak:'break-all'}} className='message-emoji'>{ReactEmoji.emojify(data?.message)}</span>
		}else if(data.type === MESSAGE_TYPES.FILE){
			return (
          <div className='position-relative'>
            <span className="message-emoji">
              <i className="fa fa-file" aria-hidden="true"></i>
            </span>
          {/* <button
            style={{ border: "0px", background:'transparent', position:'absolute', top:'-5px', right:'-5px' }}
			onClick={(e) => handleDownloadClick(e, data?.file_url)}
          >
            <i
              className="fa fa-download"
              style={{
                color: "#fff",
                fontSize: "16px",
                margin: "0px",
                padding: "0px",
              }}
            ></i>
          </button> */}
          </div>
      );
		}else if(data.type === "preview"){
			return 	<div>
						<div
							className="upload_progress"
							role="progressbar"
							aria-label="Basic example"
							aria-valuenow={0}
							aria-valuemin={0}
							aria-valuemax={100}
						>
						<div className="progress-bar" style={{ width: "25%" }} />
						</div>
					</div>
		}
	}
	return (
		isSentByCurrentUser
			? (
				<div className={"outgoing_msg position-relative"+ (" msg-type-"+message.type)}>
					<div className="sent_msg">
						<div className="sent_msg-bg">
							{
								prepareMessage(message)
							}
							<div className="dropdown chat-menu">
								<span role="button" data-bs-toggle="dropdown" aria-expanded="false" className='dropdown-toggle chat-menu-button'><i className="fa fa-ellipsis-v" aria-hidden="true"></i></span>

								<ul className="dropdown-menu">
									<li><a className="dropdown-item cp" href="/" onClick={(e) => {e.preventDefault(); handleDeleteMessage(message)}}>Delete</a></li>
									{message?.type === MESSAGE_TYPES.TEXT ? (
										<li><a className="dropdown-item cp" href="/" onClick={(e) => {e.preventDefault(); handleEditMessage(message)}}>Edit</a></li>
									) : (
										<></>
									)}
									{message?.type === MESSAGE_TYPES.FILE || message?.type === MESSAGE_TYPES.IMAGE ? (
										<li><a className="dropdown-item cp" href="/" onClick={(e) => {e.preventDefault(); handleDownloadClick(e, message?.file_url)}}>Download</a></li>
									) : (
										<></>
									)}
								</ul>
							</div>
						</div>
						<span className="time_date">
							{formateDate(message?.createdAt)}
						</span>
					</div>
				</div>
			)
			: (
				<div className="incoming_msg position-relative">
					<div className="received_msg">
						<div className="received_withd_msg">
							<div className="received_withd_msg-bg">
								{
									prepareMessage(message)
								}
								{message?.type === MESSAGE_TYPES.FILE || message?.type === MESSAGE_TYPES.IMAGE ? (
									<div className="dropdown chat-menu-left">
										<span role="button" data-bs-toggle="dropdown" aria-expanded="false" className='dropdown-toggle chat-menu-button'><i className="fa fa-ellipsis-v" aria-hidden="true"></i></span>
										<ul className="dropdown-menu">
											<li><a className="dropdown-item cp" href="/" onClick={(e) => {e.preventDefault(); handleDownloadClick(e, message?.file_url)}}>Download</a></li>
										</ul>
									</div>
								) : (
									<></>
								)}
							</div>
							<span className="time_date">
								{formateDate(message?.createdAt)}
							</span>
						</div>
					</div>
				</div>
			)
	);
}

export default Message;
