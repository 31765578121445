import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as ticketService from '../../services/ticket.services';
import CustomPagination from '../../common/custompagination';
import Loader from '../../common/loader'
import Breadcrums from '../../common/breadcrumbs';
import $ from 'jquery'
import { SWAL_SETTINGS, showFilterlist } from '../../../../utils/Constants';
import { formateDate, handleServerValidations } from '../../../../utils/commonfunction';
import StatusFilter from '../../common/statusFilter';
import CustomRangepicker from '../../common/rangepicker';
import Sorting from '../../common/sorting';



const TicketTable = () => {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const [statsupdate, setStatusUpdate] = useState("false")
    const [serialno, setSerialNo] = useState("")
    const [datalength, setDataLength] = useState("")
    const [itemperpage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalsearch, setGlobalSearch] = useState("")
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Tickets", url: "" }]
    const [resetdate, setResetDate] = useState(false);

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.pgno)
            formData.append("per_page", itemperpage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalsearch)
            ticketService.List(formData).then(data => {
                setDataLength(data.data.total_records)
                setSerialNo(data.data.offset)
                setList(data && data.data && data.data.list ? data.data.list : [])
                setPage(data && data.data && data.data.page ? data.data.page : 1);
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsupdate, sorting, search, globalsearch])

    const viewfunction = (row) => {
        navigate(`/admin/event-management/tickets/view/${row._id}`)
    }

    const goToEdit = (row) => {
        navigate(`/admin/event-management/tickets/${params.pgno}/edit/${row._id}`)
    }

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("o_id", row._id)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                ticketService.Status(formData).then(response => {
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                        setStatusUpdate(!statsupdate)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    function Deletefunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("o_id", row._id);
                ticketService.Delete(formdata).then(response => {
                    setStatusUpdate(!statsupdate)
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("deleteError")
                });
            }
        })
    }

    // sorting function start
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }
    // sorting end

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        // reset customrangepicker & customstatusfilter state using jquery//
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            {/* table section */}
            <div className="animation_fade">
                <div className="card custom-card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h6 className="main-content-label">TICKETS</h6>
                            <div className='d-flex align-items-center'>
                                <div className='form-group mb-0 me-3'>
                                    <div className="form-group mb-0 rangepicker_container">
                                        <i className="fa fa-search calender_icon"></i>
                                        <input type="search" className="form-control" value={globalsearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="me-3">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("createdAt", e) }} resetdate={resetdate}/>
                                </div>
                                <div className="me-3">
                                    <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-warning float-right mr-2">Reset Filter</button>
                                <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/event-management/tickets/add`)}>Add New</button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                <thead>
                                    <tr>
                                        <th className='sr_head'>S.No</th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Title</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="title" />
                                            </div>
                                        </th>
                                        <th>Type</th>
                                        <th>Price</th>
                                        <th>Persons</th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="createdAt" />
                                            </div>
                                        </th>
                                        <th className='status_head'>Status</th>
                                        <th className='action_head'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={8}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => {
                                                return <tr key={i}>
                                                    <td>{serialno + (i + 1)}</td>
                                                    <td>{row.title ? row.title : "N/A"}</td>
                                                    <td>{row.type ? row.type : "N/A"}</td>
                                                    <td>{row.price ? row.price : "N/A"}</td>
                                                    <td>{row.number_of_persons ? row.number_of_persons : "N/A"}</td>
                                                    <td>{row.createdAt ? formateDate(row.createdAt) : "N/A"}</td>
                                                    <td>{row.status === 1 ? <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(row, 0) }}>Active</button>
                                                        : <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(row, 1) }}>Inactive</button>}</td>

                                                    <td>
                                                        <div className="d-flex">
                                                            <button className="btn ripple btn-main-primary signbtn" onClick={() => { viewfunction(row) }}>View</button>
                                                            <button className="btn ripple btn-success mx-1" onClick={() => { goToEdit(row) }}>Edit</button>
                                                            <button className="btn ripple btn-secondary" onClick={() => { Deletefunction(row) }}>Delete</button>
                                                        </div>

                                                    </td>
                                                </tr>
                                            }) : <tr><td colSpan={8} className="text-center">No records</td></tr>}
                                        </>}

                                </tbody>
                            </table>
                        </div>
                        <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                        {datalength && datalength > 0 ?
                            <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "Tickets", path: "/admin/event-management/tickets/list" }]} /> : ""}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TicketTable