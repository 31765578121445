import React, { useEffect } from 'react'
import { setGlobalLoader } from '../../../redux/slices/globalLoader';
import { useDispatch, useSelector } from 'react-redux';
import GlobalLoader from '../shared/globalLoader';
import myImage from '../../../assets/user/images/404.svg'
import { useTranslation } from 'react-i18next';


const Error404User = () => {
    const dispatch = useDispatch();
    const loaderState = useSelector((state) => state.globalLoader);
    const { t } = useTranslation();


    useEffect(() => {
        dispatch(setGlobalLoader(false));
    }, []);


    return (
        loaderState ?
        <GlobalLoader /> :

        <div className='page-404 cpt'>
            <div className='container'>
                <div className='page-404-in text-center'>
                    <img className='mb-4' src={myImage} alt="error" />
                    <h3>{t('web_404')}</h3>
                    <p className="tx-15 mt-1 mb-4">{t('web_404_message')}</p>
                    <a className="btn btn-primary" href="/">{t('web_404_back')}</a>
                </div>
            </div>
        </div>
    )
}

export default Error404User