import React, { useState, useEffect } from 'react';
import { Field, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Breadcrums from '../../../common/breadcrumbs';
import CustomCkeditor from '../../../common/customeditor';
import * as pageServices from "../../../services/pages.services";
import { CMS_PAGES_POSITIONS, FOOTER_MENUS, SWAL_SETTINGS } from '../../../../../utils/Constants';
import { globalLoader, handleServerValidations } from '../../../../../utils/commonfunction';
import { Tooltip, Whisper } from 'rsuite';
const PageAdd = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [submitted, setSubmitted] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [initialValues, setInitialValues] = useState({
        title: '',
        name: '',
        name_de: '',
        slug: "",
        metadesciption: "",
        meta_tags: "",
        without_login: false,
        generate_qr: false,
        meta_keyword: "",
        richtext: "",
        position: "",
        footer_menu: "",
        title_de:"",
        richtext_de:""
    });


    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Pages", url: "/admin/cms/pages/list/1" }, { title: "Add", url: "" },]

    useEffect(() => {
        setInitialValues({
            title: state && state?.title ? state?.title : "",
            name: state && state?.name ? state?.name : "",
            name_de: state && state?.name_de ? state?.name_de : "",
            slug: state && state?.slug ? state?.slug : "",
            metadesciption: state && state?.metadescription ? state?.metadescription : "",
            meta_tags: state && state?.meta_tags ? state?.meta_tags : "",
            without_login: state && state?.without_login ? state?.without_login : false,
            generate_qr: state && state?.generate_qr ? state?.generate_qr : false,
            meta_keyword: state && state?.meta_keyword ? state?.meta_keyword : "",
            richtext: state && state?.description ? state?.description : "",
            position: state && state?.position ? state?.position : "",
            footer_menu: state && state?.footer_menu ? state?.footer_menu : "",
            title_de: state && state?.title_de ? state?.title_de : "",
            richtext_de: state && state?.description_de ? state?.description_de : "",
        });
        setDataLoaded(true);
    }, [])

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            {dataLoaded ? (
                <Formik
                    initialValues={initialValues}
                    validateOnBlur={false}
                    validate={values => {
                        const error = {};
                        if (!values.title || !values.title.trim()) error.title = "Title is required";
                        if (!values.slug || !values.slug.trim()) error.slug = "Slug is required";
                        if (!values.metadesciption || !values.metadesciption.trim()) error.metadesciption = "Metadesciption is required";
                        if (!values.richtext || !values.richtext.trim()) error.richtext = "Description is required";
                        // if (!values.type || !values.type.trim()) error.type = "Type is required";
                        if (values.position === 'footer') {
                            if (!values.footer_menu || !values.footer_menu.trim()) error.footer_menu = "Footer menu is required";
                        }
                        if (!values.position || !values.position.trim()) error.position = "Position is required";
                        
                        return error;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitted(true);
                        globalLoader(true);
                        let formData = new FormData();
                        formData.append('title', values.title);
                        formData.append('title_de', values.title_de);
                        formData.append('name', values.name);
                        formData.append('name_de', values.name_de);
                        formData.append('slug', values.slug);
                        formData.append('metadescription', values.metadesciption);
                        formData.append('meta_tags', values.meta_tags);
                        formData.append('without_login', values.without_login);
                        formData.append('generate_qr', values?.generate_qr);
                        formData.append('meta_keyword', values.meta_keyword);
                        formData.append('description', values.richtext);
                        formData.append('description_de', values.richtext_de);
                        formData.append('type', values.type);
                        if (values.position === 'footer') {
                            formData.append('footer_menu_name', values.footer_menu);
                        }
                        formData.append('position', values.position);
                        pageServices.Add(formData).then(response => {
                            setSubmitting(false);
                            if (response.success) {
                                Swal.fire({
                                    icon: 'success',
                                    text: response.message,
                                    ...SWAL_SETTINGS
                                })
                                setTimeout(() => {
                                    resetForm({ values: '' });
                                    globalLoader(false);
                                    navigate(`/admin/cms/pages/list/1`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    text: handleServerValidations(response),
                                    ...SWAL_SETTINGS
                                })
                                setSubmitted(false);
                                globalLoader(false);
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row row-sm">
                            <div className="col-lg-12 col-md-12 animation_fade">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div>
                                            <h6 className="main-content-label mb-3">Add Cms</h6>
                                        </div>
                                        <div className="row row-sm">
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='name' className='text-left d-flex'>Name:
                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Page Name - Backend Only</Tooltip>}>
                                                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                </Whisper>
                                                </label>
                                               <input name='name' id="name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name} className='form-control' />
                                            </div>
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='name_de' className='text-left d-flex'>Name German:
                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Page Name - Backend Only</Tooltip>}>
                                                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                </Whisper>
                                                </label>
                                               <input name='name_de' id="name_de" type='text' onChange={handleChange} onBlur={handleBlur} value={values.name_de} className='form-control' />
                                            </div>
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span>
                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Page Title - Shown in Front-End (Menus and Tab Bar)</Tooltip>}>
                                                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                </Whisper>
                                                </label>
                                                <input name='title' id="title" type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.title && touched.title && errors.title}</span>
                                            </div>
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='title' className='text-left d-flex'>Title In German:
                                                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>German Page Title - Shown in Front-End (Menus and Tab Bar)</Tooltip>}>
                                                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                </Whisper>
                                                </label>
                                                <input name='title_de' id="title_de" type='text' onChange={handleChange} onBlur={handleBlur} value={values.title_de} className='form-control' />
                                            </div>
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='slug' className='text-left d-flex'>Slug:<span className="requirestar">*</span></label>
                                                <input name='slug' id="slug" type='text' onChange={handleChange} onBlur={handleBlur} value={values.slug} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.slug && touched.slug && errors.slug}</span>
                                            </div>

                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='slug' className='text-left d-flex'>Menu Location:<span className="requirestar">*</span></label>
                                                <div className="select-down-arrow ">
                                                    <Field as='select' id="position" name={"position"} className="form-control select2 text-capitalize">
                                                        <option value="" label="Select Location">Select{" "}</option>
                                                        {CMS_PAGES_POSITIONS && Object.values(CMS_PAGES_POSITIONS).map((option, i) => {
                                                            return (
                                                                <option key={i} value={option}>
                                                                    {option}
                                                                </option>
                                                            );
                                                        })}
                                                    </Field>
                                                </div>
                                                <span className='text-danger d-flex text-left'>{errors.position && touched.position && errors.position}</span>
                                            </div>
                                            {values.position && values.position === 'footer' ?
                                                <div className='col-lg-12 text-center form-group'>
                                                    <label htmlFor='slug' className='text-left d-flex'>Footer Menu:<span className="requirestar">*</span></label>
                                                    <div className="select-down-arrow ">
                                                        <Field as='select' id="footer_menu" name={"footer_menu"} className="form-control select2 text-capitalize">
                                                            <option value="" label="Select Menu">Select{" "}</option>
                                                            {FOOTER_MENUS && Object.values(FOOTER_MENUS).map((option, i) => {
                                                                return (
                                                                    <option key={i} value={option}>
                                                                        {option.replace('_', ' ')}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                    </div>
                                                    <span className='text-danger d-flex text-left'>{errors.footer_menu && touched.footer_menu && errors.footer_menu}</span>
                                                </div> : <></>}
                                            <div className='col-lg-12 text-center form-group required'>
                                                <label htmlFor='meta_tags' className='text-left d-flex'>Meta Tags: </label>
                                                <textarea name='meta_tags' id="meta_tags" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.meta_tags} className='form-control' />
                                            </div>
                                            <div className='col-lg-12 text-center form-group required'>
                                                <label htmlFor='meta_keyword' className='text-left d-flex'>Meta Keywords: </label>
                                                <textarea name='meta_keyword' id="meta_keyword" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.meta_keyword} className='form-control' />
                                            </div>
                                            <div className='col-lg-12 text-center form-group required'>
                                                <label htmlFor='metadesciption' className='text-left d-flex'>Meta Description :<span className="requirestar">*</span> </label>
                                                <textarea name='metadesciption' id="metadesciption" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.metadesciption} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.metadesciption && touched.metadesciption && errors.metadesciption}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                                                <CustomCkeditor
                                                    fieldname={"richtext"}
                                                    setFieldValue={setFieldValue}
                                                    value={values.richtext}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                                <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                                            </div>

                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='subject' className='text-left d-flex'>Description In Gerrman:</label>
                                                <CustomCkeditor
                                                    fieldname={"richtext_de"}
                                                    setFieldValue={setFieldValue}
                                                    value={values.richtext_de}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                                {/* <span className='text-danger d-flex text-left'>{errors.richtext_de && touched.richtext_de && errors.richtext_de}</span> */}
                                            </div>
                                            <div className='col-lg-12 form-group required'>
                                                {values.position === 'other' || values.position === 'none' ? (
                                                    <></>
                                                ) : (
                                                    <div className='d-flex'>
                                                        <input name='without_login' id="without_login" type='checkbox' onChange={handleChange} onBlur={handleBlur} value={values.without_login} />
                                                        <label htmlFor='without_login' className='text-left d-flex mb-0 ms-2 no-before no-after'>Without Login
                                                        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>If Page is Private (Only Visible to Logged In Users), leave empty. 
                                                        If Page is to be Public (Visible to all - regardless of login), please check the box.</Tooltip>}>
                                                        <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                        </Whisper> 
                                                        </label>
                                                    </div>
                                                )}
                                                 <div className='d-flex mt-2'>
                                                        <input name='generate_qr' id="generate_qr" type='checkbox' onChange={handleChange} onBlur={handleBlur} value={values.generate_qr} />
                                                        <label htmlFor='generate_qr' className='text-left d-flex mb-0 ms-2 no-before no-after'>Generated Qr
                                                        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Generated Qr</Tooltip>}>
                                                        <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                        </Whisper> 
                                                        </label>
                                                    </div>
                                            </div>
                                            <div className="">
                                                <button className="btn btn-main-primary signbtn mr-2" type="submit" disabled={submitted ? true : null}>
                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                    Submit
                                                </button>
                                                <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                    <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
                </Formik>
            ) : (
                <></>
            )}
        </>
    )
}

export default PageAdd