import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import Swal from "sweetalert2";
import { useLocation, useNavigate } from 'react-router-dom';
import * as bannerService from '../../../services/banner.services';
import Breadcrums from '../../../common/breadcrumbs';
import CustomCkeditor from '../../../common/customeditor';
import { SWAL_SETTINGS } from '../../../../../utils/Constants';
import { handleServerValidations } from '../../../../../utils/commonfunction';
import * as Yup from 'yup';
import { Tooltip, Whisper, Loader } from 'rsuite';


const BannerAdd = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [previewimage, setPreviewImage] = useState("");
  const [initialValues, setInitialValues] = useState({
    title: "",
    title_de: "",
    richtext: "",
    richtext_de: "",
    files: "",
  })

  const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Banner", url: "/admin/cms/banner/list/1" }, { title: "Add", url: "" }];

  useEffect(() => {
    setInitialValues({
      title: state && state?.title ? state.title : "",
      title_de: state && state?.title_de ? state.title_de : "",
      richtext: state && state?.description ? state.description : "",
      richtext_de: state && state?.description_de ? state.description_de : "",
      files: "",
    })
    setDataLoaded(true);
  }, [])

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required').test('no-spaces', 'Title is required', (value) => value.trim()),
    richtext: Yup.string().required("Description is required").test('no-spaces', 'Description is required', (value) => value.trim()),
    files: Yup.mixed().required('Image is required').test(
      'fileType',
      'Unsupported file format',
      (value) => {
        if (!value) return false;
        const supportedFormats = ['image/jpeg', 'image/png', 'image/gif'];
        return supportedFormats.includes(value.type);
      }
    ),
  });


  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitted(true)
            let formData = new FormData();
            formData.append('title', values.title);
            formData.append('title_de', values.title_de);
            formData.append('description', values.richtext);
            formData.append('description_de', values.richtext_de);
            formData.append('image', values.files);
            bannerService.Add(formData).then(response => {
              setSubmitting(false);
              if (response.success) {
                Swal.fire({
                  icon: 'success',
                  text: response.message,
                  ...SWAL_SETTINGS
                })
                setTimeout(() => {
                  resetForm({ values: '' });
                  navigate(`/admin/cms/banner/list/1`)
                }, 2000);
              } else {
                Swal.fire({
                  icon: 'error',
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS
                })
                setSubmitted(false)
              }
            }).catch(error => {
              console.log("error ====> ", error);
            })
          }}
        >{({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,

        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row row-sm">
              <div className="col-lg-12 col-md-12 animation_fade">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">Add Banner</h6>
                    </div>
                    <div className="row row-sm">
                      <div className='col-md-12 text-center form-group'>
                        <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span> </label>
                        <input
                          name='title'
                          id='title'
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          className='form-control' />
                        <span className='text-danger d-flex text-left'><ErrorMessage name={"title"} /></span>
                      </div>
                      <div className='col-md-12 text-center form-group'>
                        <label htmlFor='title_de' className='text-left d-flex'>Title In German:</label>
                        <input
                          name='title_de'
                          id='title_de'
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title_de}
                          className='form-control' />
                        {/* <span className='text-danger d-flex text-left'><ErrorMessage name={"title_de"} /></span> */}
                      </div>
                      <div className='col-lg-12 text-center form-group'>
                        <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                        <CustomCkeditor
                          fieldname={"richtext"}
                          setFieldValue={setFieldValue}
                          value={values.richtext}
                          setFieldTouched={setFieldTouched}
                        />
                        <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                      </div>
                      <div className='col-lg-12 text-center form-group'>
                        <label htmlFor='subject' className='text-left d-flex'>Description In German:</label>
                        <CustomCkeditor
                          fieldname={"richtext_de"}
                          setFieldValue={setFieldValue}
                          value={values.richtext_de}
                          setFieldTouched={setFieldTouched}
                        />
                        {/* <span className='text-danger d-flex text-left'>{errors.richtext_de && touched.richtext_de && errors.richtext_de}</span> */}
                      </div>
                      <div className='col-lg-12 text-center form-group'>
                        <label htmlFor='files' className='text-left d-flex'>Image:<span className="requirestar">*</span>
                          <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Recommended Size (1920x887)</Tooltip>}>
                            <span className='field-more-info mt-1 ms-1 cp'>?</span>
                          </Whisper>
                        </label>
                        <input className='form-control imgInput' id="files" name="files" accept="image/*" type="file" onChange={(event) => {
                          setFieldValue("files", event.currentTarget.files[0] || "");
                          event.currentTarget.files.length === 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage(""));
                        }} />
                        <span className='text-danger d-flex text-left' id="errortext">{errors.files && touched.files && errors.files}</span>
                      </div>
                      {
                        previewimage ?
                          <div className='mb-4'>
                            <img src={previewimage} alt='Preview' style={{ height: "100px" }} />
                          </div> : ""
                      }
                      <div className="">
                        <button className="btn btn-main-primary signbtn mr-2" type="submit" disabled={submitted ? true : null}>
                          <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                          Submit
                        </button>
                        <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                          <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
        </Formik>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default BannerAdd