import React, { useEffect, useState } from 'react';
import * as otherServices from '../services/common.services';
import { useLocation } from 'react-router-dom';
import {attachDynamicStrings, setTitle} from '../../../utils/commonfunction'
const Home = () => {

    const [bannerData, setBannerData] = useState([]);
    const loc = useLocation();
    const [homeData, setHomeData] = useState();

    useEffect(() => {
        otherServices.cmsBanner().then((response) => {
            setBannerData(response?.data?.list);
            setTimeout(() => {
                const event = new CustomEvent("startSlider");
                document.dispatchEvent(event);
            }, 100);
        }).catch((err) => {
            console.log("err",err);
        })
    },[])
    

    useEffect(() => {
        otherServices.cmsDetails("home_page_content").then((response) => {
          setHomeData(attachDynamicStrings(response?.data?.description));
          setTitle(response?.data?.title);
        })
        .catch((error) => {
        //   console.log("error of terms&conditions",error);
        })
      },[loc]);

    useEffect(() => {
        otherServices.cmsDetails("kljkjk").then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
        })
      },[]);

    return (
    <>
        <div className="wraper-inner cpt">
            {/* <h3>{t('welcome')}</h3> */}
            <div className="owl-carousel owl-theme">
            {bannerData && bannerData.map((item,index) => (
            <div className="item" key={index}>
                <section 
                    className="banner-sec" 
                    style={{
                        background:`url(${item?.image}) no-repeat center center`
                    }}>
                        <div className="container">
                        <div className="row ">
                            <div className="col-md-8 m-auto pt-5 banner-sec-content">
                            <h1>{item?.title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: `${item?.description ? item?.description : "N/A"}` }}>
                                {/* {toHtml(item?.description ? item?.description : "")} */}
                                </div>
                            {/* <a className="btn btn-primary btn-icon">
                                Get started{" "}
                                <span className="icon-box">
                                <i className="ri-arrow-right-down-line" />
                                </span>
                            </a> */}
                            </div>
                        </div>
                        </div>
                    </section>
                </div>
            ))}
            </div>
            
            {/* {
                bannerData && bannerData.length?
                <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                {bannerData && bannerData.map((item,index) => (
                    <div key={index} className={"carousel-item"+index==0?" active":""} data-bs-interval="1000">
                    <section className="banner-sec" style={{background:`url(${item?.image})`}}>
                    <div className="container">
                    <div className="row ">
                        <div className="col-md-8 m-auto pt-5 banner-sec-content">
                        <h1>{item?.title}</h1>
                        <div>{toHtml(item?.description ? item?.description : "")}</div>
                        <a className="btn btn-primary btn-icon">
                            Get started{" "}
                            <span className="icon-box">
                            <i className="ri-arrow-right-down-line" />
                            </span>
                        </a>
                        </div>
                    </div>
                    </div>
                </section>
                    </div>
                    ))}
                </div>
                </div>
                    :""
            } */}
            {/* // <div className='item' key={index}>
            // <section className="banner-sec" style={{background:`url(${item?.image})`}}>
            //     <div className="container">
            //     <div className="row ">
            //         <div className="col-md-8 m-auto pt-5 banner-sec-content">
            //         <h1>{item?.title}</h1>
            //         <div>{toHtml(item?.description ? item?.description : "")}</div>
            //         <a className="btn btn-primary btn-icon">
            //             Get started{" "}
            //             <span className="icon-box">
            //             <i className="ri-arrow-right-down-line" />
            //             </span>
            //         </a>
            //         </div>
            //     </div>
            //     </div>
            // </section>
            // </div>  */}
                
            
        {/*-about-us---*/}
        {/* <div id="carouselExampleInterval2" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="1000">
                <h1>kkkkkkk</h1>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                <h1>kkkkkkk</h1>
                </div>
                <div className="carousel-item">
                <h1>kkkkkkk</h1>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval2" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval2" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            </div> */}
        {/* <section className="about-us section-padding">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6 pe-4 about-us-left">
                <div className="heading-card">
                    <small>ABOUT US</small>
                    <h2>Empowering you to achieve more, one click at a time.</h2>
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book.{" "}
                    </p>
                    <p>
                    It has survived not only five centuries, but also the leap into
                    electronic typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages.
                    </p>
                </div>
                <div className="btn-group-box mt-2">
                    {" "}
                    <Link 
                        className="btn btn-primary btn-icon"
                        to={"/about-us"}
                    >
                        Read more{" "}
                    <span className="icon-box">
                        <i className="ri-arrow-right-down-line" />
                    </span>
                    </Link>
                </div>
                </div>
                <div className="col-md-6 text-end about-us-right">
                <figure>
                    <img src={aboutimage}/>
                </figure>
                </div>
            </div>
            </div>
        </section> */}
        {/*-blog---*/}
        {/* <section className="blog-box even-blog section-padding">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6 blog-box-img">
                <figure>
                    <img src={blogImage} />
                </figure>
                </div>
                <div className="col-md-6 blog-box-content">
                <div className="heading-card">
                    <h2>Unlock new horizons and redefine what's possible.</h2>
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry.{" "}
                    </p>
                </div>
                <ul className="list-content">
                    <li>Embrace innovation. Embrace success.</li>
                    <li>Your journey starts here. Explore, create, thrive.</li>
                    <li>Empowering you to achieve more, one click at a time.</li>
                    <li>Discover a world of possibilities at your fingertips.</li>
                </ul>
                </div>
            </div>
            </div>
        </section> */}
        {/*-blog2--*/}
        {/* <section className="blog-box odd-blog section-padding">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6 blog-box-img">
                <figure>
                    <img src={blogImage2} />
                </figure>
                </div>
                <div className="col-md-6 blog-box-content">
                <div className="heading-card">
                    <h2>Embrace innovation. Embrace success.</h2>
                    <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry.
                    </p>
                </div>
                <ul className="list-content">
                    <li>Embrace innovation. Embrace success.</li>
                    <li>Your journey starts here. Explore, create, thrive.</li>
                    <li>Empowering you to achieve more, one click at a time.</li>
                    <li>Discover a world of possibilities at your fingertips.</li>
                </ul>
                </div>
            </div>
            </div>
        </section> */}
        {/*-how-its-works--*/}
        {/* <section className="how-its-works section-padding pb-0">
            <div className="container">
            <div className="heading-card-reverse text-center mb-5 col-md-5 m-auto">
                <h2 className="text-white">How it works</h2>
                <p className="text-off">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
                </p>
            </div>
            <div className="row">
                <div className="col-md-4">
                <div className="works-card">
                    <figure>
                    <img src={worksIcon1}/>
                    </figure>
                    <figcaption>
                    <h3>Create an account</h3>
                    <p>
                        Recruit and train a team of event staff and volunteers to assist
                        with various tasks such as registration, ushering, crowd
                        management, and customer service. Clearly communicate their
                        roles and responsibilities.
                    </p>
                    </figcaption>
                </div>
                </div>
                <div className="col-md-4">
                <div className="works-card">
                    <figure>
                    <img src={worksIcon2} />
                    </figure>
                    <figcaption>
                    <h3>Execute the event</h3>
                    <p>
                        On the day of the event, ensure all necessary arrangements are
                        in place and oversee the execution of the event plan. Monitor
                        the schedule, troubleshoot issues, and provide support to staff
                        and attendees as needed
                    </p>
                    </figcaption>
                </div>
                </div>
                <div className="col-md-4">
                <div className="works-card">
                    <figure>
                    <img src={worksIcon3} />
                    </figure>
                    <figcaption>
                    <h3>Evaluate the event</h3>
                    <p>
                        After the event, gather feedback from attendees, staff, and
                        stakeholders. Evaluate the success of the event based on the
                        objectives defined earlier. Identify areas of improvement and
                        collect data for future events.
                    </p>
                    </figcaption>
                </div>
                </div>
            </div>
            </div>
        </section> */}
      {/* About Us Section */}
      <div dangerouslySetInnerHTML={{ __html: `${homeData ? homeData : ""}` }}></div>
        {/*-contact-us--*/}
        {/* <ContactForm/> */}
        {/* <section className="contact-us section-padding">
            <div className="container">
            <div className="heading-card text-center mb-5 col-md-5 m-auto">
                <h2>Contact us</h2>
                <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
                </p>
            </div>
            <div className="col-md-7 m-auto contact-us-form">
                <div className="row">
                <div className="col-md-6 mb-4">
                    <input
                    type="text"
                    name=""
                    placeholder="First name*"
                    className="form-control"
                    />
                </div>
                <div className="col-md-6 mb-4">
                    <input
                    type="text"
                    name=""
                    placeholder="Last Name*"
                    className="form-control"
                    />
                </div>
                <div className="col-md-6 mb-4">
                    <input
                    type="text"
                    name=""
                    placeholder="Email Address*"
                    className="form-control"
                    />
                </div>
                <div className="col-md-6 mb-4">
                    <input
                    type="text"
                    name=""
                    placeholder="Phone Number*"
                    className="form-control"
                    />
                </div>
                <div className="col-md-12">
                    <textarea
                    className="form-control note-min"
                    placeholder="Message"
                    defaultValue={""}
                    />
                </div>
                <div className="btn-group-box mt-4 text-center">
                    {" "}
                    <a className="btn btn-primary btn-icon">
                    Send Message{" "}
                    <span className="icon-box">
                        <i className="ri-arrow-right-down-line" />
                    </span>
                    </a>
                </div>
                </div>
            </div>
            </div>
        </section> */}
        </div>
    </>
    )
}

export default Home