import React, { useEffect, useState } from "react";
import { Field, Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import {  useLocation, useNavigate } from "react-router-dom";
import * as userManagementServices from "../../services/usermgmt.services";
import Breadcrums from "../../common/breadcrumbs";
import {
  ATLEAST_ONE_CAPITAL_REGEX,
  ATLEAST_ONE_NUMBER_REGEX,
  ATLEAST_ONE_SMALL_REGEX,
  ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
  GENDER,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  SWAL_SETTINGS,
} from "../../../../utils/Constants";
import {
  blockInvalidChar,
  getNameFromList,
  handleServerValidations,
} from "../../../../utils/commonfunction";
import * as Yup from "yup";
import  {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input";
import ReactCountryFlag from "react-country-flag";
import { useSelector } from "react-redux";

const UsersAdd = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [previewimage, setPreviewImage] = useState("");
  const [newshow, setNewShow] = useState("false");
  const [conformshow, setConformShow] = useState("false");
  const [dynamicValidations, setDynamicValidations] = useState({});

  let globalData = useSelector((state) => state.globaldetail);
  const myGlobalData = useSelector((state) => state.globalData);
  const [getGlobalData, setGlobalData] = useState({});
  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  },[myGlobalData]);

  console.log("getGlobalData",getGlobalData)

  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Users", url: "/admin/user-management/users/list/1" },
    { title: "Add", url: "" },
  ];
  const [initialValues, setInitialValues] = useState({
    files: "",
    title: "",
    email: "",
    gender: "",
    notes: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    password: "",
    confirm_password: "",
    phone_number: "",
    countryCodePhone: "",
    dialCodePhone: "+" + getCountryCallingCode(getCountries()[0]),
    phone_number_work: "",
    countryCodePhoneWork: "",
    dialCodePhoneWork: "+" + getCountryCallingCode(getCountries()[0]),
  })
  
  useEffect(() => {
    setInitialValues({
      files: state && state?.files ? state?.files : "",
      title: state && state?.title ? state?.title : "",
      email: state && state?.email ? state?.email : "",
      notes: state && state?.notes ? state?.notes : "",
      gender: state && state?.gender ? state?.gender : "",
      first_name: state && state?.first_name ? state?.first_name : "",
      middle_name: state && state?.middle_name ? state?.middle_name : "",
      last_name: state && state?.last_name ? state?.last_name : "",
      password: "",
      confirm_password: "",
      phone_number: state && state?.phone_number && state?.phone_number?.number ? state?.phone_number?.number : "",
      countryCodePhone: state && state?.phone_number && state?.phone_number?.countryCode ? state?.phone_number?.countryCode : "",
      dialCodePhone: state && state?.phone_number && state?.phone_number?.code ? state?.phone_number?.code : "+" + getCountryCallingCode(getCountries()[0]),
      phone_number_work: state && state?.phone_number_work && state?.phone_number_work?.number ? state?.phone_number_work?.number : "",
      countryCodePhoneWork: state && state?.phone_number_work && state?.phone_number_work?.countryCode ? state?.phone_number_work?.countryCode : "",
      dialCodePhoneWork: state && state?.phone_number_work && state?.phone_number_work?.code ? state?.phone_number_work?.code : "+" + getCountryCallingCode(getCountries()[0]),
      employee_code: state && state?.extra_info?.employee_code ? state?.extra_info?.employee_code : "",
      employee_number: state && state?.extra_info?.employee_number ? state?.extra_info?.employee_number : "",
      event_function: state && state?.event_function ? state.event_function : "",
      extra_info: {
        employee_code: state && state?.extra_info?.employee_code ? state?.extra_info?.employee_code : "",
        employee_number: state && state?.extra_info?.employee_number ? state.extra_info?.employee_number : "",
      }


    })
    setDataLoaded(true);
  }, [])

  const showDropDown = (country) => {
    return (
      <label>
        {"   " + getNameFromList(country)} {" +"}
        {getCountryCallingCode(country)}
      </label>
    );
  };

  useEffect(() => {
    let obj = {};
    getGlobalData && getGlobalData?.settings?.profile_required_fields && getGlobalData?.settings?.profile_required_fields.map((item, index) => {
      if(item == 'gender'){
        obj.gender = Yup.string().oneOf(Object.values(GENDER),'Invalid salutation selection').required('Salutation is required')
      }
      else if(item == 'title'){
        obj.title = Yup.string().trim().required('Title is required');
      }
      else if(item == 'first_name'){
        obj.first_name = Yup.string().trim().required('First name is required');
      }
      else if(item == 'middle_name'){
        obj.middle_name = Yup.string().trim().required('Middle name is required');
      }
      else if(item == 'last_name'){
        obj.last_name = Yup.string().trim().required('Last name is required')
      }
      else if(item == 'event_function'){
        obj.event_function = Yup.string().trim().required('Function is required');
      }
      else if(item == 'phone_number'){
        obj.phone_number = Yup.string()
        .trim()
        .required('Phone number is required')
        .test("min-length", "Phone number minimum length should be 4 digits", (value) => {
          if(value?.trim()?.length < 4){
            return false
          }else{
            return true
          }
        })
        .test("max-length", "Phone number cannot be more than 13 digits", (value) => {
          if(value?.trim()?.length > 13){
            return false
          }else{
            return true
          }
        })
      }else if(item == 'phone_number_work'){
        obj.phone_number_work = Yup.string()
        .trim()
        .required('Phone number work is required')
        .test("min-length", "Phone number minimum length should be 4 digits", (value) => {
          if(value?.trim()?.length < 4){
            return false
          }else{
            return true
          }
        })
        .test("max-length", "Phone number cannot be more than 13 digits", (value) => {
          if(value?.trim()?.length > 14){
            return false
          }else{
            return true
          }
        })
      }
      else if(item == 'files'){
        obj.files = Yup.mixed().required('Image is required').test(
          'fileType',
          'Unsupported file format',
          (value) => {
            if (!value) {
              const supportedFormats = ['image/jpeg', 'image/png', 'image/gif'];
              return supportedFormats.includes(value.type);
            } else {
              return true
            }
          }
        )
      }
      else if(item == 'password'){
        obj.password =  Yup.string()
      .required("Password is required")
      .matches(
        ATLEAST_ONE_SMALL_REGEX,
        "Password should have at least 1 lowercase letter"
      )
      .matches(
        ATLEAST_ONE_CAPITAL_REGEX,
        "Password should have at least 1 capital letter"
      )
      .matches(
        ATLEAST_ONE_NUMBER_REGEX,
        "Password should have at least 1 number"
      )
      .matches(
        ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
        "Password should have at least 1 special character"
      )
      .min(PASSWORD_MIN_LENGTH, "Password should be at least 8 characters long")
      .max(
        PASSWORD_MAX_LENGTH,
        "Password cannot be more than 20 characters long"
      )
      }
      else if(item == 'confirm_password'){
          obj.confirm_password = Yup.string()
            .required("Confirm password is required")
            .oneOf([Yup.ref("password"), null], "Password and confirm password should be match.")
      }
      else if(item == 'extra_info'){
        obj.extra_info = Yup.object().shape({
          employee_number: Yup.string().trim().required('This field is required'),
          employee_code: Yup.string().trim().required('This field is required')
        })
      }
      else if(item == 'notes'){
        obj.notes = Yup.string().trim().required('Notes is required');
      }
    })
    setDynamicValidations(obj);
  },[getGlobalData]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().required("Email is required"),
    ...dynamicValidations
  });

  const isFieldRequired = (fieldName) => {
    return getGlobalData &&  getGlobalData.settings?.profile_required_fields && getGlobalData.settings?.profile_required_fields.includes(fieldName);
  };

  const handleshow = (event) => {
    if (event === "newpwd") {
      setNewShow(!newshow);
    }
    if (event === "confirepwd") {
      setConformShow(!conformshow);
    }
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log("values", values);
            setSubmitted(true);
            let formData = new FormData();
            formData.append("image", values.files);
            formData.append("title", values.title);
            formData.append("first_name", values.first_name);
            formData.append("middle_name", values.middle_name);
            formData.append("last_name", values.last_name);
            formData.append("gender", values.gender);
            formData.append("email", values.email);
            formData.append("password", values.password);
            formData.append("confirm_password", values.confirm_password);
            formData.append("notes", values.notes);
            formData.append('event_function', values.event_function);
            if (values.phone_number && values.dialCodePhone) {
              formData.append(
                "phone_number",
                JSON.stringify({
                  code: values.dialCodePhone,
                  number: values.phone_number,
                  countryCode: values.countryCodePhone,
                })
              );
            }
            if (values.phone_number_work && values.dialCodePhoneWork) {
              formData.append(
                "phone_number_work",
                JSON.stringify({
                  code: values.dialCodePhoneWork,
                  number: values.phone_number_work,
                  countryCode: values.countryCodePhoneWork,
                })
              );
            }
            formData.append('extra_info', JSON.stringify({employee_code: values.extra_info.employee_code,employee_number: values.extra_info.employee_number}));
            userManagementServices
              .Add(formData)
              .then((response) => {
                setSubmitting(false);
                console.log("response", response);
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                  resetForm({ values: "" });
                  navigate(`/admin/user-management/users/list/1`);
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                  setSubmitted(false);
                }
              })
              .catch((error) => {
                console.log("error ====> ", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">Add User</h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="title" className="text-left d-flex">
                            Title:{isFieldRequired('title') && <span className="requirestar">*</span>}
                          </label>
                          <input
                            name="title"
                            id="title"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            className="form-control"
                          />
                              <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"title"} />
                          </span>

                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="gender" className="text-left d-flex">
                            Salutation:{isFieldRequired('gender') && <span className="requirestar">*</span>}
                          </label>
                          <div className="select-down-arrow ">
                            <Field
                              as="select"
                              id="gender"
                              name={"gender"}
                              className="form-control select2 text-capitalize"
                            >
                              <option value="" label="Select Salutation">
                                Select{" "}
                              </option>
                              {GENDER &&
                                Object.values(GENDER).map((option, i) => {
                                  return (
                                    <option key={i} value={option}>
                                      {option}
                                    </option>
                                  );
                                })}
                            </Field>
                          </div>
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"gender"} />
                          </span>
                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label
                            htmlFor="first_name"
                            className="text-left d-flex"
                          >
                            First Name:{isFieldRequired('first_name') && <span className="requirestar">*</span>}
                          </label>
                          <input
                            name="first_name"
                            id="first_name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"first_name"} />
                          </span>
                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label
                            htmlFor="middle_name"
                            className="text-left d-flex"
                          >
                            Middle Name:{isFieldRequired('middle_name') && <span className="requirestar">*</span>}
                          </label>
                          <input
                            name="middle_name"
                            id="middle_name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.middle_name}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                           <ErrorMessage name={"middle_name"} />
                          </span>
                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="last_name" className="text-left d-flex">
                            Last Name:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="last_name"
                            id="last_name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.last_name}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"last_name"} />
                          </span>
                        </div>

                        <div className="col-lg-6 text-center form-group">
                          <label htmlFor="email" className="text-left d-flex">
                            Email:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="email"
                            id="email"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"email"} />
                          </span>
                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="password" className="text-left d-flex">
                            Password:{isFieldRequired('password') && <span className="requirestar">*</span>}
                          </label>
                          <div className="input-group cp">
                            <input
                              name="password"
                              id="password"
                              type={!newshow === true ? "text" : "password"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              className="form-control"
                            />
                            <span
                              className="input-group-text"
                              onClick={() => {
                                handleshow("newpwd");
                              }}
                            >
                              {!newshow === true ? (
                                <i className="far fa-eye-slash"></i>
                              ) : (
                                <i className="far fa-eye"></i>
                              )}
                            </span>
                          </div>
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"password"} />
                          </span>
                        </div>
                        <div className="col-lg-6 text-center form-group">
                          <label
                            htmlFor="confirm_password"
                            className="text-left d-flex"
                          >
                            Confirm Password:
                            {isFieldRequired('confirm_password') && <span className="requirestar">*</span>}
                          </label>
                          <div className="input-group cp">
                            <input
                              name="confirm_password"
                              id="confirm_password"
                              type={!conformshow === true ? "text" : "password"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirm_password}
                              className="form-control"
                            />
                            <span
                              className="input-group-text"
                              onClick={() => {
                                handleshow("confirepwd");
                              }}
                            >
                              {!conformshow === true ? (
                                <i className="far fa-eye-slash"></i>
                              ) : (
                                <i className="far fa-eye"></i>
                              )}
                            </span>
                          </div>
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"confirm_password"} />
                          </span>
                        </div>
                        <div className="col-lg-6 text-center form-group">
                          <label htmlFor="files" className="text-left d-flex">
                            Profile:{isFieldRequired('files') && <span className="requirestar">*</span>}
                          </label>
                          <input
                            className="form-control imgInput"
                            id="files"
                            name="files"
                            accept="image/*"
                            type="file"
                            onChange={(event) => {
                                setFieldValue("files", event.currentTarget.files[0] || "");
                                event.currentTarget.files.length === 1
                                    ? setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))
                                    : setPreviewImage("");
                            }}
                        />
                         <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"files"} />
                          </span>
                        </div>
                        {previewimage ? (
                          <div className="mb-4">
                            <img src={previewimage} style={{ height: "100px" }} alt={""} />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-lg-6 text-center form-group">
                          <label
                            className="text-left d-flex"
                            htmlFor="phone_number"
                          >
                            Phone number:{isFieldRequired('phone_number') && <span className="requirestar">*</span>}
                          </label>
                          <div className="form-control d-flex">
                            <select
                              className="selectInput"
                              style={{
                                height: "100%",
                                paddingLeft: 0,
                                paddingTop: 0,
                                marginTop: 0,
                                border: 0,
                                width: 40,
                              }}
                              value={values.countryCodePhone}
                              onChange={(event) => {
                                console.log("event", event);
                                setFieldValue(
                                  "countryCodePhone",
                                  event.target.value
                                );
                                setFieldValue(
                                  "dialCodePhone",
                                  "+" + getCountryCallingCode(event.target.value)
                                );
                              }}
                            >
                              {getCountries().map((country) => (
                                <>

                                  <option key={country} value={country}>
                                    <ReactCountryFlag countryCode={country}

                                    />
                                    {showDropDown(country)}
                                  </option>
                                </>
                              ))}
                            </select>
                            <label
                              style={{ padding: 0, margin: 0, paddingTop: 2 }}
                            >
                              {values.dialCodePhone}
                            </label>
                            <input
                              className={"phoneInput"}
                              name="phone_number"
                              style={{ border: 0 }}
                              onChange={(event) => {
                                setFieldValue(`phone_number`, event.target.value);
                              }}
                              value={`${values.phone_number}`}
                              onKeyDown={blockInvalidChar}
                            />
                          </div>
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"phone_number"} />
                          </span>
                        </div>
                        <div className="col-lg-6 text-center form-group">
                          <label
                            className="text-left d-flex"
                            htmlFor="phone_number_work"
                          >
                            Phone number work:{isFieldRequired('phone_number_work') && <span className="requirestar">*</span>}
                          </label>
                          <div className="form-control d-flex">
                            <select
                              className="selectInput"
                              style={{
                                height: "100%",
                                paddingLeft: 0,
                                paddingTop: 0,
                                marginTop: 0,
                                border: 0,
                                width: 40,
                              }}
                              value={values.countryCodePhoneWork}
                              onChange={(event) => {
                                console.log("event", event);
                                setFieldValue(
                                  "countryCodePhoneWork",
                                  event.target.value
                                );
                                setFieldValue(
                                  "dialCodePhoneWork",
                                  "+" + getCountryCallingCode(event.target.value)
                                );
                              }}
                            >
                              {getCountries().map((country) => (
                                <>

                                  <option key={country} value={country}>
                                    <ReactCountryFlag countryCode={country}

                                    />
                                    {showDropDown(country)}
                                  </option>
                                </>
                              ))}
                            </select>
                            <label
                              style={{ padding: 0, margin: 0, paddingTop: 2 }}
                            >
                              {values.dialCodePhoneWork}
                            </label>
                            <input
                              className={"phoneInput"}
                              name="phone_number_work"
                              style={{ border: 0 }}
                              onChange={(event) => {
                                setFieldValue(`phone_number_work`, event.target.value);
                              }}
                              value={`${values.phone_number_work}`}
                              onKeyDown={blockInvalidChar}
                            />
                          </div>
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"phone_number_work"} />
                          </span>
                        </div>
                        {/* {previewimage ? (
                          <div className="mb-4">
                            <img src={previewimage} style={{ height: "100px" }} alt={""} />
                          </div>
                        ) : (
                          ""
                        )} */}
                        {/* <div className="col-md-6 ">
                       <label className="form-label">Extra Information</label>
                      <input className={"form-control mb-2"}
                      placeholder="Enter your employee code"
                      type="employee_code"
                      name="employee_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employee_code}
                      autoComplete="off" />
                     <input className={"form-control"}
                      placeholder="Enter your employee number"
                      type="employee_number"
                      name="employee_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employee_number}
                      autoComplete="off" />
                  </div> */}
                  <div className="col-md-6 text-center form-group">
                          <label htmlFor="event_function" className="text-left d-flex">
                            Function:{isFieldRequired('event_function') && <span className="requirestar">*</span>}
                          </label>
                          <input
                            name="event_function"
                            id="event_function"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.event_function}
                            className="form-control"
                          />
                           <span className='text-danger d-flex text-left'><ErrorMessage name={"event_function"} /></span>
                        </div>
                  <div className="col-md-6 text-center form-group">
                          <label
                            htmlFor="first_name"
                            className="text-left d-flex"
                          >
                            Notes:{isFieldRequired('notes') && <span className="requirestar">*</span>}
                          </label>
                          <textarea
                            name="notes"
                            id="notes"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.notes}
                            className="form-control"
                            style={{ height:"82px" }}
                          />
                           <span className='text-danger d-flex text-left'><ErrorMessage name={"notes"} /></span>
                        </div>
                        <div className="col-md-6 ">
                       <label className="form-label">Extra Information{isFieldRequired('extra_info') && <span className="requirestar">*</span>}</label>
                      <input className={"form-control mb-2"}
                      placeholder="Enter your employee code"
                      type="number"
                      name="extra_info.employee_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.extra_info.employee_code}
                      autoComplete="off" />
                       <span className='text-danger d-flex text-left'><ErrorMessage name={"extra_info.employee_code"} /></span>
                     <input className={"form-control"}
                      placeholder="Enter your employee number"
                      type="number"
                      name="extra_info.employee_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.extra_info.employee_number}
                      autoComplete="off" />
                       <span className='text-danger d-flex text-left'><ErrorMessage name={"extra_info.employee_number"} /></span>
                  </div>
                        <div className="mt-5">
                          <button
                            className="btn btn-info mr-2"
                            type="submit"
                            disabled={submitted ? true : null}
                          >
                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                            Submit
                          </button>
                          <button
                            className="btn ripple btn-secondary"
                            type="button"
                            disabled={submitted ? true : null}
                            onClick={() => navigate(-1)}
                          >
                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </>
  );
};

export default UsersAdd;
