import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toHtml from 'html-react-parser'
import * as staffService from '../../services/staff.services';
import Breadcrums from '../../common/breadcrumbs';
import Loader from '../../common/loader'
import { formateDate, showStatus } from '../../../../utils/commonfunction';
import { STAFF_ROLE} from "../../../../utils/Constants";

const StaffView = (props) => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Staff", url: "/admin/staff/list/1" }, { title: "View", url: "" },]


    useEffect(() => {
        staffService.Details(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [params.id])

    const decideRole = (role) => {
        if(role === STAFF_ROLE.ADMIN){
          return <span className="badge badge-success">Admin</span>
        }else if(role === STAFF_ROLE.SUB_ADMIN){
          return <span className="badge badge-success">Sub Admin</span>
        }else if(role === 3){
          return <span className="badge badge-success">User</span>
        }else if(role === STAFF_ROLE.STAFF){
          return <span className="badge badge-success">Staff</span>
        }
        else{
            return <span className="badge badge-light">Unknown</span>
          }
      }


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="main-content-label mb-3">View Staff</h6>
                            </div>
                            {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table  table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                            <th>Profile</th>
                                            <td><img src={showdefault && showdefault.profile_image ? showdefault.profile_image : "N/A"} alt="profile" style={{ height: "100px" }} /></td>
                                            </tr>
                                            <tr>
                                                <th> First Name</th>
                                                <td>{showdefault && showdefault.first_name ? showdefault.first_name : "N/A"}</td>
                                            </tr>
                                           
                                            <tr>
                                                <th>Last Name</th>
                                                <td>{showdefault && showdefault.last_name ? showdefault.last_name : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Email</th>
                                                <td>{showdefault && showdefault.email ? showdefault.email : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Role</th>
                                                <td>{decideRole(showdefault.role)}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{showdefault && showdefault.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault.status))}</td>
                                            </tr>
                                            <tr>
                                                <th>Created Date</th>
                                                <td>{showdefault && showdefault.createdAt ? formateDate(showdefault.createdAt) : "N/A"}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {/* <h6 className="main-content-label mb-3">Description</h6>
                                    <div className="">{toHtml(showdefault && showdefault.description ? showdefault.description : "N/A")}</div> */}
                                    <div className="mt-5">
                                        <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                                            <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div> : <Loader />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StaffView