import React, { useEffect, useState } from 'react';
import { Outlet, useParams, Link, useLocation } from 'react-router-dom';
import Breadcrums from '../../common/breadcrumbs';

const View = (props) => {
  const params = useParams();
  const loc = useLocation();
  const [eventTitle, setEventTitle] = useState("");

  useEffect(() => {
    setEventTitle(loc?.state?.row?.title);
  },[]);
  
  const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Events", url: "/admin/event-management/events/list/1" }, { title: "View", url: "" }];


  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h6 className="main-content-label">{eventTitle}</h6>
              </div>
              <div className="tabs-wrap">
              <div className="profile-tab tab-menu-heading mb-4">
								<nav className="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
                <Link
                    className={"nav-link linkactive " + (loc.pathname === "/admin/event-management/events/view/event/" + params?.id ? " active" : "")}
                    aria-current="page"
                    to={"/admin/event-management/events/view/event/" + params?.id}
                  >
                    Event
                  </Link>
                  <Link
                    className={"nav-link linkactive " + (loc.pathname.includes(`/admin/event-management/events/view/hotels/${params?.id}`) ? " active" : "")}
                    to={`/admin/event-management/events/view/hotels/${params?.id}/1`}
                  >
                    Hotels
                  </Link>
                  <Link
                    className={"nav-link linkactive " + (loc.pathname.includes(`/admin/event-management/events/view/agenda/${params?.id}`)  ? " active" : "")}
                    to={`/admin/event-management/events/view/agenda/${params?.id}/1`}
                  >
                    Agenda
                  </Link>
                  <Link
                    className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/invitations/${params?.id}`) ? " active" : "")}
                    to={`/admin/event-management/events/view/invitations/${params?.id}?page=1`}
                  >
                    Invitations
                  </Link>
                  <Link
                    className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/registrations/${params?.id}`) ? " active" : "")}
                    to={`/admin/event-management/events/view/registrations/${params?.id}/1`}
                  >
                    Registrations
                  </Link>
                  <Link
                    className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/attendees/${params?.id}`) ? " active" : "")}
                    to={`/admin/event-management/events/view/attendees/${params?.id}/1`}
                  >
                    Attendees
                  </Link>
                  <Link
                    className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/event-announcements`) ? " active" : "")}
                    to={`/admin/event-management/events/view/event-announcements/${params?.id}/1`}
                  >
                    Announcements
                  </Link>
                  <Link
                    className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/checkIn-checkOut/${params?.id}`) ? " active" : "")}
                    to={`/admin/event-management/events/view/checkIn-checkOut/${params?.id}/1`}
                  >
                    Checkin/Checkout
                  </Link>
                  <Link
                    className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/event-transactions/${params?.id}`) ? " active" : "")}
                    to={`/admin/event-management/events/view/event-transactions/${params?.id}/1`}
                  >
                    Transactions
                  </Link>
									{/* <Link className={"nav-link linkactive" + (loc.pathname == "/admin/event-management/events/view/event/" + params?.id ? "active" : "")} to={`edit/${"1"}`}>General</Link>
									<Link className={"nav-link linkactive" + (loc.pathname.includes("change-password") ? " active" : "")} to={`change-password/${"1"}`}>Change Password</Link> */}
								</nav>
							</div>
                {/* <div className="row scroll-tabs filter-scroll c-o-c-a coures-scroll-arrow mb-3">
                  <button type="button" className="btn btn-arrow btn-scroll-left w-auto" 
                  // onClick={() => (bottomScroll.current.scrollLeft = bottomScroll.current.scrollLeft - 40)}
                  >
                    <i className="fa fa-chevron-left"></i>
                  </button>

                  <Link
                    style={{border: "1px solid #ccc",width: "12%", }}
                    className={"flex-sm text-sm-center nav-link pl-2 pr-2 " + (loc.pathname == "/admin/event-management/events/view/event/" + params?.id ? "active" : "") }
                    aria-current="page"
                    to={"/admin/event-management/events/view/event/" + params?.id}
                  >
                    Event
                  </Link>
                  <Link
                    style={{border: "1px solid #ccc",width: "12%",}}
                    className={"flex-sm text-sm-center nav-link pl-2 pr-2 " + (loc.pathname == `/admin/event-management/events/view/hotels/${params?.id}/1` ? "active" : "")}
                    to={`/admin/event-management/events/view/hotels/${params?.id}/1`}
                  >
                    Hotels
                  </Link>
                  <Link
                    style={{border: "1px solid #ccc",width: "12%", }}
                    className={"flex-sm text-sm-center nav-link pl-2 pr-2 " + (loc.pathname == `/admin/event-management/events/view/agenda/${params?.id}/1`  ? "active" : "")}
                    to={`/admin/event-management/events/view/agenda/${params?.id}/1`}
                  >
                    Agenda
                  </Link>
                  <Link
                    style={{border: "1px solid #ccc",width: "14%", }}
                    className={"flex-sm text-sm-center nav-link pl-2 pr-2 " + (loc.pathname == `/admin/event-management/events/view/registrations/${params?.id}/1` ? "active" : "")}
                    to={`/admin/event-management/events/view/registrations/${params?.id}/1`}
                  >
                    Registrations
                  </Link>
                  <Link
                    style={{border: "1px solid #ccc",width: "14%", }}
                    className={"flex-sm text-sm-center nav-link pl-2 pr-2 " + (loc.pathname == `/admin/event-management/events/view/invitations/${params?.id}/1` ? "active" : "")}
                    to={`/admin/event-management/events/view/invitations/${params?.id}/1`}
                  >
                    Invitations
                  </Link>
                  <Link
                    style={{border: "1px solid #ccc",width: "14%", }}
                    className={"flex-sm text-sm-center nav-link pl-2 pr-2 " + (loc.pathname == `/admin/event-management/events/view/attendees/${params?.id}/1` ? "active" : "")}
                    to={`/admin/event-management/events/view/attendees/${params?.id}/1`}
                  >
                    Attendees
                  </Link>

                  <button type="button" className="btn btn-arrow btn-scroll-right w-auto" 
                    // onClick={() => (bottomScroll.current.scrollLeft = bottomScroll.current.scrollLeft + 40)}
                    >
                      <i className="fa fa-chevron-right"></i>
                  </button>
                </div> */}

                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default View;