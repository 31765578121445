import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import * as newsletterservices from '../../services/newsletterservice';
import Breadcrums from '../../common/breadcrumbs';
import Loader from '../../common/loader'
import {  formateDate } from '../../../../utils/commonfunction';
import CustomPagination from '../../common/custompagination';
import { INVITATION_TYPES } from '../../../../utils/Constants';
import {Modal} from "rsuite";

const NewsLettersView = (props) => {
    const navigate = useNavigate()
    const [itemperpage] = useState(10);
    const [page, setPage] = useState(1);
    const [search] = useState({});
    const [globalsearch] = useState("");
    const location = useLocation();
    const [loader, setLoader] = useState(true);
    const [emailBody, setEmailBody] = useState({});
    const params = useParams()
    const [datalength, setDataLength] = useState(0);
    const [list, setList] = useState([]);
    const [showdefault, setShowDefault] = useState({})
    const [secondModal, setSecondModal] = useState(false);
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Newsletters", url: "/admin/news-letter/list/1" }, { title: "View", url: "" },]

    useEffect(() => {
        newsletterservices.Details(params.id).then(response => {
            console.log('lblvblvblvblvbvb', response)
            setShowDefault(response && response.data ? response.data : {})
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [params.id])

    useEffect(() => {
        // console.log('params.pgnoparams.pgno',params.pgno)
        if (search) {
            setLoader(true);
            const formData = new FormData();
            formData.append("page", params.pgno);
            formData.append("per_page", itemperpage);
            formData.append("search", JSON.stringify(search));
            formData.append("global_search", globalsearch);
            formData.append("o_id", params.id);
            newsletterservices.NewsLetterUsersList(formData).then((response) => {
                if (response.success) {
                    console.log('responseresponse', response)
                    setDataLength(response.data && response.data.total_records && typeof response.data.total_records == "number" ? response.data.total_records : 0);
                    // setSerialNo(data.data.offset);
                    setList(response.data && response.data.list && Array.isArray(response.data.list) ? response.data.list : []);
                    setLoader(false);

                }
            })
                .catch((error) => {
                    console.log("error ====> ", error);
                });
        }
    }, [location, search, globalsearch,params.pgno,itemperpage,params.id]);


    const showInviteType = (type) => {
        if (type === INVITATION_TYPES.SEND_NOW) {
            return <span className="badge badge-success">Sent</span>;
        } else if (type === INVITATION_TYPES.SCHEDULE) {
            return <span className="badge badge-info">Scheduled</span>;
        } else if (type === INVITATION_TYPES.SEND_LATER) {
            return <span className="badge badge-warning">Send Later</span>;
        }
    }

    
    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="main-content-label mb-3">View Newsletter</h6>
                            </div>
                            {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th>Title</th>
                                                <td>{showdefault && showdefault.title ? showdefault.title : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Type</th>
                                                <td>{showInviteType(showdefault && showdefault.type)}</td>
                                            </tr>
                                            <tr>
                                                <th>Created Date</th>
                                                <td>{showdefault && showdefault.createdAt ? formateDate(showdefault.createdAt) : "N/A"}</td>
                                            </tr>
                                        </tbody>
                                    </table>


                                </div>
                            </div> : <Loader />}
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <h6 className="main-content-label">Subscribers</h6>
                                <div className="d-flex align-items-center">
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="sorting_column">
                                                    <span>Name</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="sorting_column">
                                                    <span>Email</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="sorting_column">
                                                    <span>Salutation</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="sorting_column">
                                                    <span>Created Date</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="sorting_column">
                                                    <span>Action</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loader ? (
                                            <tr>
                                                <td colSpan={7}>
                                                    {/* Your loader component */}
                                                    <Loader />
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                            {console.log(emailBody)}
                                                {list.length ? (
                                                    list.map((row, i) => (
                                                        <tr key={i}>
                                                            <td>{row?.users_data.name ? row.users_data.name : "N/A"}</td>
                                                            <td>{row?.users_data.email ? row.users_data.email : "N/A"}</td>
                                                            <td className="text-capitalize">{row?.users_data.gender ? row.users_data.gender : "N/A"}</td>
                                                            <td>
                                                                {row.createdAt ? formateDate(row.createdAt) : "N/A"}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <button
                                                                        className="btn ripple btn-main-primary signbtn ms-2"
                                                                        onClick={() => {setSecondModal(true);setEmailBody({subject:row?.email_data?.subject, email_body:row?.email_body})}}

                                                                    >
                                                                        View
                                                                    </button>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={8} className="text-center">
                                                            No records
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div
                                className=""
                                id="example1_info"
                                role="status"
                                aria-live="polite"
                            >
                                <b>Total Records : {datalength ? datalength : "0"}</b>
                            </div>
                            {datalength && datalength > 0 ? (
                                <CustomPagination
                                    datalength={datalength}
                                    itemperpage={itemperpage}
                                    currentPage={page}
                                    setPage={setPage}
                                    pageRoute={[
                                        { name: "Newsletters", path: `/admin/news-letter/view/${params.id}` },
                                    ]}
                                />
                            ) : (
                                ""
                            )}
                            <div className="mt-3">
                                        <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                                            <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                            Back
                                        </button>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* View Modal */}
            <Modal
                size={"lg"}
                className="invitation-modal modal-height-auto"
                open={secondModal}
                onClose={() => setSecondModal(false)}
                backdrop={'static'}
            >
                <Modal.Header className="mb-3">
                    <Modal.Title>Email Body</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                       <h4>{emailBody?.subject}</h4>
                       <div dangerouslySetInnerHTML={{ __html: emailBody && emailBody.email_body ? emailBody.email_body : "N/A" }}></div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default NewsLettersView