import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { cmsDetails } from "../services/common.services";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from 'react-i18next';


function PrivacyPolicy() {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState({});
  const { t } = useTranslation();


  useEffect(() => {
    setLoading(true);
    cmsDetails("privacy-policy").then((response) => {
      console.log("response",response?.data?.description)
      setData(response?.data);
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      console.log("error of terms&conditions",error);
    })
  },[])


  return (
    <div className="wraper-inner cpt">
      {/*-banner-inner---*/}
      <section className="inner-banner">
        <div className="container">
          <div className="row ">
            <div className="banner-inner-card">
              <h1>{t('web_privacy_policy')}</h1>
              <nav className="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>{t('web_header_heading_home')}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t('web_privacy_policy')}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="content-box-main section-padding">
        <div className="container">
          {loading ? <Skeleton count={10} style={{height:'50px'}}/> : <></>}
          <div dangerouslySetInnerHTML={{ __html: `${data && data?.description ? data?.description : "N/A"}` }}> 
          {/* {toHtml(data && data?.description ? data?.description : "N/A")} */}
          </div>
          {/* <div className="content-box mb-3">
            <h3>
              There are many variations of passages of Lorem Ipsum available
            </h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              printer took a galley of type and scrambled it to make a type
              specimen book.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>
          </div>
          <div className="content-box mb-3">
            <h3>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
            </h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>
            <div className="content-box ps-5 content-list-box mb-3">
              <h3>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text.
              </h3>
              <ul className="list-content">
                <li>Embrace innovation. Embrace success.</li>
                <li>Your journey starts here. Explore, create, thrive.</li>
                <li>Empowering you to achieve more, one click at a time.</li>
                <li>Discover a world of possibilities at your fingertips.</li>
              </ul>
            </div>
          </div>
          <div className="content-box mb-3">
            <h3>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
            </h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>
            <div className="content-box ps-5 content-list-box mb-3">
              <h3>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text.
              </h3>
              <ul className="list-content">
                <li>Embrace innovation. Embrace success.</li>
                <li>Your journey starts here. Explore, create, thrive.</li>
                <li>Empowering you to achieve more, one click at a time.</li>
                <li>Discover a world of possibilities at your fingertips.</li>
              </ul>
            </div>
            <div className="content-box mb-3">
              <h3>
                There are many variations of passages of Lorem Ipsum available
              </h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text printer took a galley of type and scrambled it to make a
                type specimen book.
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </p>
            </div>
            <div className="content-box mb-3">
              <h3>
                There are many variations of passages of Lorem Ipsum available
              </h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text printer took a galley of type and scrambled it to make a
                type specimen book.
              </p>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </p>
            </div>
          </div> */}
        </div>
      </section>
      {/*-blog2--*/}
    </div>
  );
}

export default PrivacyPolicy;
