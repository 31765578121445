import axiosInstance from "../../../utils/axios";

const path = "admin/portal-design"

export const Update = async (values) => {
    return await axiosInstance().post(`${path}/update`, values);
}

export const Details = async () => {
    return await axiosInstance().get(`${path}/details`);
}
export const ResetSetting = async () => {
    return await axiosInstance().post(`${path}/reset-settings`);
}