import React, { useEffect, useLayoutEffect, useState } from 'react'
import Header from './header'
import Footer from './footer'
import SideBar from './sidebar';
import { Outlet } from 'react-router-dom';
import { setGlobalLoader } from '../../../redux/slices/globalLoader';
import * as portalDesignService from '../../admin/services/portal.design.services';
import { useDispatch, useSelector } from 'react-redux';
import GlobalLoader from './globalLoader';
import { setUserType } from '../../../redux/slices/userType';
import {  setLocalKey } from '../../../utils/commonfunction';
import { fetchDetailsApi } from '../../../redux/slices/globalDetails';
import { globalSetting } from '../../user/services/common.services';
import { addData } from '../../../redux/slices/globalData';


const Adminlayout = () => {
    const dispatch = useDispatch();
    const [dynamictitle, setDynamicTitle] = useState("Default Title")
    const loaderState = useSelector((state) => state.globalLoader);
    const globalDetails = useSelector((state) => state.globaldetail);
    const [getGlobalData, setGlobalData] = useState({});
    const myGlobalData = useSelector((state) => state.globalData);
    

    useEffect(() => {
        globalSetting().then(async (globalDetails) => {
            dispatch(addData(JSON.stringify(globalDetails?.data)));
        }).catch((err)=>{
            console.log(err)
        })
        // if(globalDetails && globalDetails.data){
        //     setLocalKey("GlobalDetails", JSON.stringify(globalDetails.data));
        // }
    },[]);

    useLayoutEffect(() => {
        portalDesignService.Details().then((response) => {
            if (response.success) {
                setDynamicTitle(response && response.data && response.data.general && response.data.general.title)
                document.title = response && response.data.general && response.data.general.title //set admin title dynamically 
            }
        }).catch((error) => {
            console.log("error=====>", error)
        })
    }, [dynamictitle])

    useLayoutEffect(() => {
        dispatch(setUserType("admin"));
        Promise.all([import('rsuite/dist/rsuite.min.css'),
        import('bootstrap/dist/css/bootstrap.min.css'),
        import("../../../assets/admin/css/skins.css"),
        import("../../../assets/admin/css/icon-list.css"),
        import("../../../assets/admin/plugins/select2/css/select2.min.css"),
        import("../../../assets/admin/css/style.css"),
        import("../../../assets/admin/css/dark-style.css"),
        import("../../../assets/admin/css/colors/default.css"),
        import("../../../assets/admin/plugins/multipleselect/multiple-select.css"),
        import("../../../assets/admin/css/sidemenu/sidemenu.css"),
        import("../../../assets/admin/plugins/summernote/summernote-bs4.css"),
        import("../../../assets/admin/plugins/fileuploads/css/fileupload.css"),
        import("../../../assets/admin/plugins/fancyuploder/fancy_fileupload.css")]).then(() => {
            dispatch(setGlobalLoader(false));
        })
    }, []);

    useEffect(() => {
        dispatch(fetchDetailsApi());
    },[])
    

  useEffect(() => {
    if (myGlobalData && myGlobalData?.data) {
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  }, [myGlobalData]);
    return (
        <>
            {
                loaderState ?
                    <GlobalLoader /> :
                    <div className='page'>
                        <SideBar />
                        <Header />
                        <div className="main-content side-content">
                            <div className="container-fluid">
                                <div className="inner-body">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                        <a href="#top" id="back-to-top"><i className="fe fe-arrow-up"></i></a>
                        <Footer />
                    </div>
            }

        </>

    )
}
export default Adminlayout