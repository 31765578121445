import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import $ from "jquery";
import { Link } from 'react-router-dom';
import * as portalDesignService from '../../admin/services/portal.design.services';
import {  hasPermission } from '../../../utils/commonfunction';

const SideBar = () => {


   const loc = useLocation();
   const [logopath, setLogoPath] = useState('');
   const [shortlogo, setShortLogo] = useState('');

   useEffect(() => {
      portalDesignService.Details().then((response) => {
         setLogoPath(response.data.logo_transparent)
         setShortLogo(response.data.logo_short)
      }).catch((error) => {
         console.log("error=====>", error)
      })
   }, [logopath])


   const toggleDropdown = (e) => {
      e.preventDefault();
      const dropDown = e.target.parentElement;
      if (dropDown.classList.contains('show')) {
         dropDown.classList.remove('show');
      } else {
         dropDown.classList.add('show');
      }
   }

   const hoverSidebar = (e) => {
      if ($("body").hasClass("main-sidebar-hide")) {
         $("body").addClass("main-sidebar-open");
      } else {
         $("body").removeClass("main-sidebar-open");
      }
   }

   const removeHoverSidebar = (e) => {
      $("body").removeClass("main-sidebar-open");
   }


   return (
      <div className="main-sidebar main-sidebar-sticky side-menu ps ps--active-y" id='app_sidebar' onMouseEnter={hoverSidebar} onMouseLeave={removeHoverSidebar}>
         <div className="sidemenu-logo">
            <a className="main-logo" href="/admin/dashboard">
               <img src={shortlogo} className="header-brand-img icon-logo admin-logo" alt="logo" style={{ height: "40px", width: "40px" }} id="logo_short" />
               <img src={logopath} className="header-brand-img desktop-logo admin-logo" alt="logo" id="logo_full" />
            </a>
         </div>
         {/* Dashboard section start */}
         <div className="main-sidebar-body">
            <ul className="nav">
               <li className={"nav-item" + (loc.pathname === "/admin/dashboard" ? " active" : "")}>
                  <Link className="nav-link" to="/admin/dashboard"><span className="shape1"></span><span className="shape2"></span><i className="fa fa-home sidemenu-icon"></i><span className="sidemenu-label">Dashboard</span></Link>
               </li>
               {/* Dashboard section end */}

               {/* User management section start */}
               {hasPermission('/admin/user-management') ?
                  <li className={`nav-item ` + (loc.pathname.includes("/user-management") ? "show active" : "")}>
                     <Link className="nav-link" to={`/admin/user-management/users/list/${1}`}><span className="shape1"></span><span className="shape2"></span><i className="fa fa-users sidemenu-icon"></i><span className="sidemenu-label">Users</span></Link>
                  </li>
                  : null}
               {/* User management section end */}


               {/* Staff section start */}
               {hasPermission('/admin/staff') ? (
                  <li className={`nav-item ` + (loc.pathname.includes("/staff") ? "show active" : "")}>
                     <Link className="nav-link" to={`/admin/staff/list/${1}`}><span className="shape1"></span><span className="shape2"></span><i className="fa fa-users-cog sidemenu-icon"></i><span className="sidemenu-label">Staff</span></Link>
                  </li>
               ) : (
                  null
               )}
               {/* Staff section end */}


               {/* Cms section start */}
               {hasPermission('/admin/cms') ? (
                  <li className={`nav-item ` + (loc.pathname.includes("/cms") ? "show active" : "")}>
                     <a className="nav-link with-sub submenu" onClick={toggleDropdown} href={"/"}>
                        <span className="shape1"></span><span className="shape2"></span>
                        <i className="ti-wallet sidemenu-icon"></i>
                        <span className="sidemenu-label">Cms</span>
                        <i className="angle fe fe-chevron-right"></i>
                     </a>
                     <ul className="nav-sub">
                        <li className={`nav-sub-item ${loc.pathname.includes("/cms/portal-design") ? " active" : ""}`}>
                           <Link className="nav-sub-link" to={`/admin/cms/portal-design`}>Portal Designer</Link>
                        </li>
                        <li className={`nav-sub-item ${loc.pathname.includes("/cms/pages") ? " active" : ""}`}>
                           <Link className="nav-sub-link" to={`/admin/cms/pages/list/${1}`}>Pages</Link>
                        </li>
                        <li className={`nav-sub-item ${loc.pathname.includes("/cms/banner") ? " active" : ""}`}>
                           <Link className="nav-sub-link" to={`/admin/cms/banner/list/${1}`}>Banners</Link>
                        </li>
                        <li className={"nav-sub-item" + (loc.pathname.includes("/cms/default-email-template") ? " active" : "")}>
                           <Link className="nav-sub-link" to={`/admin/cms/default-email-template/list/${1}`}>Automatic Emails</Link>
                        </li>
                        <li className={"nav-sub-item" + (loc.pathname.includes("/cms/push-notification-template") ? " active" : "")}>
                           <Link className="nav-sub-link" to={`/admin/cms/push-notification-template/list/${1}`}>Push Notification Template</Link>
                        </li>
                        <li className={`nav-sub-item ${loc.pathname.includes("/cms/promotional-email-templates") ? " active" : ""}`}>
                           <Link className="nav-sub-link" to={`/admin/cms/promotional-email-templates/list/${1}`}>Newsletter Emails</Link>
                        </li>
                        <li className={"nav-sub-item" + (loc.pathname.includes("/cms/settings") ? " active" : "")}>
                           <Link className="nav-sub-link" to={`/admin/cms/settings`}>Global Settings</Link>
                        </li>
                        <li className={"nav-sub-item" + (loc.pathname.includes("/cms/language-files") ? " active" : "")}>
                           <Link className="nav-sub-link" to={`/admin/cms/language-files/frontend`}>Language Files</Link>
                        </li>
                        <li className={"nav-sub-item" + (loc.pathname.includes("/cms/style-files") ? " active" : "")}>
                           <Link className="nav-sub-link" to={`/admin/cms/style-files`}>Root Style</Link>
                        </li>
                     </ul>
                  </li>
               ) : (
                  null
               )}
               {/* Cms section end */}


               {/* Newsletters section start */}
               {hasPermission('/admin/news-letter') ?
                  <li className={`nav-item ` + (loc.pathname.includes("news-letter") || loc.pathname.includes("distribution-list") ? "show active" : "")}>
                     <a className="nav-link with-sub submenu" onClick={toggleDropdown} href={"/"}>
                        <span className="shape1"></span><span className="shape2"></span>
                        <i className="fa fa-newspaper-o sidemenu-icon"></i>
                        <span className="sidemenu-label">Newsletters</span>
                        <i className="angle fe fe-chevron-right"></i>
                     </a>
                     <ul className="nav-sub">
                        <li className={`nav-sub-item ${loc.pathname.includes("/news-letter/list") ? " active" : ""}`}>
                           <Link className="nav-sub-link" to={`/admin/news-letter/list/${1}`}>Newsletters</Link>
                        </li>
                        <li className={`nav-sub-item ${loc.pathname.includes("/distribution-list/") ? " active" : ""}`}>
                           <Link className="nav-sub-link" to={`/admin/distribution-list/list/${1}`}>Distribution List</Link>
                        </li>
                     </ul>
                  </li>
                  : null}
               {/* Newsletters section end */}



               {/* Media section start */}
               {hasPermission('/admin/media') ?
                  <li className={"nav-item" + (loc.pathname.includes("media") ? " active" : "")}>
                     <Link className="nav-link" to={`/admin/media`}><span className="shape1"></span><span className="shape2"></span><i className="fa fa-picture-o sidemenu-icon"></i><span className="sidemenu-label">Media</span></Link>
                  </li>
                  : null}
               {/* Media section end */}


               {/* Email logs section start */}
               {hasPermission('/admin/email-logs') ?
                  <li className={"nav-item" + (loc.pathname.includes("email-logs") ? " active" : "")}>
                     <Link className="nav-link" to={`/admin/email-logs/list/${1}`}><span className="shape1"></span><span className="shape2"></span><i className="fa fa-envelope sidemenu-icon" aria-hidden="true"></i><span className="sidemenu-label">Email Logs</span></Link>
                  </li>
                  : null}
               {/* Email logs section end */}


               {/* Event management section start */}
               {hasPermission('/admin/event-management') ?
               <li className={`nav-item ` + (loc.pathname.includes("/event-management") ? "show active" : "")}>
                  <a className="nav-link with-sub submenu" onClick={toggleDropdown} href={"/"}>
                     <span className="shape1"></span><span className="shape2"></span>
                     <i className="fa fa-hourglass sidemenu-icon"></i>
                     <span className="sidemenu-label">Events</span>
                     <i className="angle fe fe-chevron-right"></i>
                  </a>
                  <ul className="nav-sub">
                     <li className={`nav-sub-item ${loc.pathname.includes("/event-management/events") ? " active" : ""}`}>
                        <Link className="nav-sub-link" to={`/admin/event-management/events/list/${1}`}>Events</Link>
                     </li>
                  </ul>
               </li>
               :null}
               {/*Event management section end */}

               {/* Question section start */}
               {hasPermission('/admin/question-settings') ?
               <li className={`nav-item ` + (loc.pathname.includes("/question-settings") ? "show active" : "")}>
                  <a className="nav-link with-sub submenu" onClick={toggleDropdown} href={"/"}>
                     <span className="shape1"></span><span className="shape2"></span>
                     <i className="fa fa-question-circle sidemenu-icon"></i>
                     <span className="sidemenu-label">Question Sets</span>
                     <i className="angle fe fe-chevron-right"></i>
                  </a>
                  <ul className="nav-sub">
                     <li className={`nav-sub-item ${loc.pathname.includes("/sets") ? " active" : ""}`}>
                        <Link className="nav-sub-link" to={`/admin/question-settings/sets/list/${1}`}>Question Sets / Survey</Link>
                     </li>
                  </ul>
               </li>
               :null}
               {/* Question section end */}

               {/* Activity section start */}
               {hasPermission('/admin/activity-forum') ?
               <li className={`nav-item ` + (loc.pathname.includes("/activity-forum") ? "show active" : "")}>
                  <a className="nav-link with-sub submenu" onClick={toggleDropdown} href={"/"}>
                     <span className="shape1"></span><span className="shape2"></span>
                     <i className="fa fa-sign-language sidemenu-icon"></i>
                     <span className="sidemenu-label">Activity Forums</span>
                     <i className="angle fe fe-chevron-right"></i>
                  </a>
                  <ul className="nav-sub">
                     <li className={`nav-sub-item ${loc.pathname.includes("/activities") ? " active" : ""}`}>
                        <Link className="nav-sub-link" to={`/admin/activity-forum/activities/list/${1}`}>Activity Forums / Topics</Link>
                     </li>
                     {/* <li className={"nav-sub-item" + (loc.pathname.includes("/topics") ? " active" : "")}>
                        <Link className="nav-sub-link" to={`/admin/activity-forum/topics/list/${1}`}>Topics</Link>
                     </li> */}

                  </ul>
               </li>
               :null}
               {/* Activity section end */}
               {hasPermission('/admin/announcements') ?
               <li className={`nav-item ` + (loc.pathname.includes("/announcements") ? "show active" : "")}>
                  <Link className="nav-link" to={`/admin/announcements/list/${1}`}><span className="shape1"></span><span className="shape2"></span><i className="fa fa-bullhorn sidemenu-icon"></i><span className="sidemenu-label">Announcements</span></Link>
               </li>
               :null}
               <li className={`nav-item ` + (loc.pathname.includes("/transactions") ? "show active" : "")}>
                  <Link className="nav-link" to={`/admin/transactions/list/${1}`}><span className="shape1"></span><span className="shape2"></span><i className="fa fa-bank sidemenu-icon"></i><span className="sidemenu-label">Transactions</span></Link>
               </li>
              </ul>
         </div >
      </div >
   )
}

export default SideBar;