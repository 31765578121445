import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as Topicservices from "../../services/topic.services"
import { useParams } from 'react-router-dom'
import { formateDate, TrimText, getUser } from '../../../../utils/commonfunction'
import { COMMENT_TYPES, SWAL_SETTINGS, VIDEO_UPLOAD } from '../../../../utils/Constants'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';
import CommentsToTopic from './commentsToTopic'
import Picker from '@emoji-mart/react';
import * as $ from 'jquery';
import { useSelector } from 'react-redux'

const TopicsView = () => {
    const params = useParams();
    const navigate = useNavigate();
    const selectImageInput = useRef();
    const [topicviewlist, setTopicViewList] = useState([]);
    let { state } = useLocation();
    const [id, setId] = useState();
    const [update, setUpdate] = useState("false");
    const [comment, setComment] = useState('');
    const user = getUser();
    const [replyText, setReplyText] = useState('');
    const [replyToCommentId, setReplyToCommentId] = useState(null);
    const { t } = useTranslation();
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [shouldCloseEmojiBox, setShouldCloseEmojiBox] = useState(false);
    const myGlobalData = useSelector((state) => state.globalData);
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        const settingsData = JSON.parse(myGlobalData?.data);
        if(!settingsData?.settings?.activity_forum){
            navigate("/404");
        }
      }
    },[myGlobalData]);
    const fixCommentReplies = (array = [], element) => {
        array.forEach((item, index) => {
            if (item._id === element.parent_id) {
                const existingReplyIndex = array[index].replies.findIndex(reply => reply._id === element._id);
                if (existingReplyIndex === -1) {
                    array[index].replies.push(element);
                }
            } else if (item.replies && item.replies.length > 0) {
                array[index].replies = fixCommentReplies(array[index].replies, element);
            }
        });
        return [...array];
    };

    useEffect(() => {
        getComponent();
    }, [update])

    useEffect(() => {
        const fetchData = async () => {
            try {
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };

        fetchData();
    }, [params.id, update]);

    useEffect(() => {
        setTimeout(() => {
            if (showEmojiPicker) {
                setShouldCloseEmojiBox(true);
            }
        }, 1000);
    }, [showEmojiPicker]);


    const IsFavourite = (Num, index) => {
        const formData = new FormData()
        formData.append("topic_id", params.id)
        formData.append("likeStatus", Num)
        Topicservices.IsFav(formData).then((response) => {
            if (response.success) {
                Swal.fire({
                    icon: 'success',
                    text: response.message,
                    ...SWAL_SETTINGS
                })
            }
            setUpdate(!update)
        }).catch((err) => {
            console.log("error", err)
        })
    }

    const IsFavouriteComment = (comment_id, likeCommentStatus) => {
        const formData = new FormData();
        formData.append("comment_id", comment_id);
        formData.append("likeCommentStatus", likeCommentStatus);
        Topicservices.IsFavComment(formData).then((response) => {
            if (response.success) {
                Swal.fire({
                    icon: 'success',
                    text: response.message,
                    ...SWAL_SETTINGS
                })
            }
            setUpdate(!update)
        }).catch((err) => {
            console.log("error", err)
        });
    }

    let getComponent = () => {
        const formData = new FormData();
        formData.append("activity_id", params.id);
        Topicservices.Details(params.id).then((response) => {
            let arr = [];
            response.data?.comment_data.forEach(element => {
                if (!element.parent_id) {
                    arr.push(element);
                } else {
                    arr = fixCommentReplies(arr, element)
                }
            });
            setTopicViewList(response && response.data ? { ...response.data, comment_data: arr } : {});
            setId(response.data.activity_id);
        }).catch((err) => {
            console.log(err)
        });
    }

    const handleEmojiSelect = (emoji) => {
        setComment((prevComment) => prevComment + emoji.native);
    };

    const handleEmojiSelectForReply = (emoji) => {
        setReplyText((prevComment) => prevComment + emoji.native);
    };

    const handlePostComment = (file=false, fileData) => {
        try {
            const values = { topic_id: params.id, comment: comment, comment_type: COMMENT_TYPES.TEXT };
            Topicservices.Addcomments(file === true ? fileData : values)
                .then((response) => {
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: 'Comment posted successfully!',
                            ...SWAL_SETTINGS
                        });
                        // console.log("Comment posted:", values);
                        setComment(response && response.values ? response.values : []);
                        setShowEmojiPicker(false);
                        setUpdate(!update)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: 'Please write your comment',
                            ...SWAL_SETTINGS
                        })
                    }

                })
                .catch((error) => {
                    // Handle error response
                    console.error('Error posting comment:', error);
                });
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    const handlePostReply = (parentComment, file=false, fileData) => {
        try {
            if (!replyText && file === false) {
                Swal.fire({
                    icon: 'error',
                    text: 'Please write your comment',
                    ...SWAL_SETTINGS
                });
                return;
            }
            const values = {
                topic_id: params.id,
                comment: replyText,
                parent_id: parentComment._id,
                comment_type: COMMENT_TYPES.TEXT 
            };

            Topicservices.AddReplyOnComments(file ? fileData : values)
                .then((response) => {
                    Swal.fire({
                        icon: 'success',
                        text: 'Comment posted successfully!',
                        ...SWAL_SETTINGS
                    });
                    setReplyText('');
                    setUpdate(!update);
                    setReplyToCommentId();

                })
                .catch((error) => {
                    // Handle error response
                    console.error('Error posting reply:', error);
                });
        } catch (error) {
            console.error('Error posting reply:', error);
        }
    };

     /**>>>>>>>>> UPLOAD CHUNKS >>>>>>>>>>>> */
	 const getChunks = (file) => {
        let chunks = [];
        const size = file.size;
        const totalChunks = Math.ceil(size / VIDEO_UPLOAD.CHUNK_SIZE);
        return new Promise((resove) => {
            let i = 0;
            for (i; i < totalChunks; i++) {
                chunks.push(file.slice(i * VIDEO_UPLOAD.CHUNK_SIZE, VIDEO_UPLOAD.CHUNK_SIZE * (i + 1)));
            };
            resove(chunks);
        });
    }
    const startLoading = (progress) => {
        return new Promise((resove) => {
            $('#upload_progress').css({ 'width': progress + '%' });
            $('#percent').html(progress + '%');
            resove(true);
        })
    }
    const uploadVideo = (myVid) => {
        let lastUploadedChunk = {};
        return new Promise(async (resove) => {
            let currentChunk = 0;
            const file = myVid[0];
            const myChunks = await getChunks(file);
            const totalChunks = myChunks.length;
            const timeStamp = (new Date()).getTime();
            function uploadChunk() {
                let formData = new FormData();
                formData.append("name", timeStamp + file.name);
                formData.append("size", file.size);
                formData.append("mimetype", file.mimetype);
                formData.append("tempFilePath", file.tempFilePath);
                formData.append("allChunks", totalChunks);
                formData.append("video", myChunks[currentChunk]);
                formData.append("currentChunk", currentChunk);
                if (currentChunk < totalChunks) {
                    $.ajax({
                        // url: 'upload',
                        url: `${process.env.REACT_APP_API_URL}/web/other/topic/upload-comments-file`,
                        headers: {
                            "Authorization": `Bearer ${getUser()?.token}`
                        },
                        contentType: false,
                        processData: false,
                        type: 'POST',
                        data: formData,
                        success: function (data) {
                            if (data.success) {
                                lastUploadedChunk = data;
                                startLoading(data?.data?.uploadProgress).then((resultPromise) => {
                                    currentChunk++;
                                    uploadChunk();
                                });
                            }
                        }
                    });
                } else {
                    resove(lastUploadedChunk);
                }
            };
            uploadChunk();
        });
    }
    /**<<<<<<<<< UPLOAD CHUNKS <<<<<<<<<<<< */
	function handleSelectedImage(e) {
        if(e.target.files.length > 0){
            uploadVideo(e.target.files).then((response) => {
                let messageData = {
                    comment_file_url:response.data.fileName,
                    comment_type: COMMENT_TYPES.FILE,
                    topic_id: params.id
                };
                handlePostComment(true, messageData);
            }).catch((error) => {
                console.log("Error in image uploading =>", error);
            })
        }
    }

    function handleReplyFileUpload(parentComment, e) {
        if(e.target.files.length > 0){

            uploadVideo(e.target.files).then((response) => {
                let messageData = {
                    comment_file_url:response.data.fileName,
                    comment_type: COMMENT_TYPES.FILE,
                    topic_id: params.id,
                    parent_id: parentComment._id,
                };
                handlePostReply(parentComment, true, messageData);
            }).catch((error) => {
                console.log("Error in image uploading =>", error);
            })
        }
    }

    return (
        <>
            <div className="wraper-inner cpt bg-grey">
                <section className="topics-listing grid-box-main mt-5 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="top-head-title">
                                    <a className="back-btn cp"  href="/" onClick={(e) => {e.preventDefault(); navigate(-1)} }><i className="ri-arrow-left-line" ></i></a>
                                    <div className="content-breadcum">
                                        <h3 className="text-capitalize">{state?.topic?.title}</h3>
                                        <nav className="breadcrumb text-center">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/`}>{t('web_header_heading_home')}</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to={`/activity-forum/1`}>{t('web_lbl_view_forum')}</Link>
                                                </li>

                                                <li className="breadcrumb-item"><Link to={`/activity-forum/topic/${id}`}>{state?.topic?.title}</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">{state?.topic?.title}</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                                <div className="dash-box-shadow ">
                                    <h4 className="inner-title text-capitalize">
                                        {topicviewlist?.title}</h4>
                                    <ul className="card-info-list">
                                        <li>
                                            <div className="date-info"><i className="ri-calendar-event-line"></i>
                                                {formateDate(topicviewlist?.createdAt)}
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="description-box">
                                        <p dangerouslySetInnerHTML={{ __html: `${TrimText(topicviewlist.description, 100)}` }} />
                                        <div className="like-info">
                                            <ul className="card-info-list border-box-card">
                                                <li>
                                                    <div className="date-info" onClick={() => { IsFavourite(topicviewlist.isFavourite === true ? 0 : 1) }}>
                                                        {topicviewlist.isFavourite === true ? (
                                                            <span><i className="ri-thumb-up-fill cp"></i></span>
                                                        ) : (
                                                            <span><i className="ri-thumb-up-line black-icon cp"></i></span>
                                                        )}
                                                        <span className='me-2 cp'>{topicviewlist.likes_count} {t('web_lbl_likes')}</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="card-info cp"><i className="ri-chat-3-line black-icon"></i>{topicviewlist?.comments_count} {t('web_lbl_comment')}</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="commnet-sec">
                                            <figure style={{ display: 'inline-block', margin: '0', borderRadius: '50%', overflow: 'hidden', width: '30px', height: '30px' }}>
                                                <img src={user?.profile_image} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                            </figure>
                                            <div className="commnet-box position-relative">
                                                <div className="position-relative">
                                                    <textarea
                                                        className="form-control h-4"
                                                        placeholder={t('web_lbl_write_your_comment')}
                                                        value={comment}
                                                        onChange={(e) => setComment(e.target.value)}
                                                        onClick={() => setShowEmojiPicker(false)}
                                                    />
                                                    <input className='d-none' type="file" ref={selectImageInput} onChange={handleSelectedImage}/>
                                                    <span style={{cursor:'pointer',position:'absolute', right:'35px', bottom:'10px'}} type='button' onClick={()=>{selectImageInput.current.click()}}>
                                                        <i className="fa fa-paperclip"></i>
                                                    </span>
                                                    <i className="fa-solid fa-face-smile" onClick={() => setShowEmojiPicker(!showEmojiPicker)} style={{cursor:'pointer',position:'absolute', right:'10px', bottom:'10px'}}></i>
                                                </div>
                                                {showEmojiPicker && (
                                                    <div className="emoji-picker-container">
                                                        <Picker
                                                            onEmojiSelect={handleEmojiSelect}
                                                            onClickOutside={() => {
                                                                if (shouldCloseEmojiBox) {
                                                                    setShowEmojiPicker(false);
                                                                    setShouldCloseEmojiBox(false);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                <button className="btn btn-primary mt-3" onClick={handlePostComment}>{t('web_lbl_post')}</button>
                                            </div>
                                        </div>

                                        <div className="all-comments mt-4">
                                            <h3 className="pb-4">{t('web_lbl_all_comment')} {topicviewlist?.comments_count}</h3>
                                            {topicviewlist && topicviewlist.comment_data ? (
                                                topicviewlist.comment_data.map((comment, index) => (
                                                    <CommentsToTopic getComponent={getComponent} key={index} comment={comment} IsFavouriteComment={IsFavouriteComment} topicviewlist={topicviewlist} replyToCommentId={replyToCommentId} replyText={replyText} setReplyText={setReplyText} handlePostReply={handlePostReply} setReplyToCommentId={setReplyToCommentId} user={user} handleEmojiSelectForReply={handleEmojiSelectForReply} selectImageInput={selectImageInput} handleReplyFileUpload={handleReplyFileUpload}/>
                                                ))
                                            ) : (
                                                <p>{t('web_lbl_no_comment')}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default TopicsView;