import React, { useEffect, useRef } from 'react';

import Message from './Message/Message';

import './Messages.css';

const Messages = ({ setEdit, setMessageData, setMessage, setMessages, messages, senderData, receiverData, onScrollEvt, scrollProperties:{scrollTo, smoothly, delay=0}}) => {
	const messageContainer = useRef();
	useEffect(() => {
		setTimeout(() => {
			if(scrollTo === "bottom"){
				messageContainer.current.scrollTop = messageContainer.current.scrollHeight;
			}
			if(scrollTo === "lastTopMessage"){
				messageContainer.current.scrollTop = 750;
			}
		}, delay);
	}, [messages]);
	return (
		<div className={"msg_history "+(smoothly?'smooth-scroll':'')} ref={messageContainer} onScroll={(e)=>{onScrollEvt(e)}}>
			{messages.map((data, i) => <div key={i}>
				<Message setEdit={setEdit} setMessageData={setMessageData} setMessage={setMessage} setMessages={setMessages} message={data} senderData={senderData} receiverData={receiverData} />
			</div>)}
		</div>
	);
};

export default Messages;
