import React, { useEffect, useRef, useState } from "react";
import {  Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import * as eventsService from "../../services/events.services";
import CustomCkeditor from "../../common/customeditor";
import { SWAL_SETTINGS } from "../../../../utils/Constants";
import {
  getDiffrenceInTime,
  handleServerValidations,
  getLocalizedDate,
  hasPermission
} from "../../../../utils/commonfunction";
import CustomDatetimepicker from "../../common/customDatetimepicker";
import CustomTimepicker from "../../common/timepicker";
import * as Yup from "yup";
import { Toggle } from "rsuite";
import Sorting from "../../common/sorting";
import Loader from "../../common/loader";
import CustomPagination from "../../common/custompagination";
import { Tooltip, Whisper } from 'rsuite';


const Agenda = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [sorting, setSorting] = useState({});
  const [loader, setLoader] = useState(false);
  const [agendaList, setAgendaList] = useState([]);
  const [serialno] = useState("");
  const [itemperpage] = useState(10);
  const [page, setPage] = useState(1);
  const [agendaId, setAgendaId] = useState();
  const [saveAndSubmit, setSaveAndSubmit] = useState(false);
  const customCkeditorRef = useRef(null);
  const customCkeditorRefTwo = useRef(null);
  const [errorElementsLength, setErrorElementsLength] = useState(false);
  const [workAgenda, setWorkAgenda] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [datalength, setDataLength] = useState("");

  const formikRef = useRef();
  // console.log("customCkeditorRef", customCkeditorRef);

  useEffect(() => {
    getList();
    setWorkAgenda('add');
  }, [page])

  const getList = () => {
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    // formData.append("sort", JSON.stringify(sorting));
    formData.append("event_id", params.id);
    eventsService
      .ListAgenda(formData)
      .then((response) => {
        setLoader(false);
        // setSubmitting(false);
        if (response?.success) {
          setAgendaList(response.data.list);
          setDataLength(response?.data?.total_records);
          setPage(response && response?.data && response?.data?.page ? response?.data?.page : 1);
        }
      })
      .catch(err => console.log("err", err))
  }

  const agendaInitialValues = {
    agendaName: "",
    agendaName_de: "",
    agendaDescription: "",
    // agendaDescription_de: "",
    agendaDate: "",
    agendaStartTime: "",
    agendaEndTime: "",
    minimumBookableElement: "",
    maximumBookableElement: "",
    agendaElements: [{
      id: 1,
      agendaElementName: "",
      agendaElementName_de: "",
      agendaTotalQuantity: "",
      agendaElementPrice: "",
      agendaElementTaxRate: "",
      qrCode: false
    }]
  };

  const agendaValidationSchema = Yup.object().shape({
    agendaName: Yup.string()
      .required("Name is required")
      .test("no-spaces", "Name is required", (value) => value.trim()),
    agendaName_de: Yup.string()
        .required("Name in german is required")
        .test("no-spaces", "Name in german is required", (value) => value.trim()),
    agendaDescription: Yup.string()
      .required("Description is required")
      .test("no-spaces", "Description is required", (value) => value.trim()),
    agendaDate: Yup.string()
      .required("Date is required")
      .test("no-spaces", "Date is required", (value) => value.trim()),
    agendaStartTime: Yup.string()
      .required("Start time is required")
      .test("no-spaces", "Start time is required", (value) => value.trim()),
    agendaEndTime: Yup.string()
      .required("End time is required")
      .test("greater-than-start-time", "End time should be greater than start time", (value) => {
        const startTime = formikRef?.current?.values?.agendaStartTime;
        const timeDiff = getDiffrenceInTime(startTime, value);
        if (timeDiff === 0) {
          return false
        } else {
          return true
        }
      }),
    minimumBookableElement: Yup.string()
      .trim()
      .required("Minimum bookable element is required")
      .matches(/^[0-9]+$/, "Minimum bookable element should be valid"),
    maximumBookableElement: Yup.string()
      .required("Maximum bookable element is required")
      .matches(/^[0-9]+$/, "Maximum bookable element should be valid")
      .test("no-spaces", "Maximum bookable element cannot be less than minimum bookable element", (value) => {
        const minimumBookableElement = formikRef?.current?.values?.minimumBookableElement;

        if (value < minimumBookableElement) {
          return false
        } else {
          return true
        }
      }),
    agendaElements: Yup.array()
      .min(1, 'Array must have at least 1 item').of(Yup.object().shape({
        agendaElementName: Yup.string().strict(false).trim().required('Name is required')
          .matches(/^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/, "Name should be valid"),
        agendaElementName_de: Yup.string().strict(false).trim().required('Name in german is required')
          .matches(/^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/, "Name in german should be valid"),
        agendaTotalQuantity: Yup.string().strict(false).trim().matches(/^[0-9]+$/, "Total quantity should be valid").required('Total quantity is required'),
        agendaElementPrice: Yup.string().strict(false).trim().matches(/^[0-9]+$/, "Decimal values is not allowed in price").required('Price is required'),
        agendaElementTaxRate: Yup.string().strict(false).trim().matches(/^[0-9]+$/, "Decimal values is not allowed in tax rate").required('Tax rate is required'),
      }))
  })

  const addMoreElements = () => {
    setErrorElementsLength(false);
    setSaveAndSubmit(false);
    const newObj = {
      id: formikRef.current.values.agendaElements.length + 1,
      agendaElementName: "",
      agendaElementName_de: "",
      agendaTotalQuantity: "",
      agendaElementPrice: "",
      agendaElementTaxRate: "",
      qrCode: false
    }

    const newArray = [];
    for (let i = 0; i < formikRef.current.values.agendaElements.length; i++) {
      newArray.push(formikRef.current.values.agendaElements[i]);
    }
    // formikRef.current.values.agendaElements;
    newArray.push(newObj)
    formikRef.current.setFieldValue("agendaElements", newArray)
  }

  const removeElements = (item) => {
    // console.log("item", item);
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const newAgendaElement = formikRef?.current?.values?.agendaElements?.filter(i => i.id !== item.id);
        formikRef?.current?.setFieldValue("agendaElements", newAgendaElement);
      }
    }).catch(err => console.log("err", err))
  }

  const handleSort = (e, column) => {
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };


  const goToEdit = async (item) => {
    setWorkAgenda('edit');
    formikRef.current.setFieldValue("agendaName", item.name)
    formikRef.current.setFieldValue("agendaName_de", item.name_de)
    formikRef.current.setFieldValue("agendaDescription", item.description)
    customCkeditorRef?.current?.setValue(item.description, 'editor101');
    // formikRef.current.setFieldValue("agendaDescription_de", item.description_de)
    // customCkeditorRefTwo?.current?.setValue(item.description_de, 'editor102');

    formikRef.current.setFieldValue("agendaDate", new Date(item.start_date))
    formikRef.current.setFieldValue("agendaStartTime", new Date(item.start_time))
    formikRef.current.setFieldValue("agendaEndTime", new Date(item.end_time))
    formikRef.current.setFieldValue("minimumBookableElement", item.minimum_bookable_element)
    formikRef.current.setFieldValue("maximumBookableElement", item.maximum_bookable_element)

    const elements = [];
    for (let i = 0; i < item.elements.length; i++) {
      elements.push({
        id: i + 1,
        agendaElementName: item.elements[i].name,
        agendaElementName_de: item.elements[i].name_de,
        agendaTotalQuantity: item.elements[i].total_quantity,
        agendaElementPrice: item.elements[i].price,
        agendaElementTaxRate: item.elements[i].tax_rate,
        qrCode: item.elements[i].is_require_qr,
        _id: item.elements[i]._id
      })
    }
    // console.log("elements", elements);
    formikRef.current.setFieldValue("agendaElements", elements)
    setAgendaId(item._id);
  }

  const deleteFunction = (item) => {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        eventsService
          .DeleteAgenda({ o_id: item._id })
          .then(res => {
            if (res && res.success) {
              getList();
            }
          })
          .catch(err => console.log("err", err))
      }
    }).catch(err => console.log("err", err))
  }

  const onFormSubmit = (values, resetForm) => {
    console.log("saveAndSubmit", saveAndSubmit);
    if(values.agendaElements?.length < values.minimumBookableElement){
      setErrorElementsLength(true)
      return;
    }else{
      setErrorElementsLength(false)
    }
    setDisableButton(true);
    let formData = new FormData();
    formData.append("name", values.agendaName);
    formData.append("name_de", values.agendaName_de);
    formData.append("description", values.agendaDescription);
    // formData.append("description_de", values.agendaDescription_de);
    formData.append("start_date", values.agendaDate);
    formData.append("start_time", values.agendaStartTime);
    formData.append("end_time", values.agendaEndTime);
    formData.append("maximum_bookable_element", values.maximumBookableElement);
    formData.append("minimum_bookable_element", values.minimumBookableElement);
    formData.append("event_id", params.id);

    if (!agendaId) {

      const elements = [];
      for (let i = 0; i < values.agendaElements.length; i++) {
        elements.push({
          name: values.agendaElements[i].agendaElementName,
          name_de: values.agendaElements[i].agendaElementName_de,
          total_quantity: values.agendaElements[i].agendaTotalQuantity,
          price: values.agendaElements[i].agendaElementPrice,
          tax_rate: values.agendaElements[i].agendaElementTaxRate,
          is_require_qr: values.agendaElements[i].qrCode
        })
      }
      formData.append("elements", JSON.stringify(elements));

      eventsService
        .AddAgenda(formData)
        .then((response) => {
          // console.log("res", response);
          // setSubmitting(false);
          if (response?.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            setWorkAgenda('add');
            if (saveAndSubmit) {
              setSaveAndSubmit(false);
              setTimeout(() => {
                navigate(`/admin/event-management/events/list/1`);
                setDisableButton(false);
              }, 100);
            } else {
              console.log("Heyyy")
              getList()
              resetForm({ values: "" })
              setDisableButton(false);
              customCkeditorRef.current.setValue("")
              customCkeditorRefTwo.current.setValue("")
            }
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            setDisableButton(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    } else {

      formData.append("o_id", agendaId);
      const elements = [];
      for (let i = 0; i < values.agendaElements.length; i++) {
        elements.push({
          name: values.agendaElements[i].agendaElementName,
          name_de: values.agendaElements[i].agendaElementName_de,
          total_quantity: values.agendaElements[i].agendaTotalQuantity,
          price: values.agendaElements[i].agendaElementPrice,
          tax_rate: values.agendaElements[i].agendaElementTaxRate,
          is_require_qr: values.agendaElements[i].qrCode,
          _id: values.agendaElements[i]._id
        })
      }
      formData.append("elements", JSON.stringify(elements));

      eventsService
        .EditAgenda(formData)
        .then((response) => {
          // console.log("res", response);
          // setSubmitting(false);
          if (response?.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            // 
            if (saveAndSubmit) {
              setSaveAndSubmit(false);
              setTimeout(() => {
                navigate(`/admin/event-management/events/list/1`);
              }, 100);
            } else {
              getList()
              resetForm({ values: "" });
              customCkeditorRef.current.setValue("");
              customCkeditorRefTwo.current.setValue("");
              setDisableButton(false);
            }
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            setDisableButton(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
          setDisableButton(false);
        });
    }
  }

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={agendaInitialValues}
        validateOnBlur={false}
        validationSchema={agendaValidationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => { onFormSubmit(values, resetForm); }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {console.log("values",values, errors)}
            <div className="row">
              <div className="table-responsive">
                <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                  <thead>
                    <tr>
                      <th className="sr_head">S.No</th>
                      <th>
                        <div className="sorting_column">
                          <span>Name</span>
                          <Sorting
                            sort={sorting}
                            handleSort={handleSort}
                            column="title"
                          />
                        </div>
                      </th>
                      <th>Minimum bookable element</th>
                      <th>Maximum bookable element</th>
                      <th>Date</th>
                      <th>Start time</th>
                      <th>End time</th>
                      <th>Created Date</th>
                      <th>Created By</th>
                      <th className="action_head">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <tr>
                        <td colSpan={10}>
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {agendaList.length ? (
                          agendaList.map((row, i) => {
                            return (
                              <tr key={i}>
                                <td>{serialno + (i + 1)}</td>
                                <td>{row.name ? row.name : "N/A"}</td>
                                <td>{row.minimum_bookable_element ? row.minimum_bookable_element : "N/A"}</td>
                                <td>{row.maximum_bookable_element ? row.maximum_bookable_element : "N/A"}</td>
                                <td>{row.start_date ? row.start_date?.split("T")[0] : "N/A"}</td>
                                <td>{row.start_time ? getLocalizedDate(row.start_time) : "N/A"}</td>
                                <td>{row.end_time ? getLocalizedDate(row.end_time) : "N/A"}</td>
                                <td>{row.createdAt ? row.createdAt?.split("T")[0] : "N/A"}</td>
                                <td>{row.updatedBy ? row.updatedBy?.name : row.createdBy ? row.createdBy.name : "N/A"}</td>
                                <td>
                                  <div className="d-flex">
                                    <button
                                      type="button"
                                      className="btn ripple btn-success mx-1"
                                      onClick={() => {
                                        goToEdit(row);
                                      }}
                                    >
                                      Edit
                                    </button>
                                    {hasPermission('/admin/event-management/events/edit/agenda/delete') ?
                                    <button
                                      type="button"
                                      className="btn ripple btn-secondary"
                                      onClick={() => {
                                        deleteFunction(row);
                                      }}
                                    >
                                      Delete
                                    </button>
                                    :null}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10} className="text-center">
                              No records
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className=""
                id="example1_info"
                role="status"
                aria-live="polite"
              >
                <b>Total Records : {datalength ? datalength : "0"}</b>
              </div>
              {datalength && datalength > 0 ? (
                <CustomPagination
                  datalength={datalength}
                  itemperpage={itemperpage}
                  currentPage={page}
                  setPage={setPage}
                  pageRoute={[
                    {
                      name: "Events",
                      path: `/admin/event-management/events/edit/agenda/${params.id}`,
                    },
                  ]}
                />
              ) : (
                ""
              )}
              <hr />
              <h6 className="main-content-label mb-3">{workAgenda === 'edit' ? "Edit Agenda" : "Add Agenda"}</h6>
              <div className="col-md-12 text-center form-group">
                <label htmlFor="agendaName" className="text-left d-flex">
                  Name :<span className="requirestar">*</span>{" "}
                </label>
                <input
                  name="agendaName"
                  id="agendaName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.agendaName}
                  className="form-control"
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"agendaName"} />
                </span>
              </div>

              <div className="col-md-12 text-center form-group">
                <label htmlFor="agendaName_de" className="text-left d-flex">
                  Name In German:<span className="requirestar">*</span>{" "}
                </label>
                <input
                  name="agendaName_de"
                  id="agendaName_de"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.agendaName_de}
                  className="form-control"
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"agendaName_de"} />
                </span>
              </div>
              <div className='col-lg-12 text-center form-group'>
                <label htmlFor='agendaDescription' className='text-left d-flex'>Description :<span className="requirestar">*</span></label>
                <CustomCkeditor
                  name={"editor101"}
                  ref={customCkeditorRef}
                  fieldname={"agendaDescription"}
                  setFieldValue={setFieldValue}
                  value={values.agendaDescription}
                  setFieldTouched={setFieldTouched}
                />
                <span className='text-danger d-flex text-left'>{touched.agendaDescription && errors.agendaDescription}</span>
              </div>

              {/* <div className='col-lg-12 text-center form-group'>
                <label htmlFor='agendaDescription_de' className='text-left d-flex'>Description In German :</label>
                <CustomCkeditor
                  name={"editor102"}
                  ref={customCkeditorRefTwo}
                  fieldname={"agendaDescription_de"}
                  setFieldValue={setFieldValue}
                  value={values.agendaDescription_de}
                  setFieldTouched={setFieldTouched}
                />
                <span className='text-danger d-flex text-left'>{touched.agendaDescription && errors.agendaDescription_de}</span>
              </div> */}


              <div className="col-md-4 text-center form-group">
                <label htmlFor="agendaDate" className="text-left d-flex">
                  Date :<span className="requirestar">*</span>{" "}
                </label>
                <CustomDatetimepicker
                  placeholder={"Start date"}
                  setField={setFieldValue}
                  fieldname={`agendaDate`}
                  setFieldTouched={setFieldTouched}
                  formikRef={formikRef}
                  value={values.agendaDate}
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"agendaDate"} />
                </span>
              </div>

              <div className="col-md-4 text-center form-group">
                <label htmlFor="agendaStartTime" className="text-left d-flex">
                  Start time :<span className="requirestar">*</span>{" "}
                </label>
                <CustomTimepicker
                  placeholder={"Start time"}
                  setField={setFieldValue}
                  fieldname={`agendaStartTime`}
                  setFieldTouched={setFieldTouched}
                  formikRef={formikRef}
                  value={values.agendaStartTime}
                  correspondingEndTime={formikRef?.current?.values?.agendaEndTime}
                  correspondingEndTimeName={"agendaEndTime"}
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"agendaStartTime"} />
                </span>
              </div>

              <div className="col-md-4 text-center form-group">
                <label htmlFor="agendaEndTime" className="text-left d-flex">
                  End time :<span className="requirestar">*</span>{" "}
                </label>
                <CustomTimepicker
                  placeholder={"End time"}
                  setField={setFieldValue}
                  fieldname={`agendaEndTime`}
                  setFieldTouched={setFieldTouched}
                  formikRef={formikRef}
                  value={values.agendaEndTime}
                  endTime={true}
                  correspondingStartDate={formikRef?.current?.values?.agendaStartTime}
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"agendaEndTime"} />
                </span>
              </div>


              <div className="col-md-6 text-center form-group">
                <label htmlFor="minimumBookableElement" className="text-left d-flex">
                  Minimum bookable element :<span className="requirestar">*</span>{" "}
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Users can choose a minimum of Y elements(program points/workshops) and must choose at least X elements from each Agenda.
                    If the elements are non-mandatory, minimum can be set to 0.</Tooltip>}>
                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                  </Whisper>
                </label>
                <input
                  name="minimumBookableElement"
                  id="minimumBookableElement"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.minimumBookableElement}
                  className="form-control"
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"minimumBookableElement"} />
                </span>
              </div>
              <div className="col-md-6 text-center form-group">
                <label htmlFor="maximumBookableElement" className="text-left d-flex">
                  Maximum bookable element :<span className="requirestar">*</span>{" "}
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Users can choose a maximum of Y elements(program points/workshops) and must choose at least X elements from each Agenda. 
                      If the elements are non-mandatory, minimum can be set to 0.</Tooltip>}>
                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                  </Whisper>
                </label>
                <input
                  name="maximumBookableElement"
                  id="maximumBookableElement"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.maximumBookableElement}
                  className="form-control"
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"maximumBookableElement"} />
                </span>
              </div>

              <h4>Elements</h4>

              {values.agendaElements.map((item, index) => (
                <>
                  <div key={index.toString()} className="col-md-6 text-center form-group">
                    <label key={"agendaElementNameLabel" + index.toString()} className="text-left d-flex">
                      Name :<span key={"agendaElementNameSpan" + index.toString()} className="requirestar">*</span>{" "}
                    </label>
                    <input
                      key={"agendaElementName" + index.toString()}
                      name={`agendaElements[${index}].agendaElementName`}
                      id={`agendaElements[${index}].agendaElementName`}
                      type="text"
                      onChange={(event) => setFieldValue(`agendaElements[${index}].agendaElementName`, event.nativeEvent.target.value)}
                      onBlur={handleBlur}
                      value={item?.agendaElementName}
                      className="form-control"
                    />
                    <span className="text-danger d-flex text-left">
                      <ErrorMessage name={`agendaElements[${index}].agendaElementName`} />
                    </span>
                  </div>
                  <div className="col-md-6 text-center form-group">
                    <label key={"agendaElementNameDeLabel" + index.toString()} className="text-left d-flex">
                      Name In German:<span key={"agendaElementNameDeSpan" + index.toString()} className="requirestar">*</span>{" "}
                    </label>
                    <input
                      key={"agendaElementName_de" + index.toString()}
                      name={`agendaElements[${index}].agendaElementName_de`}
                      id={`agendaElements[${index}].agendaElementName_de`}
                      type="text"
                      onChange={(event) => setFieldValue(`agendaElements[${index}].agendaElementName_de`, event.nativeEvent.target.value)}
                      onBlur={handleBlur}
                      value={item?.agendaElementName_de}
                      className="form-control"
                    />
                    <span className="text-danger d-flex text-left">
                      <ErrorMessage name={`agendaElements[${index}].agendaElementName_de`} />
                    </span>
                  </div>

                  <div className="col-md-6 text-center form-group">
                    <label key={"agendaTotalQuantityLabel" + index.toString()} className="text-left d-flex">
                      Total quantity :<span key={"agendaTotalQuantitySpan" + index.toString()} className="requirestar">*</span>{" "}
                    </label>
                    <input
                      key={"agendaTotalQuantity" + item.id.toString()}
                      name={`agendaElements[${index}].agendaTotalQuantity`}
                      id={`agendaElements[${index}].agendaTotalQuantity`}
                      type="number"
                      onChange={(event) => setFieldValue(`agendaElements[${index}].agendaTotalQuantity`, event.nativeEvent.target.value)}
                      onBlur={handleBlur}
                      value={item?.agendaTotalQuantity}
                      className="form-control"
                    />
                    <span className="text-danger d-flex text-left">
                      <ErrorMessage name={`agendaElements[${index}].agendaTotalQuantity`} />
                    </span>
                  </div>

                  <div className="col-md-6 text-center form-group">
                    <label htmlFor="agendaElementPrice" className="text-left d-flex">
                      Price :<span className="requirestar">*</span>{" "}
                      <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Price excluding Tax - If Free, then 0
                       If Price is offered Gross (eg. for events outside of Germany), then Tax should be 0.</Tooltip>}>
                      <span className='field-more-info mt-1 ms-1 cp'>?</span>
                    </Whisper>
                    </label>
                    <input
                      key={"agendaElementPrice" + item.id.toString()}
                      name={`agendaElements[${index}].agendaElementPrice`}
                      id={`agendaElements[${index}].agendaElementPrice`}
                      type="number"
                      onChange={(event) => setFieldValue(`agendaElements[${index}].agendaElementPrice`, event.nativeEvent.target.value)}
                      onBlur={handleBlur}
                      value={item.agendaElementPrice}
                      className="form-control"
                    />
                    <span className="text-danger d-flex text-left">
                      <ErrorMessage name={`agendaElements[${index}].agendaElementPrice`} />
                    </span>
                  </div>

                  <div className="col-md-6 text-center form-group">
                    <label htmlFor="agendaElementTaxRate" className="text-left d-flex">
                      Tax rate %:<span className="requirestar">*</span>{" "}
                    </label>
                    <input
                      key={"agendaElementTaxRate" + item.id.toString()}
                      name={`agendaElements[${index}].agendaElementTaxRate`}
                      id={`agendaElements[${index}].agendaElementTaxRate`}
                      type="number"
                      onChange={(event) => setFieldValue(`agendaElements[${index}].agendaElementTaxRate`, event.nativeEvent.target.value)}
                      onBlur={handleBlur}
                      value={item.agendaElementTaxRate}
                      className="form-control"
                    />
                    <span className="text-danger d-flex text-left">
                      <ErrorMessage name={`agendaElements[${index}].agendaElementTaxRate`} />
                    </span>
                  </div>

                  <div className="col-md-6 d-flex justify-content-between">
                    <label htmlFor="qrCode" className="text-left">
                      Does this requires QR code?
                    </label>
                    <Toggle
                      className="ms-3"
                      key={"qrCode" + index?.toString()}
                      checked={item.qrCode}
                      onChange={(value) => {
                        // console.log("e",e);
                        setFieldValue(`agendaElements[${index}].qrCode`, value)
                      }}
                    />
                  </div>

                  <div className="mt-2 pb-2">
                    {index !== 0 && (
                      <button
                        key={"remove" + index.toString()}
                        className="btn btn-secondary mr-2"
                        type="button"
                        onClick={() => removeElements(item)}
                      >
                        <i className="fa fa-trash pr-1" aria-hidden="true"></i>
                        Remove
                      </button>
                    )}

                    {index === values.agendaElements.length - 1 && (
                      // <div className="mt-2">
                      <button
                        key={"add" + index.toString()}
                        className="btn bg-dark btn-dark mr-2"
                        type="button"
                        onClick={addMoreElements}
                      // disabled={submitted ? true : null}
                      >
                        <i className="fa fa-plus pr-2" aria-hidden="true"></i>
                        Add more element
                      </button>
                      // </div>
                    )}
                  </div>
                </>
              ))}


              {errorElementsLength && (
                <span className="text-danger d-flex text-left">
                  <label key={"agendaElementsParent"}>Elements cannot be less than minimum bookable elements</label>
                </span>
              )}


              <div className="mt-5 ">
                <button
                  className="btn btn-info mr-2"
                  type="submit"
                  disabled={disableButton}
                >
                  <i className="fa fa-floppy-o mx-1" aria-hidden="true"></i>
                  Save
                </button>
                <button
                  className="btn btn-success mr-2"
                  type="submit"
                  disabled={disableButton || Object.values(errors).length || errorElementsLength}
                  onClick={() => {
                      setSaveAndSubmit(true);
                  }}
                >
                  <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                  Save & Exit
                </button>
                <button
                  className="btn ripple btn-secondary"
                  type="button"
                  // disabled={submitted ? true : null}
                  onClick={() => navigate(-1)}
                >
                  <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default Agenda;