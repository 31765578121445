import React, {useRef, useState } from 'react';
import { ErrorMessage,Formik } from 'formik';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import * as pushnotificationService from '../../../services/pushnotification.services';
import Breadcrums from '../../../common/breadcrumbs';
import {SWAL_SETTINGS } from '../../../../../utils/Constants';
import { handleServerValidations } from '../../../../../utils/commonfunction';
import * as Yup from 'yup';




const PushNotificationAdd = () => {
    const navigate = useNavigate()
    const textareaRef = useRef(null);
    const [submitted, setSubmitted] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const breadcrumbs = [{ title: "Dashboard", url: "/dashboard" }, { title: "Push Notifications ", url: "/admin/cms/push-notification-template/list/1" }, { title: "Add", url: "" }];


    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required').test('no-spaces', 'Title is required', (value) => value.trim()),
        slug: Yup.string().required("Slug is required").test('no-spaces', 'Slug is required', (value) => value.trim()),
        subject: Yup.string().required("Subject is required").test('no-spaces', 'Subject is required', (value) => value.trim()),
        richtext: Yup.string().required("Description is required").max(1500, "Maximum digits reached").test('no-spaces', 'Description is required', (value) => value.trim()),
    });
  
    const handleTextareaChange = () => {
      const inputValue = textareaRef.current.value;
      const count = inputValue.length;
      setCharCount(count);
    };

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    title: "",
                    title_de: "",
                    slug: "",
                    subject: "",
                    richtext: "",
                    richtext_de: ""
                }}
                validateOnBlur={false}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitted(true)
                    let formData = new FormData();
                    formData.append('title', values.title);
                    formData.append('subject', values.subject);
                    formData.append('description', values.richtext);
                    formData.append('slug', values.slug);
                    pushnotificationService.Add(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                resetForm({ values: '' });
                                navigate(`/admin/cms/push-notification-template/list/1`)
                            }, 2000);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                            setSubmitted(false)
                        }
                    }).catch(error => {
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Add Push Notification </h6>
                                    </div>
                                    <div className="row row-sm">
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span> </label>
                                            <input name='title' id='title' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"title"} /></span>
                                        </div>
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='slug' className='text-left d-flex'>Slug:<span className="requirestar">*</span> </label>
                                            <input name='slug' type='text' id="slug" onChange={handleChange} onBlur={handleBlur} value={values.slug} className='form-control' />
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"slug"} /></span>
                                        </div>
                                        <div className='col-md-12 text-center form-group'>
                                            <label htmlFor='subject' className='text-left d-flex'>Subject:<span className="requirestar">*</span></label>
                                            <input name='subject' type='text' id="subject" onChange={handleChange} onBlur={handleBlur} value={values.subject} className='form-control' />
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"subject"} /></span>
                                        </div>
                                        <div className='col-lg-12 text-center form-group'>
                                            <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                                            {/* <CustomCkeditor
                                                fieldname={"richtext"}
                                                setFieldValue={setFieldValue}
                                                value={values.richtext}
                                                setFieldTouched={setFieldTouched}
                                            /> */}
                                            <textarea
                                                ref={textareaRef}
                                                name='richtext'
                                                value={values.richtext}
                                                onChange={(e) => {handleChange(e); handleTextareaChange()}}
                                                onBlur={handleBlur}
                                                className='form-control'
                                                rows={10}
                                            />
                                            <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <span className='text-right'>You can write {1500 - charCount} letters.</span>
                                        </div>
                                        <div className="">
                                            <button className="btn btn-main-primary signbtn mr-2" type="submit" disabled={submitted ? true : null}>
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Submit
                                            </button>
                                            <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>
    )
}

export default PushNotificationAdd