import React from 'react'
import { Navigate } from 'react-router-dom'
import { getLocalKey } from '../../../utils/commonfunction'

const UserPublicRoute = ({ component: Component }) => {
    const getuserkey = getLocalKey("user") ? JSON.parse(getLocalKey("user")) : {}

    return (
        !getuserkey.token ? <Component /> : <Navigate to={{ pathname: "/" }} />
    )
}

export default UserPublicRoute;