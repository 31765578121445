import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import * as commonService from "../../../services/common.services";
import { getUser, globalLoader } from "../../../../../utils/commonfunction";
import './Join.css';
import CustomPagination from "../../../../admin/common/custompagination";
import { useTranslation } from 'react-i18next';
import FrontLoader from "../../../../globalComponents/FrontLoader";


const Join = () => {
	const [users, setUser] = useState([]);
	const [globalsearch, setGlobalSearch] = useState("");
	const [currentUser, setCurrentUser] = useState({});
	const [refresh, setRefresh] = useState(0);
	const [datalength, setDataLength] = useState("");
	const [itemperpage] = useState(12);
	const [page, setPage] = useState(1);
	const params = useParams();
	const { t } = useTranslation();
	const [loader,setLoader] = useState(true);



	useEffect(() => {
		// globalLoader(true);
		setLoader(true);
		const formData = new FormData();
		formData.append("page", params.pgno);
		formData.append("per_page", itemperpage);
		formData.append("global_search", globalsearch);
		commonService.UserList(formData).then((response) => {
			setUser(response && response.data && response.data.list ? response.data.list : []);
			setDataLength(response?.data?.total_records);
			setPage(response && response?.data && response?.data?.page ? response?.data?.page : 1);
			// globalLoader(false);
			setLoader(false);
		}).catch((err) => {
			console.log(err)
			// globalLoader(false);
			setLoader(false);
		})
	}, [globalsearch, refresh, page, params.pgno,itemperpage])

	useEffect(() => {
		setCurrentUser(getUser());
	}, [])

	const resetFilter = (e) => {
		e.preventDefault();
		setGlobalSearch("");
	};

	function compareObjectIdTimestamps(objectId1, objectId2) {
		if (objectId1.length !== 24 || objectId2.length !== 24) {
			throw new Error('Invalid ObjectId format.');
		}

		const timestamp1 = parseInt(objectId1.substring(0, 8), 16) * 1000;
		const timestamp2 = parseInt(objectId2.substring(0, 8), 16) * 1000;

		if (timestamp1 < timestamp2) {
			return `${objectId2}-${objectId1}`;
		} else if (timestamp1 > timestamp2) {
			return `${objectId1}-${objectId2}`;
		} else {
			return `${objectId1}-${objectId2}`;
		}
	}

	return (
		<div className="wraper-inner cpt bg-grey">
			<section className="user-list mt-5 pb-5" style={{ minHeight: "60vh" }}>
				<div className="container">
					<div className="user-box-head d-flex justify-content-between mb-4">
						<h3 className="inner-title mb-0">{t('web_header_heading_users')}</h3>
						<div className="user-right d-flex">
							<div className="search-box">
								<input type="text" name="" className="form-control" placeholder={t('web_lbl_search_user')} value={globalsearch} onChange={(e) => {
									setGlobalSearch(e.target.value);
								}} />
								<button className="btn-search"><i className="ti ti-search"></i></button>
							</div>
							<div className="dropdown ">
								<button className="dropdown-toggle sm-drop" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
									<i className="ti ti-dots-vertical"></i>
								</button>
								<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
									<li>
										<button className="dropdown-item"
											type="reset"
											value="Reset"
											onClick={(e) => {
												resetFilter(e);
											}}>
											{t('web_lbl_reset_filter')}
										</button>
									</li>
									<li>
										<button className="dropdown-item"
											onClick={() => {
												setRefresh((new Date()).getTime());
											}}>
											{t('web_lbl_refresh')}

										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
					{loader ?
					<FrontLoader/>
				  :
				  <>
					{users && users.length > 0 ?
						<div className="row users-rows">
							{users.map((user, i) => {
								let roomName = compareObjectIdTimestamps(user._id, currentUser._id);
								let nameUser = currentUser.name;
								return (
									<div className="col-md-3 users-box" key={i}>
										<div className="dash-box-shadow text-center">
											<figure><img className="join" src={user?.profile_image} alt="UserProfile" /></figure>
											<figcaption>
												<span className="user_name  d-block">{user?.name}</span>
												{/* <p>Lorem Ipsum is simply dummy text of the printing.</p> */}
												<Link onClick={e => (!nameUser || !roomName) ?
													e.preventDefault() : null}
													to={`/chat?to=${user._id}&room=${roomName}`
													}>
													<button className="btn btn-primary mt-2"
														type="button">{t('web_lbl_send_message')}
													</button>
												</Link>
											</figcaption>
										</div>
									</div>
								)
							})}
						</div>
						:
						<h2 style={{ textAlign: "center", fontSize: "25px" }}>
							{t('web_lbl_no_records')}
						</h2>
					}
				  </>
					 }
				</div>
				{datalength && datalength > 0 ?
					<div className="mt-4 text-right pagination-main d-flex justify-content-end  pe-4">
						<CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "users", path: "/users" }]} />
					</div>
					: ""}
			</section>
		</div>)
};

export default Join;
