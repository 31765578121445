import React, {useEffect, useRef, useState} from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useTranslation } from 'react-i18next';
//import './Input.css';

const Input = ({ edit, setMessage, sendMessage, editMessage, message, onSelectImage }) => {
	const selectImageInput = useRef();
	const [openEmojiBox, setOpenEmojiBox] = useState(false);
	const messageInput = useRef();
	const [shouldCloseEmojiBox, setShouldCloseEmojiBox] = useState(false);
	const { t } = useTranslation();

	function insertEmoji(data){
		messageInput.current.value += data.native;
		setMessage(messageInput.current.value);
	}
	useEffect(()=>{
		setTimeout(() => {
			if(openEmojiBox){
				setShouldCloseEmojiBox(true);
			}
		}, 1000);
	},[openEmojiBox]);

	return (
		<div className="type_msg">
			<form className="form">
				<div className="input_msg_write">
					<div className='w-100 position-relative'>
						<textarea
							ref={messageInput}
							className="input write_msg form-control"
							type="text"
							placeholder={t('web_lbl_type_message')}
							value={message}
							onChange={({ target: { value } }) => {setMessage(value);}}
							onKeyPress={event => event.key === 'Enter' ? (edit ? editMessage(event) : sendMessage(event)) : null}
							// maxLength={250}
						/>
						<ul className='chat-buttons'>
							<li>
								<input className='d-none' type="file" ref={selectImageInput} onChange={onSelectImage}/>
								<button className='chat-input-button' type='button' onClick={()=>{selectImageInput.current.click()}}>
									<i className="fa fa-paperclip"></i>
								</button>
							</li>
							<li>
								<button className='chat-input-button' type='button' onClick={()=>{setOpenEmojiBox(true)}}>
									<i className="fa fa-smile-o" aria-hidden="true"></i>
								</button>
							</li>
						</ul>
						{/* Emoji picker >>>>>> */}
						{openEmojiBox?<div className='emoji-picker-container'>
							<Picker data={data} onEmojiSelect={insertEmoji} onClickOutside={()=>{
							if(shouldCloseEmojiBox){
								setOpenEmojiBox(false);
								setShouldCloseEmojiBox(false);
							}
						}} />
						</div>:null}
						{/* Emoji picker <<<<<< */}
					</div>
					<button className="msg-send-btn" type="button" onClick={e => edit ? editMessage(e) : sendMessage(e)}>
						<i className="fas fa-paper-plane"></i>
					</button>
				</div>
				{/* <span className='chat-limit'>{messageInput?.current?.value?.length}/250</span> */}
			</form>
		</div>
		
	);
}

export default Input;
