import React from 'react'

const Loader = () => {
    return (
        <div className="text-center">
            <div className="card custom-card mb-0" id="loaders5">
                <div className="card-body p-0 mt-2">
                    <div className="text-center">
                        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loader