import React, { useState, useEffect, useRef } from 'react'
import { formatTimeAgo, getUser, handleServerValidations, isImage, getFileIcons} from '../../../../utils/commonfunction'
import { useTranslation } from 'react-i18next';
import Picker from '@emoji-mart/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DeleteComments, EditComments } from '../../services/topic.services';
import { COMMENT_TYPES, SWAL_SETTINGS } from '../../../../utils/Constants';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const CommentsToTopic = ({ handleReplyFileUpload, selectImageInput, getComponent,comment, IsFavouriteComment, topicviewlist, replyToCommentId, replyText, setReplyText, handlePostReply, setReplyToCommentId, user, handleEmojiSelectForReply }) => {
    const [t] = useTranslation();
    const formikRef = useRef();
    const inputRef = useRef();
    const [showEmojiPickerForReply, setShowEmojiPickerForReply] = useState(false);
    const [shouldCloseEmojiBoxForReply, setShouldCloseEmojiBoxForReply] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);
    const navigate = useNavigate();
    const myGlobalData = useSelector((state) => state.globalData);
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        const settingsData = JSON.parse(myGlobalData?.data);
        if(!settingsData?.settings?.activity_forum){
            navigate("/404");
        }
      }
    },[myGlobalData]);

    const leadData = useSelector((state) => state.designportal);

    let comment_data = '';

    useEffect(() => {
        setTimeout(() => {
            if (showEmojiPickerForReply) {
                setShouldCloseEmojiBoxForReply(true);
            }
        }, 1000);
    }, [showEmojiPickerForReply]);

    const handleEmojiSelectForEdit = (emoji) => {
        // let previousText = editModalForm?.values?.comment;
        let previousText = inputRef?.current?.value;
        editModalForm?.setFieldValue('comment',previousText+=emoji.native);
    };

    const editModalForm = useFormik({
        innerRef: formikRef,
        initialValues: {
            comment : ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            comment: Yup.string().trim().required(t('err_please_enter_comment')),
        }),
        onSubmit: (values,{resetForm}) => {
            let comment_id = document.getElementById('comment').getAttribute('data-id');
            let formData = new FormData();
            formData.append('_id',comment_id);
            formData.append('comment',values?.comment);
            EditComments(formData).then((response) => {
                if(response?.success){
                    Swal.fire({
                        icon: 'success',
                        text: response.message,
                        ...SWAL_SETTINGS
                    });
                    document.getElementById('close_model').click();
                    comment_data = '';
                    setUpdateStatus(!updateStatus);
                    document.getElementById('emoji_div').style.display='none';
                    getComponent();
                    resetForm();
                }else{
                    Swal.fire({
                        icon: 'error',
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS
                    });
                    comment_data = '';
                    document.getElementById('close_model').click();
                    setUpdateStatus(!updateStatus);
                }
            }).catch((err) => {
                Swal.fire({
                    icon: 'error',
                    text: handleServerValidations(err),
                    ...SWAL_SETTINGS
                });
                comment_data = '';
                document.getElementById('close_model').click();
                setUpdateStatus(!updateStatus);
            })
        },
    })

    const deleteFunction = (comment_id) => {
        Swal.fire({
            customClass: 'swal-wide',
            title: t('web_lbl_are_you_sure'),
            text: t('web_swal_delete'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: leadData?.data?.background_colors?.primary_active_color,
            cancelButtonColor: leadData?.data?.colors?.danger,
            confirmButtonText: t('web_swal_yes'),
            cancelButtonText: t('web_swal_no')
        }).then((result) => {
            if (result.isConfirmed) {
                let formData = new FormData();
                formData.append('_id',comment_id);
                DeleteComments(formData).then((response) => {
                    if(response?.success){
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        });
                        document.getElementById('close_model').click();
                        comment_data = '';
                        setUpdateStatus(!updateStatus);
                        getComponent();
                    }else{
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        });
                        comment_data = '';
                        document.getElementById('close_model').click();
                        setUpdateStatus(!updateStatus);
                    }
                }).catch((err) => {
                    Swal.fire({
                        icon: 'error',
                        text: handleServerValidations(err),
                        ...SWAL_SETTINGS
                    });
                    comment_data = '';
                    document.getElementById('close_model').click();
                    setUpdateStatus(!updateStatus);
                })
            }
        })
    }

    const editFunction = (comment) => {
        comment_data = comment?.comment;
        document.getElementById('open-comment-modal').click();
        document.getElementById('comment').value=comment.comment;
        formikRef?.current?.setFieldValue('comment',comment.comment);
        document.getElementById('comment').setAttribute('data-id',comment._id);
    }

    // console.log("editModalForm",editModalForm?.values)

    return (
        <div>
            <div className="comment-box-list">
                <div className="comment-box-list-inner">
                    <figure style={{ display: 'inline-block', margin: '0', borderRadius: '50%', overflow: 'hidden', width: '30px', height: '30px' }}>
                        <img src={user?.profile_image} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </figure>
                    <figcaption>
                        <h4>{comment?.user_data?.name} <small>{formatTimeAgo(comment?.createdAt)}</small></h4>
                        {/* {comment?.comment_type == COMMENT_TYPES.TEXT ? <p>{comment?.comment}</p> : <a target={'_blank'} href={comment?.comment_file_url}>{comment && comment?.comment_file_url && comment?.comment_file_url.replace(process.env.REACT_APP_SERVER_BASE_URL+"/uploads/comments/"," ")}</a>} */}
                        {/* {comment?.comment_type === COMMENT_TYPES.TEXT ? (
                            <p>{comment?.comment}</p>
                        ) : (
                            <a target={'_blank'} href={comment?.comment_file_url}>
                                {comment && comment?.comment_file_url && comment?.comment_file_url.replace(process.env.REACT_APP_SERVER_BASE_URL + "/uploads/comments/", " ")}
                            </a>
                        )} */}
                        {isImage(comment?.comment_file_name) ? (
                            <a target="_blank" rel="noopener noreferrer" href={comment?.comment_file_url}>
                                <img src={comment?.comment_file_url} alt="Comment" style={{ width: "50px", height: "50px", objectFit: 'cover' }} />
                            </a>
                        ) : (
                            <>
                                <a target={'_blank'} href={comment?.comment_file_url} rel="noreferrer">
                                    {getFileIcons(comment?.comment_file_name)}
                                </a>
                                {comment?.comment_type === COMMENT_TYPES.TEXT && (
                                    <p>{comment?.comment}</p>
                                )}
                            </>
                        )}
                        <div className="info-reply">
                            <ul className="card-info-list">
                                <li>
                                    <div className="date-info" onClick={() => { IsFavouriteComment(comment?._id, comment.isCommentFavourite === true ? 0 : 1) }}>
                                        {comment.isCommentFavourite === true ? (
                                            <span><i className="ri-thumb-up-fill cp"></i></span>
                                        ) : (
                                            <span><i className="ri-thumb-up-line black-icon cp"></i></span>
                                        )}
                                        <span className='me-2 cp'>{comment.comment_likes_count} {t('web_lbl_likes')}</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="card-info"><i className="ri-chat-3-line black-icon"></i>{comment?.replies?.length}</div>
                                </li>
                            </ul>
                            <a className="reply-text" href="/" style={{ cursor: 'pointer' }} onClick={(e) => {
                                e.preventDefault();
                                setReplyToCommentId(comment._id);
                                setReplyText("");

                            }}>{t('web_lbl_reply')}</a>
                            {/* {console.log("comment",comment)}
                            {console.log(">>>>>>>>>>>>>", )} */}
                            {getUser()?._id === comment?.user_data?._id ? (
                                <>
                                {comment?.comment_type === 'text' ? (
                                    <a className="reply-text" href="/" style={{ cursor: 'pointer' }} onClick={(e) => {
                                        e.preventDefault(); editFunction(comment);
                                    }}>{t('web_lbl_edit')}</a>
                                ) : (
                                    <></>
                                )}
                                <a className="reply-text" href="/" style={{ cursor: 'pointer' }} onClick={(e) => {
                                    e.preventDefault(); deleteFunction(comment?._id);
                                }}>{t('web_lbl_delete')}</a>
                            </>                            
                            ): (
                                <></>
                            )}
                        </div>
                        {replyToCommentId === comment._id && (
                            <div className="commnet-box position-relative">
                                <div className="position-relative">
                                    <textarea
                                        className="form-control h-4"
                                        placeholder={t('web_lbl_write_your_comment')}
                                        value={replyText}
                                        onChange={(e) => setReplyText(e.target.value)}
                                        onClick={() => setShowEmojiPickerForReply(false)}
                                    />
                                    <input className='d-none' type="file" ref={selectImageInput} onChange={(e) => handleReplyFileUpload(comment, e)}/>
                                    <span style={{cursor:'pointer',position:'absolute', right:'35px', bottom:'10px'}} type='button' onClick={()=>{selectImageInput.current.click()}}>
                                        <i className="fa fa-paperclip"></i>
                                    </span>
                                    <i className="fa-solid fa-face-smile" onClick={() => setShowEmojiPickerForReply(!showEmojiPickerForReply)} style={{ cursor: 'pointer', position: 'absolute', right:'10px', bottom:'10px'}}></i>
                                </div>
                                {/* <a className="reply-text mt-2" onClick={() => handlePostReply(comment)} style={{ cursor: 'pointer' }}>Post Reply</a> */}
                                {showEmojiPickerForReply && (
                                    <div className="emoji-picker-container">
                                        <Picker
                                            onEmojiSelect={handleEmojiSelectForReply}
                                            onClickOutside={() => {
                                                if (shouldCloseEmojiBoxForReply) {
                                                    setShowEmojiPickerForReply(false);
                                                    setShouldCloseEmojiBoxForReply(false);
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                <button className="btn btn-primary mt-3" onClick={() => handlePostReply(comment)}>{t('web_lbl_post_reply')}</button>
                            </div>
                        )}
                        {comment.replies && comment.replies.map((reply, replyIndex) => (
                            <div key={replyIndex} id='ksdjfk' className='child-comments'>
                                <CommentsToTopic getComponent={getComponent} comment={reply} IsFavouriteComment={IsFavouriteComment} topicviewlist={topicviewlist} replyToCommentId={replyToCommentId} replyText={replyText} setReplyText={setReplyText} handlePostReply={handlePostReply} setReplyToCommentId={setReplyToCommentId} user={user} handleEmojiSelectForReply={handleEmojiSelectForReply} selectImageInput={selectImageInput} handleReplyFileUpload={handleReplyFileUpload}/>
                            </div>
                        ))}
                    </figcaption>
                </div>
            </div>
            <div
                className="modal fade"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                id='staticBackdrop'
                tabIndex="-1"
                aria-labelledby={`staticBackdropLabel`}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 'calc(100vh - 1rem)' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <span className='announcements-title'>{t('web_lbl_edit_comment')}</span>
                            {/* <button type="button" className="btn-close" onClick={() => editModalForm?.setFieldValue('comment',"")} id='close_model' data-bs-dismiss="modal" aria-label="Close"></button>                        */}
                            <button type="button" className="btn-close" id='close_model' data-bs-dismiss="modal" aria-label="Close"></button>                       
                            <button type="button" id='open-comment-modal' style={{display:'none'}} data-bs-toggle="modal" data-bs-target={`#staticBackdrop`}></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={editModalForm?.handleSubmit}>
                                <div className='position-relative'>
                                    <input type='text' ref={inputRef} id='comment' name='comment' className='form-control' value={editModalForm?.values.comment} onChange={editModalForm?.handleChange}/>
                                    <i className="fa-solid fa-face-smile" onClick={() => {
                                        document.getElementById('emoji_div').style.display='block';
                                        setTimeout(() => {
                                            document.getElementById('emoji_div').setAttribute("data-shouldclose","true");
                                        }, 200);
                                    }} style={{ cursor: 'pointer', position: 'absolute', right:'10px', bottom:'10px'}}></i>
                                    {/* {showEmojiPickerForReply && ( */}
                                        <div className="emoji-picker-container" id='emoji_div' style={{display:'none'}}>
                                            <Picker
                                                onEmojiSelect={handleEmojiSelectForEdit}
                                                onClickOutside={() => {
                                                    if (document.getElementById('emoji_div').getAttribute("data-shouldclose") === "true") {
                                                        document.getElementById('emoji_div').style.display='none';
                                                        document.getElementById('emoji_div').removeAttribute("data-shouldclose");
                                                    }
                                                }}
                                            />
                                        </div>
                                    {/* )} */}
                                    <span className='text-danger'>{editModalForm?.errors && editModalForm?.errors?.comment }</span>
                                </div>
                                <div className='d-flex mt-3'>
                                    <button className='btn btn-primary' disabled={comment_data === inputRef?.current?.value} type='submit' >{t('web_lbl_post')}</button>
                                    {/* <button className='btn btn-secondary ms-2' onClick={() => editModalForm?.setFieldValue('comment',"")} type='button' data-bs-dismiss="modal" aria-label="Close">Cancel</button> */}
                                    <button className='btn btn-secondary ms-2' type='button' data-bs-dismiss="modal" aria-label="Close">{t('web_lbl_button_cancel')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommentsToTopic
