import React, {useState, useEffect} from 'react';
import Breadcrums from '../../common/breadcrumbs'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { SWAL_SETTINGS } from '../../../../utils/Constants'
import { formateDate, formatFileSize, getIcons } from '../../../../utils/commonfunction'
import toHtml from 'html-react-parser'
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-transitions.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-zoom.css';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lg-autoplay.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import 'lightgallery/css/lg-rotate.css';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import 'lightgallery/css/lg-fullscreen.css';



const GalaryDetails = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [isAudio, setIsAudio] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Media", url: "/admin/media" }, { title: "Details", url: "" }]
    console.log(location.state)

    function CopytoClipboard(text) {
        // Copy the text inside the text field
        navigator.clipboard.writeText(text);
        Swal.fire({
            icon: 'success',
            text: "Copied",
            ...SWAL_SETTINGS
        })
    }

    const checkFileType = () => {
        const filePath = location.state.filePath;
        const extension = filePath.split('.').pop().toLowerCase();
        setIsAudio(extension === 'mp3' || extension === 'wav');
        setIsVideo(extension === 'mp4' || extension === 'mov' || extension === 'avi');
    };

    const renderContent = () => {
        if (isAudio || isVideo) {
            return (
                <a href={location.state.filePath} target="_blank" rel="noopener noreferrer">{location.state.filePath}</a>
            );
        } else {
            return (
                <a href={location.state.filePath} target="_blank" rel="noopener noreferrer">{location.state.filePath}</a>
            );
        }
    };

    useEffect(() => {
        checkFileType();
    }, []);

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                    <div className="card custom-card productdesc">
                        <div className="card-body h-100">
                            <div className="row">
                                <div className="col-xl-6 col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="col-2">
                                            <div className="clearfix carousel-slider">
                                                <div
                                                    id="thumbcarousel"
                                                    className="carousel slide"
                                                    data-interval="false"
                                                >
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 offset-md-1 col-sm-9 col-8">
                                            <div className="product-carousel">
                                                <div
                                                    id="carousel"
                                                    className="carousel slide"
                                                    data-ride="false"
                                                >
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active gallery-detail">
                                                            {getIcons(location.state.filePath) === "image" ? <LightGallery
                                                                speed={500}
                                                                plugins={[lgThumbnail, lgZoom, lgFullscreen, lgRotate]}
                                                            >
                                                                <a href={location.state.filePath} className="d-block h-100">
                                                                    <img alt={location.state.fileName} src={location.state.filePath} />
                                                                </a>
                                                            </LightGallery> : toHtml(getIcons(location.state.filePath))}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="col-3"><strong>File url:</strong></div>
                                        <div className="col-9">
                                            {renderContent()}
                                            <button type="button" className="btn btn-sm ripple btn-main-primary signbtn ms-2" style={{position:'absolute'}} onClick={() => { CopytoClipboard(location.state.filePath) }}>
                                                <i className="fa fa-clone" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3"><strong>File name:</strong></div>
                                        <div className="col-9">
                                            {location.state.fileName}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3"><strong>File size:</strong></div>
                                        <div className="col-9">
                                            {formatFileSize(location.state.size)}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3"><strong>File upload time:</strong></div>
                                        <div className="col-9">
                                            {formateDate(location.state.createdAt)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5">
                                <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                                    <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default GalaryDetails