import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as staffServices from '../../services/staff.services';
import CustomPagination from "../../common/custompagination";
import Sorting from "../../common/sorting";
import Loader from "../../common/loader";
import Breadcrums from "../../common/breadcrumbs";
import $ from "jquery";
import * as Yup from 'yup';
import { ErrorMessage, Field, Formik } from 'formik';
import {
  SWAL_SETTINGS,
  showFilterlist,
  STAFF_ROLE,
} from "../../../../utils/Constants";
import {
  decideRole,
  formateDate,
  handleServerValidations,
  hasPermission,
  removeSpecialCharacter,
} from "../../../../utils/commonfunction";
import StatusFilter from "../../common/statusFilter";
import CustomRangepicker from "../../common/rangepicker";
import { Modal, Button } from "rsuite";
const StaffTable = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Staff", url: "" },
  ];
  const showFilterOfRoles = [{ name: "Role", status__id: "" }, { name: "Super Admin", status__id: 1 }, { name: "Sub Admin", status__id: 2 }, { name: "Staff", status__id: 4 }]
  const [resetdate, setResetDate] = useState(false);
  /********** MULTI SELECT >>>>>>********* */
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedAllData, setSelectedAllData] = useState(false);
  const [adminId, setAdminId] = useState("");
  const selectAllCheckbox = useRef();
  const [secondModal, setSecondModal] = useState(false);

  /********** MULTI SELECT <<<<<<********* */

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      staffServices
        .List(formData)
        .then((data) => {
          data?.data?.list?.forEach((item) => {
            if (item?.is_super_admin) {
              setAdminId(item?._id)
            }
          })
          setDataLength(data.data.total_records);
          // setSerialNo(data.data.offset);
          setList(data && data.data && data.data.list ? data.data.list : []);
          setSelectedAllData(data && data.data && data.data.data_ids ? data.data.data_ids : []);
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [location, statsupdate, sorting, search, globalsearch,itemperpage,params.pgno]);


  const viewfunction = (row) => {
    navigate(`/admin/staff/view/${row._id}`);
  };

  const goToEdit = (row) => {
    navigate(`/admin/staff/${params.pgno}/edit/${row._id}`);
  };

  const goToDuplicate = (row) => {
    navigate(`/admin/staff/add`, { state: row });
  };

  const ChangeStatus = (data, Num) => {
    let ids = Array.isArray(data) ? data : [data];
    const formData = new FormData();
    formData.append("o_id", JSON.stringify(ids));
    formData.append("status", Num);
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        staffServices
          .Status(formData)
          .then((response) => {
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setStatusUpdate(!statsupdate);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("error===>");
          });
      }
    });
  };

  function Deletefunction(data) {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let ids = Array.isArray(data) ? data : [data];
        const formdata = new FormData();
        formdata.append("o_id", JSON.stringify(ids));
        // formdata.append("o_id", row._id);
        staffServices
          .Delete(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              /** removing deleted ids from selectedRecords */
              setSelectedRecords((data) => data.filter((item) => { return ids.indexOf(item) === -1}));
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  }

  // sorting function start
  const handleSort = (e, column) => {
    setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // search or filter function
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer end

  const handleFilterChange = (e) => {
    console.log('valuevalu1e', e.target.value)
    if (e.target.value) {
      prepareSearch("role", Number(e.target.value))
    } else {
      prepareSearch("role", "")
    }
  };


  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    // reset customrangepicker & customstatusfilter state using jquery//
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
  };

  /********** MULTI SELECT >>>>>>********* */
  useEffect(() => {
    if (selectedRecords.length === datalength) {
      selectAllCheckbox.current.indeterminate = false;
      selectAllCheckbox.current.checked = true;
    } else if (selectedRecords.length) {
      selectAllCheckbox.current.indeterminate = true;
    } else {
      selectAllCheckbox.current.indeterminate = false;
    }
  }, [selectedRecords]);
  const selectRecord = (userData, check) => {
    if (check) {
      setSelectedRecords((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedRecords((previousState) => previousState.filter((item) => item !== userData._id));
    }
  };
  const handleSelectAll = (check) => {
    if (check) {
      setSelectedRecords(selectedAllData);
    } else {
      setSelectedRecords([]);
    }
  };
  const isSelected = (data) => {
    return (selectedRecords.filter((item) => data?._id === item).length > 0);
  };
  /********** MULTI SELECT <<<<<<<<<********* */

  const inviteValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First name is required")
      .test("no-spaces", "First name is required", (value) => value.trim()).matches(/^[A-Za-z]+$/, "Only alphabets are allowed in the first name"),
    last_name: Yup.string()
      .required("Last name is required")
      .test("no-spaces", "Last name is required", (value) => value.trim()).matches(/^[A-Za-z]+$/, "Only alphabets are allowed in the last name"),
    email: Yup.string().email().required("Email is required"),
    role: Yup.string()
      .required("Role is required"),
  });

  const resendStaffInvite = (_id) => {
    staffServices.reInviteStaff({ 'o_id': _id }).then((response) => {
      if (response?.success) {
        Swal.fire({
          icon: "success",
          text: response.message,
          ...SWAL_SETTINGS,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: handleServerValidations(response),
          ...SWAL_SETTINGS,
        });
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {/* table section */}
      <div className="animation_fade">
        <div className="card custom-card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="main-content-label">Staff</h6>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0 me-3">
                  <div className="form-group mb-0 rangepicker_container filter_design">
                    <i className="fa fa-search calender_icon"></i>
                    <input
                      type="search"
                      className="form-control"
                      value={globalsearch}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="me-3">
                  <CustomRangepicker
                    GetDateRange={(e) => {
                      prepareSearch("createdAt", e);
                    }}
                    resetdate={resetdate}
                  />
                </div>
                <div className="me-3">
                  <StatusFilter
                    data={showFilterlist}
                    prepareSearch={prepareSearch}
                  />
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select className="form-control cp" onChange={handleFilterChange} id="defaultstatus">
                        {showFilterOfRoles && showFilterOfRoles.length > 0 && showFilterOfRoles.map((option, i) => {
                          return (
                            <option key={i} value={option.status__id}>
                              {option.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  type="reset"
                  value="Reset"
                  onClick={resetFilter}
                  className="btn btn-warning float-right mr-2"
                >
                  Reset Filter
                </button>
                {hasPermission('/admin/staff/add') ?
                <button
                  className="btn ripple btn-main-primary signbtn"
                  onClick={() => navigate(`/admin/staff/add`)}
                >
                  Add New
                </button>
                :null}
                {hasPermission('/admin/staff/invite') ?
                <button
                  className="btn ripple btn-main-primary signbtn ms-2"
                  onClick={() => setSecondModal(true)}
                >
                  Invite
                </button>
                :null}
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                <thead>
                  <tr>
                    <th className={"position-relative select_head "+ (hasPermission('/admin/staff/multi-select') ? '': "d-none")}>
                      <div className="select-check-container">
                        <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                          className="ckbox  cp">
                          <input
                            ref={selectAllCheckbox}
                            id="selectAllCheck"
                            checked={
                              datalength && datalength === selectedRecords.length
                            }
                            onChange={(e) => handleSelectAll(e.target.checked)} type="checkbox" />
                          <span></span>
                        </label>
                      </div>
                    </th>
                    <th style={{ width: 50 }}>Image</th>
                    <th>
                      <div className="sorting_column">
                        <span>Name</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="name"
                        />
                      </div>
                    </th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>
                      <div className="sorting_column">
                        <span>Created Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          defaultSorting={defaultSorting}
                          column="createdAt"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="sorting_column">
                        <span>Modify Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          defaultSorting={defaultSorting}
                          column="updatedAt"
                        />
                      </div>
                    </th>
                    <th className="status_head">Status</th>
                    <th className="action_head">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={9}>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {list.length ? (
                        list.map((row, i) => {
                          return (
                            <tr key={i} className={isSelected(row) ? "selected" : ""}>
                              <td className={"position-relative "+ (hasPermission('/admin/staff/multi-select') ? '': "d-none")}>
                                <div className="select-check-container">
                                  <label htmlFor={row?._id + "input"} style={{ lineHeight: 'unset' }}
                                    className="ckbox cp">
                                    <input
                                      id={row?._id + "input"}
                                      checked={isSelected(row)}
                                      onChange={(e) =>
                                        selectRecord(row, e?.target?.checked)
                                      }
                                      type="checkbox"
                                      className="form-check select-check cp" />
                                    <span></span>
                                  </label>
                                </div>
                              </td>
                              <td>
                                {
                                  row?.profile_image ?
                                    <div className='row_image'>
                                      <img className='rounded' alt="profile" src={row.profile_image} />
                                    </div>
                                    : "N/A"
                                }
                              </td>
                              <td>{row?.name ? row.name : "N/A"}</td>
                              <td>{row?.email ? row.email : "N/A"}</td>
                              <td>
                                {decideRole(row.role)}
                              </td>
                              <td>
                                {row?.createdAt
                                  ? formateDate(row.createdAt)
                                  : "N/A"}
                              </td>
                              <td>
                                {row?.updatedAt
                                  ? formateDate(row.updatedAt)
                                  : "N/A"}
                              </td>
                              {hasPermission('/admin/staff/status') ?
                              <td>
                                {row?.status === 1 ? (
                                  <button
                                    className="btn ripple btn-main-primary signbtn"
                                    onClick={() => {
                                      ChangeStatus(row?._id, 0);
                                    }}
                                  >
                                    Active
                                  </button>
                                ) : (
                                  <button
                                    className="btn ripple btn-secondary"
                                    onClick={() => {
                                      ChangeStatus(row?._id, 1);
                                    }}
                                  >
                                    Inactive
                                  </button>
                                )}
                              </td>
                              :
                              <td>{row?.status === 1  ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>}</td>
                              }

                              <td>
                                <div className="d-flex">
                                  <button
                                    className="btn ripple btn-main-primary signbtn"
                                    onClick={() => {
                                      viewfunction(row);
                                    }}
                                  >
                                    View
                                  </button>
                                  {row?.is_super_admin ? (
                                    <></>
                                  ) : (
                                    <>
                                      {hasPermission('/admin/staff/*/edit/*') ?
                                      <button
                                        className="btn ripple btn-success mlAction "

                                        onClick={() => {
                                          goToEdit(row);
                                        }}
                                      >
                                        Edit
                                      </button>
                                      :null}
                                      {hasPermission('/admin/staff/delete') ?
                                      <button
                                        className="btn ripple btn-secondary mlAction "

                                        onClick={() => {
                                          Deletefunction(row?._id);
                                        }}
                                      >
                                        Delete
                                      </button>
                                      :null}
                                      {hasPermission('/admin/staff/more') ?

                                      <div className="btn-group mlAction">
                                        <button
                                          type="button"
                                          className="btn btn-dark dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          More
                                        </button>
                                        <ul className="dropdown-menu">
                                        {hasPermission('/admin/staff/duplicate') ?
                                          <li>
                                            <button
                                              type="button"
                                              className="dropdown-item"
                                              onClick={() => goToDuplicate(row)}
                                            >
                                              Duplicate
                                            </button>
                                          </li>
                                          :null}
                                          {hasPermission('/admin/staff/resend') ?
                                          <>
                                           {row?.invitation_pending ? (
                                          <li>
                                          <a style={{ color: "#647194" }}
                                          href="/"
                                            className="dropdown-item"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              resendStaffInvite(row?._id);
                                            }}
                                          >
                                            Resend
                                          </a>
                                        </li>) : (
                                          <></>
                                        )}
                                          </>
                                          :null}
                                         </ul>
                                      </div>
                                      :
                                      null
                                      }

                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={9} className="text-center">
                            No records
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              id="example1_info"
              role="status"
              aria-live="polite"
            >
              <b>Total Records : {datalength ? datalength : "0"}</b>
            </div>
            {datalength && datalength > 0 ? (
              <CustomPagination
                datalength={datalength}
                itemperpage={itemperpage}
                currentPage={page}
                setPage={setPage}
                pageRoute={[
                  {
                    name: "Events",
                    path: "/admin/staff/list",
                  },
                ]}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
      {selectedRecords.length ?
        <div className="bulk_actions">
          <div className="d-flex justify-content-between align-items-center">
            <div className='d-flex align-items-center'>
              <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "23px", color: "#35b159" }}></i>
              <h6 className='ms-2 mt-1 mb-0'>Selected {selectedRecords.length} {selectedRecords.length > 1 ? "records" : "record"} of {datalength}</h6>
            </div>
            <div>
              <ul>
              {hasPermission('/admin/staff/status') ?
                <li>
                  <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(selectedRecords, 1) }}>Active</button>
                </li>
                :null}
                {hasPermission('/admin/staff/status') ?
                <li>
                  <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(selectedRecords, 0) }}>Inactive</button>
                </li>
                :null}
                {hasPermission('/admin/staff/delete') ?
                <>
                 {selectedRecords.includes(adminId) ? (
                  <></>
                ) : (
                  <li>
                    <button className="btn ripple btn-secondary" onClick={() => { Deletefunction(selectedRecords) }}>Delete</button>
                  </li>
                )}
                </>
                :null}
               </ul>
            </div>
          </div>
        </div>
        : ""
      }
      {/***********MULTI SELECTION OPTIONS <<<<<<*************/}

      {/* Invite Modal */}
      <Modal
        size={"lg"}
        className="invitation-modal modal-height-auto"
        open={secondModal}
        onClose={() => setSecondModal(false)}
        backdrop={'static'}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Invite Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                role: "",
              }}
              validationSchema={inviteValidationSchema}
              onSubmit={(values, { resetForm }) => {
                let formData = new FormData();
                formData.append('first_name', values.first_name);
                formData.append('last_name', values.last_name);
                formData.append('email', values.email);
                formData.append('role', values.role);
                staffServices.invite(formData).then(response => {
                  if (response.success) {
                    Swal.fire({
                      icon: 'success',
                      text: response.message,
                      ...SWAL_SETTINGS
                    })
                    resetForm({ values: '' });
                    navigate(`/admin/staff/list/1`);
                    setSecondModal(false);
                  } else {
                    Swal.fire({
                      icon: 'error',
                      text: handleServerValidations(response),
                      ...SWAL_SETTINGS
                    })
                  }
                }).catch(error => {
                  console.log("error ====> ", error);
                })
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
              }) => (
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="row">
                    <div className="col-lg-6 text-center form-group mt-4">
                      <label className="text-left d-flex">First Name:<span className="requirestar">*</span></label>
                      <input
                        name="first_name"
                        id="first_name"
                        type="text"
                        className="form-control"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"first_name"} />
                      </span>
                    </div>

                    <div className="col-lg-6 text-center form-group mt-4">
                      <label className="text-left d-flex">Last Name:<span className="requirestar">*</span></label>
                      <input
                        name="last_name"
                        id="last_name"
                        type="text"
                        className="form-control"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"last_name"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group mt-4">
                      <label className="text-left d-flex">Email:<span className="requirestar">*</span></label>
                      <input
                        name="email"
                        id="email"
                        type="text"
                        className="form-control"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"email"} />
                      </span>
                    </div>
                    <div className='col-lg-6 text-center form-group mt-4'>
                      <label htmlFor='slug' className='text-left d-flex'>Role:<span className="requirestar">*</span></label>
                      <div className="select-down-arrow ">
                        <Field as='select' id="role" name={"role"} className="form-control select2">
                          <option value="" label="Select role">Select{" "}</option>
                          {STAFF_ROLE && Object.keys(STAFF_ROLE).map((option, i) => {
                            return (
                              <option className="text-capitalize" key={i} value={STAFF_ROLE[option]}>
                                {removeSpecialCharacter(option)}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"role"} /></span>
                    </div>
                  </div>
                  <Modal.Footer className="mt-4">
                    <Button
                      onClick={() => {
                        setSecondModal(false);
                      }}
                      appearance="subtle"
                    >
                      Close
                    </Button>
                    <Button
                      appearance="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StaffTable;
