import React, { useEffect, useRef, useState } from "react";
import { Field, Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams, useLocation, useOutletContext } from "react-router-dom";
import * as eventsService from "../../services/events.services";
import * as questionSetServices from "../../services/questionset";
import CustomCkeditor from "../../common/customReactEditor";
import { CURRENCY, SWAL_SETTINGS } from "../../../../utils/Constants";
import {
  handleServerValidations,
} from "../../../../utils/commonfunction";
import CustomDatetimepicker from "../../common/customDatetimepicker";
import * as Yup from "yup";
import { TagPicker, Toggle } from "rsuite";
import * as staffService from "../../services/staff.services";
import { DatePicker } from 'rsuite';
import { Tooltip, Whisper } from 'rsuite';


const Event = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [previewimage, setPreviewImage] = useState("");
  const [previewimagegerman, setPreviewImageGerman] = useState("");
  const [queSetList, setQuesetList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [showdefault, setShowDefault] = useState({});
  const [secondaryRegistrantsAllowed, setSecondaryRegistrantsAllowed] = useState(false);
  const [qrCodeRequired, setQrCodeRequired] = useState(false);
  const [accomodationOffered, setAccomodationOffered] = useState(false);
  const [chooseInOutDate, setChooseInOutDate] = useState(false);
  const [chooseRoomCategory, setChooseRoomCategory] = useState(false);
  const [chooseEventAttendance, setChooseEventAttendance] = useState(false);
  const [visaInvitation, setVisaInvitation] = useState(false);
  const [dateLoaded, setDataLoaded] = useState(false);
  const [ckData, setCkData] = useState(params.id ? false : true);
  const [secondEmailAddressCC, setSecondEmailAddressCC] = useState(false);
  const [image, setImage] = useState();
  const [imageGerman, setImageGerman] = useState();
  const formikRef = useRef();
  const [id, setId] = useOutletContext();
  const [eventInitialValues, setEventInitialValues] = useState({
    title: "",
    files: "",
    files_de: "",
    hostingCompany: "",
    startDate: "",
    endDate: "",
    eventManager: [],
    guestAvailable: false,
    description: showdefault && showdefault.description ? showdefault.description : '',
    questionGroupPrimary: "",
    currency: "",
    questionGroupSecondary: "",
    totalQuantity: "",
    price: "",
    tax: "",
    purchaseTimesStartDate: "",
    purchaseTimesEndDate: null,
  })

  useEffect(() => {
    if (state) {
      setEventInitialValues({
        title: state && state?.title ? state?.title : "",
        // title_de: state && state?.title_de ? state?.title_de : "",
        files: "",
        files_de: "",
        hostingCompany: state && state?.hosting_company ? state?.hosting_company : "",
        startDate: state && state?.start_date ? new Date(state?.start_date) : "",
        endDate: state && state?.end_date ? new Date(state?.end_date) : "",
        eventManager: state && state?.event_manager_id ? state.event_manager_id : [],
        guestAvailable: state && state?.guest_can_add_secondary ? state.guest_can_add_secondary : false,
        description: state && state?.description ? state.description : "",
        // description_de: state && state?.description_de ? state.description_de : "",
        questionGroupPrimary: state && state?.question_group_primary ? state.question_group_primary : "",
        currency: state && state?.currency ? state.currency : "",
        questionGroupSecondary: state && state?.question_group_secondary ? state.question_group_secondary : "",
        totalQuantity: state && state?.event_quota_data && state?.event_quota_data?.total_quantity ? state?.event_quota_data?.total_quantity : "",
        price: state && state?.event_quota_data && state?.event_quota_data?.price ? state?.event_quota_data?.price : "",
        tax: state && state?.event_quota_data && state?.event_quota_data?.tax ? state?.event_quota_data?.tax : "",
        purchaseTimesStartDate: state && state?.event_quota_data && state?.event_quota_data?.start_date ? new Date(state?.event_quota_data?.start_date) : "",
        purchaseTimesEndDate: state && state?.event_quota_data && state?.event_quota_data?.end_date ? new Date(state?.event_quota_data?.end_date) : null,
      });
      {console.log("anii",state.description)};
      setDataLoaded(true);
      setCkData(true);
    }
    setDataLoaded(true);
  }, [])

  const data = staffList && staffList.map(
    item => ({ label: item?.name, value: item?._id })
  );

  useEffect(() => {
    questionSetServices
      .Options()
      .then((response) => {
        if (response && response.success) {
          setQuesetList(response && response.data ? response.data : []);
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
    staffService
      .Options()
      .then((response) => {
        if (response && response.success) {
          setStaffList(response && response.data)
          if (params.id) {
            getData(params.id, response.data);
          } else if (id) {
            getData(id, response.data);
          }
        }
      })
      .catch((error) => {
        console.log("err", error);
      })

    // else{
    //   setTimeout(() => {
    //     setDataLoaded(true);
    // },100)
    // }
  }, [])

  const getData = (id, data) => {
    eventsService.Details(id).then(response => {
      if (response && response.success) {
        setShowDefault(response.data ? response.data : [])
        setTimeout(() => {
          formikRef?.current?.setFieldValue("title", response?.data?.title);
          // formikRef?.current?.setFieldValue("title_de", response?.data?.title_de);
          formikRef?.current?.setFieldValue("files", response?.data?.image);
          setPreviewImage(response?.data?.image)
          formikRef?.current?.setFieldValue("files_de", response?.data?.image_de);
          setPreviewImageGerman(response?.data?.image_de)
          formikRef?.current?.setFieldValue("hostingCompany", response?.data?.hosting_company);
          formikRef?.current?.setFieldValue("startDate", new Date(response?.data?.start_date));
          formikRef?.current?.setFieldValue("endDate", new Date(response?.data?.end_date));
          formikRef?.current?.setFieldValue("eventManager", response?.data?.event_manager_id);
          formikRef?.current?.setFieldValue("guestAvailable", response?.data?.guest_can_add_secondary);
          setSecondaryRegistrantsAllowed(response?.data?.secondary_registrants_allowed_to_agenda_options);
          setQrCodeRequired(response?.data?.is_qr_required);
          formikRef?.current?.setFieldValue("description", response?.data?.description);
          // customCkeditorRef?.current?.setValue(response?.data?.description);
          // formikRef?.current?.setFieldValue("description_de", response?.data?.description_de);
          // customCkeditorRefTwo?.current?.setValue(response?.data?.description_de);
          setAccomodationOffered(response?.data?.accommodation_offered);
          setChooseInOutDate(response?.data?.choice_arrival_departure_date);
          setChooseRoomCategory(response?.data?.choice_room_category);
          setChooseEventAttendance(response?.data?.choice_event_attendance_date);
          setVisaInvitation(response?.data?.askto_visa_verification);
          formikRef?.current?.setFieldValue("questionGroupPrimary", response?.data?.question_group_primary);
          formikRef?.current?.setFieldValue("currency", response?.data?.currency?.code);
          if (response?.data?.question_group_secondary) {
            formikRef?.current?.setFieldValue("questionGroupSecondary", response?.data?.question_group_secondary);
          }
          formikRef?.current?.setFieldValue("totalQuantity", response?.data?.event_quota_data?.total_quantity);
          formikRef?.current?.setFieldValue("price", response?.data?.event_quota_data?.price);
          formikRef?.current?.setFieldValue("tax", response?.data?.event_quota_data?.tax);
          formikRef?.current?.setFieldValue("purchaseTimesStartDate", new Date(response?.data?.event_quota_data?.start_date));
          formikRef?.current?.setFieldValue("purchaseTimesEndDate", new Date(response?.data?.event_quota_data?.end_date));
          setTimeout(() => {
            setCkData(true);
          },1000)
        }, 100)

        //   setTimeout(() => {
        //     setDataLoaded(true);
        // },100)
      }

    }).catch(error => {
      console.log("error=====>", error)
    })
  }

  const eventValidationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .test("no-spaces", "Title is required", (value) => value.trim()),
    files: Yup.mixed()
      .required("Image is required")
      .test("fileType", "Unsupported file format", (value) => {
        if (!value) return false;
        if (value?.name) {
          if (value?.name?.includes(".jpg") || value?.name?.includes(".png") || value?.name?.includes(".jpeg") || value?.name?.includes(".gif") || value?.name?.includes(".webp")) {
            return true
          } else {
            return false
          }
        } else {
          return true;
        }
      }),
    hostingCompany: Yup.string()
      .required("Hosting company is required")
      .test("no-spaces", "Hosting company is required", (value) => value.trim()),
    startDate: Yup.string()
      .required("Start date is required")
      .test("no-spaces", "Start date is required", (value) => value.trim()),
    endDate: Yup.string()
      .required("End date is required")
      .test("no-spaces", "End date is required", (value) => value.trim()),
    eventManager: Yup.mixed().required("Event manager is required"),
    guestAvailable: Yup.boolean(),
    description: Yup.string()
      .required("Description is required")
      .test("no-spaces", "Description required", (value) => value.trim()),
    questionGroupPrimary: Yup.string()
      .required("Please select question group")
      .test("no-spaces", "Please select question group", (value) => value.trim()),
    currency: Yup.string().required("Please select currency"),
    // .test("no-spaces", "Please select currency", (value) => value.trim()),
    totalQuantity: Yup.string()
      .required("Total quantity is required")
      .test("no-spaces", "Total quantity is required", (value) => value.trim()),
    price: Yup.string()
      .strict(false)
      .trim()
      .matches(
        /^[0-9]+$/,
        "Decimal values is not allowed in price ",
      ),
    tax: Yup.string()
      .strict(false)
      .trim()
      .matches(
        /^[0-9]+$/,
        "Decimal values is not allowed in tax",
      )
      .test("check-price", "Please enter price first", (value) => {
        if (value && value > 0) {
          if (formikRef.current.values.price) {

            return true
          } else {
            return false
          }
        } else {
          return true
        }
      })
      .test("check-price", "Please enter tax", (value) => {
        if (formikRef.current.values.price) {
          if (value) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      }),
    purchaseTimesStartDate: Yup.string()
      .required("Start date is required")
      .test("no-spaces", "Start date is required", (value) => value.trim()),
    purchaseTimesEndDate: Yup.string()
      .required("End date is required")
      .test("no-spaces", "End date is required", (value) => value.trim()),
    questionGroupSecondary: Yup.string().when("guestAvailable", {
      is: true,
      then: () => Yup.string().required('Please select question group'),
      otherwise: () => Yup.string()
    })
  });

  const onFormSubmit = (values) => {
    setSubmitted(true);
    let formData = new FormData();
    formData.append("title", values.title);
    // formData.append("title_de", values.title_de);
    formData.append("currency", values.currency);
    formData.append("hosting_company", values.hostingCompany);
    formData.append("start_date", values.startDate);
    formData.append("end_date", values.endDate);
    formData.append("event_manager_id", JSON.stringify(values.eventManager));
    formData.append("guest_can_add_secondary", values.guestAvailable);
    formData.append("secondary_registrants_allowed_to_agenda_options", secondaryRegistrantsAllowed);
    formData.append("is_qr_required", qrCodeRequired);
    formData.append("description", values.description);
    // formData.append("description_de", values.description_de);
    formData.append("accommodation_offered", accomodationOffered);
    formData.append("choice_arrival_departure_date", chooseInOutDate);
    formData.append("choice_room_category", chooseRoomCategory);
    formData.append("choice_event_attendance_date", chooseEventAttendance);
    formData.append("askto_visa_verification", visaInvitation);
    formData.append("question_group_primary", values.questionGroupPrimary);
    formData.append("askto_second_email", secondEmailAddressCC);
    if (values.questionGroupSecondary !== "") {
      formData.append("question_group_secondary", values.questionGroupSecondary);
    }
    formData.append("total_quantity", values.totalQuantity);
    if (values.price) {
      formData.append("price", values.price);
    } else {
      formData.append("price", 0);
    }

    if (values.tax) {
      formData.append("tax", values.tax);
    } else {
      formData.append("tax", 0);
    }
    formData.append("quota_start_date", values.purchaseTimesStartDate);
    formData.append("quota_end_date", values.purchaseTimesEndDate);

    if (!params.id) {
      formData.append("image", values.files);
      formData.append("image_de", values.files_de);
      eventsService
        .Add(formData)
        .then((response) => {
          if (response?.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            setId(response.data._id)
            setTimeout(() => {
              // resetForm({ values: "" });
              navigate(`/admin/event-management/events/add/hotels/${response.data._id}`);
            }, 2000);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            setSubmitted(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    } else {
      formData.append('o_id', params.id)
      if (image) {
        setImage(false);
        formData.append("image", values.files);
      }
      if (imageGerman) {
        setImageGerman(false);
        formData.append("image_de", values.files_de);
      }
      eventsService
        .Edit(formData)
        .then((response) => {
          // console.log("res", response);
          // setSubmitting(false);
          if (response?.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            // setId(response.data._id)
            setTimeout(() => {
              // resetForm({ values: "" });
              navigate(`/admin/event-management/events/edit/hotels/${params.id}`);
            }, 2000);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            setSubmitted(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }

  // const minDate = new Date();

  const isDateDisabled = (date, maxDate, minDate) => {
    // const minDate = new Date('2023-10-10');
    // const maxDate = new Date('2023-10-13');
    return date < minDate || date > maxDate;
    // return date > minDate;
  };

 

  return (
    <>
      {dateLoaded ? (
        <Formik
          validateOnBlur={false}
          innerRef={formikRef}
          enableReinitialize
          initialValues={eventInitialValues}
          validationSchema={eventValidationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => onFormSubmit(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log("Event", values)}
              <div className="row">
                <div className="col-md-12 text-center form-group">
                  <label htmlFor="title" className="text-left d-flex">
                    Event title :<span className="requirestar">*</span>{" "}
                  </label>
                  <input
                    name="title"
                    id="title"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"title"} />
                  </span>
                </div>
                {/* <div className="col-md-12 text-center form-group">
                  <label htmlFor="title_de" className="text-left d-flex">
                    Event title in german :{" "}
                  </label>
                  <input
                    name="title_de"
                    id="title_de"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title_de}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"title_de"} />
                  </span>
                </div> */}
                <div className="col-md-6 text-center form-group ">
                  <label htmlFor="files" className="text-left d-flex">
                    Event image :<span className="requirestar">*</span>{" "}
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Recommended Size (1290x486)</Tooltip>}>
                      <span className='field-more-info mt-1 ms-1 cp'>?</span>
                    </Whisper>
                  </label>
                  <input
                    style={{}}
                    className="form-control h-auto"
                    id="files"
                    name="files"
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      setFieldValue(
                        "files",
                        event.currentTarget.files[0] || ""
                      );
                      setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))
                      if (params.id) {
                        setImage(true)
                      }
                    }}
                  />
                  <span
                    className="text-danger d-flex text-left"
                    id="errortext"
                  >
                    {touched.files && errors.files}
                  </span>
                  {previewimage ? (
                  <div className="d-flex mt-2">
                    <img src={previewimage} style={{ height: "100px" }} alt="Preview" />
                  </div>
                ) : (
                  ""
                )}
                </div>
                <div className="col-md-6 text-center form-group ">
                  <label htmlFor="files_de" className="text-left d-flex">
                    Event image german :
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Recommended Size (1290x486)</Tooltip>}>
                      <span className='field-more-info mt-1 ms-1 cp'>?</span>
                    </Whisper>
                  </label>
                  <input
                    style={{}}
                    className="form-control h-auto"
                    id="files_de"
                    name="files_de"
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      setFieldValue(
                        "files_de",
                        event.currentTarget.files[0] || ""
                      );
                      setPreviewImageGerman(URL.createObjectURL(event.currentTarget.files[0]))
                      if (params.id) {
                        setImageGerman(true)
                      }
                    }}
                  />
                  <span
                    className="text-danger d-flex text-left"
                    id="errortext"
                  >
                    {touched.files_de && errors.files_de}
                  </span>
                  {previewimagegerman ? (
                  <div className="d-flex mt-2">
                    <img src={previewimagegerman} style={{ height: "100px" }} alt="Preview" />
                  </div>
                ) : (
                  ""
                )}
                </div>
                <div className="col-md-6 text-center form-group">
                  <label htmlFor="title" className="text-left d-flex">
                    Hosting company :<span className="requirestar">*</span>{" "}
                  </label>
                  <input
                    name="hostingCompany"
                    id="hostingCompany"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hostingCompany}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"hostingCompany"} />
                  </span>
                </div>
                <div className="col-md-6 text-center form-group">
                  <label htmlFor="title" className="text-left d-flex">
                    Start date :<span className="requirestar">*</span>{" "}
                  </label>
                  <CustomDatetimepicker
                    placeholder={"Start date"}
                    setField={setFieldValue}
                    fieldname={`startDate`}
                    setFieldTouched={setFieldTouched}
                    formikRef={formikRef}
                    value={values.startDate}
                    correspondingEndDate={formikRef.current?.values?.endDate}
                    correspondingEndDateName={'endDate'}
                    endDateValue={values.endDate}
                  />

                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"startDate"} />
                  </span>
                </div>
                <div className="col-md-6 text-center form-group">
                  <label htmlFor="title" className="text-left d-flex">
                    End date :<span className="requirestar">*</span>{" "}
                  </label>
                  <CustomDatetimepicker
                    placeholder={"End date"}
                    setField={setFieldValue}
                    fieldname={`endDate`}
                    setFieldTouched={setFieldTouched}
                    formikRef={formikRef}
                    value={values.endDate}
                    endDate={true}
                    correspondingStartDate={formikRef?.current?.values?.startDate}
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"endDate"} />
                  </span>
                </div>

                <div className="col-md-6 text-center form-group">
                  <div className="d-flex justify-content-between pt-5 pb-3">
                    <label htmlFor="guestAvailable" className="text-left">
                      Is the guest can be added?
                    </label>
                    <Toggle
                      className="ms-3"
                      // defaultChecked={values.guestAvailable}
                      checked={values.guestAvailable}
                      onChange={() => {
                        if (values.guestAvailable) {
                          setFieldValue("guestAvailable", false)
                        } else {
                          setFieldValue("guestAvailable", true)
                        }
                      }}
                    />
                  </div>

                  {values.guestAvailable && (
                    <div className="d-flex justify-content-between pb-3">
                      <label htmlFor="secondaryRegistrantsAllowed" className="text-left">
                        Is secondary registrants allowed to register agenda option?
                      </label>
                      <Toggle
                        className="ms-3"
                        checked={secondaryRegistrantsAllowed}
                        // defaultChecked={secondaryRegistrantsAllowed}
                        onChange={() => {
                          setSecondaryRegistrantsAllowed(!secondaryRegistrantsAllowed);
                        }}
                      />
                    </div>
                  )}

                  <div className="d-flex justify-content-between">
                    <label htmlFor="qrCodeRequired" className="text-left d-flex">
                      Wheather or not QR code is required
                      <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>QR Codes will be generated per User as a method of check-in and check-out at events</Tooltip>}>
                        <span className='field-more-info mt-1 ms-1 cp'>?</span>
                      </Whisper>
                    </label>
                    <Toggle
                      className="ms-3"
                      // defaultChecked={qrCodeRequired}
                      checked={qrCodeRequired}
                      onChange={() => {
                        setQrCodeRequired(!qrCodeRequired);
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-6 text-center form-group">
                  <label
                    htmlFor="eventManager"
                    className="text-left d-flex"
                  >
                    Event manager:<span className="requirestar">*</span>{" "}
                  </label>
                  <div className="select-down-arrow text-left">
                    {/* <Field
                    as="select"
                    id="eventManager"
                    name={`eventManager`}
                    className="form-control select2"
                    style={{ fontSize: 14 }}
                  >
                    <option value="" label="Select" className="">
                      Select{" "}
                    </option>
                    {staffList &&
                      staffList.length > 0 &&
                      staffList.map((option, i) => {
                        return (
                          <option key={i} value={option._id} defaultValue={values.eventManager}>
                            {option.name}
                          </option>
                        );
                      })}
                  </Field> */}
                    <TagPicker
                      data={data}
                      defaultValue={values.eventManager}
                      className="w-100 rsuite-input"
                      onChange={(e) => setFieldValue('eventManager', e)}
                      cleanable={false}
                      value={values.eventManager}
                    />
                  </div>
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={`eventManager`} />
                  </span>
                </div>

                <div className='col-lg-12 text-center form-group'>
                  <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                  {/* {
                  dataLoaded ? */}
                  {/* <CustomCkeditor
                    name={"editor99"}
                    fieldname={"description"}
                    setFieldValue={setFieldValue}
                    value={values.description}
                    setFieldTouched={setFieldTouched}
                    ref={customCkeditorRef}
                  /> */}
                  {ckData ? (
                    <CustomCkeditor
                      fieldname={"description"}
                      setFieldValue={setFieldValue}
                      value={values.description}
                      setFieldTouched={setFieldTouched}
                    />
                   ) : <></>} 
                  <span className='text-danger d-flex text-left'>{touched.description && errors.description}</span>
                </div>
                {/* <div className='col-lg-12 text-center form-group'>
                  <label htmlFor='subject' className='text-left d-flex'>Description In German:</label>
                  {ckDataGerman ? (
                    <CustomCkeditor
                      fieldname={"description_de"}
                      setFieldValue={setFieldValue}
                      value={values.description_de}
                      setFieldTouched={setFieldTouched}
                    />
                  ) : <></>}
                  
                  <span className='text-danger d-flex text-left'>{touched.description_de && errors.description_de}</span>
                </div> */}

                <h4 className="col-md-12 pt-3">Question settings</h4>
                <div className="col-md-6 text-center form-group">

                  <div className="d-flex justify-content-between pt-2 pb-3">
                    <label htmlFor="accomodationOffered" className="text-left">
                      Is accomodation offered?
                    </label>
                    <Toggle
                      className="ms-3"
                      checked={accomodationOffered}
                      onChange={() => {
                        setAccomodationOffered(!accomodationOffered);
                      }}
                    />
                  </div>

                  {accomodationOffered && (<div className="d-flex justify-content-between pb-3">
                    <label htmlFor="chooseInOutDate" className="text-left">
                      User can choose arrival and departure date
                    </label>
                    <Toggle
                      className="ms-3"
                      // defaultChecked={chooseInOutDate}
                      checked={chooseInOutDate}
                      onChange={() => {
                        setChooseInOutDate(!chooseInOutDate);
                      }}
                    />
                  </div>
                  )}

                  {accomodationOffered && (
                    <div className="d-flex justify-content-between pb-3">
                      <label htmlFor="chooseRoomCategory" className="text-left">
                        User can choose room category
                      </label>
                      <Toggle
                        className="ms-3"
                        // defaultChecked={chooseRoomCategory}
                        checked={chooseRoomCategory}
                        onChange={() => {
                          setChooseRoomCategory(!chooseRoomCategory);
                        }}
                      />
                    </div>
                  )}

                  <div className="d-flex justify-content-between pb-3">
                    <label htmlFor="chooseEventAttendance" className="text-left">
                      Can the user choose event attendance dates?
                    </label>
                    <Toggle
                      className="ms-3"
                      // defaultChecked={chooseEventAttendance}
                      checked={chooseEventAttendance}
                      onChange={() => {
                        setChooseEventAttendance(!chooseEventAttendance);
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-between pb-3">
                    <label htmlFor="visaInvitation" className="text-left">
                      Is the visa invitation letter asked?
                    </label>
                    <Toggle
                      className="ms-3"
                      // defaultChecked={visaInvitation}
                      checked={visaInvitation}
                      onChange={() => {
                        setVisaInvitation(!visaInvitation);
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column pb-3">
                    <label htmlFor="questionGroupPrimary" className="text-left">
                      Primary registrant question group :<span className="requirestar">*</span>
                    </label>
                    <div className="select-down-arrow">
                      <Field
                        as="select"
                        id="questionGroupPrimary"
                        name={`questionGroupPrimary`}
                        className="form-control select2"
                        style={{ fontSize: 14 }}
                      >
                        <option value="" label="Select">
                          Select{" "}
                        </option>
                        {queSetList &&
                          queSetList.length > 0 &&
                          queSetList.map((option, i) => {
                            return (
                              <option key={i} value={option._id} defaultValue={values.questionGroupPrimary}>
                                {option.title}
                              </option>
                            );
                          })}
                      </Field>
                    </div>
                    <span className="text-danger d-flex text-left">
                      <ErrorMessage name={`questionGroupPrimary`} />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between pt-3">
                    <label htmlFor="secondEmailAddressCC" className="text-left">
                      Is the second email address as CC asked?
                    </label>
                    <Toggle
                      className="ms-3"
                      // defaultChecked={secondEmailAddressCC}
                      checked={secondEmailAddressCC}
                      onChange={() => {
                        setSecondEmailAddressCC(!secondEmailAddressCC);
                      }}
                    />
                  </div>

                  {values.guestAvailable && (
                    <div className="d-flex flex-column ">
                      <label htmlFor="select" className="text-left">
                        Secondary registrant question group :<span className="requirestar">*</span>
                      </label>
                      <div className="select-down-arrow">
                        <Field
                          as="select"
                          id="questionGroupSecondary"
                          name={`questionGroupSecondary`}
                          className="form-control select2"
                          style={{ fontSize: 14 }}
                        >
                          <option value="" label="Select">
                            Select{" "}
                          </option>
                          {queSetList &&
                            queSetList.length > 0 &&
                            queSetList.map((option, i) => {
                              return (
                                <option key={i} value={option._id} defaultValue={values.questionGroupSecondary}>
                                  {option.title}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={`questionGroupSecondary`} />
                      </span>
                    </div>
                  )}
                </div>

                <h4 className="col-md-12">Event quota</h4>
                <div className="col-md-12 text-center form-group">
                  <label htmlFor="totalQuantity" className="text-left d-flex">
                    Total quantity :<span className="requirestar">*</span>{" "}
                  </label>
                  <input
                    name="totalQuantity"
                    id="totalQuantity"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.totalQuantity}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"totalQuantity"} />
                  </span>
                </div>

                <div className="d-flex flex-column pb-3">
                  <label htmlFor="currency" className="text-left">
                    Currency :<span className="requirestar">*</span>
                  </label>
                  <div className="select-down-arrow">
                    <Field
                      as="select"
                      id="currency"
                      name={`currency`}
                      className="form-control select2"
                      style={{ fontSize: 14 }}
                    >
                      <option value="" label="Select">
                        Select{" "}
                      </option>
                      {CURRENCY &&
                        CURRENCY.map((option, i) => {
                          return (
                            <option key={i} value={option.code} defaultValue={values.currency}>
                              {option.name}{" "}{"(" + option.sign + ")"}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={`currency`} />
                  </span>
                </div>

                <div className="col-md-6 text-center form-group">
                  <label htmlFor="price" className="text-left d-flex">
                    Price :<span className="requirestar"></span>{" "}
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Price excluding Tax - If Free, then 0
                      If Price is offered Gross (eg. for events outside of Germany), then Tax should be 0.</Tooltip>}>
                      <span className='field-more-info mt-1 ms-1 cp'>?</span>
                    </Whisper>
                  </label>
                  <input
                    name="price"
                    id="price"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.price}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"price"} />
                  </span>
                </div>

                <div className="col-md-6 text-center form-group">
                  <label htmlFor="tax" className="text-left d-flex">
                    Tax % :<span className="requirestar"></span>{" "}
                  </label>
                  <input
                    name="tax"
                    id="tax"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tax}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"tax"} />
                  </span>
                </div>

                <h5 className="col-md-12 d-flex">Purchase times
                <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Purchase Dates describe the Start and End Date for the Time Period is which Users can register for the event.</Tooltip>}>
                  <span className='field-more-info mt-1 ms-1 cp'>?</span>
                </Whisper>
                </h5>
                <div className="col-md-6 text-center form-group">
                  <label htmlFor="purchaseTimesStartDate" className="text-left d-flex">
                    Start date :<span className="requirestar">*</span>{" "}
                  </label>
                  <CustomDatetimepicker
                    placeholder={"Start date"}
                    setField={setFieldValue}
                    fieldname={`purchaseTimesStartDate`}
                    setFieldTouched={setFieldTouched}
                    formikRef={formikRef}
                    value={values.purchaseTimesStartDate}
                    correspondingEndDate={formikRef.current?.values?.purchaseTimesEndDate}
                    correspondingEndDateName={'purchaseTimesEndDate'}
                    endDateValue={values.purchaseTimesEndDate}
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"purchaseTimesStartDate"} />
                  </span>
                </div>

                <div className="col-md-6 text-center form-group">
                  <label htmlFor="purchaseTimesEndDate" className="text-left d-flex">
                    End date :<span className="requirestar">*</span>{" "}
                  </label>
                  <DatePicker
                    className='w-100'
                    placeholder={"End date"}
                    fieldname={`purchaseTimesEndDate`}
                    value={values.purchaseTimesEndDate}
                    onChange={(e) => setFieldValue('purchaseTimesEndDate', e)}
                    formikRef={formikRef}
                    endDate={true}
                    shouldDisableDate={(date) => isDateDisabled(date, values.endDate, values.purchaseTimesStartDate)}
                    oneTap={true}
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"purchaseTimesEndDate"} />
                  </span>
                </div>

                <div className="mt-5">
                  <button
                    className="btn btn-main-primary signbtn mr-2"
                    type="submit"
                    disabled={submitted ? true : null}
                  >
                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                    Submit
                  </button>
                  <button
                    className="btn ripple btn-secondary"
                    type="button"
                    disabled={submitted ? true : null}
                    onClick={() => navigate(-1)}
                  >
                    <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </>
  )
}

export default Event;