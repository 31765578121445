import React from 'react';
import './InfoBar.css';
import {useNavigate } from 'react-router-dom';
import { Tooltip, Whisper } from 'rsuite';
import { useTranslation } from 'react-i18next';


const InfoBar = ({ receiverData }) => {
	const navigate = useNavigate();
    const { t } = useTranslation();


	return (
		<>
	<div className="mesgs-head d-flex align-items-center">
		<figure><img className='rounded-circle border' src={receiverData?.profile_image} alt="sunil" /></figure>
		<figcaption><h3>{receiverData?.name}</h3></figcaption>
		{/* <div className="rightInnerContainer">
		</div> */}
		<Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>{t('web_lbl_leave_chat')}</Tooltip>}>
			{/* <Link to="/users" className='chat_leave_btn'>
				<i className="ri-arrow-right-circle-fill"></i>
			</Link> */}
			    <a className='chat_leave_btn' href="/" type='button' onClick={(e) => {e.preventDefault(); navigate(-1)}}>
				<i className="ri-arrow-right-circle-fill"></i>
                 </a>
		</Whisper>
	</div>
	</>
	)
	};

export default InfoBar;
