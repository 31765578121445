import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sorting from "../../common/sorting";
import $ from "jquery";
import { useParams } from "react-router-dom";
import CustomPagination from "../../common/custompagination";
import Loader from "../../common/loader";
import * as eventServices from '../../services/events.services';
import {
  addSpecialCharacter,
  formateDate,
  globalLoader,
  removeCommas,
  writeMyExcel
} from "../../../../utils/commonfunction";
import CustomRangepicker from "../../common/rangepicker";
import { USER_TYPE } from "../../../../utils/Constants";


const ViewAttendees = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState({});
  const [attendeessearch, setAttendeesSearch] = useState("");
  const [resetdate, setResetDate] = useState(false);
  const params = useParams();
  const [attendeesList, setAttendeesList] = useState([]);
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const [allUsersExportFlag] = useState(false);
  const [csvHeaders, setCsvHeaders] = useState(["Creation date", "Change Date", "Status", "Salutation", "Title", "First Name", "Middle Name", "Last Name", "Email", "Attendees Start Date", "Attendees End Date", "Ticket Number"]);
  const [userType] = useState(Object.keys(USER_TYPE).map((item => {
    return {
      label: item, value: USER_TYPE[item]
    }
  })));


  useEffect(() => {
    setLoader(true);
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("search", JSON.stringify(search));
    formData.append("attendees_search", attendeessearch);
    formData.append("event_id", params.id);
    eventServices
      .AttendeesList(formData)
      .then((response) => {
        setDataLength(response.data.total_records);
        setAttendeesList(response && response?.data && response?.data?.list ? response.data.list : []);
        setPage(response && response.data && response.data.page ? response.data.page : 1);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error ====> ", error);
        setLoader(false);
      });

  }, [location, sorting, search, attendeessearch, params.pgno, itemperpage, params.id]);

  /********** Sorting Function Start >>>>>>********* */
  const handleSort = (e, column) => {
    // setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  /********** Sorting Function End >>>>>>********* */


  /********** Search or filter function start >>>>>>********* */
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  /********** Search or Filter Function End >>>>>>********* */


  /********** reset filter function start >>>>>>********* */
  const resetFilter = (e) => {
    e.preventDefault();
    setAttendeesSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
    $("#resetFilter")[0].selectedIndex = 0;
  };
  /********** reset filter function start >>>>>>********* */

  /** This function is used to export all user's data */
  const exportAllUsers = (event_id, done) => {
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", datalength);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("search", JSON.stringify(search));
    formData.append("event_id", params.id);
    eventServices
      .AttendeesList(formData)
      .then((response) => {
        let data = response && response?.data && response?.data?.list ? response.data.list : [];
        let tempHeaders = { length: 0, list: [] }
        let tempHeadersFinally = []
        let lengthArr = []
        //   data.map((item, index) => {
        //     if (lengthArr?.length < item?.agenda_data?.length) {
        //       lengthArr = Array(item?.agenda_data?.length).fill([])
        //     }
        //     item.agenda_data.forEach((element, i) => {
        //         if (lengthArr[i].length < element.elements.length) {
        //             lengthArr[i] = Array(element.elements.length).fill("N/A");
        //         }
        //     });
        // });
        data.forEach((item, index) => {
          if (lengthArr?.length < item?.agenda_data?.length) {
            lengthArr = Array(item?.agenda_data?.length).fill([]);
          }
          item.agenda_data.forEach((element, i) => {
            if (lengthArr[i].length < element.elements.length) {
              lengthArr[i] = Array(element.elements.length).fill("N/A");
            }
          });
        });
        let row = data.map((item) => {
          if (item?.user_type === 'primary') {
            const tempRow = [
              removeCommas(formateDate(item?.createdAt)),
              removeCommas(formateDate(item?.updatedAt)),
              item?.status === 1 ? "Active" : "Inactive",
              removeCommas(item?.user_data?.gender) ? removeCommas(item.user_data.gender) : "N/A",
              removeCommas(item?.user_data?.title) ? removeCommas(item.user_data.title) : "N/A",
              removeCommas(item?.user_data?.first_name) ? removeCommas(item.user_data.first_name) : "N/A",
              removeCommas(item?.user_data?.middle_name) ? removeCommas(item.user_data.middle_name) : "N/A",
              removeCommas(item?.user_data?.last_name) ? removeCommas(item.user_data.last_name) : "N/A",
              removeCommas(item?.user_data?.email) ? removeCommas(item.user_data.email) : "N/A",
              removeCommas(formateDate(item?.registration_data?.start_date)),
              removeCommas(formateDate(item?.registration_data?.end_date)),
              item?.tickets_data?.ticket_number ? `${item?.tickets_data?.ticket_number}(${item?.tickets_data?.type})` : "N/A"
            ];
            const tempData = []
            for (let i = 0; i < lengthArr?.length; i++) {
              tempData.push(`agenda ${i + 1}`);
              tempRow.push(removeCommas(item?.agenda_data[i]?.name ?? "N/A"));
              if (item?.agenda_data[i]?.elements || lengthArr[i]?.length) {
                for (let j = 0; j < lengthArr[i]?.length; j++) {
                  tempData.push(`element ${j + 1}`);
                  tempRow.push(removeCommas(item?.agenda_data[i]?.elements[j]?.name ?? "N/A"));
                }
              }
            }
            if (tempData.length > tempHeaders.length) {
              tempHeaders = { length: tempData?.length, list: tempData }
            }
            setCsvHeaders([...csvHeaders, ...tempHeaders.list])
            tempHeadersFinally = [...csvHeaders, ...tempHeaders?.list];
            return tempRow
          }
          else if (item?.user_type === 'secondary') {
            const tempRow = [
              removeCommas(formateDate(item?.createdAt)),
              removeCommas(formateDate(item?.updatedAt)),
              item?.status === 1 ? "Active" : "Inactive",
              removeCommas(item?.user_data?.gender) ? removeCommas(item.user_data.gender) : "N/A",
              removeCommas(item?.user_data?.title) ? removeCommas(item.user_data.title) : "N/A",
              removeCommas(item?.user_data?.first_name) ? removeCommas(item.first_name) : "N/A",
              removeCommas(item?.user_data?.middle_name) ? removeCommas(item.middle_name) : "N/A",
              removeCommas(item?.user_data?.last_name) ? removeCommas(item.last_name) : "N/A",
              removeCommas(item?.user_data?.email) ? removeCommas(item?.user_data?.email) : "N/A",
              removeCommas(formateDate(item?.registration_data?.start_date)),
              removeCommas(formateDate(item?.registration_data?.end_date)),
              item?.tickets_data?.ticket_number ? `${item?.tickets_data?.ticket_number}(${item?.tickets_data?.type})` : "N/A"
            ];
            const tempData = []
            for (let i = 0; i < lengthArr?.length; i++) {
              removeCommas(tempData.push(`agenda ${i + 1}`))
              removeCommas(tempRow.push(item?.agenda_data[i]?.name ?? "N/A"));
              if (item?.agenda_data[i]?.elements || lengthArr[i]?.length) {
                for (let j = 0; j < lengthArr[i].length; j++) {
                  removeCommas(tempData.push(`element ${j + 1}`))
                  removeCommas(tempRow.push(item?.agenda_data[i]?.elements[j]?.name ?? "N/A"));
                }
              }
            }
            if (tempData.length > tempHeaders?.length) {
              tempHeaders = { length: tempData?.length, list: tempData }
              setCsvHeaders([...csvHeaders, ...tempHeaders?.list]);
              tempHeadersFinally = [...csvHeaders, ...tempHeaders?.list];
            }
            return tempRow
          }
          return '';
        });
        // setAllUsersToExport(row);
        // done(true);
        writeMyExcel([tempHeadersFinally, ...row], `Attendee Report-${addSpecialCharacter(attendeesList[0]?.event_data?.title)}.xlsx`).then(() => {
          setTimeout(() => {
            globalLoader(false);
          }, 1000);
        }).catch((err) => {
          console.log(err);
        });
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });
  };
  // useEffect(() => {
  //   if (allUsersToExport.length) {
  //     setAllUsersExportFlag(true);
  //     setTimeout(() => {
  //       setAllUsersExportFlag(false);
  //       exportAllButton?.current?.link?.click();
  //     }, 1000);
  //   }
  // }, [allUsersToExport]);
  /**function is used to export all user's data end */

  const handleViewDetails = (attendeesId, event_id) => {
    const to = `/admin/event-management/events/view/attendees/${attendeesId}`;
    // Pass event_id in the state object
    const state = { event_id };
    navigate(to, { state });
  };

  const handleFilterChange = (e) => {
    if (e.target.value) {
      prepareSearch("user_type", e.target.value)
    } else {
      prepareSearch("user_type", "")
    }
  };

  const setStatus = (status) => {
    if (status === 0) {
      return <span className='badge badge-warning'>Pending</span>
    } else if (status === 1) {
      return <span className='badge badge-success'>Active</span>
    } else if (status === 2) {
      return <span className='badge badge-danger'>Cancelled</span>
    }
  }


  return (
    <>
      <div className="row">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h6 className="main-content-label"><span></span></h6>
          <div className="d-flex align-items-center">
            <div className="form-group mb-0 me-3">
              <div className="form-group mb-0 rangepicker_container filter_design">
                <i className="fa fa-search calender_icon"></i>
                <input
                  type="search"
                  className="form-control"
                  value={attendeessearch}
                  placeholder="Search"
                  onChange={(e) => {
                    setAttendeesSearch(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="me-3">
              <CustomRangepicker
                GetDateRange={(e) => {
                  prepareSearch("createdAt", e);
                }}
                resetdate={resetdate}
              />
            </div>
            <div className="me-3">
              <div className="form-group mb-0 filter_icon_container filter_design">
                <i className="fa fa-filter mr-2 filter_icon"></i>
                <div className="select-down-arrow">
                  <select className="form-control cp" onChange={handleFilterChange} id="resetFilter" style={{ width: "100px" }}>
                    <option value="">
                      Type
                    </option>
                    {userType && userType.length > 0 && userType.map((option, i) => {
                      return (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <button
              type="reset"
              value="Reset"
              onClick={resetFilter}
              className="btn btn-warning float-right mr-2"
            >
              Reset Filter
            </button>
            {
              allUsersExportFlag ?
                <>
                  <button className="btn ripple btn-main-primary signbtn mr-2" onClick={exportAllUsers}>Export Report</button>
                  {/* <CSVLink
                    ref={exportAllButton}
                    style={{ display: 'none' }}
                    data={allUsersToExport}
                    asyncOnClick={true}
                    headers={csvHeaders}
                    filename={`Attendee Report-${addSpecialCharacter(attendeesList[0]?.event_data?.title)}.${EXPORT_EXTENSION_TYPE}`}

                  >
                    Export Report
                  </CSVLink> */}
                </>
                : <button className="btn ripple btn-main-primary signbtn mr-2" onClick={exportAllUsers}>Export Report</button>
            }
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Type</th>
                <th className="status_head">Status</th>
                <th>
                  <div className="sorting_column">
                    <span>Created Date</span>
                    <Sorting sort={sorting} handleSort={handleSort} column="createdAt" />
                  </div>
                </th>
                <th className="action_head">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={10}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {attendeesList.length ? (
                    attendeesList.map((row, i) => {
                      if (row.user_type === "primary") {
                        return (
                          <tr key={i}>

                            <td className="text-capitalize">
                              {row?.user_data?.first_name
                                ? row.user_data.first_name
                                : "N/A"}
                            </td>
                            <td className="text-capitalize">
                              {row?.user_data?.middle_name
                                ? row.user_data.middle_name
                                : "N/A"}
                            </td>
                            <td className="text-capitalize">
                              {row?.user_data?.last_name
                                ? row.user_data.last_name
                                : "N/A"}
                            </td>
                            <td>
                              {row?.user_data?.email
                                ? row.user_data.email
                                : "N/A"}
                            </td>
                            <td className="text-capitalize">{row.user_type === 'primary' ? <span className="badge badge-success">primary</span> : "N/A"}</td>
                            <td>{typeof row?.registration_data?.status !== 'undefined' ? setStatus(row?.registration_data.status) : "N/A"}</td>
                            <td>
                              {row?.createdAt ? formateDate(row?.createdAt) : "N/A"}
                            </td>
                            <td>

                              <div className="d-flex">
                                <button
                                  className="btn ripple btn-main-primary signbtn"
                                  onClick={() => handleViewDetails(row._id, params.id)}
                                >
                                  View
                                </button>

                              </div>


                            </td>
                          </tr>
                        );
                      }
                      else {
                        return (
                          <tr key={i}>
                            <td className="text-capitalize">
                              {row?.first_name
                                ? row.first_name
                                : "N/A"}
                            </td>
                            <td className="text-capitalize">
                              {row?.middle_name
                                ? row.middle_name
                                : "N/A"}
                            </td>
                            <td className="text-capitalize">
                              {row?.last_name
                                ? row.last_name
                                : "N/A"}
                            </td>
                            <td>
                              {row?.email
                                ? row.email
                                : "N/A"}
                            </td>

                            <td>{row.user_type === 'secondary' ? <span className="badge badge-danger">Guest</span> : "N/A"}</td>
                            <td>{typeof row?.registration_data?.status !== 'undefined' ? setStatus(row?.registration_data.status) : "N/A"}</td>
                            <td>
                              {row?.createdAt ? formateDate(row?.createdAt) : "N/A"}
                            </td>
                            <td>
                              <div className="d-flex">
                                <button
                                  className="btn ripple btn-main-primary signbtn"
                                  onClick={() => handleViewDetails(row._id, params.id)}
                                >
                                  View
                                </button>

                              </div>
                            </td>
                          </tr>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        No records
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="" id="example1_info" role="status" aria-live="polite">
          <b>Total Records: {datalength ? datalength : "0"}</b>
        </div>
        {datalength && datalength > 0 ? (
          <CustomPagination
            datalength={datalength}
            itemperpage={itemperpage}
            currentPage={page}
            setPage={setPage}
            pageRoute={[
              {
                name: "Attendees",
                path: `/admin/event-management/events/view/attendees/${params.id}`,
              },
            ]}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ViewAttendees;