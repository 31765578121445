import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalKey } from '../../../utils/commonfunction';
import { setGlobalLoader } from '../../../redux/slices/globalLoader';
import { useDispatch, useSelector } from 'react-redux';
import GlobalLoader from './globalLoader';

const PublicRoute = ({ component: Component }) => {
    const dispatch = useDispatch();
    const loaderState = useSelector((state) => state.globalLoader);
    const getuserkey = getLocalKey("user") ? JSON.parse(getLocalKey("user")) : {};

    useEffect(() => {
        Promise.all([import('rsuite/dist/rsuite.min.css'),
        import('bootstrap/dist/css/bootstrap.min.css'),
        import("../../../assets/admin/css/skins.css"),
        import("../../../assets/admin/css/icon-list.css"),
        import("../../../assets/admin/plugins/select2/css/select2.min.css"),
        import("../../../assets/admin/css/style.css"),
        import("../../../assets/admin/css/dark-style.css"),
        import("../../../assets/admin/css/colors/default.css"),
        import("../../../assets/admin/plugins/multipleselect/multiple-select.css"),
        import("../../../assets/admin/css/sidemenu/sidemenu.css"),
        import("../../../assets/admin/plugins/summernote/summernote-bs4.css"),
        import("../../../assets/admin/plugins/fileuploads/css/fileupload.css"),
        import("../../../assets/admin/plugins/fancyuploder/fancy_fileupload.css")]).then(() => {
            dispatch(setGlobalLoader(false));
        })
        
    }, []);


    return (
        <>
            {
                getuserkey.role !== 1 ||  getuserkey.role !== 2 ||  getuserkey.role !== 4?
                    loaderState ?
                        <GlobalLoader /> :
                        <Component />
                    : <Navigate to={{ pathname: "/admin/dashboard" }} />
            }
        </>
    )

}

export default PublicRoute;