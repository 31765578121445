import { createSlice } from '@reduxjs/toolkit';

export const globalDataSlice = createSlice({
    name: 'globalData',
    initialState: {
        data: null,
    },
    reducers: {
        addData: (state, action) => {
            state.data = action.payload;
        },
    }
})

export const { addData } = globalDataSlice.actions;
export default globalDataSlice.reducer;