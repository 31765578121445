import React, {useState } from 'react'
import { Field, Formik } from 'formik'
import Swal from "sweetalert2"
import { useNavigate } from 'react-router-dom'
import * as newsletterservices from '../../services/newsletterservice';
import Breadcrums from '../../common/breadcrumbs';
import { SWAL_SETTINGS } from '../../../../utils/Constants';
import { handleServerValidations } from '../../../../utils/commonfunction';
import { GENDER } from '../../../../utils/Constants';


const NewsLettersAdd = (props) => {
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Users", url: "/admin/news-letter/list/1" }, { title: "Add", url: "" },]


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    Fname: "",
                    Email: "",
                    gender: "",
                }}
                validate={values => {
                    const error = {};
                    if (!values.Fname) error.Fname = "Name is required";
                    if (!values.gender) error.gender = "Gender is required";
                    if (!values.Email) {
                        error.Email = 'Email is required';
                    } 
                    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) {
                    //     error.Email = 'Invalid email address';
                    // }
                    else if (!/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email))
                    {
                      error.Email = "Invalid email address";
                    }
                    return error;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    console.log(values)
                    setSubmitted(true)
                    let formData = new FormData();
                    formData.append('name', values.Fname);
                    formData.append('email', values.Email);
                    formData.append('gender', values.gender);
                    newsletterservices.Add(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                resetForm({ values: '' });
                                navigate(`/admin/news-letter/list/1`)
                            }, 2000);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                            setSubmitted(false)
                        }
                    }).catch(error => {
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Add News Letter</h6>
                                    </div>
                                    <div className="row row-sm">
                                        <div className='col-md-6 text-center mb-2'>
                                            <label htmlFor='Fname' className='text-left d-flex'>Name:<span className="requirestar">*</span> </label>
                                            <input name='Fname' id='Fname' type='text' onChange={handleChange} onBlur={handleBlur} value={values.Fname} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.Fname && touched.Fname && errors.Fname}</span>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <label className="text-left d-flex" htmlFor='Email'>Email:<span className="requirestar">*</span></label>
                                            <input type="text" id="Email" name='Email' className="form-control" placeholder="Enter your email" value={values.Email} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                                            <span className='text-danger d-flex text-left'>{errors.Email && touched.Email && errors.Email}</span>
                                        </div>
                                        <div className="col-lg-6 text-center form-group">
                                            <label htmlFor='gender' className='text-left d-flex'>Gender:<span className="requirestar">*</span> </label>
                                            <div className="form-group">
                                                <Field as='select' id="gender" name={"gender"} className="form-control select2 text-capitalize">
                                                    <option value="" label="Select Gender">Select{" "}</option>
                                                    {GENDER && Object.values(GENDER).map((option, i) => {
                                                        return (
                                                            <option key={i} value={option}>
                                                                {option}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>
                                                <span className='text-danger d-flex text-left'>{errors.gender && touched.gender && errors.gender}</span>
                                            </div>
                                        </div>
                                        <div className="">
                                            <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Submit
                                            </button>
                                            <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>
    )
}

export default NewsLettersAdd