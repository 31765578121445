import React, { useEffect, useRef, useState } from 'react'
import { ErrorMessage, Formik } from 'formik'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { DistributionDetails, DistributionUsersDelete, DistributionUsersExcel, DistributionUsersList, Edit, UsersAdd } from '../../../services/distributionlist.services';
import { formateDate, globalLoader, handleServerValidations, hasPermission } from '../../../../../utils/commonfunction';
import Swal from 'sweetalert2';
import { SWAL_SETTINGS } from '../../../../../utils/Constants';
import Breadcrums from '../../../common/breadcrumbs';
import * as Yup from 'yup';
import { Button, Loader, Modal } from 'rsuite';
import CustomPagination from '../../../common/custompagination';




const DistributionEdit = (props) => {
    let formikRef = useRef();
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const [saveType, setSaveType] = useState('')
    const [loader, setLoader] = useState(true)
    const [datalength, setDataLength] = useState("")
    const [itemperpage] = useState(10)
    const [page, setPage] = useState(1);
    const [usersData, setUsersData] = useState([]);
    const [query] = useSearchParams();
    const [statsupdate, setStatusUpdate] = useState(false);
    const [open, setOpen] = useState(false);
    const [secondModal, setSecondModal] = useState(false);
    const [thirdModal, setThirdModal] = useState(false);
    const [skippedUsersModal, setSkippedUsersModal] = useState(false);
    const fileInputRef = useRef();
    const [userdatalength, setUserDataLength] = useState(0);
    const [userData, setUserData] = useState([]);
    const [refreshList, setRefreshList] = useState(false);
    /********** MULTI SELECT >>>>>>********* */
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [selectedAllData, setSelectedAllData] = useState(false);
    const selectAllCheckbox = useRef();
    /********** MULTI SELECT <<<<<<********* */

    const [selectedNewRecords, setSelectedNewRecords] = useState([]);
    const [selectedAllNewData, setSelectedAllNewData] = useState(false);
    const selectAllNewCheckbox = useRef();

    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Distribution List", url: "/admin/distribution-list/list/1" }, { title: "Edit", url: "" }]

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Title is required").test("no-spaces", "Title is required", (value) => value.trim()),
    });
    const [skippedUserData, setSkippedUserData] = useState([]);


    useEffect(() => {
        DistributionDetails(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : [])
            setTimeout(() => {
            }, 100)
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [params.id])

    useEffect(() => {
        setLoader(true)
        const formData = new FormData()
        formData.append("page", query.get('page'))
        formData.append("per_page", itemperpage)
        formData.append("distribution_id", params.id);
        DistributionUsersList(formData).then(data => {
            setUsersData(data && data?.data && data?.data.list ? data.data.list : []);
            setDataLength(data.data.total_records)
            setPage(data && data.data && data.data.page ? data.data.page : 1);
            setSelectedAllNewData(data && data.data && data.data.data_ids ? data.data.data_ids : []);
            setLoader(false)
        }).catch(error => {
            console.log("Error:", error);
        });
    }, [page, itemperpage, statsupdate, params.id, refreshList]);



    function Deletefunction(data) {
        Swal.fire({
            customClass: "swal-wide",
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#403fad",
            cancelButtonColor: "#f1388b",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                let ids = Array.isArray(data) ? data : [data];
                const formdata = new FormData();
                formdata.append("o_id", JSON.stringify(ids));
                DistributionUsersDelete(formdata)
                    .then((response) => {
                        setStatusUpdate(!statsupdate);
                        if (response?.success) {
                            Swal.fire({
                                icon: "success",
                                text: response?.message,
                                ...SWAL_SETTINGS,
                            });
                            /** removing deleted ids from selectedRecords */
                            setSelectedNewRecords((data) => data.filter((item) => {return ids.indexOf(item) === -1}));
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log("deleteError");
                    });
            }
        });
    }

    const handleClick = () => {
        navigate(`/admin/distribution-list/users/list/${params.id}/1`)
    };

    const handleUploadExcel = (e) => {
        globalLoader(true);
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append("file", e.target.files[0]);
            DistributionUsersExcel(formData)
                .then((response) => {
                    setTimeout(() => {
                        if (response?.success) {
                            fileInputRef.current.value = "";
                            setOpen(false);
                            setThirdModal(false);
                            setSecondModal(true);
                            setUserData(response?.data?.users);
                            setSelectedRecords(response?.data?.data_ids);
                            setSelectedAllData(response?.data?.data_ids);
                            setUserDataLength(response?.data?.users?.length);
                            setSkippedUserData(response?.data?.skippedUsers);
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS,
                            });
                        }
                        globalLoader(false);
                    }, 1500);
                })
                .catch((error) => {
                    console.log("error", error);
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(error),
                        ...SWAL_SETTINGS,
                    });
                    globalLoader(false);
                });
        }
    }
    /********** MULTI SELECT For Modal >>>>>>********* */
    useEffect(() => {
        if (secondModal) {
            if (selectedRecords.length === userdatalength) {
                selectAllCheckbox.current.indeterminate = false;
                selectAllCheckbox.current.checked = true;
            } else if (selectedRecords.length) {
                selectAllCheckbox.current.indeterminate = true;
            } else {
                selectAllCheckbox.current.indeterminate = false;
            }
        }
    }, [selectedRecords, secondModal]);

    const selectRecord = (userData, check) => {
        if (check) {
            setSelectedRecords((previousState) => [...previousState, userData._id]);
        } else {
            setSelectedRecords((previousState) => previousState.filter((item) => item !== userData._id));
        }
    };
    const handleSelectAll = (check) => {
        if (check) {
            setSelectedRecords(selectedAllData);
        } else {
            setSelectedRecords([]);
        }
    };
    const isSelected = (data) => {
        return (selectedRecords.filter((item) => data?._id === item).length > 0);
    };
    /********** MULTI SELECT For Modal <<<<<<<<<********* */



    /********** MULTI SELECT For List >>>>>>********* */
    useEffect(() => {
        // if (secondModal) {
        if (selectedNewRecords.length === datalength) {
            selectAllNewCheckbox.current.indeterminate = false;
            selectAllNewCheckbox.current.checked = true;
        } else if (selectedNewRecords.length) {
            selectAllNewCheckbox.current.indeterminate = true;
        } else {
            selectAllNewCheckbox.current.indeterminate = false;
        }
        // }
    }, [selectedNewRecords]);

    const selectNewRecord = (userData, check) => {
        if (check) {
            setSelectedNewRecords((previousState) => [...previousState, userData._id]);
        } else {
            setSelectedNewRecords((previousState) => previousState.filter((item) => item !== userData._id));
        }
    };
    const handleSelectNewAll = (check) => {
        if (check) {
            setSelectedNewRecords(selectedAllNewData);
        } else {
            setSelectedNewRecords([]);
        }
    };
    const isNewSelected = (data) => {
        return (selectedNewRecords.filter((item) => data?._id === item).length > 0);
    };

    /********** MULTI SELECT For List <<<<<<<<<********* */


    const onOkayClick = () => {
        try {
            let formData = new FormData();
            formData.append("distribution_id", params?.id);
            formData.append("user_ids", JSON.stringify(selectedRecords));
            UsersAdd(formData).then((response) => {
                if (response?.success) {
                    Swal.fire({
                        icon: 'success',
                        text: response?.message,
                        ...SWAL_SETTINGS
                    })
                    setThirdModal(false);
                    setSecondModal(false);
                    setRefreshList(!refreshList);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                    });
                }
            }).catch((err) => {
                Swal.fire({
                    icon: "error",
                    text: err?.message,
                    ...SWAL_SETTINGS,
                });
            });

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    title: showdefault && showdefault?.title ? showdefault.title : "",
                }}
                innerRef={formikRef}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    let formData = new FormData();
                    formData.append('o_id', params.id)
                    formData.append('title', values.title);
                    Edit(formData).then(response => {
                        setSubmitting(false);
                        if (response?.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response?.message,
                                ...SWAL_SETTINGS
                            })
                            if (saveType !== 'Save') {
                                setTimeout(() => {
                                    navigate(`/admin/distribution-list/list/1`)
                                }, 1000);
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                        }
                    }).catch(error => {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(error),
                            ...SWAL_SETTINGS
                        })
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Edit Distribution List</h6>
                                    </div>
                                    <div className="row row-sm">
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title' className='text-left d-flex'> Title:<span className="requirestar">*</span> </label>
                                            <input
                                                name='title'
                                                id='title'
                                                type='text'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.title}
                                                className='form-control' />
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"title"} /></span>
                                        </div>
                                        <div className="mt-5" >
                                            <button onClick={() => { setSaveType('Save') }} className="btn btn-info mr-2" type="submit" >
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Save
                                            </button>
                                            <button onClick={() => { setSaveType('') }} className="btn btn-success mr-2" type="submit">
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Save & Exit
                                            </button>
                                            <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(`/admin/distribution-list/list/1`)}>
                                                <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                                <h6 className="main-content-label">Selected Users Details</h6>
                                                <div className="d-flex align-items-center">
                                                    <div className="form-group mb-0 me-3">
                                                        {hasPermission('/admin/distribution-list/users-list/list/add') ?
                                                            <button
                                                                className="btn ripple btn-main-primary signbtn mr-2"
                                                                type="button"
                                                                onClick={() => setOpen(true)}
                                                            >
                                                                Add More Users
                                                            </button>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive position-relative">
                                                <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                                    <thead>
                                                        <tr>
                                                            <th className={"position-relative select_head " + (hasPermission('/admin/distribution-list/users/list/multi-select') ? '' : "d-none")}>
                                                                <div className="select-check-container">
                                                                    <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                                                                        className="ckbox  cp">
                                                                        <input
                                                                            ref={selectAllNewCheckbox}
                                                                            id="selectAllCheck"
                                                                            checked={
                                                                                datalength && datalength === selectedNewRecords.length
                                                                            }
                                                                            onChange={(e) => handleSelectNewAll(e.target.checked)} type="checkbox" />
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                            </th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Created By</th>
                                                            <th>Created Date</th>
                                                            {hasPermission('/admin/distribution-list/users-list/list/delete') ?
                                                                <th className='action_head'>Actions</th>
                                                                : null}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loader ?
                                                            <tr>
                                                                <td colSpan={8}><Loader /></td>
                                                            </tr> : <>
                                                                {usersData?.length ? usersData.map((data, i) => {
                                                                    return <tr key={i} className={isNewSelected(data) ? "selected" : ""}>
                                                                        <td className={"position-relative " + (hasPermission('/admin/distribution-list/users/list/multi-select') ? '' : "d-none")}>
                                                                            <div className="select-check-container">
                                                                                <label htmlFor={data?._id + "input"} style={{ lineHeight: 'unset' }}
                                                                                    className="ckbox cp">
                                                                                    <input
                                                                                        id={data?._id + "input"}
                                                                                        checked={isNewSelected(data)}
                                                                                        onChange={(e) =>
                                                                                            selectNewRecord(data, e?.target?.checked)
                                                                                        }
                                                                                        type="checkbox"
                                                                                        className="form-check select-check cp" />
                                                                                    <span></span>
                                                                                </label>
                                                                            </div>
                                                                        </td>
                                                                        <td>{data?.user_data?.name ? <Link to={`/admin/user-management/users/view/${data?.user_data?._id}?page=1&chat_page=1`}>{data?.user_data.name}</Link> : "N/A"}</td>
                                                                        <td>{data?.user_data?.email ? <Link to={`/admin/user-management/users/view/${data?.user_data?._id}?page=1&chat_page=1`}>{data?.user_data.email}</Link> : "N/A"}</td>
                                                                        <td>{data?.createdBy?.name ? <Link to={`/admin/staff/view/${data.createdBy._id}`}>{data.createdBy.name}</Link> : "N/A"}</td>
                                                                        <td>{data?.createdAt ? formateDate(data.createdAt) : "N/A"}</td>
                                                                        {hasPermission('/admin/distribution-list/users-list/list/delete') ?
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <button className="btn ripple btn-secondary mlAction" type="button" onClick={() => { Deletefunction(data?._id); }}
                                                                                    >
                                                                                        Delete
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                            : null}
                                                                    </tr>
                                                                }) : <tr><td colSpan={8} className="text-center">No records</td></tr>}
                                                            </>}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                                            {datalength && datalength > 0 ?
                                                <CustomPagination datalength={datalength} paramName={'page'} itemperpage={itemperpage} currentPage={page} setPage={setPage} query={"true"} pageRoute={[{ name: "Distribution List", path: `/admin/distribution-list/${params.pgno}/edit/${params.id}` }]} /> : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 back-button">
                                    <button className="btn ripple btn-dark" type='button' onClick={() => navigate(`/admin/distribution-list/list/1`)}>
                                        <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>

            {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
            {selectedNewRecords.length ?
                <div className="bulk_actions">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='d-flex align-items-center'>
                            <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "23px", color: "#35b159" }}></i>
                            <h6 className='ms-2 mt-1 mb-0'>Selected {selectedNewRecords.length} {selectedNewRecords.length > 1 ? "records" : "record"} of {datalength}</h6>
                        </div>
                        <div>
                            <ul>
                                {hasPermission('/admin/distribution-list/users-list/list/delete') ?
                                    <li>
                                        <button className="btn ripple btn-secondary" onClick={() => { Deletefunction(selectedNewRecords) }}>Delete</button>
                                    </li>
                                    : null}
                            </ul>
                        </div>
                    </div>
                </div>
                : ""
            }
            {/***********MULTI SELECTION OPTIONS <<<<<<*************/}

            <Modal size={"lg"} open={open} backdrop={'static'}
                onClose={() => { setOpen(false) }}>
                <Modal.Header className="mb-3">
                    <Modal.Title>Add More Users</Modal.Title>
                    <div className="mt-4">
                        <div className="d-flex align-items-center">
                            <span>Please Select One Option To Continue</span>
                        </div>
                    </div>
                </Modal.Header>
                <div className="w-100">

                </div>
                <Button className="btn ripple btn-main-primary signbtn mr-2"
                    type="button"
                    onClick={() => handleClick()}
                >
                    By Existing Users
                </Button>
                <button className="btn ripple btn-main-primary signbtn mr-2 ms-2 position-relative cp" type="button" onClick={() => setThirdModal(true)}>
                    Upload Excel
                </button>
                <Modal.Footer className="mt-4">
                    <Button
                        onClick={() => {
                            setOpen(false);
                        }}
                        appearance="subtle"
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size={"lg"} open={secondModal} backdrop={'static'} onClose={() => { setSecondModal(false) }}>
                <Modal.Header className="mb-3">
                    <Modal.Title>Selected users</Modal.Title>
                    <div className="mt-4">
                    </div>
                </Modal.Header>
                <div className="clearfix"></div>
                <div className="w-100 ovr-scroll">
                    <table className="table table-hover cp table-bordered border-t0 key-buttons text-nowrap w-100">
                        <thead>
                            <tr>
                                <th className={"position-relative select_head " + (hasPermission('/admin/distribution-list/users/list/multi-select') ? '' : "d-none")}>
                                    <div className="select-check-container">
                                        <label htmlFor="selectAllCheck1" style={{ lineHeight: 'unset' }}
                                            className="ckbox  cp">
                                            <input
                                                ref={selectAllCheckbox}
                                                id="selectAllCheck1"
                                                checked={
                                                    userdatalength && userdatalength === selectedRecords.length
                                                }
                                                onChange={(e) => handleSelectAll(e.target.checked)} type="checkbox" />
                                            <span></span>
                                        </label>
                                    </div>
                                </th>
                                <th>Name</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loader ?
                                <tr>
                                    <td colSpan={3}><Loader /></td>
                                </tr> : <>
                                    {userData?.length ? userData.map((item, i) => {
                                        return <tr
                                            key={i}
                                            className={isSelected(item) ? "selected" : ""}
                                        >
                                            <td className={"position-relative " + (hasPermission('/admin/distribution-list/users/list/multi-select') ? '' : "d-none")}>
                                                <div className="select-check-container">
                                                    <label htmlFor={item?._id + "input"} style={{ lineHeight: 'unset' }}
                                                        className="ckbox cp">
                                                        <input
                                                            id={item?._id + "input"}
                                                            checked={isSelected(item)}
                                                            onChange={(e) =>
                                                                selectRecord(item, e?.target?.checked)
                                                            }
                                                            type="checkbox"
                                                            className="form-check select-check cp" />
                                                        <span></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td className="text-capitalize">{item?.name ? item?.name : "N/A"}</td>
                                            <td>{item?.email ? item?.email : "N/A"}</td>
                                        </tr>
                                    }) : <tr><td colSpan={3} className="text-center">No records</td></tr>}</>}
                        </tbody>
                    </table>
                </div>
                <div
                    className=""
                    id="example1_info"
                    role="status"
                    aria-live="polite"
                >
                    <b className="mt-2">Total Records : {userdatalength ? userdatalength : "0"}</b>
                </div>
                {skippedUserData?.length > 0  ? 
                    <div className="mt-4 d-flex">
                    <b className="">Skipped Users : {skippedUserData?.length}</b>
                    <a href="/" className="ms-2"
                        onClick={(e) => {e.preventDefault(); setSkippedUsersModal(true) }}
                    >
                        View
                    </a>
                </div>:
                <></>
                }
                <div className="d-flex justify-content-between align-items-center mt-5">
                    <h6 className="ms-2 mt-1 mb-0">{selectedRecords?.length ? "Selected " + selectedRecords?.length + " of records " + userdatalength : ""}</h6>
                    <h6 className="ms-2 mt-1 mb-0"><span></span></h6>
                    <div>
                        <Button onClick={() => { setSecondModal(false) }} appearance="subtle">
                            Cancel
                        </Button>
                        <Button
                            className="ms-2"
                            disabled={selectedRecords?.length === 0}
                            onClick={() => onOkayClick()}
                            appearance="primary"
                        >
                            Add
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal size={"lg"} open={thirdModal} backdrop={'static'}
                onClose={() => { setThirdModal(false) }}>
                <Modal.Header className="mb-3">
                    <Modal.Title>Upload Excel</Modal.Title>
                    <div className="mt-4">
                        <div className="d-flex align-items-center">
                            <span></span>
                        </div>
                    </div>
                </Modal.Header>
                <div className="w-100">
                </div>
                <form
                    id="reader_id"
                    style={{
                        height: "300px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div className="text-center ">
                        <label htmlFor="file_reader">
                            <input
                                ref={fileInputRef}
                                type={"file"}
                                className='d-none'
                                onChange={handleUploadExcel}
                                accept=".xls,.xlsx"
                                id="file_reader"
                            />
                            <i
                                className="fa fa-upload cp"
                                aria-hidden="true"
                                style={{ fontSize: "7em", color: "#3598ff" }}
                            ></i>
                        </label>
                        <p className="text-muted mb-0">Please upload excel file.</p>
                        <a className="my-0 download-file" href={`${process.env.REACT_APP_API_URL.replace('/webservice/api/v1', "")}/uploads/default/distribution_list_sample.xlsx`} download>Download sample file.</a>
                    </div>
                </form>
                <Modal.Footer className="mt-4">
                    <Button
                        onClick={() => {
                            setThirdModal(false);
                        }}
                        appearance="subtle"
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size={"lg"} open={skippedUsersModal} backdrop={'static'} onClose={() => { setSkippedUsersModal(false) }}>
                <Modal.Header className="mb-3">
                    <Modal.Title>Skipped Users Details</Modal.Title>
                    <div className="mt-4">
                    </div>
                </Modal.Header>
                <div className="clearfix"></div>
                <div className="w-100 ovr-scroll">
                    <table className="table table-hover cp table-bordered border-t0 key-buttons text-nowrap w-100">
                        <thead>
                            <tr>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loader ?
                                <tr>
                                    <td colSpan={1}><Loader /></td>
                                </tr> : <>
                                    {skippedUserData?.length ? skippedUserData.map((item, i) => {
                                        return <tr
                                            key={i}
                                        >
                                            <td>{item}</td>
                                        </tr>
                                    }) : <tr><td colSpan={1} className="text-center">No records</td></tr>}</>}
                        </tbody>
                    </table>
                </div>
                <div
                    className=""
                    id="example1_info"
                    role="status"
                    aria-live="polite"
                >
                    <b className="mt-2">Total Records : {skippedUserData?.length}</b>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-5">
                    <h6 className="ms-2 mt-1 mb-0"><span></span></h6>
                    <div>
                        <Button onClick={() => { setSkippedUsersModal(false) }} appearance="subtle">
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DistributionEdit;