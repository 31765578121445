import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as emailLogsServices from '../../services/emaillogs.services';
import Breadcrums from '../../common/breadcrumbs';
import Loader from '../../common/loader'
import {  globalLoader, hasPermission, formateDate } from '../../../../utils/commonfunction';
import { EMAIL_STATUS, SWAL_SETTINGS } from '../../../../utils/Constants';
import Swal from 'sweetalert2';


const EmailLogsView = (props) => {
  const navigate = useNavigate()
  const params = useParams()
  const [showdefault, setShowDefault] = useState({})
  const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Email Logs", url: "/admin/email-logs/list/1" }, { title: "View", url: "" },]


  

  useEffect(() => {
    emailLogsServices.Details(params.id).then(response => {
      setShowDefault(response && response.data ? response.data : [])
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [params.id])

  const resendEmail = (log_id) => {
    globalLoader(true)
    emailLogsServices.ResendEmail({ log_id: log_id }).then((response) => {
      if (response.success) {
        globalLoader(false)
        Swal.fire({
          icon: "success",
          text: response.message,
          ...SWAL_SETTINGS,
        });
      }
    }).catch((error) => {
      globalLoader(false)
      console.log("error", error);
    })
  }


  const getStatus = (data) => {
    if (data?.status === EMAIL_STATUS.PENDING) {
      return (
        <span className="badge badge-warning">
          Pending
        </span>
      )
    } else if (data?.status === EMAIL_STATUS.IN_PROGRESS) {
      return (
        <span className="badge badge-info">
          In Progress
        </span>
      )
    } else if (data?.status === EMAIL_STATUS.SUCCESS) {
      return (
        <span className="badge badge-success">
          Success
        </span>
      )
    } else if (data?.status === EMAIL_STATUS.FAILED) {
      return (
        <>
          <span className="badge badge-danger">
            Failed
          </span>
          {hasPermission('/admin/email-logs/resend') ?
          <button
            disabled={data?.status === EMAIL_STATUS.FAILED ? false : true}
            className="btn ripple btn-success mx-1"
            onClick={() => {
              resendEmail(data?._id)
            }}>
            Resend
          </button>
          :null}
        </>
      )
    }
  }


  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="main-content-label mb-3">View Email Logs</h6>
              </div>
              {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                <div className="col-lg-12 form-group">
                  <table id="simple-table" className="table  table-bordered table-hover">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>{showdefault && showdefault?.name ? showdefault.name : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{showdefault && showdefault?.email ? showdefault.email : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Email Type</th>
                        <td>{showdefault?.send_type === "automatic" ? <span className="badge badge-success">Automatic</span> : <span className="badge badge-info">Regular</span>}</td>
                      </tr>
                      <tr>
                        <th>Usage</th>
                        <td>{showdefault && showdefault?.email_subject ? showdefault?.email_subject : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>{getStatus(showdefault && showdefault)}</td>
                      </tr>
                      <tr>
                        <th>CreatedAt</th>
                        <td>{showdefault && showdefault?.email_sent_time && showdefault?.email_sent_time ? formateDate(showdefault?.email_sent_time) : "N/A"}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault?.email_body ? showdefault.email_body : "N/A" }}></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="mt-5">
                    <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                      <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                      Back
                    </button>
                  </div>
                </div>
              </div> : <Loader />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailLogsView