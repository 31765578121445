import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Swal from "sweetalert2";
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrums from '../../common/breadcrumbs';
import CustomCkeditor from '../../common/customeditor';
import * as activityServices from '../../services/activity.services';
import { SWAL_SETTINGS } from '../../../../utils/Constants';
import { handleServerValidations } from '../../../../utils/commonfunction';


const ActivitySetEdit = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [saveType, setSaveType] = useState('')
	
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Activity Forums", url: "/admin/activity-forum/activities/list/1" }, { title: "Edit", url: "" }]


 
    useEffect(() => {
        activityServices.Details(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : []);
            setTimeout(() => {
                setDataLoaded(true);
            },100)
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])



    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    title: showdefault && showdefault.title ? showdefault.title : "",
                    title_de: showdefault && showdefault.title_de ? showdefault.title_de : "",
                    richtext: showdefault && showdefault.description ? showdefault.description : "",
                    richtext_de: showdefault && showdefault.description_de ? showdefault.description_de : "",
                }}
                validate={values => {
                    const error = {};
                    if (!values.title || !values.title.trim()) error.title = "Title is required";
                    if (!values.richtext || !values.richtext.trim()) error.richtext = "Description is required";
                    return error;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    let formData = new FormData();
                    formData.append('o_id', params.id)
                    formData.append('title', values.title);
                    formData.append('title_de', values.title_de);
                    formData.append('description', values.richtext);
                    formData.append('description_de', values.richtext_de);
                    activityServices.Edit(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            if(saveType !== 'Save'){
                            setTimeout(() => {
                                navigate(`/admin/activity-forum/activities/list/${params.pgno}`)
                            }, 1000);
                        }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            });
                        }
                    }).catch(error => {
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Edit Activity</h6>
                                    </div>
                                    <div className="row row-sm">
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span> </label>
                                            <input name='title' id='title' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.title && touched.title && errors.title}</span>
                                        </div>
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title_de' className='text-left d-flex'>Title In German:</label>
                                            <input name='title_de' id='title_de' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title_de} className='form-control' />
                                            {/* <span className='text-danger d-flex text-left'>{errors.title_de && touched.title_de && errors.title_de}</span> */}
                                        </div>
                                        <div className='col-lg-12 text-center form-group'>
                                            <label htmlFor='subject' className='text-left d-flex'>Description (En):<span className="requirestar">*</span></label>
                                            {
                                                dataLoaded ?
                                                <CustomCkeditor
                                                    fieldname={"richtext"}
                                                    setFieldValue={setFieldValue}
                                                    value={values.richtext}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                                :
                                                ""
                                            }
                                            <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                                        </div>
                                        <div className='col-lg-12 text-center form-group'>
                                            <label htmlFor='subject' className='text-left d-flex'>Description In German (En):</label>
                                            {
                                                dataLoaded ?
                                                <CustomCkeditor
                                                    fieldname={"richtext_de"}
                                                    setFieldValue={setFieldValue}
                                                    value={values.richtext_de}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                                :
                                                ""
                                            }
                                            {/* <span className='text-danger d-flex text-left'>{errors.richtext_de && touched.richtext_de && errors.richtext_de}</span> */}
                                        </div>
                                        <div className="">
                                            <button onClick={()=>{setSaveType('Save')}} className="btn btn-info mr-2" type="submit" >
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Save
                                            </button>
                                            <button  onClick={()=>{setSaveType('')}} className="btn btn-success mr-2" type="submit">
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Save & Exit
                                            </button>
                                            <button className="btn ripple btn-secondary" type='button'  onClick={() => navigate(-1)}>
                                                <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    {/* // : "Loader"} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>
    )
}

export default ActivitySetEdit