import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Swal from "sweetalert2";
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrums from '../../../common/breadcrumbs';
import CustomCkeditor from '../../../common/customeditor';
import { SWAL_SETTINGS } from '../../../../../utils/Constants';
import { handleServerValidations } from '../../../../../utils/commonfunction';
import * as bannerService from '../../../services/banner.services';
import * as Yup from 'yup';
import { Tooltip, Whisper, Loader } from 'rsuite';


const BannerEdit = (props) => {
	const navigate = useNavigate()
	const params = useParams()
	const [showdefault, setShowDefault] = useState({})
	const [previewimage, setPreviewImage] = useState("")
	const [dataLoaded, setDataLoaded] = useState(false);
	const [saveType, setSaveType] = useState('')

	const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Banners", url: "/admin/cms/banner/list/1" }, { title: "Edit", url: "" }]


	useEffect(() => {
		bannerService.Details(params.id).then(response => {
			setShowDefault(response && response.data ? response.data : [])
			setPreviewImage(response.data.image);
			setTimeout(() => {
				setDataLoaded(true);
			}, 100)
		}).catch(error => {
			console.log("error=====>", error)
		})
	}, [])


	const validationSchema = Yup.object().shape({
		title: Yup.string().required('Title is required').test('no-spaces', 'Title is required', (value) => value.trim()),
		richtext: Yup.string().required("Description is required").test('no-spaces', 'Description is required', (value) => value.trim()),
		files: Yup.mixed().required('Image is required').test(
			'fileType',
			'Unsupported file format',
			(value) => {
				if (!value) return false;
				if (value.type) {
					const supportedFormats = ['image/jpeg', 'image/png', 'image/gif'];
					return supportedFormats.includes(value.type);
				} else {
					return true;
				}
			}
		),
	});


	return (
		<>
			<Breadcrums data={breadcrumbs} />
			{dataLoaded ? (
			<Formik
				enableReinitialize
				initialValues={{
					title: showdefault && showdefault.title ? showdefault.title : "",
					title_de: showdefault && showdefault.title_de ? showdefault.title_de : "",
					richtext: showdefault && showdefault.description ? showdefault.description : "",
					richtext_de: showdefault && showdefault.description_de ? showdefault.description_de : "",
					files: showdefault && showdefault.image ? showdefault.image : "",
				}}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting }) => {
					let formData = new FormData();
					formData.append('o_id', params.id);
					formData.append('title', values.title);
					formData.append('title_de', values.title_de);
					formData.append('description', values.richtext);
					formData.append('description_de', values.richtext_de);
					formData.append('image', values.files);
					bannerService.Edit(formData).then(response => {
						setSubmitting(false);
						if (response.success) {
							Swal.fire({
								icon: 'success',
								text: response.message,
								...SWAL_SETTINGS
							})
							if (saveType !== 'Save') {
								setTimeout(() => {
									navigate(`/admin/cms/banner/list/${params.pgno}`)
								}, 1000);
							}
						} else {
							Swal.fire({
								icon: 'error',
								text: handleServerValidations(response),
								...SWAL_SETTINGS
							});
						}
					}).catch(error => {
						console.log("error ====> ", error);
					})
				}}
			>{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				setFieldValue,
				setFieldTouched,
				isSubmitting,

			}) => (
				<form onSubmit={handleSubmit}>
					<div className="row row-sm">
						<div className="col-lg-12 col-md-12 animation_fade">
							<div className="card custom-card">
								<div className="card-body">
									<div>
										<h6 className="main-content-label mb-3">Edit Banner</h6>
									</div>
									<div className="row row-sm">
										<div className='col-md-12 text-center form-group'>
											<label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span> </label>
											<input name='title' id='title' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
											<span className='text-danger d-flex text-left'>{errors.title && touched.title && errors.title}</span>
										</div>
										<div className='col-md-12 text-center form-group'>
											<label htmlFor='title_de' className='text-left d-flex'>Title In German:</label>
											<input name='title_de' id='title_de' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title_de} className='form-control' />
											{/* <span className='text-danger d-flex text-left'>{errors.title_de && touched.title_de && errors.title_de}</span> */}
										</div>

										<div className='col-lg-12 text-center form-group'>
											<label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
											{
												dataLoaded ?
													<CustomCkeditor
														fieldname={"richtext"}
														setFieldValue={setFieldValue}
														value={values.richtext}
														setFieldTouched={setFieldTouched}
													/>
													:
													""
											}
											<span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
										</div>

										<div className='col-lg-12 text-center form-group'>
											<label htmlFor='subject' className='text-left d-flex'>Description In German:</label>
											{
												dataLoaded ?
													<CustomCkeditor
														fieldname={"richtext_de"}
														setFieldValue={setFieldValue}
														value={values.richtext_de}
														setFieldTouched={setFieldTouched}
													/>
													:
													""
											}
											{/* <span className='text-danger d-flex text-left'>{errors.richtext_de && touched.richtext_de && errors.richtext_de}</span> */}
										</div>

										<div className='col-lg-12 text-center form-group'>
											<label htmlFor='files' className='text-left d-flex'>Image:<span className="requirestar">*</span>
												<Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Recommended Size (1920x887)</Tooltip>}>
													<span className='field-more-info mt-1 ms-1 cp'>?</span>
												</Whisper>
											</label>
											<input className='form-control imgInput' id="files" name="files" accept="image/*" type="file" onChange={(event) => {
												setFieldValue("files", event.currentTarget.files[0] || "");
												event.currentTarget.files.length === 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage(""));
											}} />
											<span className='text-danger d-flex text-left' id='errortext'>{errors.files && touched.files && errors.files}</span>
										</div>
										{
											previewimage ?
												<div className='mb-4'>
													<img src={previewimage} alt='Preview' style={{ height: "100px" }} />
												</div> : ""
										}

										<div className="">
											<button onClick={() => { setSaveType('Save') }} className="btn btn-info mr-2" type="submit" >
												<i className="ace-icon fa fa-check bigger-110 mx-1"></i>
												Save
											</button>
											<button onClick={() => { setSaveType('') }} className="btn btn-success mr-2" type="submit">
												<i className="ace-icon fa fa-check bigger-110 mx-1"></i>
												Save & Exit
											</button>
											<button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
												<i className="ace-icon fa fa-times bigger-110 mx-1"></i>
												Cancel
											</button>
										</div>
									</div>
									{/* // : "Loader"} */}
								</div>
							</div>
						</div>
					</div>
				</form>
			)}
			</Formik>
			 ) : (
				<Loader />
				)}
		</>
	)
}

export default BannerEdit