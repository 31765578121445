import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Formik } from 'formik';
import Logo from '../../../../src/assets/admin/img/brand/icon.png'
import { encodeValue, handleServerValidations } from '../../../utils/commonfunction';
import * as authService from '../services/auth.services';
import { SWAL_SETTINGS } from '../../../utils/Constants';
import Swal from 'sweetalert2';


const ForgotPwdPage = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useState('false')

    return (
        <>
            <Formik
                initialValues={{ email: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Email is required';
                    } 
                    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    //     errors.email = 'Invalid email address';
                    // }
                    else if (!/^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
                    {
                      errors.email = "Invalid email address";
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setLoader(true)
                    let formData = new FormData();
                    formData.append("email", values.email);
                    authService.forgetPassword(formData).then(response => {
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            resetForm()
                            setTimeout(() => {
                                setLoader(false)
                                let email = encodeValue(values.email)
                                navigate(`/admin/otp-verification/${email}?type=password_reset`);
                            }, 2000);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                setLoader(false)
                            }, 2000);
                        }
                    }).catch(error => {
                        console.log("error: ", error);
                    })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <div className='page main-signin-wrapper'>
                        <div className='innerbody'>
                            <div className="row signpages text-center">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="row row-sm">
                                            <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                                                <div className="mt-5 pt-0 p-0 pos-absolute">
                                                    <img src={Logo} className="ht-100 mb-2" alt="logo" />
                                                    <div className="clearfix"></div>
                                                    <h5 className="mt-2 text-white">Reset Your Password</h5>
                                                    <span className="tx-white-6 tx-14 mb-5 mt-xl-0">Signup to create, discover and connect with the global community</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                                                <div className="container-fluid">
                                                    <div className="row row-sm">
                                                        <div className="card-body mt-2 mb-2">
                                                            {/* <img src="../assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo" /> */}
                                                            <div className="clearfix"></div>
                                                            <h5 className="text-left mb-2">Forgot Password</h5>
                                                            <p className="mb-4 text-muted tx-13 ml-0 text-left">OTP will be sent to your Registered Email</p>
                                                            <form onSubmit={handleSubmit}>
                                                                <div className="form-group text-left">
                                                                    <label>Email</label>
                                                                    <input className={"form-control" + (errors.email && touched.email ? " is-invalid state-invalid" : "")}
                                                                        placeholder="Enter your email"
                                                                        type="email"
                                                                        name="email"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}
                                                                        autoComplete="off" />
                                                                    <span className='text-danger text-left d-blockerr-spn'>{errors.email && touched.email && errors.email}</span>
                                                                </div>
                                                                <button className={"btn ripple btn-main-primary btn-block signbtn" + (loader === true ? " disabled" : "")} type="submit">Request Email</button>
                                                                {loader === true ?
                                                                    <div className="spinner-border text-primary mt-2" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div> : ""}
                                                            </form>
                                                            <div className="card-footer border-top-0 pl-0 mt-3 text-left ">
                                                                <p className='mb-2'>Did you remembered your password?</p>
                                                                <p className="mb-0">Try to <Link to="/admin/login">Login</Link></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    )
}

export default ForgotPwdPage