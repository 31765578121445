import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toHtml from 'html-react-parser'
import * as eventsService from '../../services/events.services';
import Loader from '../../common/loader'
import { formateDate, showStatus, getStringfromPTag } from '../../../../utils/commonfunction';

const ViewEvent = (props) => {
  const navigate = useNavigate()
  const [eventList, setEventList] = useState()
  const params = useParams()
  const [showdefault, setShowDefault] = useState({})

  useEffect(() => {
    eventsService.Details(params.id).then(response => {
      console.log("res", response);
      if (response && response.success) {
        setEventList(response.data.event_quota_data)
        setShowDefault(response.data ? response.data : [])
      }
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [params.id])

  return (
    <>
      {/* <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="main-content-label">Event</h6>
        <div className="d-flex align-items-center">
          <div className="form-group mb-0 me-3">
            <div className="form-group mb-0 rangepicker_container">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                style={{ opacity: '0' }}
              />
            </div>
          </div>
        </div>
      </div> */}
      {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
        <div className="col-lg-12 form-group">
          <table id="simple-table" className="table  table-bordered table-hover">
            <tbody>
              <tr>
                <th>Title</th>
                <td>{showdefault && showdefault.title ? showdefault.title : "N/A"}</td>
              </tr>
              <tr>
                <th>Title In German</th>
                <td>{showdefault && showdefault.title_de ? showdefault.title_de : "N/A"}</td>
              </tr>
              <tr>
                <th>Image</th>
                <td><img src={showdefault && showdefault.image ? showdefault.image : ""} alt="default" style={{ height: "100px" }} /></td>
              </tr>
              <tr>
                <th>Image German</th>
                <td><img src={showdefault && showdefault.image_de ? showdefault.image_de : ""} alt="default" style={{ height: "100px" }} /></td>
              </tr>
              <tr>
                <th>Hosting Company</th>
                <td>{showdefault && showdefault.hosting_company ? showdefault.hosting_company : "N/A"}</td>
              </tr>
              <tr>
                <th>Start Date</th>
                <td>{showdefault && showdefault.start_date ? showdefault.start_date.split("T")[0] : "N/A"}</td>
              </tr>
              <tr>
                <th>Last Date</th>
                <td>{showdefault && showdefault.end_date ? showdefault.end_date.split("T")[0] : "N/A"}</td>
              </tr>
              <tr>
                <th>Is the guest can be added </th>
                <td>{showdefault && showdefault.guest_can_add_secondary && showdefault.guest_can_add_secondary === true ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>Is secondary registrants allowed to register agenda option</th>
                <td>{showdefault && showdefault.secondary_registrants_allowed_to_agenda_options &&
                  showdefault.secondary_registrants_allowed_to_agenda_options === true ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>Wheather or not QR code is required</th>
                <td>{showdefault && showdefault.is_qr_required && showdefault.is_qr_required === true ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>Is accomodation offered</th>
                <td>{showdefault && showdefault.accommodation_offered && showdefault.accommodation_offered === true ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>User can choose arrival and departure date</th>
                <td>{showdefault && showdefault.choice_arrival_departure_date && showdefault.choice_arrival_departure_date === true ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>User can choose room category</th>
                <td>{showdefault && showdefault.choice_room_category && showdefault.choice_room_category === true ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>Can the user choose event attendance dates</th>
                <td>{showdefault && showdefault.choice_event_attendance_date && showdefault.choice_event_attendance_date === true ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>Is the visa invitation letter asked</th>
                <td>{showdefault && showdefault.askto_visa_verification && showdefault.askto_visa_verification === true ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>Primary registrant question group</th>
                <td>{showdefault && showdefault.question_group_primary_data &&
                  showdefault.question_group_primary_data.title ? showdefault.question_group_primary_data.title : "N/A"}</td>
              </tr>
              <tr>
                <th>Is the second email address as CC asked</th>
                <td>{showdefault && showdefault.askto_second_email && showdefault.askto_second_email === true ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>Secondary registrant question group</th>
                <td>{showdefault && showdefault.guest_can_add_secondary &&
                  showdefault.question_group_secondary_data === true ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{showdefault && showdefault.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault.status))}</td>
              </tr>
              <tr>
                <th>Created Date</th>
                <td>{showdefault && showdefault.createdAt ? formateDate(showdefault.createdAt) : "N/A"}</td>
              </tr>
              <tr>
                <th>Modified Date</th>
                <td>{showdefault && showdefault.updatedAt ? formateDate(showdefault.updatedAt) : "N/A"}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="mb-3" dangerouslySetInnerHTML={{ __html: getStringfromPTag(showdefault.description) }}></div>
                </td>
              </tr>
            </tbody>
          </table>

          {/* <h6 className="main-content-label mb-3">Description</h6>
          <div className="mb-3" dangerouslySetInnerHTML={{ __html: getStringfromPTag(showdefault.description) }}></div> */}

          {/* <h6 className="main-content-label mb-3 pt-3">Events Dates</h6> */}
          {/* {showdefault && Object.keys(showdefault).length > 0 && showdefault.timetable && showdefault.timetable.map((data, index) => (
            <div className="accordion mb-3" id={"accordionExample" + index} key={index}>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded="true" aria-controls={"collapseOne" + index}>
                    {data && data.title ? data.title : "N/A"}
                  </button>
                </h2>
                <div id={"collapseOne" + index} className="accordion-collapse collapse" data-bs-parent={"#accordionExample" + index}>
                  <div className="accordion-body">
                    <table id="simple-table" className="table  table-bordered table-hover">
                      <tbody>
                        <tr>
                          <th>Title</th>
                          <td>{data && data.title ? data.title : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Venue</th>
                          <td>{data && data.venue_data && data.venue_data.title ? data.venue_data.title : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Workshop</th>
                          <td>{data && data.workshop_data && data.workshop_data.title ? data.workshop_data.title : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Ticket</th>
                          <td>{data && data.tickets_data && data.tickets_data.title ? data.tickets_data.title : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>StartDate</th>
                          <td>{data && data.start_date ? formateDate(data.start_date) : ""}</td>
                        </tr>
                        <tr>
                          <th>Start Time</th>
                          <td>{data && data.start_time ? formatetime(data.start_time) : ""}</td>
                        </tr>
                        <tr>
                          <th>End Time</th>
                          <td>{data && data.end_time ? formatetime(data.end_time) : ""}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        </div>
      </div> : <Loader />}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="main-content-label">Quota</h6>
        <div className="d-flex align-items-center">
          <div className="form-group mb-0 me-3">
            <div className="form-group mb-0 rangepicker_container">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                style={{ opacity: '0' }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
              <th>
                <div className="sorting_column">
                  <span>Total Quantity</span>
                </div>
              </th>
              <th>Sold Quantity</th>
              <th className="sr_head">Available Quantity</th>
              <th>Price</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>tax</th>
            </tr>
          </thead>
          <tbody>
            <>
              {eventList ? (
                <tr >
                  <td>{eventList.total_quantity}</td>
                  <td>{eventList.sold_quantity}</td>
                  <td>{eventList.available_quantity}</td>
                  <td>{eventList.price}</td>
                  <td>{eventList.start_date ? eventList.start_date.split("T")[0] : "N/A"}</td>
                  <td>{eventList.end_date ? eventList.end_date.split("T")[0] : "N/A"}</td>
                  <td>{eventList.tax}</td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    No records
                  </td>
                </tr>
              )}
            </>
          </tbody>
        </table>
        <div className="mt-5">
          <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
            <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
            Back
          </button>
        </div>
      </div>

    </>
  )
}

export default ViewEvent;