import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Breadcrums from "../../common/breadcrumbs";
import Sorting from "../../common/sorting";
import $ from "jquery";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CustomPagination from "../../common/custompagination";
import Loader from "../../common/loader";
import { ErrorMessage, Field, Formik } from 'formik';
import * as transactionsService from '../../services/transactions.services'
import {
  SWAL_SETTINGS,
  TRANSACTION_STATUS,
  REFUND_TYPES,
  TRANSACTION_TYPES,
  getType,
} from "../../../../utils/Constants";
import {
  formateDate,
  globalLoader,
  handleServerValidations,
  removeSpecialCharacter,
  removeCommas,
  writeMyExcel
} from "../../../../utils/commonfunction";
import CustomRangepicker from "../../common/rangepicker";
import { Button, Modal } from "rsuite";


const TransactionsTable = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Transactions", url: "" },
  ];
  const [resetdate, setResetDate] = useState(false);
  const params = useParams();
  const [list, setList] = useState([]);
  const [statsupdate] = useState("false");
  const [amount, setAmount] = useState({});
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [transactionId, setTransactionId] = useState('');
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const [secondModal, setSecondModal] = useState(false);
  /* States for export report work */
  const [allUsersExportFlag] = useState(false);
  const [csvHeaders] = useState(["Name", "Email", "Event Name", "Paypal Payment Id", "Amount","Type","Status","Created Date"]);



  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      transactionsService
        .List(formData)
        .then((data) => {
          setDataLength(data.data.total_records);
          setList(data && data.data && data.data.list ? data.data.list : []);
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [location, statsupdate, sorting, search, globalsearch, secondModal]);


  // sorting function start
  const handleSort = (e, column) => {
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // search or filter function start
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer function  end

  // reset filter function start
  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
    $("#resetTransactionType")[0].selectedIndex = 0;
  };

  const getStatus = (status) => {
    if (status === TRANSACTION_STATUS.PENDING) {
      return <span className="badge badge-warning">Pending</span>;
    } else if (status === TRANSACTION_STATUS.SUCCESS) {
      return <span className="badge badge-success">Success</span>;
    } else if (status === TRANSACTION_STATUS.FAILED) {
      return <span className="badge badge-danger">Failed</span>;
    } else if (status === TRANSACTION_STATUS.REFUNDED) {
      return <span className="badge badge-info">Refunded</span>;
    }
  };

  const viewfunction = (row) => {
    navigate(`/admin/transactions/view/${row._id}`);
  };

  /** This function is used to export all transaction's data */
  const exportAllUsers = (event_id, done) => {
    globalLoader(true);
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", datalength);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("search", JSON.stringify(search));
    formData.append("global_search", globalsearch);
    transactionsService
      .List(formData)
      .then((response) => {
        let data = response && response?.data && response?.data?.list ? response.data.list : [];
        let row = data.map((item => {
          return [
            removeCommas(item?.user_data?.name) ? removeCommas(item?.user_data?.name) : "N/A",
            removeCommas(item?.user_data?.email) ? removeCommas(item?.user_data?.email) : "N/A",
            removeCommas(item?.event_data?.title) ? removeCommas(item?.event_data?.title) : "N/A",
            removeCommas(item?.paypal_pay_id) ? removeCommas(item?.paypal_pay_id) : "N/A",
            removeCommas(item?.amount) ? (item?.currency?.sign ? item?.currency?.sign : '') + removeCommas(item?.amount) : "0.00",
            removeCommas(item?.type) ? removeCommas(item?.type) : "N/A",
            item?.status,
            removeCommas(formateDate(item?.createdAt)),
          ];
        }));
        writeMyExcel([csvHeaders, ...row],`transaction-report.xlsx`).then(()=>{
        setTimeout(() => {
          globalLoader(false);
        }, 1000);
        }).catch((err)=>{
          console.log(err);
        });
      }).catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  };


  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="animation_fade">
        <div className="card custom-card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="main-content-label">Transactions</h6>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0 me-3">
                  <div className="form-group mb-0 rangepicker_container filter_design">
                    <i className="fa fa-search calender_icon"></i>
                    <input
                      type="search"
                      className="form-control"
                      value={globalsearch}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="me-3">
                  <CustomRangepicker
                    GetDateRange={(e) => {
                      prepareSearch("createdAt", e);
                    }}
                    resetdate={resetdate}
                  />
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select className="form-control text-capitalize cp" onChange={(e) => { prepareSearch("status", e.target.value) }} id="defaultstatus">
                        <option value="">Status</option>
                        {TRANSACTION_STATUS && Object.values(TRANSACTION_STATUS).map((option, i) => {
                          return (
                            <option key={i} value={option}>
                              {removeSpecialCharacter(option)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select className="form-control text-capitalize cp" onChange={(e) => { prepareSearch("transaction_type", e.target.value) }} id="resetTransactionType">
                        <option value="">Type</option>
                        {TRANSACTION_TYPES && Object.values(TRANSACTION_TYPES).map((option, i) => {
                          return (
                            <option key={i} value={option}>
                              {removeSpecialCharacter(option)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  type="reset"
                  value="Reset"
                  onClick={resetFilter}
                  className="btn btn-warning float-right mr-2"
                >
                  Reset Filter
                </button>
                {
                  allUsersExportFlag ?
                    <>
                      <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength === 0} onClick={exportAllUsers}>Export Report</button>
                    </>
                    : <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength === 0} onClick={exportAllUsers}>Export Report</button>
                }
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                <thead>
                  <tr>
                    <th>
                      <div className="sorting_column">
                        <span>Title</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="title"
                        />
                      </div>
                    </th>
                    <th>Email</th>
                    <th>Event Name</th>
                    <th>Paypal Pay Id</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>
                      <div className="sorting_column">
                        <span>Created  Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="createdAt"
                        />
                      </div>
                    </th>
                    <th className="status_head">Status</th>
                    <th className="action_head">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={9}>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {list.length ? (
                        list.map((row, i) => {
                          return (
                            <tr key={i} >
                              <td>{row && row?.user_data?.name ? <Link to={`/admin/user-management/users/view/${row?.user_id}`}>{row?.user_data?.name}</Link> : "N/A"}</td>
                              <td>{row && row?.user_data?.email ? <Link to={`/admin/user-management/users/view/${row?.user_id}`}>{row?.user_data?.email}</Link> : "N/A"}</td>
                              <td>{row && row?.event_data?.title ? <Link to={`/admin/event-management/events/view/event/${row?.event_data._id}`}>{row?.event_data?.title}</Link> : "N/A"}</td>
                              <td>{row?.paypal_pay_id ? row?.paypal_pay_id : "N/A"}</td>
                              <td>{row?.amount ? (row?.currency?.sign ? row?.currency?.sign : '') + row?.amount : "0.00"}</td>
                              <td>{row?.type ? getType(row.type) : "N/A"}</td>
                              <td>
                                {row?.createdAt
                                  ? formateDate(row?.createdAt)
                                  : "N/A"}
                              </td>
                              <td>{row?.status ? getStatus(row?.status) : "N/A"}</td>
                              <td>
                                <div className="d-flex">
                                  <button
                                    className="btn ripple btn-main-primary signbtn"
                                    onClick={() => {
                                      viewfunction(row);
                                    }}
                                  >
                                    View
                                  </button>
                                  {row?.status === TRANSACTION_STATUS.SUCCESS && row.type !== TRANSACTION_TYPES.REFUND && row?.amount !== row?.total_refunded_amount ?
                                    <button
                                      className="btn ripple btn-success mlAction "
                                      onClick={() => { setSecondModal(true); setTransactionId(row?._id); setAmount({amount: row?.amount, total_refunded_amount: row?.total_refunded_amount,event_data:row?.event_data})}}
                                    >
                                      Refund
                                    </button>
                                    : null}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={9} className="text-center">
                            No records
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              id="example1_info"
              role="status"
              aria-live="polite"
            >
              <b>Total Records : {datalength ? datalength : "0"}</b>
            </div>
            {datalength && datalength > 0 ? (
              <CustomPagination
                datalength={datalength}
                itemperpage={itemperpage}
                currentPage={page}
                setPage={setPage}
                pageRoute={[
                  {
                    name: "Events",
                    path: "/admin/transactions/list",
                  },
                ]}
              />
            ) : (
              ""
            )}
            <Modal
              size={"lg"}
              className="invitation-modal modal-height-auto"
              open={secondModal}
              onClose={() => setSecondModal(false)}
              backdrop={'static'}
            >
              <Modal.Header className="mb-3">
                <Modal.Title>Refund transaction</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <Formik
                    initialValues={{
                      amount: "",
                      refund_type: "",
                      reason: "",
                    }}
                    innerRef={formikRef}
                    validate={(values) => {
                      const errors = {};
                      if (values.refund_type === REFUND_TYPES.PARTIAL) {
                        console.log("refund_type");
                        if (!values.amount) {
                          errors.amount = "Amount is required"
                        }
                      }
                      return errors;
                    }}
                    onSubmit={(values, { resetForm }) => {
                      globalLoader(true);
                      let formData = new FormData();
                      formData.append('amount', values.amount);
                      formData.append('refund_type', values.refund_type);
                      formData.append('reason', values.reason);
                      formData.append('o_id', transactionId);
                      transactionsService.Refund(formData).then(response => {
                        if (response.success) {
                          Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                          })
                          resetForm({ values: '' });
                          setSecondModal(false);
                          globalLoader(false);
                        } else {
                          Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                          })
                          globalLoader(false);
                        }
                      }).catch(error => {
                        console.log("error ====> ", error);
                        globalLoader(false);
                      })
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleBlur,
                      touched,
                      handleSubmit,
                    }) => (
                      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                        <div className="row">
                          <div className='col-lg-12 text-center form-group'>
                            <label htmlFor='slug' className='text-left d-flex'>Refund type:</label>
                            <div className="select-down-arrow ">
                              <Field as='select' 
                              id="refund_type" 
                              name="refund_type" 
                              className="form-control select2"
                              >
                                <option value="">Type</option>
                                {amount.total_refunded_amount !== 0 ? (
                                  <option value={REFUND_TYPES.PARTIAL}>
                                    Refund partial amount
                                  </option>
                                ) : (
                                  <>
                                    <option value={REFUND_TYPES.FULL}>
                                      Refund full amount
                                    </option>
                                    <option value={REFUND_TYPES.PARTIAL}>
                                      Refund partial amount
                                    </option>
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                          {values.refund_type === REFUND_TYPES.PARTIAL && (
                            <div className="mb-3">
                              <label htmlFor='amount' className='text-left d-flex'>Enter amount:<span className="requirestar">*</span>{" "}</label>
                              <input type='number' id='amount' name='amount' className='form-control' value={values.amount} onChange={handleChange} />
                              <span className='text-danger d-flex text-left'><ErrorMessage name={"amount"} /></span>
                            </div>
                          )}
                          <div>
                            <label htmlFor='reasons' className='text-left d-flex'>Reason:</label>
                            <textarea type='text' id='reason' name='reason' className='form-control' style={{ minHeight: "150px" }} value={values.reason} onChange={handleChange} />
                          </div>
                        </div>
                        <Modal.Footer className="mt-4">
                          <Button
                            onClick={() => {
                              setSecondModal(false);
                            }}
                            appearance="subtle"
                          >
                            Close
                          </Button>
                          <Button
                            appearance="primary"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Refund
                          </Button>
                          {amount.total_refunded_amount !== 0 ? 
                          <div className="note-message">
                          <p>You have already refunded the amount ({(amount?.event_data?.currency?.sign ? amount.event_data.currency.sign : '') + amount?.total_refunded_amount})</p>
                          </div>
                          : null
                          }
                        </Modal.Footer>
                      </div>
                    )}
                  </Formik>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsTable;