import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalKey, setLocalKey } from '../../../utils/commonfunction';

const UserPrivateRoute = ({ component: Component }) => {
    const getuserkey = getLocalKey("user") ? JSON.parse(getLocalKey("user")) : {}

    if(getuserkey && getuserkey.token ){

    }else{
        if(window.location.pathname!=="/" && window.location.pathname!=="/login")
        {
            setLocalKey("return_url", window.location.href);
        }
    }
   
    return (
        getuserkey && getuserkey.token ? <Component /> : <Navigate to="/login" />
    )
}

export default UserPrivateRoute;