import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import {
  calculatePercentage,
  capitalizeFirstLetter,
  formateDateWithMonth,
  getDate,
  getDayBetweenDates,
  getFormatedTime,
  globalLoader,
  handleServerValidations,
  myToFixed,
  prepareQuestionsFormData,
  removeSpecialCharacter,
} from "../../../../utils/commonfunction";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { EMAIL_REGEX, GENDER, ROOM_TYPE, SWAL_SETTINGS } from "../../../../utils/Constants";
import toHtml from "html-react-parser";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as eventsService from "../../services/events.services";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import * as $ from "jquery";
import Breadcrums from "../../common/breadcrumbs";
import Loader from "../../common/loader";
import { Rating } from "react-simple-star-rating";
import DynamicQuestions from "../../../user/shared/DynamicQuestions";


const EditRegistrationDetailStepTwo = () => {
    const { state } = useLocation();
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState([]);
  const [params] = useSearchParams();
  const [apiDone, setApiDone] = useState(false);
  const [preSelectedValues, setPreSelectedValues] = useState([]);
  const [invitationDetailsData, setInvitationDetailsData] = useState("");
  const [guestAgenda, setGuestAgenda] = useState([]);
  const [primaryAgendaDetails, setPrimaryAgendaDetails] = useState([]);
  const [guestAgendaDetails, setGuestAgendaDetails] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [availableHotelList, setAvailableHotelList] = useState([]);
  const [checkInDate] = useState("");
  const [checkOutDate] = useState("");
  const [dynamicValidations, setDynamicValidations] = useState({});
  const [dynamicValidationsForInvoice, setDynamicValidationsForInvoice] =
    useState({});
  const [registrationData, setRegistrationData] = useState([]);
  const [guestValidation, setGuestValidation] = useState(registrationData?.add_guest);
  const [disableBtn, setDisableBtn] = useState(false);
  const [formikHandle, setFormikHandle] = useState(0);
  const [finalHotelData, setFinalHotelData] = useState({
    nights: 0,
    sub_total: 0,
  });
  const [agendaPricing, setAgendaPricing] = useState([]);
  const [primaryAgendaSubtotal, setPrimaryAgendaSubtotal] = useState(0);
  const [editApiDone, setEditApiDone] = useState(true);
  const [guestAgendaSubtotal, setGuestAgendaSubtotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [oldPricing, setOldPricing] = useState({});
  const dynamicQuestionsRef = useRef();
  const [questionFilesUploadPath] = useState("registrations/questions");
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "View Event", url: "/admin/event-management/events/list/1" },
    {
      title: "View Registrations",
      url: `/admin/event-management/events/view/registrations/${params.get('event_id')}/1`,
    },
    { title: "Edit Registered User info", url: "" },
  ];

  const formikRef = useRef();

  useEffect(()=>{
    if(window?.registrationFormStatus && window?.registrationFormStatus === true){
      /**Proceed with the second step */
    }else{
      navigate(`/admin/event-management/events/registration-edit/?event_id=${params.get("event_id")}&invitation_id=${params.get("invitation_id")}&reg_id=${params.get("reg_id")}&user_id=${params.get("user_id")}`);
    }
  },[]);

  useEffect(() => {
    eventsService
      .EventDetailsForEdit(params.get("event_id"), params.get('user_id'))
      .then((response) => {
        if (response.success) {
          setDetailsData(response?.data);
          setAvailableHotelList(response?.data?.hotels_data);
          setGuestAgenda(response?.data?.agenda_data);
          if (params.get("edit") === true || params.get("edit") === "true") {
            // setFormikHandle(
            //   response?.data?.secondary_question_set_data?.questions
            //     ? response?.data?.secondary_question_set_data?.questions.length
            //     : 0
            // );
          } else { 
            setFormikHandle(
              response?.data?.secondary_question_set_data?.questions
                ? response?.data?.secondary_question_set_data?.questions.length
                : 0
            );
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    if (params.get("edit") === true || params.get("edit") === "true") {
      setEditApiDone(false);
      eventsService
        .RegistrationsDetails(params.get("reg_id"))
        .then((response) => {
          if (response.success) {
            setRegistrationData(response?.data);
            setEditApiDone(true);
            if (params.get("edit") === true || params.get("edit") === "true") {
              setFormikHandle(response?.data?.guest_user?.questions_data
                ? response?.data?.guest_user?.questions_data.length
                : 0
              );
            }
            let selectedAgenda =
              response?.data &&
              response?.data?.guest_user &&
              response?.data?.guest_user?.agenda
                ? response?.data?.guest_user?.agenda
                : [];
            if (detailsData) {
              guestAgenda &&
                guestAgenda.forEach((item, index) => {
                  selectedAgenda &&
                    selectedAgenda.forEach((agenda, agendaIndex) => {
                      if (item?._id === agenda?.agendaId) {
                        item.elements &&
                          item.elements.forEach((element, elementIndex) => {
                            agenda.elementIds &&
                              agenda.elementIds.forEach(
                                (selectedElement, i) => {
                                  if (selectedElement === element._id) {
                                    element.checked = true;
                                  }
                                }
                              );
                          });
                      }
                    });
                });
              handleElementPrice();
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          setEditApiDone(true);
        });
    }
  }, [detailsData]);

  useEffect(() => {
    let primaryAgenda = [];
    state &&
      state?.values &&
      state?.values?.pricing &&
      state?.values?.pricing?.primaryAgenda &&
      state?.values?.pricing?.primaryAgenda.forEach((item, index) => {
        let singleAgenda = {};
        singleAgenda = {
          name: item?.elementPrice?.name,
          price: item?.elementPrice?.price,
          tax: item?.elementPrice?.tax_rate,
        };
        primaryAgenda.push(singleAgenda);
      });
    setPrimaryAgendaDetails([...primaryAgenda]);
    let subTotal = 0;
    primaryAgenda.forEach((item, index) => {
      let price = item.price;
      let tax = calculatePercentage(item.tax, price);
      subTotal += price + tax;
    });
    setPrimaryAgendaSubtotal(subTotal);
  }, [state?.values?.pricing?.primaryAgenda]);

  useEffect(() => {
    eventsService.oldPricing({registration_id : params.get("reg_id")}).then((response) => {
      if(response?.success){
        setOldPricing(response?.data);
      }
    }).catch((error) => {
      console.log("error",error)
    })
  },[])

  useEffect(() => {
    let guestAgenda = [];
    agendaPricing &&
      agendaPricing.forEach((item, index) => {
        let singleAgenda = {};
        singleAgenda = {
          name: item?.elementPrice?.name,
          price: item?.elementPrice?.price,
          tax: item?.elementPrice?.tax_rate,
        };
        guestAgenda.push(singleAgenda);
      });
    setGuestAgendaDetails([...guestAgenda]);
    let subTotal = 0;
    guestAgenda.forEach((item, index) => {
      let price = item.price;
      let tax = calculatePercentage(item.tax, price);
      subTotal += price + tax;
    });
    setGuestAgendaSubtotal(subTotal);
  }, [agendaPricing]);

  useEffect(() => {
    let obj = {};
    // if(registrationData?.add_guest ){
    //   setGuestValidation(true);
    // }
    if (guestValidation) {
      obj.gender = Yup.string().trim().required("Salutation is required");
      obj.firstName = Yup.string().trim().required("First name is required");
      obj.lastName = Yup.string().trim().required("Last name is required");
      obj.passportNumber = Yup.string().test(
        "check_visa",
        "Passport number is required",
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.surname = Yup.string().test(
        "check_visa",
        "Surname is required",
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.visaFirstName = Yup.string().test(
        "check_visa",
        "Visa first name is required",
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.dateOfBirth = Yup.string().test(
        "check_visa",
        "Date of birth is required",
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.dateOfExpiry = Yup.string().test(
        "check_visa",
        "Date of expiry is required",
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.dateOfIssue = Yup.string().test(
        "check_visa",
        "Date of issue is required",
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.nationality = Yup.string().test(
        "check_visa",
        "Nationality is required",
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.authority = Yup.string().test(
        "check_visa",
        "Authority is required",
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.placeOfBirth = Yup.string().test(
        "check_visa",
        "Place of birth is required",
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.mobileNumber = Yup.string()
        .trim()
        .required("Mobile number is required")
        .test(
          "min-length",
          "Phone number minimum length should be 4 digits",
          (value) => {
            const numberWithCode = value.substring(value.indexOf(" ") + 1);
            if (numberWithCode.trim().length < 4) {
              return false;
            } else {
              return true;
            }
          }
        )
        .test(
          "max-length",
          "Mobile number cannot be more than 13 digits",
          (value) => {
            const numberWithCode = value.substring(value.indexOf(" ") + 1);
            if (numberWithCode.trim().length > 14) {
              return false;
            } else {
              return true;
            }
          }
        );
      obj.email = Yup.string()
        .trim()
        .required("Email is required")
        .matches(
          EMAIL_REGEX,
          "Email should be valid"
        );
      obj.specificValue = Yup.string().test(
        "test-value",
        "Please enter specify text",
        (value) => {
          if (formikRef?.current?.values?.allergic) {
            if (value) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      );
    }
    setDynamicValidations(obj);
  }, [guestValidation]);

  useEffect(() => {
    if(params.get("invitation_id") && params.get("invitation_id")!="null"){
    eventsService
      .InvitationsDetailsByEmail(params.get("invitation_id"))
      .then((response) => {
        if (response.success) {
          setInvitationDetailsData(response?.data);
          setApiDone(true);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
    }
    else{
      setApiDone(true);
    }
  }, []);

  useEffect(() => {
    if (params.get("edit") === true || params.get("edit") === "true") {
      if (detailsData && invitationDetailsData && registrationData) {
        let preSelectedAns = [];
        let questionArr = registrationData?.guest_user?.questions_data.length
          ? registrationData?.guest_user?.questions_data
          : [];
        questionArr.forEach((item) => {
          let singleAns = {};
          singleAns = {
            question_id: item.question_id,
            question_title: item.question_title,
            answer: item.answer,
          };
          preSelectedAns.push(singleAns);
        });
        setPreSelectedValues([...preSelectedAns]);
      }
    }
  }, [invitationDetailsData, detailsData, registrationData]);

  useEffect(() => {
    let obj = {};
    if (totalPrice !== 0) {
      obj.companyName = Yup.string()
        .trim()
        .required("Company Name is required");
      obj.address = Yup.string().trim().required("Address is required");
      obj.postalCode = Yup.string().matches(/^[0-9a-zA-Z]+$/, 'Postal code must contain only alphanumeric characters').required("Postal code is required");
      obj.city = Yup.string().trim().required("City is required");
      obj.country = Yup.string().trim().required("Country is required");
    }
    setDynamicValidationsForInvoice(obj);
  }, [totalPrice]);

  function validateElements(index = null) {
    let errors = [];
    if (isFormSubmitted || index === null) {
      for (let i = 0; i < guestAgenda.length; i++) {
        const max = guestAgenda[i].maximum_bookable_element;
        const min = guestAgenda[i].minimum_bookable_element;
        let selectedElements = guestAgenda[i].elements.filter(
          (item) => item.checked
        ).length;
        if (min) {
          if (min <= selectedElements) {
            $(`#error-min-${guestAgenda[i]._id}-${i}`).text("");
          } else {
            $(`#error-min-${guestAgenda[i]._id}-${i}`).text(
              "Please select at least minimum " +
                min +
                (min === 1 ? " element" : " elements")
            );
            errors.push({
              message:
                "Please select at least minimum " +
                min +
                (min === 1 ? " element" : " elements"),
              id: `error-min-${guestAgenda[i]._id}-${i}`,
            });
          }
        }
        if (max) {
          if (max >= selectedElements) {
            $(`#error-max-${guestAgenda[i]._id}-${i}`).text("");
          } else {
            $(`#error-max-${guestAgenda[i]._id}-${i}`).text(
              "Cannot select more than " + max + " elements"
            );
            errors.push({
              message: "Cannot select more than " + max + " elements",
              id: `error-max-${guestAgenda[i]._id}-${i}`,
            });
          }
        }
      }
    } else {
      const max = guestAgenda[index].maximum_bookable_element;
      const min = guestAgenda[index].minimum_bookable_element;
      let selectedElements = guestAgenda[index].elements.filter(
        (item) => item.checked
      ).length;
      if (min) {
        if (min <= selectedElements) {
          $(`#error-min-${guestAgenda[index]._id}-${index}`).text("");
        } else {
          $(`#error-min-${guestAgenda[index]._id}-${index}`).text(
            "Please select at least minimum " +
              min +
              (min === 1 ? " element" : " elements")
          );
          errors.push({
            message:
              "Please select at least minimum " +
              min +
              (min === 1 ? " element" : " elements"),
            id: `error-min-${guestAgenda[index]._id}-${index}`,
          });
        }
      }
      if (max) {
        if (max >= selectedElements) {
          $(`#error-max-${guestAgenda[index]._id}-${index}`).text("");
        } else {
          $(`#error-max-${guestAgenda[index]._id}-${index}`).text(
            "Cannot select more than " + max + " elements"
          );
          errors.push({
            message: "Cannot select more than " + max + " elements",
            id: `error-max-${guestAgenda[index]._id}-${index}`,
          });
        }
      }
    }
    return errors;
  }

  const handleElementPrice = () => {
    let allElementPrice = [];
    guestAgenda &&
      guestAgenda.forEach((item, index) => {
        item?.elements?.forEach((element, eIndex) => {
          if (element?.checked) {
            allElementPrice.push({ elementPrice: element });
          }
        });
      });
    setAgendaPricing(allElementPrice);
  };

  const initialValues = {
    title: "",
    gender: "",
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    vegan: false,
    vegitarian: false,
    allergic: false,
    specificValue: "",
    passportNumber: "",
    surname: "",
    visaFirstName: "",
    dateOfBirth: "",
    dateOfIssue: "",
    nationality: "",
    placeOfBirth: "",
    dateOfExpiry: "",
    authority: "",
    guestVisaInvitation: false,
    questionData: [...preSelectedValues],
    add_guest: false,
    userPermission: false,
    companyName: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    accommondation: false,
    checkIn: "",
    checkOut: "",
    roomType: "",
    hotel:
    availableHotelList && availableHotelList === 1
        ? availableHotelList[0]._id
        : "Select",
  };
  
  const validationObject = {
    ...dynamicValidationsForInvoice,
    ...dynamicValidations,
    checkIn: Yup.string().test(
      "check_acommondation",
      "Checkin date is required",
      (value) => {
        if (formikRef?.current?.values.accommondation) {
          return Boolean(value);
        } else {
          return true;
        }
      }
    ),
    checkOut: Yup.string().test(
      "check_acommondation",
      "Checkout date is required",
      (value) => {
        if (formikRef?.current?.values.accommondation) {
          return Boolean(value);
        } else {
          return true;
        }
      }
    ),
    hotel: Yup.string().test(
      "check_acommondation",
      "Hotel is required",
      (value) => {
          if (formikRef?.current?.values.accommondation) {
            if(value.length > 10){
            return Boolean(value);
            }else{
              return false
            }
          } else {
            return true;
          }
      }
    ),
    roomType: Yup.string().test(
      "check_acommondation",
      "Room type is required",
      (value) => {
          if (formikRef?.current?.values.accommondation) {
            if(value !== "Select"){
              return Boolean(value);
            }else{
              return false;
            }
          } else {
            return true;
          }
      }
    ),
  };

  const validationSchema = Yup.object().shape(validationObject);

  const selectAgendaElement = (parentArray, items, value, agendaIndex) => {
    const newArray = parentArray.elements.map((i) => {
      if (i._id === items._id) {
        i.checked = value;
        return i;
      } else {
        return i;
      }
    });

    const selectedAgenda = { ...parentArray, elements: newArray };
    let anotherAgendas = [...guestAgenda];
    anotherAgendas[agendaIndex] = selectedAgenda;
    setGuestAgenda(anotherAgendas);
  };

  useEffect(() => {
    if (params.get("edit") === true || params.get("edit") === "true") {
      checkHotelPrice();
    }
  }, [
    formikRef?.current?.values?.hotel,
    formikRef?.current?.values?.roomType,
    formikRef?.current?.values?.checkIn,
    formikRef?.current?.values?.checkOut,
  ]);

  const onSubmitHandler = (values, formData) => {
    globalLoader(true);
    if (values.userPermission) {
        let editRegistrationSubmissionData = {
          event_id: params.get("event_id"),
          invitation_id: params.get("invitation_id"),
          registration_id: params.get("reg_id"),
          user_id: params.get("user_id"),
          accommodation: values.accommondation,
          add_guest: values.add_guest,
          vegan: state.values.vegan,
          vegetarian: state.values.vegeterian,
          allergic: state.values.allergic,
          specifyText: state.values.specifyText,
          visaInvitation: state.values.visaInvitation,
          start_date: state && state.values && state.values.startDate ? state.values.startDate : detailsData?.start_date,
          end_date: state && state.values && state.values.endDate ? state.values.endDate : detailsData?.end_date,
          check_in: getDate(values.checkIn, "YYYY-MM-DD", false),
          check_out: getDate(values.checkOut, "YYYY-MM-DD", false),
          room_type: values.roomType,
          hotel: values.hotel,
          company_name: values.companyName,
          address: values.address,
          postal_code: values.postalCode,
          city: values.city,
          country: values.country,
          passport_number: state.values.passportNumber,
          surname: state.values.surname,
          first_name: state.values.firstName,
          date_of_birth: state.values.dateOfBirth,
          date_of_issue: state.values.dateOfIssue,
          nationality: state.values.nationality,
          place_of_birth: state.values.placeOfBirth,
          date_of_expiry: state.values.dateOfExpiry,
          authority: state.values.authority,
          agenda: state.values.agenda,
          questionData: state.values.questionData,
          total_price: totalPrice,
          guest_details: {
            agenda: guestAgenda,
            gender: values.gender,
            title: values.title,
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
            mobileNumber: values.mobileNumber,
            email: values.email,
            vegan: values.vegan,
            vegitarian: values.vegitarian,
            allergic: values.allergic,
            specificValue: values.specificValue,
            passportNumber: values.passportNumber,
            surname: values.surname,
            visaFirstName: values.visaFirstName,
            dateOfBirth: values.dateOfBirth,
            dateOfIssue: values.dateOfIssue,
            nationality: values.nationality,
            placeOfBirth: values.placeOfBirth,
            dateOfExpiry: values.dateOfExpiry,
            authority: values.authority,
            guestVisaInvitation: values.guestVisaInvitation,
            questionData: values.questionData,
          },
        };
        formData.append('main_data', JSON.stringify(editRegistrationSubmissionData));
        eventsService.EditEventRegistration(formData).then((response) => {
          if(response.success){
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            globalLoader(false);
            navigate(`/admin/event-management/events/view/registrations/${params.get("event_id")}/1`);
          }else{
            globalLoader(false);
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        }).catch((error) => {
          console.log("error",error);
        })
    } else {
      globalLoader(false);
      Swal.fire({
        icon: "error",
        text: "Please accept terms and condition",
        ...SWAL_SETTINGS,
      });
    }
  };

  const checkValidationError = () => {
    setTimeout(() => {
      if (
        formikRef?.current?.errors &&
        Object.keys(formikRef?.current?.errors).length
      ) {
        Swal.fire({
          icon: "error",
          text: "Please fill all required field",
          ...SWAL_SETTINGS,
        });
      }
    }, 100);
  };

  const checkHotelPrice = () => {
    let formikData = formikRef?.current?.values;
    let hotelsData = detailsData && detailsData?.hotels_data && detailsData?.hotels_data;
    if (formikData && hotelsData && hotelsData.length) {
      let currentHotel = hotelsData.filter(
        (item) => item?._id === formikData.hotel
      )[0];
      if (currentHotel) {
        // setCheckInDate(currentHotel?.start_date);
        // setCheckOutDate(currentHotel?.end_date);
      }
      if (
        currentHotel &&
        formikData?.checkIn &&
        formikData?.checkOut &&
        formikData?.roomType
      ) {
        let nights = getDayBetweenDates(
          formikData?.checkIn,
          formikData?.checkOut
        );
        let quotaDateFormated = currentHotel.quota.map((item) => {
          return {
            date: item.date.split("T")[0],
            double_room: item.double_room,
            single_room: item.single_room,
          };
        });
        let roomPrice = 0;
        let breakfastPrice = 0;
        nights.forEach((night) => {
        // let thisNightDate = `${new Date(night).getFullYear()}-${
          //   new Date(night).getMonth() + 1
          // }-${new Date(night).getDate()}`;
          let quota = quotaDateFormated.filter(
            (quota) => moment(new Date(night)).format("DD-MM-YYYY") === quota.date
          )[0];
          if (quota) {
            if (formikData?.roomType === ROOM_TYPE.Single_Room) {
              roomPrice += quota.single_room.price;
              breakfastPrice += quota.single_room.breakfast_price;
            } else {
              roomPrice += quota.double_room.price;
              breakfastPrice += quota.double_room.breakfast_price;
            }
          }
        });
        let finalData = {
          taxes: {
            breakfast_tax: currentHotel.breakfast_tax,
            city_tax: currentHotel.city_tax,
            hotel_tax: currentHotel.tax,
          },
          prices: {
            hotel_tax: myToFixed(calculatePercentage(currentHotel.tax, roomPrice)),
            room_price: myToFixed(roomPrice),
            breakfast_tax: myToFixed(calculatePercentage(currentHotel.breakfast_tax,breakfastPrice)),
            breakfast: myToFixed(breakfastPrice),
            city_tax: myToFixed(currentHotel.city_tax),
          },
          nights: nights.length,
          sub_total: myToFixed((calculatePercentage(currentHotel.tax, roomPrice) + roomPrice + breakfastPrice + currentHotel.city_tax + calculatePercentage(currentHotel.breakfast_tax,breakfastPrice))),
        };
        setFinalHotelData(finalData);
      }
    }
  };

  const shouldDisabledHotel = (values) => {
    if(availableHotelList.length === 0){
      return true;
    }else if(availableHotelList.length === 1){
      return true;
    }else if(values.hotel === "Select"){
      return false;
    }else if(values.hotel === ""){
      return false;
    }else{
      return false;
    }
  }

  const updateHotelList = () => {
    if (
      formikRef?.current?.values?.checkIn &&
      formikRef?.current?.values?.checkOut
    ) {
    eventsService
        .CheckHotelAvailability({
          start_date: moment(formikRef?.current?.values?.checkIn).format(
            "YYYY-MM-DD"
          ),
          end_date: moment(formikRef?.current?.values?.checkOut).format(
            "YYYY-MM-DD"
          ),
          event_id: detailsData?._id,
        })
        .then((response) => {
          if (response?.success) {
            if (response?.data.length <= 0) {
              Swal.fire({
                icon: "error",
                text: "Hotel not available between this range",
                ...SWAL_SETTINGS,
              });
              formikRef?.current?.setFieldValue('hotel', "Select");
            }
            setAvailableHotelList(response?.data);
            formikRef?.current?.setFieldValue('hotel', response?.data && response?.data.length === 1 ? response?.data[0]._id : "Select");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="card custom-card main-content-body-profile">
        <div className="wraper-inner cpt bg-grey">
          {apiDone &&
          (formikHandle === 0 || preSelectedValues.length) &&
          editApiDone ? (
            <Formik
              validateOnBlur={false}
              innerRef={formikRef}
              initialValues={{
                ...(params.get("edit") === true || params.get("edit") === "true"
                  ? {
                      gender:
                        registrationData &&
                        registrationData?.guest_user &&
                        registrationData?.guest_user?.gender
                          ? registrationData?.guest_user?.gender
                          : "",
                      title:
                        registrationData &&
                        registrationData?.guest_user &&
                        registrationData?.guest_user?.title
                          ? registrationData?.guest_user?.title
                          : "",
                      firstName:
                        registrationData &&
                        registrationData?.guest_user &&
                        registrationData?.guest_user?.first_name
                          ? registrationData.guest_user.first_name
                          : "",
                      middleName:
                        registrationData &&
                        registrationData?.guest_user &&
                        registrationData?.guest_user?.middle_name
                          ? registrationData.guest_user.middle_name
                          : "",
                      lastName:
                        registrationData &&
                        registrationData?.guest_user &&
                        registrationData?.guest_user?.last_name
                          ? registrationData.guest_user.last_name
                          : "",
                      mobileNumber:
                        registrationData &&
                        registrationData?.guest_user &&
                        registrationData?.guest_user?.phone_number
                          ? registrationData.guest_user.phone_number
                          : "",
                      email:
                        registrationData &&
                        registrationData?.guest_user &&
                        registrationData?.guest_user?.email
                          ? registrationData.guest_user.email
                          : "",
                      vegan:
                        registrationData &&
                        registrationData?.guest_user &&
                        registrationData?.guest_user?.vegan
                          ? registrationData.guest_user.vegan
                          : false,
                      vegitarian:
                        registrationData &&
                        registrationData?.guest_user &&
                        registrationData?.guest_user?.vegetarian
                          ? registrationData.guest_user.vegetarian
                          : false,
                      allergic:
                        registrationData &&
                        registrationData?.guest_user &&
                        registrationData?.guest_user?.allergic
                          ? registrationData.guest_user.allergic
                          : false,
                      specificValue:
                        registrationData &&
                        registrationData?.guest_user &&
                        registrationData?.guest_user?.allergy_reason
                          ? registrationData.guest_user.allergy_reason
                          : "",
                      passportNumber: "",
                      surname: "",
                      visaFirstName: "",
                      dateOfBirth: "",
                      dateOfIssue: "",
                      nationality: "",
                      placeOfBirth: "",
                      dateOfExpiry: "",
                      authority: "",
                      guestVisaInvitation: false,
                      questionData: [...preSelectedValues],
                      add_guest:
                        registrationData && registrationData?.add_guest
                          ? registrationData?.add_guest
                          : false,
                      userPermission: true,
                      companyName:
                        registrationData && registrationData?.company_name
                          ? registrationData?.company_name
                          : "",
                      address:
                        registrationData && registrationData?.address
                          ? registrationData?.address
                          : "",
                      postalCode:
                        registrationData && registrationData?.postal_code
                          ? registrationData?.postal_code
                          : "",
                      city:
                        registrationData && registrationData?.city
                          ? registrationData?.city
                          : "",
                      country:
                        registrationData && registrationData?.country
                          ? registrationData?.country
                          : "",
                      accommondation:
                        registrationData && registrationData?.accommodation
                          ? registrationData?.accommodation
                          : false,
                      checkIn:
                        registrationData && registrationData?.check_in
                          ? registrationData?.check_in
                          : "",
                      checkOut:
                        registrationData && registrationData?.check_out
                          ? registrationData?.check_out
                          : "",
                      roomType:
                        registrationData && registrationData?.room_type
                          ? registrationData?.room_type
                          : "",
                      hotel:
                        detailsData?.hotels_data &&
                        detailsData.hotels_data.length === 1
                          ? detailsData.hotels_data[0]._id
                          : registrationData &&
                            registrationData?.hotel_data &&
                            registrationData?.hotel_data?._id
                          ? registrationData?.hotel_data?._id
                          : "Select",
                    }
                  : initialValues),
              }}
              onSubmit={(values) => {
                // onSubmitHandler(values);
                if (values?.add_guest) {
                  const questionFormResult = dynamicQuestionsRef?.current?.submit();
                  if(questionFormResult?.isValid){
                    const questionResult = dynamicQuestionsRef?.current?.getValues();
                    let finalForm = prepareQuestionsFormData(questionResult, window.registrationFinalForm, questionFilesUploadPath, "stepTwo");
                    onSubmitHandler(values, finalForm);
                  }else{
                    return
                  }
                }else{
                  onSubmitHandler(values, window.registrationFinalForm);
                }
              }}
              validationSchema={validationSchema}
              validate={(values) => {
                return {};
              }}
            >
              {({
                values,
                errors,
                handleSubmit,
                setFieldValue,
                handleChange,
                handleBlur,
                touched,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <section className="event-page-register mt-5 pb-5">
                    <div className="container-fluid">
                      <h3 className="inner-title">Registration</h3>
                      <div className="dash-box-shadow-2 position-relative">
                        <h4 className="reg-title">{detailsData?.title}</h4>
                        {detailsData?.event_quota?.price > 0 ? (
                        <span className="mb-2 price-text">
                          Price:
                          <span>
                            {detailsData?.currency?.sign}
                            {detailsData?.event_quota?.price}
                          </span>
                        </span>)
                        : <span className="mb-2 price-text text-success">Free</span>}
                        <ul className="card-info-list pb-0">
                          <li>
                            <div className="date-info pb-0">
                              <i className="ri-calendar-event-line black-icon" />
                              {formateDateWithMonth(detailsData?.start_date)}
                              {" - "}
                              {formateDateWithMonth(detailsData?.end_date)}
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="step-process">
                        <ul className="progessbar-list d-flex">
                          <li className="active">
                            <span>01</span>
                            <h6>Step 1</h6>
                          </li>
                          <li className="active">
                            <span>02</span>
                            <h6>Step 2</h6>
                          </li>
                        </ul>
                      </div>
                      {detailsData &&
                      detailsData.guest_can_add_secondary === true ? (
                        <div className="col-md-6 mb-3 ps-0 about-accommodation">
                          <h4 className="reg-title">About Guest </h4>
                          <div className="toggle-box d-flex align-items-center justify-content-between mb-2">
                            <label className="form-label">
                              Do you want add guest ?
                            </label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={values.add_guest}
                                onChange={async (e) => {
                                  await setFieldValue(
                                    "add_guest",
                                    e.target.checked
                                  );
                                  setGuestValidation(e.target.checked);
                                  setGuestAgendaSubtotal(0);
                                  if (e.target.checked) {
                                    await setFieldValue(
                                      "roomType",
                                      ROOM_TYPE.Double_Room
                                    );
                                  } else {
                                    await setFieldValue("roomType", "");
                                  }
                                  checkHotelPrice();
                                }}
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {detailsData &&
                        detailsData.accommodation_offered === true &&
                        availableHotelList && (
                        // availableHotelList.length != 0 && (
                          <div className="col-md-6 mb-3 ps-0 about-accommodation">
                            <h4 className="reg-title">About Accommodation</h4>
                            <div className="toggle-box d-flex align-items-center justify-content-between mb-2">
                              <label className="form-label">
                                Do you need accommodation?
                              </label>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={values.accommondation}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "accommondation",
                                      e.target.checked
                                    );
                                    setFinalHotelData({
                                      nights: 0,
                                      sub_total: 0,
                                    });
                                    if (e.target.checked) {
                                      checkHotelPrice();
                                    }
                                  }}
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                          </div>
                        )}
                      {values.accommondation && values.accommondation && (
                        <div>
                          <div className="accommodation-checkin">
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  Check-in<span className="requirestar">*</span>
                                </label>
                                <div className="date-box date datepicker">
                                  <DatePicker
                                    showMonthDropdown
                                    scrollableYearDropdown
                                    showYearDropdown
                                    yearDropdownItemNumber={30}
                                    onChange={async (date) => {
                                      await setFieldValue("checkIn", date);
                                      await setFieldValue("checkOut", "");
                                      checkHotelPrice();
                                      updateHotelList();
                                    }}
                                    className={`form-control`}
                                    value={
                                      values.checkIn === ""
                                        ? ""
                                        : moment(values.checkIn).format(
                                            "DD/MM/YYYY"
                                          )
                                    }
                                    minDate={
                                      new Date(checkInDate) && new Date()
                                    }
                                    maxDate={new Date(checkOutDate)}
                                  />
                                  <span className="input-group-append">
                                    <span className="input-cal-icon ">
                                      <i className="ri-calendar-2-line"></i>
                                    </span>
                                  </span>
                                </div>
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.checkIn &&
                                    touched.checkIn &&
                                    errors.checkIn}
                                </span>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  Check-out
                                  <span className="requirestar">*</span>
                                </label>
                                <div className="date-box date datepicker">
                                  <DatePicker
                                    showMonthDropdown
                                    scrollableYearDropdown
                                    showYearDropdown
                                    yearDropdownItemNumber={30}
                                    onChange={async (date) => {
                                      await setFieldValue("checkOut", date);
                                      checkHotelPrice();
                                      updateHotelList();
                                    }}
                                    className={`form-control`}
                                    value={
                                      values.checkOut === ""
                                        ? ""
                                        : moment(values.checkOut).format(
                                            "DD/MM/YYYY"
                                          )
                                    }
                                    minDate={
                                      new Date(checkInDate) &&
                                      new Date(
                                        values.checkIn
                                          ? values.checkIn
                                          : new Date()
                                      )
                                    }
                                    maxDate={new Date(checkOutDate)}
                                  />
                                  <span className="input-group-append">
                                    <span className="input-cal-icon ">
                                      <i className="ri-calendar-2-line"></i>
                                    </span>
                                  </span>
                                </div>
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.checkOut &&
                                    touched.checkOut &&
                                    errors.checkOut}
                                </span>
                              </div>
                              <div className="col-md-6 mb-4">
                                <label className="form-label">
                                  Choose hotel
                                  <span className="requirestar">*</span>
                                </label>
                                <div className="select-down-arrow">
                                  <select
                                    disabled={shouldDisabledHotel(values)}
                                    id="hotel"
                                    onChange={async (e) => {
                                      await setFieldValue(
                                        "hotel",
                                        e.target.value
                                      );
                                      checkHotelPrice();
                                    }}
                                    name={"hotel"}
                                    value={values.hotel}
                                    className={"form-control text-capitalize"}
                                  >
                                    <option label="Select Hotel">
                                      Select{" "}
                                    </option>
                                    {availableHotelList &&
                                      availableHotelList.map((option, i) => {
                                        return (
                                          <option key={i} value={option._id}>
                                            {option.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.hotel &&
                                    touched.hotel &&
                                    errors.hotel}
                                </span>
                              </div>
                              <div className="col-md-6 mb-4">
                                <label className="form-label">
                                  Choose room type
                                  <span className="requirestar">*</span>
                                </label>
                                <div className="select-down-arrow">
                                  <select
                                    disabled={
                                      values.hotel === "Select" || ""
                                        ? true
                                        : false
                                    }
                                    as="select"
                                    id="roomType"
                                    value={values.roomType}
                                    onChange={async (e) => {
                                      await handleChange(e);
                                      checkHotelPrice();
                                    }}
                                    name={"roomType"}
                                    className={`form-control text-capitalize ${
                                      values.hotel === "Select" || ""
                                        ? "cursor-no-drop"
                                        : ""
                                    }`}
                                  >
                                    <option label="Select Room Type">
                                      Select{" "}
                                    </option>
                                    {values.hotel !== "" &&
                                      Object.keys(ROOM_TYPE).map(
                                        (option, i) => {
                                          return (
                                            <option
                                              disabled={
                                                values.add_guest === true &&
                                                option === "Single_Room"
                                                  ? true
                                                  : false
                                              }
                                              key={i}
                                              value={ROOM_TYPE[option]}
                                            >
                                              {removeSpecialCharacter(option)}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.roomType &&
                                    touched.roomType &&
                                    errors.roomType}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {values.add_guest ? (
                        <div className="dash-box-shadow-2">
                          <div className="workshop-box">
                            <h4 className="reg-title">About Guests</h4>
                            <div className="accommodation-checkin">
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <label className="form-label">Title</label>
                                  <input
                                    type="text"
                                    name="title"
                                    placeholder="Title"
                                    className="form-control"
                                    value={values.title}
                                    onChange={(event) =>
                                      setFieldValue(`title`, event.target.value)
                                    }
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label className="form-label">
                                    Salutation
                                    <span className="requirestar">*</span>
                                  </label>
                                  <div className="select-down-arrow">
                                    <Field
                                      as="select"
                                      id="gender"
                                      name={`gender`}
                                      className="form-control select2"
                                      style={{ fontSize: 14 }}
                                    >
                                      <option
                                        value=""
                                        label="Select"
                                        className=""
                                      >
                                        Select{" "}
                                      </option>
                                      {GENDER &&
                                        Object.keys(GENDER).length > 0 &&
                                        Object.values(GENDER).map((item, i) => {
                                          return (
                                            <option key={i} value={item}>
                                              {capitalizeFirstLetter(item)}
                                            </option>
                                          );
                                        })}
                                    </Field>
                                  </div>
                                  <span className="text-danger d-flex text-left">
                                    <ErrorMessage name={`gender`} />
                                  </span>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label className="form-label">
                                    First name
                                    <span className="requirestar">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="firstName"
                                    placeholder="First name"
                                    className="form-control"
                                    value={values.firstName}
                                    onChange={(event) =>
                                      setFieldValue(
                                        `firstName`,
                                        event.nativeEvent.target.value
                                      )
                                    }
                                  />
                                  <span className="text-danger d-flex text-left">
                                    <ErrorMessage name={`firstName`} />
                                  </span>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label className="form-label">
                                    Middle name
                                  </label>
                                  <input
                                    type="text"
                                    name="middleName"
                                    placeholder="Middle name"
                                    className="form-control"
                                    value={values.middleName}
                                    onChange={(event) =>
                                      setFieldValue(
                                        `middleName`,
                                        event.target.value
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label className="form-label">
                                    Last name
                                    <span className="requirestar">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Last name"
                                    value={values.lastName}
                                    className="form-control"
                                    onChange={(event) =>
                                      setFieldValue(
                                        `lastName`,
                                        event.nativeEvent.target.value
                                      )
                                    }
                                  />
                                  <span className="text-danger d-flex text-left">
                                    <ErrorMessage name={`lastName`} />
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <label className="form-label">
                                    Mobile number
                                    <span className="requirestar">*</span>
                                  </label>
                                  <PhoneInput
                                    type="text"
                                    defaultCountry="de"
                                    style={{ padding: 0, border: 0 }}
                                    countrySelectorStyleProps={{
                                      height: "auto",
                                      buttonStyle: {
                                        height: "100%",
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                      },
                                    }}
                                    inputStyle={{
                                      height: "100%",
                                      width: "100%",
                                      paddingLeft: 12,
                                      fontSize: 14,
                                    }}
                                    name="mobileNumber"
                                    placeholder="Mobile number"
                                    className="form-control"
                                    forceDialCode={true}
                                    value={values.mobileNumber}
                                    onChange={(value, CountryIso2) => {
                                      setFieldValue(`mobileNumber`, value);
                                    }}
                                  />
                                  <span className="text-danger d-flex text-left">
                                    <ErrorMessage name={`mobileNumber`} />
                                  </span>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label className="form-label">
                                    Email address
                                    <span className="requirestar">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="email"
                                    placeholder="Email address"
                                    className="form-control"
                                    value={values.email}
                                    onChange={(event) =>
                                      setFieldValue(
                                        `email`,
                                        event.nativeEvent.target.value
                                      )
                                    }
                                  />
                                  <span className="text-danger d-flex text-left">
                                    <ErrorMessage name={`email`} />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="about-dietary mt-3 mb-3">
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <h4 className="reg-title">
                                  About Dietary requirements
                                </h4>
                                <h5 className="reg-sub-title mb-2">
                                  Do you have a special requirement regarding
                                  your diet
                                </h5>
                                <div className="chek-box-card mt-3">
                                  <div className="form-check black-check">
                                    <input
                                      name="vegan"
                                      className="form-check-input"
                                      type="checkbox"
                                      value={values.vegan}
                                      checked={values.vegan}
                                      id="vegan"
                                      onChange={(event) => {
                                        setFieldValue(
                                          `vegan`,
                                          event.target.checked
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label cp"
                                      htmlFor="vegan"
                                    >
                                      {" "}
                                      I am vegan
                                    </label>
                                  </div>
                                  <div className="form-check black-check">
                                    <input
                                      name="vegitarian"
                                      className="form-check-input"
                                      type="checkbox"
                                      value={values.vegitarian}
                                      checked={values.vegitarian}
                                      id="vegitarian"
                                      onChange={(event) => {
                                        setFieldValue(
                                          `vegitarian`,
                                          event.target.checked
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label cp"
                                      htmlFor="vegitarian"
                                    >
                                      I am vegetarian
                                    </label>
                                  </div>
                                  <div className="form-check black-check">
                                    <input
                                      name="allergic"
                                      className="form-check-input"
                                      type="checkbox"
                                      value={values.allergic}
                                      checked={values.allergic}
                                      id="allergic"
                                      onChange={(event) => {
                                        setFieldValue(
                                          `allergic`,
                                          event.target.checked
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label cp"
                                      htmlFor="allergic"
                                    >
                                      I am allergic/others
                                    </label>
                                  </div>
                                </div>
                                <span className="text-danger d-flex text-left">
                                  <ErrorMessage name={`food_prefrences`} />
                                </span>
                                {values.allergic && (
                                  <div className="form-group mt-3 please-specify">
                                    <label className="form-label">
                                      Please specify
                                    </label>
                                    <input
                                      type="text"
                                      name="specificValue"
                                      placeholder="Please specify"
                                      className="form-control"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.specificValue}
                                    />
                                    <span className="text-danger text-start d-blockerr-spn">
                                      {errors.specificValue &&
                                        touched.specificValue &&
                                        errors.specificValue}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {guestAgenda &&
                          guestAgenda.length > 0 &&
                          detailsData?.secondary_registrants_allowed_to_agenda_options ? (
                            <div className="guestAgenda-box">
                              <h4 className="reg-title">
                                About Agenda Options
                              </h4>
                              {guestAgenda &&
                                guestAgenda.map((item, index) => (
                                  <div key={index}>
                                    <h4 className="reg-title">{item.name}</h4>
                                    <ul className="card-info-list">
                                      <li>
                                        <div className="date-info pb-0">
                                          <i className="ri-calendar-event-line black-icon" />
                                          {item.start_date &&
                                            formateDateWithMonth(
                                              item.start_date
                                            )}{" "}
                                        </div>
                                      </li>
                                      <li>
                                        <div className="card-info pb-0">
                                          <i className="ri-alarm-line black-icon" />
                                          {item.start_time &&
                                            getFormatedTime(
                                              item.start_time
                                            )}{" "}
                                          -{" "}
                                          {item.end_time &&
                                            getFormatedTime(item.end_time)}
                                        </div>
                                      </li>
                                    </ul>
                                    {toHtml(item.description)}
                                    <div className="radio-group-coustom d-flex row mt-3">
                                      {item.elements.map((items, i) => (
                                        <div
                                          className="form-check radio-coustom mb-2"
                                          key={i}
                                        >
                                          <input
                                            disabled={
                                              !items?.available_quantity
                                                ? true
                                                : false
                                            }
                                            className="form-check-input cp"
                                            type="checkbox"
                                            name="flexRadioDefault"
                                            id={`flexRadioDefault1${i}${index}`}
                                            checked={items?.checked}
                                            onChange={(e) => {
                                              selectAgendaElement(
                                                item,
                                                items,
                                                e.target.checked,
                                                index
                                              );
                                              validateElements(index);
                                              handleElementPrice();
                                              setDisableBtn(true);
                                            }}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`flexRadioDefault1${i}${index}`}
                                          >
                                            <h5>{items.name}</h5>
                                            {items.price > 0 ? (
                                              <span>
                                                {detailsData?.currency?.sign}
                                                {items.price +
                                                  (items.price * items.tax_rate) /
                                                    100}{" "}
                                                <small>
                                                  inclusive {items.tax_rate}% tax
                                                </small>
                                              </span>
                                            ) : (
                                              <span className="text-success">Free</span>
                                            )}
                                            {!items?.available_quantity ? (
                                              <span>
                                                <small className="ms-5 ps-5 text-danger">
                                                  Out of stock
                                                </small>
                                              </span>
                                            ) : (
                                              <></>
                                            )}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                    <span
                                      className="text-danger text-start d-blockerr-spn"
                                      id={`error-min-${item._id}-${index}`}
                                    ></span>
                                    <span
                                      className="text-danger text-start d-blockerr-spn"
                                      id={`error-max-${item._id}-${index}`}
                                    ></span>
                                    <hr className="divider less-space" />
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="more-information">
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <h4 className="reg-title">
                                  Few more information needed
                                </h4>
                                {/* <FieldArray
                                  name="questionData"
                                  render={(arrayHelper) => (
                                    <div className="">
                                      <div className="">
                                        {detailsData?.secondary_question_set_data?.questions.map(
                                          (item, index) => (
                                            <div key={index}>
                                              {item.question_type ===
                                                "short_ans" && (
                                                <div className="form-group mb-3">
                                                  <label
                                                    className="form-label"
                                                    htmlFor={`short_answer${index}`}
                                                  >
                                                    {index + 1 + "."}{" "}
                                                    {item.title}
                                                    {item.is_required ? (
                                                      <span className="requirestar">
                                                        *
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </label>
                                                  <input
                                                    type="text"
                                                    name={`questionData[${index}].answer`}
                                                    id={`short_answer${index}`}
                                                    placeholder="Please give your answer..."
                                                    className="form-control"
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                    }}
                                                    required={item?.is_required}
                                                    value={
                                                      values?.questionData &&
                                                      values?.questionData[
                                                        index
                                                      ]?.answer
                                                    }
                                                  />
                                                  {item.is_required &&
                                                  disableBtn &&
                                                  !values?.questionData[index]
                                                    ?.answer ? (
                                                    <span
                                                      className="text-danger text-start d-blockerr-spn"
                                                      id="required_question"
                                                    >
                                                      This field is required.
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              )}
                                              {item.question_type ===
                                                "long_ans" && (
                                                <div className="form-group mb-3">
                                                  <label
                                                    className="form-label"
                                                    htmlFor={`long_ans${index}`}
                                                  >
                                                    {index + 1 + "."}{" "}
                                                    {item.title}
                                                    {item.is_required ? (
                                                      <span className="requirestar">
                                                        *
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </label>
                                                  <input
                                                    type="text"
                                                    id={`long_ans${index}`}
                                                    name={`questionData[${index}].answer`}
                                                    placeholder="Please give your answer..."
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={
                                                      values?.questionData &&
                                                      values?.questionData[
                                                        index
                                                      ]?.answer
                                                    }
                                                    required={item?.is_required}
                                                  />
                                                  {item.is_required &&
                                                  disableBtn &&
                                                  !values?.questionData[index]
                                                    ?.answer ? (
                                                    <span
                                                      className="text-danger text-start d-blockerr-spn"
                                                      id="required_question"
                                                    >
                                                      This field is required.
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              )}
                                              {item.question_type ===
                                                "single_choice" && (
                                                <div className="form-group mb-3">
                                                  <label className="form-label">
                                                    {index + 1 + "."}{" "}
                                                    {item.title}
                                                    {item.is_required ? (
                                                      <span className="requirestar">
                                                        *
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </label>
                                                  {item.options.map(
                                                    (item4, i) => (
                                                      <div
                                                        key={i}
                                                        className="form-radio"
                                                      >
                                                        <input
                                                          className="me-2"
                                                          id={`single_choice${i}${index}`}
                                                          type="radio"
                                                          name={`questionData[${index}].answer`}
                                                          onChange={() =>
                                                            setFieldValue(
                                                              `questionData[${index}].answer`,
                                                              item4
                                                            )
                                                          }
                                                          value={
                                                            values?.questionData &&
                                                            values
                                                              ?.questionData[
                                                              index
                                                            ]?.answer
                                                          }
                                                          checked={
                                                            item4 ===
                                                            values
                                                              ?.questionData[
                                                              index
                                                            ]?.answer
                                                          }
                                                          required={
                                                            item?.is_required
                                                          }
                                                        />
                                                        <label
                                                          className="ml-4"
                                                          htmlFor={`single_choice${i}${index}`}
                                                        >
                                                          {item4}
                                                        </label>
                                                      </div>
                                                    )
                                                  )}
                                                  {item.is_required &&
                                                  disableBtn &&
                                                  !values?.questionData[index]
                                                    ?.answer ? (
                                                    <span
                                                      className="text-danger text-start d-blockerr-spn"
                                                      id="required_question"
                                                    >
                                                      This field is required.
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              )}

                                              {item.question_type ===
                                                "likeStatus" && (
                                                <div
                                                  key={item._id}
                                                  className="card-questions-box mt-3"
                                                >
                                                  <label className="form-label">
                                                    {index + 1 + "."}{" "}
                                                    {item.title}
                                                    {item.is_required ? (
                                                      <span className="requirestar">
                                                        *
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </label>
                                                  <div className="radio-list">
                                                    <input
                                                      type="radio"
                                                      id={`likeRadio_${index}`}
                                                      className={`${
                                                        item.is_required
                                                          ? "question-required"
                                                          : ""
                                                      }`}
                                                      name={`likeRadioGroup_${item._id}`}
                                                      checked={
                                                        values?.questionData[
                                                          index
                                                        ]?.answer === 1
                                                      }
                                                      onChange={() =>
                                                        setFieldValue(
                                                          `questionData[${index}].answer`,
                                                          1
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={`likeRadio_${index}`}
                                                    >
                                                      <i className="fa fa-thumbs-up cp"></i>
                                                    </label>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        className={`${
                                                          item.is_required
                                                            ? "question-required"
                                                            : ""
                                                        }`}
                                                        id={`dislikeRadio_${item._id}`}
                                                        name={`likeRadioGroup_${item._id}`}
                                                        checked={
                                                          values?.questionData[
                                                            index
                                                          ]?.answer === 0
                                                        }
                                                        onChange={() =>
                                                          setFieldValue(
                                                            `questionData[${index}].answer`,
                                                            0
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        htmlFor={`dislikeRadio_${item._id}`}
                                                      >
                                                        <i className="fa fa-thumbs-down cp"></i>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  {item.is_required &&
                                                  disableBtn &&
                                                  !values?.questionData[index]
                                                    ?.answer ? (
                                                    <span
                                                      className="text-danger text-start d-blockerr-spn"
                                                      id="required_question"
                                                    >
                                                      This field is required.
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              )}

                                              {item.question_type ===
                                                "slider" && (
                                                <div
                                                  key={item._id}
                                                  className="card-questions-box mt-3"
                                                >
                                                  <label className="form-label">
                                                    {index + 1 + "."}{" "}
                                                    {item.title}
                                                    {item.is_required ? (
                                                      <span className="requirestar">
                                                        *
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </label>
                                                  <div className="range-slider">
                                                    <div id="tooltip" />
                                                    <input
                                                      id="range"
                                                      type="range"
                                                      required={
                                                        item.is_required
                                                      }
                                                      className={`${
                                                        item.is_required
                                                          ? "question-required"
                                                          : ""
                                                      }`}
                                                      onChange={(e) =>
                                                        setFieldValue(
                                                          `questionData[${index}].answer`,
                                                          e.target.value
                                                        )
                                                      }
                                                      defaultValue={
                                                        values?.questionData[
                                                          index
                                                        ]?.answer
                                                          ? values
                                                              ?.questionData[
                                                              index
                                                            ]?.answer
                                                          : item.min_slider_value
                                                      }
                                                      min={
                                                        item.min_slider_value
                                                      }
                                                      max={
                                                        item.max_slider_value
                                                      }
                                                    />
                                                  </div>
                                                  {item.is_required &&
                                                  disableBtn &&
                                                  !values?.questionData[index]
                                                    ?.answer ? (
                                                    <span
                                                      className="text-danger text-start d-blockerr-spn"
                                                      id="required_question"
                                                    >
                                                      This field is required.
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              )}

                                              {item.question_type ===
                                                "emoji" && (
                                                <div
                                                  key={item._id}
                                                  className="card-questions-box mt-3"
                                                >
                                                  <label className="form-label">
                                                    {index + 1 + "."}{" "}
                                                    {item.title}
                                                    {item.is_required ? (
                                                      <span className="requirestar">
                                                        *
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </label>
                                                  <ul className="select-face-list">
                                                    {Array.isArray(
                                                      item.emoji_options
                                                    )
                                                      ? item?.emoji_options.map(
                                                          (option, i) => (
                                                            <li>
                                                              <div className="form-radio position-relative">
                                                                <input
                                                                  type="radio"
                                                                  id={`emoji_click${i}${item._id}`}
                                                                  name={
                                                                    item._id
                                                                  }
                                                                  required={
                                                                    item.is_required
                                                                  }
                                                                  value={option}
                                                                  checked={option === values?.questionData[index]?.answer}
                                                                  onChange={() =>
                                                                    setFieldValue(
                                                                      `questionData[${index}].answer`,
                                                                      option
                                                                    )
                                                                  }
                                                                  className={`form-check-input ${
                                                                    item.is_required
                                                                      ? "question-required"
                                                                      : ""
                                                                  }`}
                                                                />
                                                                {console.log("values",values)}
                                                                <label
                                                                  className="form-check-label"
                                                                  htmlFor={`emoji_click${i}${item._id}`}
                                                                >
                                                                  <span
                                                                    className="active-emoji"
                                                                    style={{
                                                                      fontSize:
                                                                        "40px",
                                                                    }}
                                                                  >
                                                                    {option}
                                                                  </span>
                                                                </label>
                                                              </div>
                                                            </li>
                                                          )
                                                        )
                                                      : null}
                                                  </ul>
                                                  {item.is_required &&
                                                  disableBtn &&
                                                  !values?.questionData[index]
                                                    ?.answer ? (
                                                    <span
                                                      className="text-danger text-start d-blockerr-spn"
                                                      id="required_question"
                                                    >
                                                      This field is required.
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              )}

                                              {item.question_type ===
                                                "dropdown" && (
                                                <div
                                                  key={index}
                                                  className="card-questions-box mt-3"
                                                >
                                                  <label className="form-label">
                                                    {index + 1 + "."}{" "}
                                                    {item.title}
                                                    {item.is_required ? (
                                                      <span className="requirestar">
                                                        *
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </label>
                                                  {Array.isArray(
                                                    item.options
                                                  ) ? (
                                                    <div className="dropdown">
                                                      <select
                                                        defaultValue={
                                                          values?.questionData[
                                                            index
                                                          ]?.answer
                                                        }
                                                        onChange={(e) =>
                                                          setFieldValue(
                                                            `questionData[${index}].answer`,
                                                            e.target.value
                                                          )
                                                        }
                                                        className={`form-control ${
                                                          item.is_required
                                                            ? "question-required"
                                                            : ""
                                                        }`}
                                                        required={
                                                          item?.is_required
                                                        }
                                                      >
                                                        <option value={""}>
                                                          Select
                                                        </option>
                                                        {item.options.map(
                                                          (option, i) => (
                                                            <>
                                                              <option
                                                                key={i}
                                                                value={option}
                                                              >
                                                                {option}
                                                              </option>
                                                            </>
                                                          )
                                                        )}
                                                      </select>
                                                    </div>
                                                  ) : null}
                                                  {item.is_required &&
                                                  disableBtn &&
                                                  !values?.questionData[index]
                                                    ?.answer ? (
                                                    <span
                                                      className="text-danger text-start d-blockerr-spn"
                                                      id="required_question"
                                                    >
                                                      This field is required.
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              )}

                                              {item.question_type ===
                                                "rating" && (
                                                <div
                                                  key={item._id}
                                                  className="card-questions-box mt-3"
                                                >
                                                  <label className="form-label">
                                                    {index + 1 + "."}{" "}
                                                    {item.title}
                                                    {item.is_required ? (
                                                      <span className="requirestar">
                                                        *
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </label>
                                                  <div className="rating">
                                                    <Rating
                                                      onClick={(e) =>
                                                        setFieldValue(
                                                          `questionData[${index}].answer`,
                                                          e
                                                        )
                                                      }
                                                      initialValue={
                                                        values?.questionData[
                                                          index
                                                        ]?.answer
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              )}

                                              {item.question_type ===
                                                "multiple_choice" && (
                                                <div className="form-group">
                                                  <label className="form-label">
                                                    {index + 1 + "."}{" "}
                                                    {item.title}
                                                    {item.is_required ? (
                                                      <span className="requirestar">
                                                        *
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </label>
                                                  {item?.options?.map(
                                                    (item5, i) => (
                                                      <div key={i}>
                                                        <div className="form-check black-check ">
                                                          <Field
                                                            className="form-check-input cp"
                                                            id={`multiple_choice${i}${index}`}
                                                            type="checkbox"
                                                            name={`questionData[${index}].answer`}
                                                            value={item5}
                                                            required={
                                                              item?.is_required &&
                                                              !values
                                                                ?.questionData[
                                                                index
                                                              ]?.answer?.length
                                                            }
                                                          />
                                                          <label
                                                            className="form-check-label"
                                                            htmlFor={`multiple_choice${i}${index}`}
                                                          >
                                                            {item5}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                  {item.is_required &&
                                                  disableBtn &&
                                                  !values?.questionData[index]
                                                    ?.answer?.length ? (
                                                    <span
                                                      className="text-danger text-start d-blockerr-spn"
                                                      id="required_question"
                                                    >
                                                      This field is required.
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )}
                                /> */}
                                {detailsData?.secondary_question_set_data?.questions && detailsData?.secondary_question_set_data?.questions.length ?
                                <DynamicQuestions ref={dynamicQuestionsRef} questions={detailsData?.secondary_question_set_data?.questions} showCount={true}
                                  defaultValues={registrationData?.guest_user?.questions_data}
                                  questionTitleClass="" footer="" videoUploadPath={questionFilesUploadPath} />
                                : null
                              }
                              </div>
                            </div>
                          </div>
                          {detailsData?.askto_visa_verification ? (
                            <>
                              <h4 className="reg-title">
                                About guest visa invitation letter
                              </h4>
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <div className="toggle-box d-flex align-items-center justify-content-between">
                                    <h4>
                                      Do you need a Visa Invitation letter?
                                    </h4>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={values.guestVisaInvitation}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "guestVisaInvitation",
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              {values.guestVisaInvitation ? (
                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    <label className="form-label">
                                      Passport number{" "}
                                      <span className="requirestar">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="passportNumber"
                                      id="passportNumber"
                                      placeholder=""
                                      className="form-control"
                                      value={values.passportNumber}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="text-danger text-start d-blockerr-spn">
                                      {errors.passportNumber &&
                                        touched.passportNumber &&
                                        errors.passportNumber}
                                    </span>
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <label className="form-label">
                                      Surname (as stated in your passport)
                                      <span className="requirestar">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="surname"
                                      id="surname"
                                      placeholder=""
                                      className="form-control"
                                      value={values.surname}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="text-danger text-start d-blockerr-spn">
                                      {errors.surname &&
                                        touched.surname &&
                                        errors.surname}
                                    </span>
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <label className="form-label">
                                      First Name (as stated in your passport)
                                      <span className="requirestar">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="visaFirstName"
                                      id="visaFirstName"
                                      placeholder=""
                                      className="form-control"
                                      value={values.visaFirstName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="text-danger text-start d-blockerr-spn">
                                      {errors.visaFirstName &&
                                        touched.visaFirstName &&
                                        errors.visaFirstName}
                                    </span>
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <label className="form-label">
                                      Date of birth
                                      <span className="requirestar">*</span>
                                    </label>
                                    <div className="date-box date datepicker">
                                      <DatePicker
                                        showMonthDropdown
                                        scrollableYearDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={30}
                                        onChange={(date) =>
                                          setFieldValue("dateOfBirth", date)
                                        }
                                        className="form-control"
                                        value={
                                          values.dateOfBirth === ""
                                            ? ""
                                            : moment(values.dateOfBirth).format(
                                                "DD/MM/YYYY"
                                              )
                                        }
                                      />
                                      <span className="input-group-append">
                                        <span className="input-cal-icon ">
                                          <i className="ri-calendar-2-line"></i>
                                        </span>
                                      </span>
                                    </div>
                                    <span className="text-danger text-start d-blockerr-spn">
                                      {errors.dateOfBirth &&
                                        touched.dateOfBirth &&
                                        errors.dateOfBirth}
                                    </span>
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <label className="form-label">
                                      Place of birth
                                      <span className="requirestar">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="placeOfBirth"
                                      id="placeOfBirth"
                                      placeholder=""
                                      className="form-control"
                                      value={values.placeOfBirth}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="text-danger text-start d-blockerr-spn">
                                      {errors.placeOfBirth &&
                                        touched.placeOfBirth &&
                                        errors.placeOfBirth}
                                    </span>
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <label className="form-label">
                                      Nationality
                                      <span className="requirestar">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="nationality"
                                      id="nationality"
                                      placeholder=""
                                      className="form-control"
                                      value={values.nationality}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="text-danger text-start d-blockerr-spn">
                                      {errors.nationality &&
                                        touched.nationality &&
                                        errors.nationality}
                                    </span>
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <label className="form-label">
                                      Date of issue
                                      <span className="requirestar">*</span>
                                    </label>
                                    <div className="date-box date datepicker">
                                      <DatePicker
                                        showMonthDropdown
                                        scrollableYearDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={30}
                                        onChange={(date) =>
                                          setFieldValue("dateOfIssue", date)
                                        }
                                        className="form-control"
                                        value={
                                          values.dateOfIssue === ""
                                            ? ""
                                            : moment(values.dateOfIssue).format(
                                                "DD/MM/YYYY"
                                              )
                                        }
                                        maxDate={new Date()}
                                      />
                                      <span className="input-group-append">
                                        <span className="input-cal-icon ">
                                          <i className="ri-calendar-2-line"></i>
                                        </span>
                                      </span>
                                    </div>
                                    <span className="text-danger text-start d-blockerr-spn">
                                      {errors.dateOfIssue &&
                                        touched.dateOfIssue &&
                                        errors.dateOfIssue}
                                    </span>
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <label className="form-label">
                                      Date of expiry
                                      <span className="requirestar">*</span>
                                    </label>
                                    <div className="date-box date datepicker">
                                      <DatePicker
                                        showMonthDropdown
                                        scrollableYearDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={30}
                                        onChange={(date) =>
                                          setFieldValue("dateOfExpiry", date)
                                        }
                                        className="form-control"
                                        value={
                                          values.dateOfExpiry === ""
                                            ? ""
                                            : moment(
                                                values.dateOfExpiry
                                              ).format("DD/MM/YYYY")
                                        }
                                        minDate={new Date()}
                                      />
                                      <span className="input-group-append">
                                        <span className="input-cal-icon ">
                                          <i className="ri-calendar-2-line"></i>
                                        </span>
                                      </span>
                                    </div>
                                    <span className="text-danger text-start d-blockerr-spn">
                                      {errors.dateOfExpiry &&
                                        touched.dateOfExpiry &&
                                        errors.dateOfExpiry}
                                    </span>
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <label className="form-label">
                                      Authority, Country
                                      <span className="requirestar">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="authority"
                                      id="authority"
                                      placeholder=""
                                      className="form-control"
                                      value={values.authority}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="text-danger text-start d-blockerr-spn">
                                      {errors.authority &&
                                        touched.authority &&
                                        errors.authority}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {totalPrice !== 0 ? (
                        <div>
                          <h4 className="reg-title">About Invoice</h4>
                          <h5 className="reg-sub-title mb-2">
                            What should be the billing address?
                          </h5>
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Company name
                                <span className="requirestar">*</span>
                              </label>
                              <input
                                type="text"
                                name="companyName"
                                placeholder=""
                                value={values.companyName}
                                className="form-control"
                                onChange={handleChange}
                              />
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.companyName &&
                                  touched.companyName &&
                                  errors.companyName}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Address<span className="requirestar">*</span>
                              </label>
                              <input
                                type="text"
                                name="address"
                                placeholder=""
                                className="form-control"
                                value={values.address}
                                onChange={handleChange}
                              />
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.address &&
                                  touched.address &&
                                  errors.address}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Zip Code/Postal Code
                                <span className="requirestar">*</span>
                              </label>
                              <input
                                type="text"
                                name="postalCode"
                                placeholder=""
                                value={values.postalCode}
                                className="form-control"
                                onChange={handleChange}
                              />
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.postalCode &&
                                  touched.postalCode &&
                                  errors.postalCode}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                City<span className="requirestar">*</span>
                              </label>
                              <input
                                type="text"
                                name="city"
                                placeholder=""
                                value={values.city}
                                className="form-control"
                                onChange={handleChange}
                              />
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.city && touched.city && errors.city}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Country<span className="requirestar">*</span>
                              </label>
                              <input
                                type="text"
                                name="country"
                                placeholder=""
                                className="form-control"
                                onChange={handleChange}
                                value={values.country}
                              />
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.country &&
                                  touched.country &&
                                  errors.country}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {finalHotelData?.prices?.room_price ||
                      state?.values?.pricing?.event_price > 0 ||
                      (primaryAgendaDetails &&
                        primaryAgendaDetails.length > 0 &&
                        primaryAgendaDetails[0].price > 0) ||
                      (guestAgendaDetails &&
                        guestAgendaDetails.length &&
                        guestAgendaDetails[0].price > 0) > 0 ? (
                        <>
                          {params.get("edit") === true ||
                          params.get("edit") === "true" ? (
                            <>
                              <h4 className="reg-title">About Subtotal</h4>
                              <div className="price-details">
                                <div className="price-details-blk grid-box">
                                  {state?.values?.pricing?.event_price <= 0 ? (
                                    <div className="price-details-blk-in mb-3">
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span className="fw-bold text-success">
                                            This event is free.
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="price-details-blk-in mb-3">
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            Event fee{" "}
                                            {values.add_guest ? "(x 2)" : ""}
                                          </span>
                                          {(function () {
                                            let eventFee = values.add_guest
                                              ? myToFixed(
                                                  state?.values?.pricing
                                                    ?.event_price * 2
                                                )
                                              : myToFixed(
                                                  state?.values?.pricing
                                                    ?.event_price
                                                );
                                            function eventData() {
                                              if (
                                                eventFee !==
                                                oldPricing?.event_price
                                              ) {
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {oldPricing?.event_price
                                                        ? myToFixed(
                                                            oldPricing?.event_price
                                                          )
                                                        : "0"}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {eventFee}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {eventFee}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }
                                            return (
                                              <div className="ms-auto">
                                                {eventData()}
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            Tax (
                                            {detailsData &&
                                              detailsData?.event_quota &&
                                              detailsData?.event_quota?.tax}
                                            %)
                                          </span>
                                          {(function () {
                                            let eventTax = values.add_guest
                                              ? myToFixed(
                                                  calculatePercentage(
                                                    detailsData?.event_quota
                                                      ?.tax,
                                                    state?.values?.pricing
                                                      ?.event_price
                                                  ) * 2
                                                )
                                              : myToFixed(
                                                  calculatePercentage(
                                                    detailsData?.event_quota
                                                      ?.tax,
                                                    state?.values?.pricing
                                                      ?.event_price
                                                  )
                                                );
                                            function eventData() {
                                              if (
                                                eventTax !==
                                                oldPricing?.event_tax_price
                                              ) {
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {oldPricing?.event_tax_price
                                                        ? myToFixed(
                                                            oldPricing?.event_tax_price
                                                          )
                                                        : "0"}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {eventTax}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {eventTax}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }

                                            return (
                                              <div className="ms-auto">
                                                {eventData()}
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                      <hr className="my-2" />
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span className="fw-bold">
                                            Sub total
                                          </span>
                                          {(function () {
                                            let eventSubtotal = values.add_guest
                                              ? state?.values?.pricing
                                                  ?.event_price *
                                                  2 +
                                                Number(
                                                  myToFixed(
                                                    calculatePercentage(
                                                      detailsData?.event_quota
                                                        ?.tax,
                                                      state?.values?.pricing
                                                        ?.event_price
                                                    ) * 2
                                                  )
                                                )
                                              : Number(
                                                  myToFixed(
                                                    state?.values?.pricing
                                                      ?.event_price +
                                                      calculatePercentage(
                                                        detailsData?.event_quota
                                                          ?.tax,
                                                        state?.values?.pricing
                                                          ?.event_price
                                                      )
                                                  )
                                                );
                                            function eventSub() {
                                              if (
                                                eventSubtotal !==
                                                oldPricing?.event_subtotal
                                              ) {
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {oldPricing?.event_subtotal
                                                        ? myToFixed(
                                                            oldPricing?.event_subtotal
                                                          )
                                                        : "0"}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(eventSubtotal)}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(eventSubtotal)}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }

                                            return (
                                              <div className="fw-bold ms-auto">
                                                {eventSub()}
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {values.accommondation ||
                                  params.get("edit") === true ||
                                  params.get("edit") === "true" ? (
                                    <>
                                      {values.hotel !== "Select" ? (
                                        <div className="price-details-blk-in mb-3">
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>
                                                Hotel ({finalHotelData?.nights}{" "}
                                                x night)
                                              </span>
                                              {(function () {
                                                let roomPrice = finalHotelData
                                                  ?.prices?.room_price
                                                  ? finalHotelData?.prices
                                                      ?.room_price
                                                  : 0;
                                                function eventData() {
                                                  if (
                                                    roomPrice !==
                                                    oldPricing?.hotel_data
                                                      ?.prices?.room_price
                                                  ) {
                                                    return (
                                                      <>
                                                        <small
                                                          style={{
                                                            textDecoration:
                                                              "line-through",
                                                          }}
                                                        >
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {oldPricing
                                                            ?.hotel_data?.prices
                                                            ?.room_price
                                                            ? myToFixed(
                                                                oldPricing
                                                                  ?.hotel_data
                                                                  ?.prices
                                                                  ?.room_price
                                                              )
                                                            : 0}
                                                        </small>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {roomPrice}
                                                        </span>
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {roomPrice}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                }

                                                return (
                                                  <div className="ms-auto">
                                                    {eventData()}
                                                  </div>
                                                );
                                              })()}
                                            </div>
                                          </div>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>
                                                Hotel tax (
                                                {finalHotelData?.taxes
                                                  ?.hotel_tax
                                                  ? finalHotelData?.taxes
                                                      ?.hotel_tax
                                                  : "0"}
                                                %){" "}
                                              </span>
                                              {(function () {
                                                let hotelTax = finalHotelData
                                                  ?.prices?.hotel_tax
                                                  ? finalHotelData?.prices
                                                      ?.hotel_tax
                                                  : 0;
                                                function eventData() {
                                                  if (
                                                    hotelTax !==
                                                    oldPricing?.hotel_data
                                                      ?.prices?.hotel_tax
                                                  ) {
                                                    return (
                                                      <>
                                                        <small
                                                          style={{
                                                            textDecoration:
                                                              "line-through",
                                                          }}
                                                        >
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {oldPricing
                                                            ?.hotel_data?.prices
                                                            ?.hotel_tax
                                                            ? myToFixed(oldPricing?.hotel_data?.prices?.hotel_tax)
                                                            : 0}
                                                        </small>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {hotelTax}
                                                        </span>
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {hotelTax}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                }

                                                return (
                                                  <div className="ms-auto">
                                                    {eventData()}
                                                  </div>
                                                );
                                              })()}
                                            </div>
                                          </div>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>Breakfast </span>
                                              {(function () {
                                                let Breakfast = finalHotelData
                                                  ?.prices?.breakfast
                                                  ? finalHotelData?.prices
                                                      ?.breakfast
                                                  : 0;
                                                function eventData() {
                                                  if (
                                                    Breakfast !==
                                                    oldPricing?.hotel_data
                                                      ?.prices?.breakfast
                                                  ) {
                                                    return (
                                                      <>
                                                        <small
                                                          style={{
                                                            textDecoration:
                                                              "line-through",
                                                          }}
                                                        >
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {oldPricing
                                                            ?.hotel_data?.prices
                                                            ?.breakfast
                                                            ? oldPricing
                                                                ?.hotel_data
                                                                ?.prices
                                                                ?.breakfast
                                                            : 0}
                                                        </small>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {Breakfast}
                                                        </span>
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {Breakfast}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                }

                                                return (
                                                  <div className="ms-auto">
                                                    {eventData()}
                                                  </div>
                                                );
                                              })()}
                                            </div>
                                          </div>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>
                                                Breakfast tax (
                                                {finalHotelData?.taxes
                                                  ?.breakfast_tax
                                                  ? finalHotelData?.taxes
                                                      ?.breakfast_tax
                                                  : "0"}
                                                %)
                                              </span>
                                              {/* <span className="ms-auto">
                                        {detailsData?.currency?.sign}
                                        {finalHotelData?.prices?.breakfast_tax ? finalHotelData?.prices?.breakfast_tax : 0}
                                      </span> */}
                                              {(function () {
                                                let breakfastTax =
                                                  finalHotelData?.prices
                                                    ?.breakfast_tax
                                                    ? finalHotelData?.prices
                                                        ?.breakfast_tax
                                                    : 0;
                                                function eventData() {
                                                  if (
                                                    breakfastTax !==
                                                    myToFixed(
                                                      oldPricing?.hotel_data
                                                        ?.prices?.breakfast_tax
                                                    )
                                                  ) {
                                                    return (
                                                      <>
                                                        <small
                                                          style={{
                                                            textDecoration:
                                                              "line-through",
                                                          }}
                                                        >
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {oldPricing
                                                            ?.hotel_data?.prices
                                                            ?.breakfast_tax
                                                            ? myToFixed(
                                                                oldPricing
                                                                  ?.hotel_data
                                                                  ?.prices
                                                                  ?.breakfast_tax
                                                              )
                                                            : 0}
                                                        </small>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {breakfastTax}
                                                        </span>
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {breakfastTax}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                }

                                                return (
                                                  <div className="ms-auto">
                                                    {eventData()}
                                                  </div>
                                                );
                                              })()}
                                            </div>
                                          </div>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>City tax </span>
                                              {/* <span className="ms-auto">
                                        {detailsData?.currency?.sign}
                                        {finalHotelData?.prices?.city_tax ? finalHotelData?.prices?.city_tax : 0}
                                      </span> */}
                                              {(function () {
                                                let cityTax = finalHotelData
                                                  ?.prices?.city_tax
                                                  ? finalHotelData?.prices
                                                      ?.city_tax
                                                  : 0;
                                                function eventData() {
                                                  if (
                                                    cityTax !==
                                                    oldPricing?.hotel_data
                                                      ?.prices?.city_tax
                                                  ) {
                                                    return (
                                                      <>
                                                        <small
                                                          style={{
                                                            textDecoration:
                                                              "line-through",
                                                          }}
                                                        >
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {oldPricing
                                                            ?.hotel_data?.prices
                                                            ?.city_tax
                                                            ? myToFixed(
                                                                oldPricing
                                                                  ?.hotel_data
                                                                  ?.prices
                                                                  ?.city_tax
                                                              )
                                                            : 0}
                                                        </small>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {cityTax}
                                                        </span>
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {cityTax}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                }

                                                return (
                                                  <div className="ms-auto">
                                                    {eventData()}
                                                  </div>
                                                );
                                              })()}
                                            </div>
                                          </div>
                                          <hr className="my-2" />
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span className="fw-bold">
                                                Sub total
                                              </span>
                                              {/* <span className="fw-bold ms-auto">
                                        {detailsData?.currency?.sign}
                                        {finalHotelData?.sub_total}
                                      </span> */}
                                              {(function () {
                                                let hotelSubtotal =
                                                  finalHotelData?.sub_total;
                                                function eventSub() {
                                                  if (
                                                    hotelSubtotal !==
                                                    oldPricing?.hotel_data
                                                      ?.subtotal
                                                  ) {
                                                    return (
                                                      <>
                                                        <small
                                                          style={{
                                                            textDecoration:
                                                              "line-through",
                                                          }}
                                                        >
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {oldPricing
                                                            ?.hotel_data
                                                            ?.subtotal
                                                            ? myToFixed(
                                                                oldPricing
                                                                  ?.hotel_data
                                                                  ?.subtotal
                                                              )
                                                            : 0}
                                                        </small>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {hotelSubtotal}
                                                        </span>
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {hotelSubtotal}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                }

                                                return (
                                                  <div className="fw-bold ms-auto">
                                                    {eventSub()}
                                                  </div>
                                                );
                                              })()}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {primaryAgendaDetails &&
                                  primaryAgendaDetails?.length > 0 ? (
                                    <div className="price-details-blk-in mb-3">
                                      <span className="fw-bold">
                                        Primary user agenda
                                      </span>
                                      {primaryAgendaDetails &&
                                        primaryAgendaDetails.map(
                                          (item, index) => {
                                            return (
                                              <div className="ps-3" key={index}>
                                                <div className="price-details-row">
                                                  <div className="d-flex">
                                                    <span className="text-capitalize">
                                                      {item.name}
                                                    </span>
                                                    <span className="ms-auto">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(item.price)}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="price-details-row">
                                                  <div className="d-flex">
                                                    <span>
                                                      Tax ({item.tax}%)
                                                    </span>
                                                    <span className="ms-auto">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(
                                                        calculatePercentage(
                                                          item.tax,
                                                          item.price
                                                        )
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                                {index ===
                                                primaryAgendaDetails.length -
                                                  1 ? (
                                                  <></>
                                                ) : (
                                                  <hr className="my-2" />
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      <hr className="my-2" />
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span className="fw-bold">
                                            Sub total
                                          </span>
                                          {(function () {
                                            function eventSub() {
                                              if (
                                                primaryAgendaSubtotal !==
                                                oldPricing?.primary_agenda
                                                  ?.subtotal
                                              ) {
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(
                                                        oldPricing
                                                          ?.primary_agenda
                                                          ?.subtotal
                                                      )}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(
                                                        primaryAgendaSubtotal
                                                      )}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(
                                                        primaryAgendaSubtotal
                                                      )}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }

                                            return (
                                              <div className="fw-bold ms-auto">
                                                {eventSub()}
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {values.add_guest ? (
                                    <>
                                      {agendaPricing &&
                                      agendaPricing.length > 0 ? (
                                        <div className="price-details-blk-in mb-3">
                                          <span className="fw-bold">
                                            Guest user agenda
                                          </span>
                                          {guestAgendaDetails &&
                                            guestAgendaDetails.map(
                                              (item, index) => {
                                                return (
                                                  <div
                                                    className="ps-3"
                                                    key={index}
                                                  >
                                                    <div className="price-details-row">
                                                      <div className="d-flex">
                                                        <span className="text-capitalize">
                                                          {item.name}
                                                        </span>
                                                        <span className="ms-auto">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {myToFixed(
                                                            item.price
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="price-details-row">
                                                      <div className="d-flex">
                                                        <span>
                                                          Tax ({item.tax}%)
                                                        </span>
                                                        <span className="ms-auto">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {myToFixed(
                                                            calculatePercentage(
                                                              item.tax,
                                                              item.price
                                                            )
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    {index ===
                                                    guestAgendaDetails.length -
                                                      1 ? (
                                                      <></>
                                                    ) : (
                                                      <hr className="my-2" />
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          <hr className="my-2" />
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span className="fw-bold">
                                                Sub total
                                              </span>
                                              {(function () {
                                                function eventSub() {
                                                  if (
                                                    guestAgendaSubtotal !==
                                                    oldPricing?.secondary_agenda
                                                      ?.subtotal
                                                  ) {
                                                    return (
                                                      <>
                                                        <small
                                                          style={{
                                                            textDecoration:
                                                              "line-through",
                                                          }}
                                                        >
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {myToFixed(
                                                            oldPricing
                                                              ?.secondary_agenda
                                                              ?.subtotal
                                                          )}
                                                        </small>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {myToFixed(
                                                            guestAgendaSubtotal
                                                          )}
                                                        </span>
                                                      </>
                                                    );
                                                  } else {
                                                    return (
                                                      <>
                                                        <span className="ms-2">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {myToFixed(
                                                            guestAgendaSubtotal
                                                          )}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                }

                                                return (
                                                  <div className="fw-bold ms-auto">
                                                    {eventSub()}
                                                  </div>
                                                );
                                              })()}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="price-details-blk-in">
                                    <div className="price-details-row">
                                      <div className="d-flex">
                                        <h3 className="fw-bold p-0">Total</h3>
                                        <h3 className="fw-bold p-0 ms-auto">
                                          {(function () {
                                            let total =
                                              Number(
                                                values.add_guest
                                                  ? state?.values?.pricing
                                                      ?.event_price * 2
                                                  : state?.values?.pricing
                                                      ?.event_price
                                              ) +
                                              Number(
                                                values.add_guest
                                                  ? calculatePercentage(
                                                      detailsData?.event_quota
                                                        ?.tax,
                                                      state?.values?.pricing
                                                        ?.event_price
                                                    ) * 2
                                                  : calculatePercentage(
                                                      detailsData?.event_quota
                                                        ?.tax,
                                                      state?.values?.pricing
                                                        ?.event_price
                                                    )
                                              ) +
                                              Number(
                                                values.accommondation
                                                  ? finalHotelData?.sub_total
                                                  : 0
                                              ) +
                                              Number(
                                                primaryAgendaSubtotal
                                                  ? primaryAgendaSubtotal
                                                  : 0
                                              ) +
                                              Number(
                                                guestAgendaSubtotal
                                                  ? guestAgendaSubtotal
                                                  : 0
                                              );

                                            function eventSub() {
                                              if (
                                                total !== oldPricing?.grand_total
                                              ) {
                                                setTotalPrice(total);
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(
                                                        oldPricing?.grand_total
                                                      )}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {total
                                                        ? myToFixed(total)
                                                        : "00"}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                setTotalPrice(total);
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {total
                                                        ? myToFixed(total)
                                                        : "00"}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }
                                            return (
                                              <div className="fw-bold ms-auto">
                                                {eventSub()}
                                              </div>
                                            );
                                          })()}
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-3">
                                    {totalPrice > oldPricing?.grand_total ? (
                                      <h4 className="text-danger">
                                        Payable price{" "}
                                        {detailsData?.currency?.sign}
                                        {myToFixed(
                                          Number(totalPrice) -
                                            Number(oldPricing?.grand_total)
                                        )}
                                      </h4>
                                    ) : (
                                      <></>
                                    )}
                                    {totalPrice < oldPricing?.grand_total ? (
                                      <h4 className="text-success">
                                        Refundable price{" "}
                                        {detailsData?.currency?.sign}
                                        {myToFixed(
                                          Number(oldPricing?.grand_total) -
                                            Number(totalPrice)
                                        )}
                                      </h4>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <h4 className="reg-title">About Subtotal</h4>
                              <div className="price-details">
                                <div className="price-details-blk grid-box">
                                  <div className="price-details-blk-in mb-3">
                                    <div className="price-details-row">
                                      <div className="d-flex">
                                        <span>
                                          Event fee{" "}
                                          {values.add_guest ? "(x 2)" : ""}
                                        </span>
                                        <span className="ms-auto">
                                          {detailsData?.currency?.sign}
                                          {values.add_guest
                                            ? myToFixed(
                                                state?.values?.pricing
                                                  ?.event_price * 2
                                              )
                                            : myToFixed(
                                                state?.values?.pricing
                                                  ?.event_price
                                              )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="price-details-row">
                                      <div className="d-flex">
                                        <span>
                                          Tax (
                                          {detailsData &&
                                            detailsData?.event_quota &&
                                            detailsData?.event_quota?.tax}
                                          %)
                                        </span>
                                        <span className="ms-auto">
                                          {detailsData?.currency?.sign}
                                          {values.add_guest
                                            ? myToFixed(
                                                calculatePercentage(
                                                  detailsData?.event_quota?.tax,
                                                  state?.values?.pricing
                                                    ?.event_price
                                                ) * 2
                                              )
                                            : myToFixed(
                                                calculatePercentage(
                                                  detailsData?.event_quota?.tax,
                                                  state?.values?.pricing
                                                    ?.event_price
                                                )
                                              )}
                                        </span>
                                      </div>
                                    </div>
                                    <hr className="my-2" />
                                    <div className="price-details-row">
                                      <div className="d-flex">
                                        <span className="fw-bold">
                                          Sub total
                                        </span>
                                        <span className="fw-bold ms-auto">
                                          {detailsData?.currency?.sign}
                                          {values.add_guest
                                            ? myToFixed(
                                                state?.values?.pricing
                                                  ?.event_price *
                                                  2 +
                                                  calculatePercentage(
                                                    detailsData?.event_quota
                                                      ?.tax,
                                                    state?.values?.pricing
                                                      ?.event_price
                                                  ) *
                                                    2
                                              )
                                            : myToFixed(
                                                state?.values?.pricing
                                                  ?.event_price +
                                                  calculatePercentage(
                                                    detailsData?.event_quota
                                                      ?.tax,
                                                    state?.values?.pricing
                                                      ?.event_price
                                                  )
                                              )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {values.accommondation ? (
                                    <>
                                      {values.hotel !== "Select" ? (
                                        <div className="price-details-blk-in mb-3">
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>
                                                Hotel ({finalHotelData?.nights}{" "}
                                                x night)
                                              </span>
                                              <span className="ms-auto">
                                                {detailsData?.currency?.sign}
                                                {finalHotelData?.prices
                                                  ?.room_price
                                                  ? finalHotelData?.prices
                                                      ?.room_price
                                                  : 0}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>
                                                Hotel tax (
                                                {
                                                  finalHotelData?.taxes
                                                    ?.hotel_tax
                                                }
                                                %){" "}
                                              </span>
                                              <span className="ms-auto">
                                                {detailsData?.currency?.sign}
                                                {finalHotelData?.prices
                                                  ?.hotel_tax
                                                  ? finalHotelData?.prices
                                                      ?.hotel_tax
                                                  : 0}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>Breakfast </span>
                                              <span className="ms-auto">
                                                {detailsData?.currency?.sign}
                                                {finalHotelData?.prices
                                                  ?.breakfast
                                                  ? finalHotelData?.prices
                                                      ?.breakfast
                                                  : 0}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>
                                                Breakfast tax (
                                                {
                                                  finalHotelData?.taxes
                                                    ?.breakfast_tax
                                                }
                                                %)
                                              </span>
                                              <span className="ms-auto">
                                                {detailsData?.currency?.sign}
                                                {finalHotelData?.prices
                                                  ?.breakfast_tax
                                                  ? finalHotelData?.prices
                                                      ?.breakfast_tax
                                                  : 0}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>City tax </span>
                                              <span className="ms-auto">
                                                {detailsData?.currency?.sign}
                                                {finalHotelData?.prices
                                                  ?.city_tax
                                                  ? finalHotelData?.prices
                                                      ?.city_tax
                                                  : 0}
                                              </span>
                                            </div>
                                          </div>
                                          <hr className="my-2" />
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span className="fw-bold">
                                                Sub total
                                              </span>
                                              <span className="fw-bold ms-auto">
                                                {detailsData?.currency?.sign}
                                                {finalHotelData?.sub_total}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {primaryAgendaDetails &&
                                  primaryAgendaDetails.length > 0 ? (
                                    <div className="price-details-blk-in mb-3">
                                      <span className="fw-bold">
                                        Primary user agenda
                                      </span>
                                      {primaryAgendaDetails &&
                                        primaryAgendaDetails.map(
                                          (item, index) => {
                                            return (
                                              <div className="ps-3" key={index}>
                                                <div className="price-details-row">
                                                  <div className="d-flex">
                                                    <span className="text-capitalize">
                                                      {item.name}
                                                    </span>
                                                    <span className="ms-auto">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(item.price)}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="price-details-row">
                                                  <div className="d-flex">
                                                    <span>
                                                      Tax ({item.tax}%)
                                                    </span>
                                                    <span className="ms-auto">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(
                                                        calculatePercentage(
                                                          item.tax,
                                                          item.price
                                                        )
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                                {index ===
                                                primaryAgendaDetails.length -
                                                  1 ? (
                                                  <></>
                                                ) : (
                                                  <hr className="my-2" />
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      <hr className="my-2" />
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span className="fw-bold">
                                            Sub total
                                          </span>
                                          <span className="fw-bold ms-auto">
                                            {detailsData?.currency?.sign}
                                            {primaryAgendaSubtotal
                                              ? myToFixed(primaryAgendaSubtotal)
                                              : "00"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {values.add_guest ? (
                                    <>
                                      {agendaPricing &&
                                      agendaPricing.length > 0 ? (
                                        <div className="price-details-blk-in mb-3">
                                          <span className="fw-bold">
                                            Guest user agenda
                                          </span>
                                          {guestAgendaDetails &&
                                            guestAgendaDetails.map(
                                              (item, index) => {
                                                return (
                                                  <div
                                                    className="ps-3"
                                                    key={index}
                                                  >
                                                    <div className="price-details-row">
                                                      <div className="d-flex">
                                                        <span className="text-capitalize">
                                                          {item.name}
                                                        </span>
                                                        <span className="ms-auto">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {myToFixed(
                                                            item.price
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="price-details-row">
                                                      <div className="d-flex">
                                                        <span>
                                                          Tax ({item.tax}%)
                                                        </span>
                                                        <span className="ms-auto">
                                                          {
                                                            detailsData
                                                              ?.currency?.sign
                                                          }
                                                          {myToFixed(
                                                            calculatePercentage(
                                                              item.tax,
                                                              item.price
                                                            )
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    {index ===
                                                    guestAgendaDetails.length -
                                                      1 ? (
                                                      <></>
                                                    ) : (
                                                      <hr className="my-2" />
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          <hr className="my-2" />
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span className="fw-bold">
                                                Sub total
                                              </span>
                                              <span className="fw-bold ms-auto">
                                                {detailsData?.currency?.sign}
                                                {guestAgendaSubtotal
                                                  ? myToFixed(
                                                      guestAgendaSubtotal
                                                    )
                                                  : "00"}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="price-details-blk-in">
                                    <div className="price-details-row">
                                      <div className="d-flex">
                                        <h3 className="fw-bold p-0">Total</h3>
                                        <h3 className="fw-bold p-0 ms-auto">
                                          {detailsData?.currency?.sign}
                                          {(function () {
                                            let total =
                                              Number(
                                                values.add_guest
                                                  ? state?.values?.pricing
                                                      ?.event_price * 2
                                                  : state?.values?.pricing
                                                      ?.event_price
                                              ) +
                                              Number(
                                                values.add_guest
                                                  ? calculatePercentage(
                                                      detailsData?.event_quota
                                                        ?.tax,
                                                      state?.values?.pricing
                                                        ?.event_price
                                                    ) * 2
                                                  : calculatePercentage(
                                                      detailsData?.event_quota
                                                        ?.tax,
                                                      state?.values?.pricing
                                                        ?.event_price
                                                    )
                                              ) +
                                              Number(
                                                values.accommondation
                                                  ? finalHotelData?.sub_total
                                                  : 0
                                              ) +
                                              Number(
                                                primaryAgendaSubtotal
                                                  ? primaryAgendaSubtotal
                                                  : 0
                                              ) +
                                              Number(
                                                guestAgendaSubtotal
                                                  ? guestAgendaSubtotal
                                                  : 0
                                              );
                                            if (total) {
                                              setTotalPrice(total);
                                              return myToFixed(total);
                                            } else {
                                              setTotalPrice(total);
                                              return "00";
                                            }
                                          })()}
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="about-invoice mt-4">
                        <div className="">
                          <div className="form-check black-check mb-4">
                            <input
                              className="form-check-input cp"
                              type="checkbox"
                              name="userPermission"
                              value={values.userPermission}
                              onChange={handleChange}
                              id="userPermission"
                              checked={values.userPermission}
                            />
                            <label
                              className="form-check-label cp"
                              htmlFor="userPermission"
                            >
                              I agree that my data will be stored, processed and
                              that my data may be disclosed to third parties
                              that are related to this event.
                            </label>
                          </div>
                          <button
                            className="btn btn-secondary"
                            type="submit"
                            onClick={() => {
                            dynamicQuestionsRef?.current?.submit();
                              setIsFormSubmitted(true);
                              validateElements();
                              checkValidationError();
                            }}
                          >
                            Continue
                          </button>
                          <button
                            className="btn btn-secondary ms-2"
                            type="button"
                            onClick={() => {
                              navigate(
                                `/admin/event-management/events/registration-edit/${params.get(
                                  "event_id"
                                )}?invitation_id=${params.get(
                                  "invitation_id"
                                )}&reg_id=${params.get(
                                  "reg_id"
                                )}&user_id=${params.get("user_id")}`
                              );
                            }}
                          >
                            Previous
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </Form>
              )}
            </Formik>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
}

export default EditRegistrationDetailStepTwo;