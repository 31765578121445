import React, { useEffect, useState } from 'react'
import { Field,  Formik } from 'formik'
import Swal from "sweetalert2"
import { useLocation, useNavigate } from 'react-router-dom'
import * as topicsService from '../../services/topic.services';
import * as activityServices from '../../services/activity.services';
import Breadcrums from '../../common/breadcrumbs';
import CustomCkeditor from '../../common/customeditor';
import { SWAL_SETTINGS } from '../../../../utils/Constants';
import { handleServerValidations } from '../../../../utils/commonfunction';


const TopicAdd = (props) => {
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const [activityList, setActivityList] = useState([])
    const location = useLocation();
    const [dataLoaded, setDataLoaded] = useState(false)
    const [initialValues, setInitialValues] = useState({
        title:  "",
        title_de:  "",
        activity_id:"",
        richtext:"",
        richtext_de:""
    });

    console.log()


    const [activityId] = useState(location?.state?.activity_data?._id ? location?.state?.activity_data?._id : location.state);
    const breadcrumbs = [
      { title: "Dashboard", url: "/admin/dashboard" },
      { title: "Activity Forums", url: "/admin/activity-forum/activities/list/1" },
      {
        title: "View",
        url: `/admin/activity-forum/activities/view/${activityId}?page=1`,
      },
      {
        title: "Topics",
        url: `/admin/activity-forum/activities/view/${activityId}?page=1`,
      },
      { title: "Add", url: "" },
    ];

    useEffect(() => {
        setInitialValues({
            title: location?.state?.title? location?.state?.title : "",
            title_de: location?.state?.title_de? location?.state?.title_de : "",
            activity_id: location?.state?.activity_data?._id? location?.state?.activity_data?._id : "",
            richtext: location?.state?.description?location?.state?.description: "",
            richtext_de: location?.state?.description_de?location?.state?.description_de: ""
        })
        setDataLoaded(true);
    }, []);

    useEffect(() => {
        activityServices.Options().then(response => {
            setActivityList(response && response.data ? response.data : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            {dataLoaded ? (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validateOnBlur={false}
                validate={values => {
                    const error = {};
                    if (!values.title || !values.title.trim()) error.title = "Title is required";
                    if (!values.activity_id || !values.activity_id.trim()) error.activity_id  = "Activity is required";
                    if (!values.richtext) error.richtext = "Description is required";
                    return error;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    console.log(values, values)
                    setSubmitted(true)
                    let formData = new FormData();
                    formData.append('title', values.title);
                    formData.append('title_de', values.title_de);
                    formData.append('activity_id', values.activity_id );
                    formData.append('description', values.richtext);
                    formData.append('description_de', values.richtext_de);
                    topicsService.Add(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                resetForm({ values: '' });
                               navigate(`/admin/activity-forum/activities/view/${activityId}/?page=1`)
                            }, 2000);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                            setSubmitted(false)
                        }
                    }).catch(error => {
                        console.log("error ====> ", error);
                    })
                }} 
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Add Topic</h6>
                                    </div>
                                    <div className="row row-sm">
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span> </label>
                                            <input name='title' id='title' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.title && touched.title && errors.title}</span>
                                        </div>
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title_de' className='text-left d-flex'>Title In German:</label>
                                            <input name='title_de' id='title_de' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title_de} className='form-control' />
                                            {/* <span className='text-danger d-flex text-left'>{errors.title_de && touched.title_de && errors.title_de}</span> */}
                                        </div>
                                        <div className="col-lg-6 text-center form-group">
                                            <label htmlFor='activity_id' className='text-left d-flex'>Activity Type:<span className="requirestar">*</span> </label>
                                            <div className="form-group select-down-arrow">
                                                <Field as='select' id="activity_id " name={"activity_id"} className="form-control select2">
                                                    <option value="" label="Select">Select{" "}</option>
                                                    {activityList && activityList.length > 0 && activityList.map((option, i) => {
                                                        return (
                                                            <option disabled={typeof activityId === 'string' && activityId === option._id ? false : true} key={i} value={option._id}>
                                                                {option.title}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>
                                                <span className='text-danger d-flex text-left'>{errors.activity_id&& touched.activity_id&& errors.activity_id}</span>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 text-center form-group'>
                                            <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                                            <CustomCkeditor
                                                fieldname={"richtext"}
                                                setFieldValue={setFieldValue}
                                                value={values.richtext}
                                                setFieldTouched={setFieldTouched}
                                            />
                                            <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                                        </div>
                                        <div className='col-lg-12 text-center form-group'>
                                            <label htmlFor='subject' className='text-left d-flex'>Description In German:</label>
                                            <CustomCkeditor
                                                fieldname={"richtext_de"}
                                                setFieldValue={setFieldValue}
                                                value={values.richtext_de}
                                                setFieldTouched={setFieldTouched}
                                            />
                                            {/* <span className='text-danger d-flex text-left'>{errors.richtext_de && touched.richtext_de && errors.richtext_de}</span> */}
                                        </div>
                                        <div className="">
                                            <button className="btn btn-main-primary signbtn mr-2" type="submit" disabled={submitted ? true : null}>
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Submit
                                            </button>
                                            <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
            ) : (
                <></>
            )}
        </>
    )
}

export default TopicAdd