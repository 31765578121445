import React,{useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import likeImage from "../../../assets/user/images/thump-icon.png";
import { useTranslation } from 'react-i18next';

const InvoicePaymentPage = () => {

    const { t } = useTranslation();

    let globalData = useSelector((state) => state.globaldetail);

  const [getGlobalData, setGlobalData] = useState({});
  const myGlobalData = useSelector((state) => state.globalData);
  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  },[myGlobalData]);

    return (
        <>
            <div className="wraper-inner cpt bg-grey">
                <section className="inner-space space-b">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 m-auto thanku-card-main">
                                    <div className="dash-box-shadow pb-5">
                                        <div className="thanku-card text-center mb-4 ">
                                            <figure className="mb-3">
                                                <img src={likeImage} alt="Like" />
                                            </figure>
                                            <div dangerouslySetInnerHTML={{ __html: `${getGlobalData && getGlobalData?.settings && getGlobalData?.settings?.pay_by_invoice_content ? getGlobalData?.settings?.pay_by_invoice_content : "N/A"}` }}></div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <Link className='btn btn-primary mb-4' to={"/settings/profile"}>{t('web_lbl_go_to_profile')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
            </div>
        </>
    )
}

export default InvoicePaymentPage