import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as eventsService from '../../services/events.services';
import Breadcrums from '../../common/breadcrumbs';


const ViewInvoiceDetail = () => {
    const location = useLocation();
    const {registrationId} = useParams();
    const [registeredUsers, setRegisteredUsers] = useState({});
    const { state } = location;
    const { event_id } = state || {};
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "View Event", url: "/admin/event-management/events/list/1" }, { title: "View Registrations", url: `/admin/event-management/events/view/registrations/${event_id}/1` }, { title: "View Invoice info", url: "" }];
    useEffect(() => {
        if (registrationId) {
            eventsService.RegistrationsDetails(registrationId)
                .then((response) => {
                    if (response?.success) {
                        setRegisteredUsers(response?.data);
                    }
                })
                .catch(err => console.log("err", err));
        }
    }, []);

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12">
                    <div className="card custom-card main-content-body-profile">
                        <div className="tab-content">
                            <div className="main-content-body tab-pane p-4 border-top-0 active" id="about">
                                <div className="card-body p-0 border p-0 rounded-10">
                                    <div className="main-content side-content pt-0 ml-0">
                                        <embed src={registeredUsers?.invoice_pdf} type="application/pdf"   height="720px" width="100%"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            </div>
        </>
    );
};

export default ViewInvoiceDetail;