import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as otherService from "../services/webother.service";
import {
  formateDate, globalLoader,
} from "../../../utils/commonfunction";
import CustomPagination from "../../admin/common/custompagination";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-transitions.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-autoplay.css";
import "lightgallery/css/lg-rotate.css";
import "lightgallery/css/lg-fullscreen.css";
import { useTranslation } from "react-i18next";
import FrontLoader from "../../globalComponents/FrontLoader";

const TransactionList = () => {
  const params = useParams();
  const [list, setList] = useState([]);
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(9);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [loader,setLoader]= useState(true);

  useEffect(() => {
    // globalLoader(true);
    setLoader(true);
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    otherService
      .transactionList(formData)
      .then((res) => {
        console.log(res?.data?.list);
        if (res && res.success) {
          setList(res?.data?.list);
          setDataLength(res?.data?.total_records);
          setPage(res && res?.data && res?.data?.page ? res?.data?.page : 1);
          // globalLoader(false);
          setLoader(false);
        } else {
          // globalLoader(false);
          setLoader(false);
        }
      })
      .catch((err) => console.log("err", err));
  }, [page,params.pgno,itemperpage]);

  return (
    <div className="dash-main-R">
      <div className="row">
        <div className="col-md-9 m-auto">
          <h3 className="dash-title">{t("web_sidebar_transaction_list")}</h3>

          <div className="dash-box-shadow" style={{ minHeight:"100px" }}>
            <div className="row">
              {loader ? 
               <FrontLoader/>
             :
             <>
              {list?.length ? (
                list.map((item, index) => (
                  <div className="col-md-6 my-2" key={index}>
                    <div className="grid-box grid-transaction-box">
                      
                      <div>
                            <p>{t('lbl_transaction_details')}:</p>
                            <div><span>{t('lbl_id')}:</span> {item?.paypal_pay_id}</div>
                            <div><span>{t('lbl_amount')}:</span>{item?.currency?.sign}{item?.amount}</div>
                            <div><span>{t('lbl_date')}:</span> {formateDate(item?.createdAt)}</div>
                        </div>
                      {item?.type === "refund" && (
                        <div>
                            <p>{t('lbl_refund_detail')}:</p>
                            <div><span>{t('lbl_refund_type')}:</span> {item?.refund_type}</div>
                            <div><span>{t('lbl_refund_reason')}:</span> {item?.refund_reason}</div>
                            <div><span>{t('lbl_total_refund_amount')}:</span> {item?.total_refunded_amount}</div>
                            <div><span>{t('lbl_refund_id')}:</span> {item?.paypal_refund_id} </div>
                            {item?.refund_date && (<div><span>{t('lbl_refund_date')}:</span>{formateDate(item?.refund_date)}</div>)}
                        </div>
                        )}
                        <div>
                            <span>{t('lbl_status')}:
                            {item?.status === "success" && t('lbl_success')}
                            {item?.status === "pending" && t('lbl_pending')}
                            {item?.status === "cancelled" && t('lbl_cancelled')}
                            </span>
                        </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-md-12 my-2">
                  <div className="text-center">
                    {t("web_lbl_no_records")}
                  </div>
                </div>
              )}
             </>
              }
            </div>
            {datalength && datalength > 0 ? (
              <div className="mt-4 text-right pagination-main d-flex justify-content-end  pe-4">
                <CustomPagination
                  datalength={datalength}
                  itemperpage={itemperpage}
                  currentPage={page}
                  setPage={setPage}
                  pageRoute={[
                    { name: "Sharemedia", path: "/settings/transaction-list" },
                  ]}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TransactionList;
