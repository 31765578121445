import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { cmsDetails } from "../services/common.services";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from 'react-i18next';


function TermsCondition() {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState({});
  const { t } = useTranslation();


  useEffect(() => {
    setLoading(true);
    cmsDetails("terms-condition").then((response) => {
      console.log("response",response?.data?.description)
      setData(response?.data);
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      console.log("error of terms&conditions",error);
    })
  },[])

  return (
    <div className="wraper-inner cpt">
      {/*-banner-inner---*/}
      <section className="inner-banner">
        <div className="container">
          <div className="row ">
            <div className="banner-inner-card">
              <h1>{t('web_terms')} &amp; {t('web_terms_conditions')}</h1>
              <nav className="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>{t('web_header_heading_home')}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {t('web_terms')} &amp; {t('web_terms_conditions')}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="content-box-main section-padding">
        <div className="container">
          {loading ? <Skeleton  count={10} style={{height:'50px'}}/> : <></>}
          <div dangerouslySetInnerHTML={{ __html: `${data && data?.description ? data?.description : "N/A"}` }}>
          {/* {toHtml(data && data?.description ? data?.description : "N/A")} */}
          {/* dangerouslySetInnerHTML={{ __html: `${data && data?.description ? data?.description : "N/A"}` }} */}
          </div>
        </div>
      </section>
      {/*-blog2--*/}
    </div>
  );
}

export default TermsCondition;
