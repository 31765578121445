import React, { useEffect, useState } from 'react'
import { Field, Formik } from 'formik'
import Swal from "sweetalert2"
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrums from '../../common/breadcrumbs';
import CustomCkeditor from '../../common/customeditor';
import { SWAL_SETTINGS } from '../../../../utils/Constants';
import * as ticketService from '../../services/ticket.services';
import { blockInvalidChar, handleServerValidations } from '../../../../utils/commonfunction';
import { TICKET_TYPES } from '../../../../utils/Constants'


const TicketEdit = (props) => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const [submitted, setSubmitted] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Tickets", url: "/admin/event-management/tickets/list/1" }, { title: "Edit", url: "" }]


    useEffect(() => {
        ticketService.Details(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : []);
            setTimeout(() => {
                setDataLoaded(true);
            },100)
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [params.id])


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    title: showdefault && showdefault.title ? showdefault.title : "",
                    ticket_type: showdefault && showdefault.type ? showdefault.type : "",
                    price: showdefault && showdefault.price ? showdefault.price : "",
                    person: showdefault && showdefault.number_of_persons ? showdefault.number_of_persons : "",
                    richtext: showdefault && showdefault.description ? showdefault.description : "",
                }}
                validate={values => {
                    const error = {};
                    if (!values.title || !values.title.trim()) error.title = "Title is required";
                    if (!values.ticket_type) error.ticket_type = "Type is required";
                    if (values.ticket_type === "paid") {
                        if (!values.price) error.price = "Price is required";
                    }
                    if (!values.person) error.person = "No of persons is required";
                    if (!values.richtext || !values.richtext.trim()) error.richtext = "Description is required";
                    return error;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitted(true)
                    let formData = new FormData();
                    formData.append('o_id', params.id)
                    formData.append('title', values.title);
                    formData.append('type', values.ticket_type);
                    if (values.ticket_type === "paid") {
                        formData.append('price', values.price);
                    }
                    formData.append('number_of_persons', values.person);
                    formData.append('description', values.richtext);
                    ticketService.Edit(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                navigate(`/admin/event-management/tickets/list/${params.pgno}`)
                            }, 1000);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                            setSubmitted(false)
                        }
                    }).catch(error => {
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Edit Ticket</h6>
                                    </div>
                                    <div className="row row-sm">
                                        <div className='col-md-12 text-center form-group'>
                                            <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span> </label>
                                            <input name='title' id='title' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.title && touched.title && errors.title}</span>
                                        </div>
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='person' className='text-left d-flex'>Persons:<span className="requirestar">*</span></label>
                                            <input name='person' type='text' id="person" onChange={handleChange} onBlur={handleBlur} value={values.person} className='form-control' onKeyDown={blockInvalidChar} />
                                            <span className='text-danger d-flex text-left'>{errors.person && touched.person && errors.person}</span>
                                        </div>
                                        <div className="col-lg-6 text-center form-group">
                                            <label htmlFor='ticket_type' className='text-left d-flex'>Type:<span className="requirestar">*</span> </label>
                                            <div className="form-group select-down-arrow">
                                                <Field as='select' id="ticket_type" name={"ticket_type"} className="form-control select2 text-capitalize">
                                                    <option value="" label="Select">Select{" "}</option>
                                                    {TICKET_TYPES && Object.values(TICKET_TYPES).map((option, i) => {
                                                        return (
                                                            <option key={i} value={option}>
                                                                {option}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>
                                                <span className='text-danger d-flex text-left'>{errors.ticket_type && touched.ticket_type && errors.ticket_type}</span>
                                            </div>
                                        </div>
                                        {values.ticket_type === "paid" ? <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='price' className='text-left d-flex'>Price:<span className="requirestar">*</span></label>
                                            <input name='price' type='text' id="price" onChange={handleChange} onBlur={handleBlur} value={values.price} className='form-control' onKeyDown={blockInvalidChar} />
                                            <span className='text-danger d-flex text-left'>{errors.price && touched.price && errors.price}</span>
                                        </div> : ""}
                                        <div className='col-lg-12 text-center form-group'>
                                            <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                                            {
                                                dataLoaded ?
                                                <CustomCkeditor
                                                    fieldname={"richtext"}
                                                    setFieldValue={setFieldValue}
                                                    value={values.richtext}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                                :
                                                ""
                                            }
                                            <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                                        </div>

                                        <div className="">
                                            <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Save
                                            </button>
                                            <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    {/* // : "Loader"} */}

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>
    )
}

export default TicketEdit