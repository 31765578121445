import React, { forwardRef, useRef, useState } from 'react'
import { QUESTION_TYPES } from '../../../utils/Constants';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-transitions.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-zoom.css';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lg-autoplay.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import 'lightgallery/css/lg-rotate.css';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import 'lightgallery/css/lg-fullscreen.css';
import { useCallback } from 'react';
import { convertTo12HourFormat, getArrayOf, getDate } from '../../../utils/commonfunction';

const ShowAnswer = forwardRef(({question, setVideoPlaySource, parentClass=""}, ref)=> {
    let lightGalleryRef = useRef();
    
    
    const onLightGalleryInit = useCallback((detail) => {
        if (detail) {
            lightGalleryRef.current = detail.instance;
        }
    }, []);
    function showImageGallery(){
        lightGalleryRef?.current?.openGallery();
    }
    const playVideo = (video, type) => {
        setVideoPlaySource(video, type);
    };

    return (
        <div className={`${parentClass} question_type-${question?.question_type}`}>
            {(function(){
                if(question?.answer){
                    if (question?.question_type === QUESTION_TYPES.MULTIPLE_CHOICE) {
                        return (
                            <div className='d-flex flex-wrap'>
                                {question?.answer?.map((option, i)=>{
                                    return(
                                        <span key={i} className="me-1 badge bg-success d-inline-flex align-items-center justify-content-center text-capitalize">{option}</span>
                                    )
                                })}
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.SLIDER) {
                        return (
                            <div>
                                {question?.answer}
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.SHORT_ANS) {
                        return (
                            <div>
                                {question?.answer}
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.LONG_ANS) {
                        return (
                            <div>
                                {question?.answer}
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.DROPDOWN) {
                        return (
                            <div>
                                {question?.answer}
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.SINGLE_CHOICE) {
                        return (
                            <div className='text-capitalize'>
                                {question?.answer}
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.TIME) {
                        return (
                            <div>
                                {convertTo12HourFormat(question?.answer)}
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.CALENDAR) {
                        return (
                            <div>
                                {getDate(question?.answer, "DD-MM-YYYY", false)}
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.LIKE_DISLIKE) {
                        return (
                            <div className='position-relative' style={{height:'20px'}}>
                                <span className='h-100' style={{fontSize:'30px', top:'-12px',position:"absolute", }}>
                                {question?.answer === 0 || question?.answer === "0" ?
                                    <i className="ri-thumb-down-fill text-primary"></i>
                                    :
                                    <i className="ri-thumb-up-fill text-primary"></i>
                                }
                                </span>
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.EMOJI) {
                        return (
                            <div className='position-relative' style={{height:'20px'}}>
                                <span className='h-100' style={{fontSize:'30px', top:'-12px',position:"absolute", }}>
                                    {question?.answer}
                                </span>
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.RATING) {
                        return (
                            <div className='position-relative' style={{ height: '20px' }}>
                                <span className='h-100' style={{ fontSize: '30px', top: '-12px', position: "absolute", }}>
                                    {getArrayOf(question?.answer).map((rating, i) => {
                                        return (
                                            <i key={i} className="ri-star-fill text-primary"></i>
                                        )
                                    })
                                    }
                                </span>
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.SIGNATURE || question?.question_type === QUESTION_TYPES.FREE_HAND_DRAWING) {
                        return (
                            <div className="demo-avatar-group cp" onClick={showImageGallery}> 
                                <div className="main-img-user avatar-sm" style={{border:'1px solid #000'}}>
                                    <img src={question?.answer} alt="avatar" className="rounded-circle" />
                                </div>
                                <LightGallery speed={500} addClass="show-drawing" onInit={onLightGalleryInit} plugins={[lgThumbnail, lgZoom, lgFullscreen, lgRotate]} >
                                    <a href={question?.answer} className={`h-100 d-none`}>
                                        <img src={question?.answer} alt="Image" />
                                    </a>
                                </LightGallery>
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.PHOTO_UPLOAD) {
                        if(question?.answer?.length > 3){
                            return (
                                <div className="demo-avatar-group cp" onClick={showImageGallery}>                      
                                    {question?.answer?.slice(0, 3).map((image,i)=>{
                                        return (
                                            <div className="main-img-user avatar-sm" key={i}>
                                                <img src={image} alt="avatar" className="rounded-circle" />
                                            </div>
                                        )
                                    })}
                                    <div className="main-img-user avatar-sm d-flex align-items-center justify-content-center border-1 bg-dark text-white">
                                        +{question?.answer?.length - 3}
                                    </div>
                                    <LightGallery speed={500} onInit={onLightGalleryInit} plugins={[lgThumbnail, lgZoom, lgFullscreen, lgRotate]} >
                                        {question?.answer?.map((image, i) => {
                                            return (
                                                <a key={i} href={image} className={`h-100 d-none`}>
                                                    <img src={image} alt="Image" />
                                                </a>
                                            )
                                        })}
                                    </LightGallery>
                                </div>
                            )
                        }else{
                            return (
                                <div className="demo-avatar-group cp" onClick={showImageGallery}> 
                                    {question?.answer.map((image,i)=>{
                                        return (
                                            <div className="main-img-user avatar-sm" key={i}>
                                                <img src={image} alt="avatar" className="rounded-circle" />
                                            </div>
                                        )
                                    })}
                                    <LightGallery speed={500} onInit={onLightGalleryInit} plugins={[lgThumbnail, lgZoom, lgFullscreen, lgRotate]} >
                                        {question?.answer?.map((image, i) => {
                                            return (
                                                <a key={i} href={image} className={`h-100 d-none`}>
                                                    <img src={image} alt="Image" />
                                                </a>
                                            )
                                        })}
                                    </LightGallery>
                                </div>
                            )
                        }
                    } else if (question?.question_type === QUESTION_TYPES.VOICE_MEMO) {
                        return (
                            <div>
                                <span onClick={()=>{playVideo(question?.answer, "audio")}} className="me-1 badge bg-primary btn-hover cp d-inline-flex align-items-center justify-content-center"><i className="ri-play-fill"></i> Voice Memo</span>
                            </div>
                        )
                    } else if (question?.question_type === QUESTION_TYPES.VIDEO_UPLOAD) {
                        return (
                            <div>
                                {question?.answer?.map((video, i)=>{
                                    return(
                                        <span key={i} onClick={()=>{playVideo(video, "video")}} className="me-1 badge bg-primary btn-hover cp d-inline-flex align-items-center justify-content-center"><i className="ri-play-fill"></i> Video {i+1}</span>
                                    )
                                })}
                                
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                {question?.answer}
                            </div>
                        )
                    }
                }else{
                    return <span className='badge bg-warning'>Not Answered</span>
                }
            })()}
        </div>
    )
    
});

export default ShowAnswer;
