import React, { useEffect, useState } from 'react'
import { Field, Formik, ErrorMessage } from 'formik'
import Swal from "sweetalert2"
import {  useLocation, useNavigate } from 'react-router-dom'
import * as staffService from '../../services/staff.services'
import Breadcrums from '../../common/breadcrumbs';
import { ATLEAST_ONE_CAPITAL_REGEX, ATLEAST_ONE_NUMBER_REGEX, ATLEAST_ONE_SMALL_REGEX, ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, SWAL_SETTINGS } from '../../../../utils/Constants';
import { handleServerValidations, removeSpecialCharacter } from '../../../../utils/commonfunction';
import * as Yup from 'yup';
import { STAFF_ROLE } from '../../../../utils/Constants';

const StaffAdd = (props) => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [previewimage, setPreviewImage] = useState("");
  const [roleOptions] = useState(Object.keys(STAFF_ROLE).filter(key=>key!=="USER"));
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    role:"",
    files: "",
  });
  const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Staff", url: "/admin/staff/list/1" }, { title: "Add", url: "" },];
  const [newshow, setNewShow] = useState("false");
  const [conformshow, setConformShow] = useState("false");

  useEffect(() => {
    setInitialValues({
      first_name: state && state.first_name ? state.first_name : "",
      last_name: state && state.last_name ? state.last_name : "",
      email: state && state.email ? state.email : "",
      password: "",
      confirm_password: "",
      role: state && state.role ? state.role : "",
      files: "",
    });
    setDataLoaded(true);
  },[])

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(' First Name is required'),
    last_name: Yup.string().required(' Last Name is required'),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
    .required("Password is required")
    .matches(
      ATLEAST_ONE_SMALL_REGEX,
      "Password should have at least 1 lowercase letter"
    )
    .matches(
      ATLEAST_ONE_CAPITAL_REGEX,
      "Password should have at least 1 capital letter"
    )
    .matches(
      ATLEAST_ONE_NUMBER_REGEX,
      "Password should have at least 1 number"
    )
    .matches(
      ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
      "Password should have at least 1 special character"
    )
    .min(PASSWORD_MIN_LENGTH, "Password should be at least 8 characters long")
    .max(
      PASSWORD_MAX_LENGTH,
      "Password cannot be more than 20 characters long"
    ),
    confirm_password: Yup.string().required("Confirm password is required").oneOf([Yup.ref("password"), null], "Passwords don't match"),
    role: Yup.string().required("Role is required"),
});

  const handleshow = (event) => {
    if (event === "newpwd") {
      setNewShow(!newshow);
    }
    if (event === "confirepwd") {
      setConformShow(!conformshow);
    }
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log("values", values)
          setSubmitted(true)
          let formData = new FormData();
          formData.append('profile_image', values.files);
          formData.append('first_name', values.first_name);
          formData.append('last_name', values.last_name);
          formData.append('email', values.email);
          formData.append('password', values.password);
          formData.append('confirm_password', values.confirm_password);
          formData.append('role',values.role);
          staffService.Add(formData).then(response => {
            setSubmitting(false);
            if (response.success) {
              Swal.fire({
                icon: 'success',
                text: response.message,
                ...SWAL_SETTINGS
              })
              setTimeout(() => {
                resetForm({ values: '' });
                navigate(`/admin/staff/list/1`)
              }, 2000);
            } else {
              Swal.fire({
                icon: 'error',
                text: handleServerValidations(response),
                ...SWAL_SETTINGS
              })
              setSubmitted(false)
            }
          }).catch(error => {
            console.log("error ====> ", error);
          })
        }}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="row row-sm">
            <div className="col-lg-12 col-md-12 animation_fade">
              <div className="card custom-card">
                <div className="card-body">
                  <div>
                    <h6 className="main-content-label mb-3">Add New Staff</h6>
                  </div>
                  <div className="row row-sm">
                    <div className='col-md-6 text-center form-group'>
                      <label htmlFor='title' className='text-left d-flex'> First Name:<span className="requirestar">*</span> </label>
                      <input
                        name='first_name'
                        id='first_name'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        className='form-control' />
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"first_name"} /></span>
                    </div>
                    <div className='col-md-6 text-center form-group'>
                      <label htmlFor='title' className='text-left d-flex'> Last Name:<span className="requirestar">*</span> </label>
                      <input
                        name='last_name'
                        id='last_name'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                        className='form-control' />
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"last_name"} /></span>
                    </div>

                    <div className="col-lg-6 text-center form-group">
                      <label htmlFor='title' className='text-left d-flex'>Email:<span className="requirestar">*</span> </label>
                      <input
                        name='email'
                        id='email'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className='form-control'
                      />
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"email"} /></span>
                    </div>

                    <div className='col-md-6 text-center form-group'>
                      <label htmlFor='title' className='text-left d-flex'>Password:<span className="requirestar">*</span> </label>
                      <div className="input-group cp">
                      <input
                        name='password'
                        id='password'
                        type={!newshow === true ? "text" : "password"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className='form-control' />
                          <span
                              className="input-group-text"
                              onClick={() => {
                                handleshow("newpwd");
                              }}
                            >
                              {!newshow === true ? (
                                <i className="far fa-eye-slash"></i>
                              ) : (
                                <i className="far fa-eye"></i>
                              )}
                            </span>
                            </div>
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"password"} /></span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label htmlFor='title' className='text-left d-flex'>Confirm Password:<span className="requirestar">*</span> </label>
                      <div className="input-group cp">
                      <input
                        name='confirm_password'
                        id='confirm_password'
                        type={!conformshow === true ? "text" : "password"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirm_password}
                        className='form-control'
                      />
                        <span
                              className="input-group-text"
                              onClick={() => {
                                handleshow("confirepwd");
                              }}
                            >
                              {!conformshow === true ? (
                                <i className="far fa-eye-slash"></i>
                              ) : (
                                <i className="far fa-eye"></i>
                              )}
                            </span>
                          </div>
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"confirm_password"} /></span>
                    </div>
                     <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='slug' className='text-left d-flex'>Role:<span className="requirestar">*</span></label>
                      <div className="select-down-arrow ">
                        <Field as='select' id="role" name={"role"} className="form-control select2">
                          <option value="" label="Select role">Select{" "}</option>
                          {roleOptions && roleOptions.map((option, i) => {
                            return (
                              <option className="text-capitalize" key={i} value={STAFF_ROLE[option]}>
                                {removeSpecialCharacter(option)}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"role"} /></span>
                    </div>
                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='files' className='text-left d-flex'>Profile:</label>
                      {/* <input className='form-control imgInput' id="files" name="files" accept="image/*" type="file" onChange={(event) => {
                        setFieldValue("files", event.currentTarget.files[0] || "");
                        { event.currentTarget.files.length === 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                      }} /> */}
                      <input className='form-control imgInput' id="files" name="files" accept="image/*" type="file" onChange={(event) => {
                          setFieldValue("files", event.currentTarget.files[0] || "");
                          if (event.currentTarget.files.length === 1) {
                              setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]));
                          } else {
                              setPreviewImage("");
                          }
                        }} 
                      />

                      {/* <span className='text-danger d-flex text-left' id="errortext">{errors.files && touched.files && errors.files}</span> */}
                    </div>
                    {
                      previewimage ?
                        <div className='mb-4'>
                          <img src={previewimage} style={{ height: "100px" }} alt={""} />
                        </div> : ""
                    }
                    {/* DYNAMIC OPTION CODE START FOR EVENT OPTIONS */}
                    {/* <div className="col-lg-12 mb-2">
                      <div>
                        <h6 className="main-content-label mb-3">Add Dates</h6>
                      </div>
                      <FieldArray
                        name="timetable"
                        render={arrayHelpers => (
                          <div className='row'>
                            {values.timetable && values.timetable.length > 0 ? (
                              values.timetable.map((item, index) => (
                                <div className='col-lg-12' key={index}>
                                  <div className='w-100 dynamic_options' key={index}>
                                    <div className='row'>
                                      <div className='col-md-3 text-center form-group'>
                                        <label htmlFor='timetable[${index}].title' className='text-left d-flex'>Title:<span className="requirestar">*</span> </label>
                                        <input type='text'
                                          className='form-control'
                                          name={`timetable[${index}].title`}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        <span className='text-danger d-flex text-left'><ErrorMessage name={`timetable[${index}].title`} /></span>
                                      </div>
                                      <div className='col-md-3 text-center form-group'>
                                        <label htmlFor='timetable[${index}].venue_id' className='text-left d-flex'>Venue:<span className="requirestar">*</span> </label>
                                        <Field as='select' id="timetable[${index}].venue_id" name={`timetable[${index}].venue_id`} className="form-control select2">
                                          <option value="" label="Select">Select{" "}</option>
                                          {venueList && venueList.length > 0 && venueList.map((option, i) => {
                                            return (
                                              <option key={i} value={option._id}>
                                                {option.title}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                        <span className='text-danger d-flex text-left'><ErrorMessage name={`timetable[${index}].venue_id`} /></span>
                                      </div>

                                      <div className='col-md-3 text-center form-group'>
                                        <label htmlFor='timetable[${index}].workshop_id' className='text-left d-flex'>Workshop:<span className="requirestar">*</span> </label>
                                        <Field as='select' id="timetable[${index}].workshop_id" name={`timetable[${index}].workshop_id`} className="form-control select2">
                                          <option value="" label="Select">Select{" "}</option>
                                          {workshopList && workshopList.length > 0 && workshopList.map((option, i) => {
                                            return (
                                              <option key={i} value={option._id}>
                                                {option.title}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                        <span className='text-danger d-flex text-left'><ErrorMessage name={`timetable[${index}].workshop_id`} /></span>
                                      </div>

                                      <div className='col-md-3 text-center form-group'>
                                        <label htmlFor='timetable[${index}].ticket_id' className='text-left d-flex'>Ticket:<span className="requirestar">*</span> </label>
                                        <Field as='select' id="timetable[${index}].ticket_id" name={`timetable[${index}].ticket_id`} className="form-control select2">
                                          <option value="" label="Select">Select{" "}</option>
                                          {ticketlist && ticketlist.length > 0 && ticketlist.map((option, i) => {
                                            return (
                                              <option key={i} value={option._id}>
                                                {option.title}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                        <span className='text-danger d-flex text-left'><ErrorMessage name={`timetable[${index}].ticket_id`} /></span>
                                      </div>

                                      <div className='col-md-3 text-center form-group'>
                                        <label htmlFor='match_date' className='text-left d-flex'>Start Date:<span className="requirestar">*</span> </label>
                                        <CustomDatetimepicker setField={setFieldValue} fieldname={`timetable[${index}].start_date`} placeholder={"Start"} setFieldTouched={setFieldTouched} />
                                        <span className='text-danger d-flex text-left'><ErrorMessage name={`timetable[${index}].start_date`} /></span>
                                      </div>
                                      <div className='col-md-3 text-center form-group'>
                                        <label htmlFor='start_time' className='text-left d-flex'>Start Time:<span className="requirestar">*</span> </label>
                                        <CustomTimepicker setField={setFieldValue} fieldname={`timetable[${index}].start_time`} placeholder={"Start-time"} setFieldTouched={setFieldTouched} />
                                        <span className='text-danger d-flex text-left'><ErrorMessage name={`timetable[${index}].start_time`} /></span>
                                      </div>
                                      <div className='col-md-3 text-center form-group'>
                                        <label htmlFor='end_time' className='text-left d-flex'>End Time:<span className="requirestar">*</span> </label>
                                        <CustomTimepicker setField={setFieldValue} fieldname={`timetable[${index}].end_time`} placeholder={"End-Time"} setFieldTouched={setFieldTouched} />
                                        <span className='text-danger d-flex text-left'><ErrorMessage name={`timetable[${index}].end_time`} /></span>
                                      </div>
                                      <div className='col-md-3 text-center form-group'>
                                        <label className='text-left d-flex' style={{ opacity: 0 }}>Actions</label>
                                        <div className="text-center form-group">
                                          <div className='d-flex'>
                                            {
                                              values.timetable.length > 1 ?
                                                <button
                                                  type="button"
                                                  className="ml-2 op_button danger"
                                                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                >
                                                  <i className="ti-close"></i>
                                                </button>
                                                : ""
                                            }
                                            {
                                              index == (values.timetable.length - 1) ?
                                                <button
                                                  type="button"
                                                  role='button'
                                                  className="ml-2 op_button success disabled"
                                                  onClick={() => arrayHelpers.push({ title: "", venue_id: "", workshop_id: "", ticket_id: "", start_date: "", start_time: "", end_time: "" })}  // push an empty object in an array
                                                  disabled={!item.title || !item.venue_id || !item.workshop_id || !item.start_date || !item.start_time || !item.end_time ? true : ""}
                                                ><i className="ti-plus"></i>
                                                </button>
                                                : ""
                                            }
                                          </div></div>

                                      </div>
                                    </div>
                                  </div>
                                </div>

                              ))
                            ) : null}
                          </div>
                        )}
                      />
                    </div> */}
                    {/* Dynamic option end */}

                    {/* <div className='col-md-12 text-center form-group'>
                      <label htmlFor='short_desc' className='text-left d-flex'>Short Description:<span className="requirestar">*</span></label>
                      <textarea name='short_desc' type='text' id="short_desc" onChange={handleChange} onBlur={handleBlur} value={values.short_desc} className='form-control' />
                      <span className='text-danger d-flex text-left'>{errors.short_desc && touched.short_desc && errors.short_desc}</span>
                    </div>

                    <div className='col-lg-12 text-center form-group'>
                      <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                      <CustomCkeditor
                        fieldname={"richtext"}
                        setFieldValue={setFieldValue}
                        value={values.richtext}
                        setFieldTouched={setFieldTouched}
                      />
                      <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                    </div>

                    <div className='col-lg-12 text-center form-group'>
                      <label htmlFor='files' className='text-left d-flex'>Image:<span className="requirestar">*</span> </label>
                      <input className='form-control imgInput' id="files" name="files" accept="image/*" type="file" onChange={(event) => {
                        setFieldValue("files", event.currentTarget.files[0] || "");
                        { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                      }} />
                      <span className='text-danger d-flex text-left' id="errortext">{errors.files && touched.files && errors.files}</span>
                    </div>
                    {
                      previewimage ?
                        <div className='mb-4'>
                          <img src={previewimage} style={{ height: "100px" }} />
                        </div> : ""
                    } */}

                    {/* multiple images */}
                    {/* <input
                      style={{ display: 'none' }}
                      ref={inputRef}
                      id="imagesfiles"
                      multiple name="imagesfiles"
                      accept="image/*" type="file"
                      onChange={(event) => {
                        setFieldValue("imagesfiles", event.currentTarget.files)
                      }} />
                    <div className="ff_fileupload_wrap" onClick={() => handleClick()}>
                      <div className="ff_fileupload_dropzone_wrap">
                        <label className='text-left d-flex' htmlFor="newimagesfiles">Multiple images:</label>
                        <button type='button' className='ff_fileupload_dropzone'></button>
                      </div>
                    </div>
                    <div className='mt-3'>
                      {values.imagesfiles.length > 0 &&
                        Object.values(values.imagesfiles).map((file) => (
                          <img
                            key={file.name}
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            style={{ height: "100px" }}
                            className="preview-image mx-2"
                            onClick={() => handleImageDelete(values.imagesfiles, file.name, setFieldValue)}
                          />
                        ))}
                    </div> */}
                    {/* multiple image end */}
                    <div className="mt-5" >
                      <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                        <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                        Submit
                      </button>
                      <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                        <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      </Formik>
      ) : (
        <></>
      )}
    </>
  )
}

export default StaffAdd