import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './slices/profileslice';
import globalLoaderSlice from './slices/globalLoader';
import userTypeSlice from './slices/userType';
import DesignPortalSlice from './slices/designportal';
import GlobalDetailsSlice from './slices/globalDetails';
import globalDataSlice from './slices/globalData';

const store = configureStore({
    reducer: {
        profile: profileReducer,
        globalData: globalDataSlice,
        user:userTypeSlice,
        globalLoader: globalLoaderSlice,
        designportal: DesignPortalSlice,
        globaldetail: GlobalDetailsSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default store;

