import axiosInstance from "../../../utils/axios";
const path = "admin/gallery"

export const List = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const Add = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}

export const Delete = async (values) => {
    return await axiosInstance().post(`${path}/delete`, values);
}