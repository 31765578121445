import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalKey} from '../../../utils/commonfunction';
import CheckPermission from './checkPermission';

const PrivateRoute = ({ component: Component, route }) => {
    const userData = getLocalKey("user") ? JSON.parse(getLocalKey("user")) : {};
    localStorage.setItem("i18nextLng","en");
    localStorage.setItem("system_language","en");
    return (
        <>
            {(function(){
                if(Object.keys(userData).length && userData.role !== 3){
                    return <CheckPermission Component={Component} route={route}/>
                }else{
                    return <Navigate to="/admin/login" />;
                }
            })()}
        </>
    )
}

export default PrivateRoute;