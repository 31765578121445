import React from 'react'

export default function GlobalLoader() {
    return (
        <>
            <div id="global-loader">
                <div className="text-center">
                    <div className="lds-ripple"><div></div><div></div></div>
                </div>
            </div>
        </>
    )
}
