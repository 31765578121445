import React, { useEffect, useRef, useState } from 'react'
import { ErrorMessage, Field, Formik } from 'formik'
import Swal from "sweetalert2"
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrums from '../../common/breadcrumbs';
import { ANNOUNCEMENT_TYPES, SWAL_SETTINGS } from '../../../../utils/Constants';
import { handleServerValidations, hasPermission, } from '../../../../utils/commonfunction';
import * as announcementsService from '../../services/announcements.services'
import * as Yup from 'yup';
import { removeSpecialCharacter } from '../../../../utils/commonfunction';
import * as eventServices from '../../services/events.services'
import CustomCkeditor from '../../common/customeditor';


const AnnouncementsEdit = () => {
    let formikRef = useRef();
    const navigate = useNavigate()
    const [eventOptions, setEventOptions] = useState([]);
    const params = useParams();
    const [showdefault, setShowDefault] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [saveType, setSaveType] = useState('')

    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Announcements", url: "/admin/announcements/list/1" }, { title: "Edit", url: "" }]

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Title is required").test("no-spaces", "Title is required", (value) => value.trim()),
        description: Yup.string().required("Description is required").test("no-spaces", "Description is required", (value) => value.trim()),
        announcement_type: Yup.string().required("Announcement Type is required"),
        event_id: Yup.string().test('announcement_type', 'Event Name is required', (value) => {
            if (formikRef.current.values.announcement_type === 'event') {
                return Boolean(value)
            } else {
                return true;
            }
        }),
    });

    useEffect(() => {
        announcementsService.Details(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : [])
            setTimeout(() => {
                setDataLoaded(true);
            }, 100)
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    useEffect(() => {
        eventServices.Options().then((response) => {
            if (response.success) {
                setEventOptions(response?.data);
            }
        }).catch((error) => {
            console.log("error", error)
        })
    }, [])

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    title: showdefault && showdefault?.title ? showdefault.title : "",
                    title_de: showdefault && showdefault?.title_de ? showdefault.title_de : "",
                    description: showdefault && showdefault?.description ? showdefault.description : "",
                    description_de: showdefault && showdefault?.description_de ? showdefault.description_de : "",
                    announcement_type: showdefault && showdefault?.announcement_type ? showdefault.announcement_type : "",
                    event_id: showdefault && showdefault?.event_data?._id ? showdefault.event_data?._id : "",

                }}
                innerRef={formikRef}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    let formData = new FormData();
                    formData.append('o_id', params.id)
                    formData.append('title_de', values.title_de);
                    formData.append('title', values.title);
                    formData.append('description', values.description);
                    formData.append('description_de', values.description_de);
                    formData.append('announcement_type', values.announcement_type);
                    if (values.announcement_type === 'event') {
                        formData.append("event_id", values?.event_id);
                    }
                    announcementsService.Edit(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            if (saveType !== 'Save') {
                                setTimeout(() => {
                                    window.history.back();
                                }, 1000);
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            });
                        }
                    }).catch(error => {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(error),
                            ...SWAL_SETTINGS
                        })
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Edit Announcement</h6>
                                    </div>
                                    <div className="row row-sm">
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title' className='text-left d-flex'> Title:<span className="requirestar">*</span> </label>
                                            <input
                                                name='title'
                                                id='title'
                                                type='text'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.title}
                                                className='form-control' />
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"title"} /></span>
                                        </div>
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title_de' className='text-left d-flex'> Title In German:</label>
                                            <input
                                                name='title_de'
                                                id='title_de'
                                                type='text'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.title_de}
                                                className='form-control' />
                                            {/* <span className='text-danger d-flex text-left'><ErrorMessage name={"title_de"} /></span> */}
                                        </div>
                                        <div className='col-lg-6 text-center form-group'>
                                            <label htmlFor='slug' className='text-left d-flex'>Announcement Type<span className="requirestar">*</span></label>
                                            <div className="select-down-arrow ">
                                                <Field as='select' id="announcement_type" name={"announcement_type"} className="form-control select2">
                                                    <option value="" label="Select">Select{" "}</option>
                                                    {/* Always show the "event" option */}
                                                    {hasPermission('/admin/announcements/event') && (
                                                        <option value={ANNOUNCEMENT_TYPES.EVENT}>
                                                            {removeSpecialCharacter('EVENT')}
                                                        </option>
                                                    )}
                                                    {/* Conditionally render the "general" option based on permissions */}
                                                    {hasPermission('/admin/announcements/general') && (
                                                        <option value={ANNOUNCEMENT_TYPES.GENERAL}>
                                                            {removeSpecialCharacter('GENERAL')}
                                                        </option>
                                                    )}
                                                </Field>
                                            </div>
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"announcement_type"} /></span>
                                        </div>
                                        {values.announcement_type === ANNOUNCEMENT_TYPES.EVENT ?
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='slug' className='text-left d-flex'>Select Event:<span className="requirestar">*</span></label>
                                                <div className="select-down-arrow ">
                                                    <select
                                                        className="form-control select2"
                                                        value={values.event_id}
                                                        onChange={(e) => setFieldValue('event_id', e.target.value)}
                                                    >
                                                        <option value="" label="Select">
                                                            Select{" "}
                                                        </option>
                                                        {eventOptions &&
                                                            eventOptions.length > 0 &&
                                                            eventOptions.map((option, i) => {
                                                                return (
                                                                    <option key={i} value={option._id}>
                                                                        {option.title}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                </div>
                                                <span className='text-danger d-flex text-left'><ErrorMessage name={"event_id"} /></span>
                                            </div> : <></>}

                                        <div className='col-lg-12 text-center form-group'>
                                            <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                                            {
                                                dataLoaded ?
                                                    <CustomCkeditor
                                                        fieldname={"description"}
                                                        setFieldValue={setFieldValue}
                                                        value={values.description}
                                                        setFieldTouched={setFieldTouched}
                                                    />
                                                    :
                                                    ""
                                            }
                                            <span className='text-danger d-flex text-left'>{errors.description && touched.description && errors.description}</span>
                                        </div>
                                        <div className='col-lg-12 text-center form-group'>
                                            <label htmlFor='subject' className='text-left d-flex'>Description In German:</label>
                                            {
                                                dataLoaded ?
                                                    <CustomCkeditor
                                                        fieldname={"description_de"}
                                                        setFieldValue={setFieldValue}
                                                        value={values.description_de}
                                                        setFieldTouched={setFieldTouched}
                                                    />
                                                    :
                                                    ""
                                            }
                                            {/* <span className='text-danger d-flex text-left'>{errors.description_de && touched.description_de && errors.description_de}</span> */}
                                        </div>
                                        <div className="mt-5" >
                                            <button onClick={() => { setSaveType('Save') }} className="btn btn-info mr-2" type="submit" >
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Save
                                            </button>
                                            <button onClick={() => { setSaveType('') }} className="btn btn-success mr-2" type="submit">
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Save & Exit
                                            </button>
                                            <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>
    )
}

export default AnnouncementsEdit;