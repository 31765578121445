import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
    Loader,
} from "rsuite";
import { SWAL_SETTINGS } from "../../../../../utils/Constants";
import { formateDate, handleServerValidations, hasPermission } from "../../../../../utils/commonfunction";
import CustomPagination from "../../../common/custompagination";
import Breadcrums from "../../../common/breadcrumbs";
import { DistributionListUsers, UsersAdd } from "../../../services/distributionlist.services";
import Swal from "sweetalert2";
import CustomRangepicker from "../../../common/rangepicker";

const DistributionUsersList = () => {

    const params = useParams();
    const [statsupdate] = useState("false");
    const [datalength, setDataLength] = useState(0);
    const [loader, setLoader] = useState(true);
    const breadcrumbs = [
        { title: "Dashboard", url: "/admin/dashboard" },
        { title: "Distribution List", url: "/admin/distribution-list/list/1" },
        { title: "Edit", url: `/admin/distribution-list/${params?.pgno}/edit/${params?.id}?page=1` },
        { title: "Select Users", url: "" }
    ];
    const [userData, setUserData] = useState([]);
    const [itemperpage] = useState(10);
    const [refreshList, setRefreshList] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [search, setSearch] = useState({});
    const [globalsearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false);
    /********** MULTI SELECT >>>>>>********* */
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [selectedAllData, setSelectedAllData] = useState(false);
    const selectAllCheckbox = useRef();
    /********** MULTI SELECT <<<<<<********* */



    useEffect(() => {
        let formData = new FormData();
        formData.append("per_page", itemperpage);
        formData.append("search", JSON.stringify(search));
        formData.append("global_search", globalsearch.trim());
        formData.append("page", page);
        formData.append("distribution_id", params?.id)
        DistributionListUsers(formData)
            .then((response) => {
                setDataLength(response?.data?.total_records);
                setUserData(response && response.data && response.data.list ? response.data.list : []);
                setLoader(false);
                setSelectedAllData(response && response.data && response.data.data_ids ? response.data.data_ids : []);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, [globalsearch, search, statsupdate, page, refreshList,itemperpage,params?.id]);

    /********** MULTI SELECT >>>>>>********* */
    useEffect(() => {
        if (selectedRecords.length === datalength) {
            selectAllCheckbox.current.indeterminate = false;
            selectAllCheckbox.current.checked = true;
        } else if (selectedRecords.length) {
            selectAllCheckbox.current.indeterminate = true;
        } else {
            selectAllCheckbox.current.indeterminate = false;
        }
    }, [selectedRecords]);
    const selectRecord = (userData, check) => {
        if (check) {
            setSelectedRecords((previousState) => [...previousState, userData._id]);
        } else {
            setSelectedRecords((previousState) => previousState.filter((item) => item !== userData._id));
        }
    };
    const handleSelectAll = (check) => {
        if (check) {
            setSelectedRecords(selectedAllData);
        } else {
            setSelectedRecords([]);
        }
    };
    const isSelected = (data) => {
        return (selectedRecords.filter((item) => data?._id === item).length > 0);
    };
    /********** MULTI SELECT <<<<<<<<<********* */

    const handleClick = () => {
        try {
            let formData = new FormData();
            formData.append("distribution_id", params?.id);
            formData.append("user_ids", JSON.stringify(selectedRecords));
            UsersAdd(formData).then((response) => {
                if (response?.success) {
                    Swal.fire({
                        icon: "success",
                        text: response?.message,
                        ...SWAL_SETTINGS,
                    });
                    setRefreshList(!refreshList);
                    navigate(`/admin/distribution-list/${params?.pgno}/edit/${params?.id}?page=1`)
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS,
                    });
                }
            }).catch((err) => {
                Swal.fire({
                    icon: "error",
                    text: err?.message,
                    ...SWAL_SETTINGS,
                });
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    };

    const resetFilter = (e) => {
        e.preventDefault();
        setGlobalSearch("");
        setSearch({});
        setResetDate(!resetdate);
    };

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="animation_fade">
                <div className="card custom-card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h6 className="main-content-label">Select Users</h6>
                            <div className="d-flex align-items-center">
                                <div className="form-group mb-0 me-3">
                                    <div className="form-group mb-0 rangepicker_container filter_design">
                                        <i className="fa fa-search calender_icon"></i>
                                        <input
                                            type="search"
                                            className="form-control"
                                            value={globalsearch}
                                            placeholder="Search"
                                            onChange={(e) => {
                                                setGlobalSearch(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="me-3">
                                    <CustomRangepicker
                                        GetDateRange={(e) => {
                                            prepareSearch("createdAt", e);
                                        }}
                                        resetdate={resetdate}
                                    />
                                </div>
                                <button
                                    type="reset"
                                    value="Reset"
                                    onClick={resetFilter}
                                    className="btn btn-warning float-right mr-2"
                                >
                                    Reset Filter
                                </button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                <thead>
                                    <tr>
                                        <th className={"position-relative select_head " + (hasPermission('/admin/distribution-list/multi-select') ? '' : "d-none")}>
                                            <div className="select-check-container">
                                                <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                                                    className="ckbox  cp">
                                                    <input
                                                        ref={selectAllCheckbox}
                                                        id="selectAllCheck"
                                                        checked={
                                                            datalength && datalength === selectedRecords.length
                                                        }
                                                        onChange={(e) => handleSelectAll(e.target.checked)} type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={4}><Loader /></td>
                                        </tr> : <>
                                            {userData.length ? userData.map((item, i) => {
                                                return <tr
                                                    key={i}
                                                    className={isSelected(item) ? "selected" : ""}
                                                >
                                                    <td className={"position-relative " + (hasPermission('/admin/distribution-list/multi-select') ? '' : "d-none")}>
                                                        <div className="select-check-container">
                                                            <label htmlFor={item?._id + "input"} style={{ lineHeight: 'unset' }}
                                                                className="ckbox cp">
                                                                <input
                                                                    id={item?._id + "input"}
                                                                    checked={isSelected(item)}
                                                                    onChange={(e) =>
                                                                        selectRecord(item, e?.target?.checked)
                                                                    }
                                                                    type="checkbox"
                                                                    className="form-check select-check cp" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td className="text-capitalize">{item?.name ? <Link to={`/admin/user-management/users/view/${item?._id}?page=1&chat_page=1`}>{item.name}</Link> : "N/A"}</td>
                                                    <td>{item?.email ? <Link to={`/admin/user-management/users/view/${item?._id}?page=1&chat_page=1`}>{item.email}</Link> : "N/A"}</td>
                                                    <td>{item?.createdAt ? formateDate(item.createdAt) : "N/A"}</td>
                                                </tr>
                                            }) : <tr><td colSpan={4} className="text-center">No records found in this list</td></tr>}</>}
                                </tbody>
                            </table>
                        </div>
                        <div
                            className=""
                            id="example1_info"
                            role="status"
                            aria-live="polite"
                        >
                            <b>Total Records : {datalength ? datalength : "0"}</b>
                        </div>
                        {datalength && datalength > 0 ? (
                            <CustomPagination
                                datalength={datalength}
                                itemperpage={itemperpage}
                                currentPage={page}
                                setPage={setPage}
                                pageRoute={[
                                    { name: "Distribution Users List", path: `/admin/distribution-list/users/list/${params.id}` },
                                ]}
                            />
                        ) : (
                            ""
                        )}
                        <div className="d-flex justify-content-between align-items-center mt-5">
                            <h6 className="ms-2 mt-1 mb-0">{selectedRecords.length ? "Selected " + selectedRecords.length + " of records " + datalength : ""}</h6>
                            <div>
                                <button className="btn ripple btn-main-primary signbtn mr-2 ms-2"
                                    disabled={selectedRecords.length === 0}
                                    onClick={() =>  handleClick()}
                                    appearance="primary"
                                    type="button"
                                >
                                    Add Users To The List
                                </button>

                            </div>
                        </div>
                        <div className="mt-5">
                            <button className="btn ripple btn-dark" type='button' onClick={() => navigate(`/admin/distribution-list/${params?.pgno}/edit/${params?.id}?page=1`)}>
                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DistributionUsersList;




