import React, { useEffect, useState } from 'react'
import { Field, Formik } from 'formik'
import Swal from "sweetalert2"
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrums from '../../common/breadcrumbs';
import CustomCkeditor from '../../common/customeditor';
import * as questionSetServices from '../../services/questionset';
import { SURVEY_TYPES, SWAL_SETTINGS } from '../../../../utils/Constants';
import { handleServerValidations, hasPermission, removeSpecialCharacter } from '../../../../utils/commonfunction';


const QuestionSetEdit = (props) => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false);
    const [saveType, setSaveType] = useState('')
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Question Sets", url: "/admin/question-settings/sets/list/1" }, { title: "Edit", url: "" }]


 
    useEffect(() => {
        questionSetServices.Details(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : [])
            setTimeout(() => {
                setDataLoaded(true);
            },100)
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [params.id])


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    title: showdefault && showdefault.title ? showdefault.title : "",
                    title_de: showdefault && showdefault.title_de ? showdefault.title_de : "",
                    type: showdefault && showdefault.type ? showdefault.type : "",
                    richtext: showdefault && showdefault.description ? showdefault.description : "",
                    richtext_de: showdefault && showdefault.description_de ? showdefault.description_de : "",
                }}
                validate={values => {
                    const error = {};
                    if (!values.title) error.title = "Title is required";
                    if (!values.type) error.type = "Type is required";
                    if (!values.richtext) error.richtext = "Description is required";
                    return error;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    console.log("values",values)
                    let formData = new FormData();
                    formData.append('o_id', params.id)
                    formData.append('title', values.title);
                    formData.append('title_de', values.title_de);
                    formData.append('type', values.type);
                    formData.append('description', values.richtext);
                    formData.append('description_de', values.richtext_de);
                    questionSetServices.Edit(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            if(saveType !=='Save'){
                            setTimeout(() => {
                            navigate(`/admin/question-settings/sets/list/${params.pgno}/`)
                                }, 1000);
                                }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                        }
                    }).catch(error => {
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Edit Question Type</h6>
                                    </div>
                                    <div className="row row-sm">
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span> </label>
                                            <input name='title' id='title' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.title && touched.title && errors.title}</span>
                                        </div>
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title_de' className='text-left d-flex'>Title In German:</label>
                                            <input name='title_de' id='title_de' type='text' onChange={handleChange} onBlur={handleBlur} value={values.title_de} className='form-control' />
                                            {/* <span className='text-danger d-flex text-left'>{errors.title_de && touched.title_de && errors.title_de}</span> */}
                                        </div>
                                        <div className="col-lg-6 text-center form-group">
                                            <label htmlFor='type' className='text-left d-flex'>Type:<span className="requirestar">*</span> </label>
                                            <div className="form-group select-down-arrow">
                                                <Field as='select' id="type" name={"type"} className="form-control select2 text-capitalize">
                                                    <option value="" label="Select">Select{" "}</option>
                                                    {hasPermission('/admin/question-settings/sets/question-set') && (
                                                            <option value={SURVEY_TYPES.QUESTION_SET}>
                                                                {removeSpecialCharacter('Question-Set')}
                                                            </option>
                                                        )}
                                                        {hasPermission('/admin/question-settings/sets/survey') && (
                                                            <option value={SURVEY_TYPES.SURVEY}>
                                                                {removeSpecialCharacter('Survey')}
                                                            </option>
                                                        )}
                                                </Field>
                                            </div>
                                            <span className='text-danger d-flex text-left'>{errors.type && touched.type && errors.type}</span>
                                        </div>
                                        <div className='col-lg-12 text-center form-group'>
                                            <label htmlFor='subject' className='text-left d-flex'>Description (En):<span className="requirestar">*</span></label>
                                            {
                                                dataLoaded ?
                                                <CustomCkeditor
                                                    fieldname={"richtext"}
                                                    setFieldValue={setFieldValue}
                                                    value={values.richtext}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                                :
                                                ""
                                            }
                                            <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                                        </div>
                                        <div className='col-lg-12 text-center form-group'>
                                            <label htmlFor='subject' className='text-left d-flex'>Description In German (En):</label>
                                            {
                                                dataLoaded ?
                                                <CustomCkeditor
                                                    fieldname={"richtext_de"}
                                                    setFieldValue={setFieldValue}
                                                    value={values.richtext_de}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                                :
                                                ""
                                            }
                                            {/* <span className='text-danger d-flex text-left'>{errors.richtext_de && touched.richtext_de && errors.richtext_de}</span> */}
                                        </div>
                                        <div className="">
                                            <button onClick={()=>{setSaveType('Save')}} className="btn btn-info mr-2" type="submit" >
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Save
                                            </button>
                                            <button  onClick={()=>{setSaveType('')}} className="btn btn-success mr-2" type="submit">
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Save & Exit
                                            </button>
                                            <button className="btn ripple btn-secondary" type='button'  onClick={() => navigate(-1)}>
                                                <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    {/* // : "Loader"} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>
    )
}

export default QuestionSetEdit