import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as eventsService from "../../services/events.services";
import * as emailTemplateServices from "../../services/email.template";
import CustomPagination from "../../common/custompagination";
import Sorting from "../../common/sorting";
import Loader from "../../common/loader";
import Breadcrums from "../../common/breadcrumbs";
import $ from "jquery";
import {
  SWAL_SETTINGS,
  EMAIL_TEMPLATE_TYPES,
} from "../../../../utils/Constants";
import {
  formatDateFromTime,
  globalLoader,
  handleServerValidations,
  hasPermission
} from "../../../../utils/commonfunction";
import CustomRangepicker from "../../common/rangepicker";
import {
  Modal,
  Button,
  Tag,
  TagGroup,
} from "rsuite";
import { Options } from "../../services/email.template";
import { List } from "../../services/usermgmt.services";

const EventsTable = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Events", url: "" },
  ];
  const [resetdate, setResetDate] = useState(false);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [inviteUsersRefreshList, setInviteUsersRefreshList] = useState();
  const [searchData, setSearchData] = useState("");
  const [selectedModal, setSelectedModal] = useState(false);
  const [emailOptions, setEmailOptions] = useState([]);
  const [eventId] = useState("");
  const [slug, setSlug] = useState("");
  const [id, setId] = useState("");
  const [previewTemplate, setPreviewTemplate] = useState(false);
  const [previewData, setPreviewData] = useState(false);
  /********** MULTI SELECT >>>>>>********* */
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedAllData, setSelectedAllData] = useState(false);
  const selectAllCheckbox = useRef();
  /********** MULTI SELECT <<<<<<********* */

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      eventsService
        .List(formData)
        .then((data) => {
          console.log("data", data);
          setDataLength(data.data.total_records);
          setList(data && data.data && data.data.list ? data.data.list : []);
          /********** MULTI SELECT >>>>>>********* */
          setSelectedAllData(
            data && data.data && data.data.data_ids ? data.data.data_ids : []
          );
          /********** MULTI SELECT <<<<<<********* */
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [location, statsupdate, sorting, search, globalsearch,itemperpage,params.pgno]);

  useEffect(() => {
    Options({ type: EMAIL_TEMPLATE_TYPES.PROMOTIONAL })
      .then((response) => {
        setEmailOptions(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    let obj = {};
    if (searchData) {
      obj.global_search = searchData;
    }
    List(obj)
      .then((response) => {
        setUserData(response?.data?.list);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [inviteUsersRefreshList]);

  const viewfunction = (row) => {
    navigate(`/admin/event-management/events/view/event/${row._id}`,{state:{row}});
  };

  const goToEdit = (row) => {
    navigate(`/admin/event-management/events/edit/${row._id}`);
  };


  useEffect(() => {
    if(previewTemplate){
      emailTemplateServices.Details(id).then((response) => {
        if(response.success){
          setPreviewData(response?.data)
        }
      }).catch((error) => {
        console.log("error",error);
      })
    }
  },[id,previewTemplate]);

  const previewEmailTemplate = () => {
    setPreviewTemplate(true);
  }

  function Deletefunction(data) {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let ids = Array.isArray(data) ? data : [data];
        const formdata = new FormData();
        formdata.append("o_id", JSON.stringify(ids));
        eventsService
          .Delete(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
                /** removing deleted ids from selectedRecords */
                setSelectedRecords((data) => data.filter((item) => {
                  return ids.indexOf(item) === -1;
                }));
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  }

  // sorting function start
  const handleSort = (e, column) => {
    setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  //On send function
  const onSendClick = () => {
    globalLoader(true);
    setSelectedModal(false);
    setSelectedUser([]);
    document.getElementById("reader_id").reset();
    // console.log("Dattaa", eventId, selectedUser, slug);
    eventsService
      .InviteUsers({
        event_id: eventId,
        users: JSON.stringify(selectedUser),
        email_slug: slug,
      })
      .then((response) => {
        globalLoader(false);
        if (response?.success) {
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: response.message,
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        globalLoader(false);
        Swal.fire({
          icon: "error",
          text: error,
          ...SWAL_SETTINGS,
        });
      });
  };

  //Handle user selection
  const selectUser = (userData, check) => {
    if (check) {
      setSelectedUser((previousState) => [...previousState, userData]);
    } else {
      setSelectedUser((previousState) =>
        previousState.filter((item) => item !== userData)
      );
    }
  };

  const isSelected = (item) => {
    return selectedUser.filter((data) => item?._id === data._id).length > 0;
  };

  const searchUsersForInvite = (e) => {
    if (e.target.value.trim()) {
      setSearchData(e.target.value);
      setInviteUsersRefreshList(new Date().getTime());
    } else {
      setSearchData("");
      setInviteUsersRefreshList(new Date().getTime());
    }
  };

  const onOkayClick = () => {
    setOpen(false);
    setSelectedModal(true);
  };

  const selectRow = (item) => {
    let id = item?._id;
    $("#" + id + "label").trigger("click");
  };

  const readExcelFile = (e) => {
    console.log("e.target.files[0]", e.target.files[0]);
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      eventsService
        .ReadExcel(formData)
        .then((response) => {
          if (response.success) {
            setSelectedModal(true);
            console.log(response);
            setSelectedUser(response?.data);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          Swal.fire({
            icon: "error",
            text: handleServerValidations(error),
            ...SWAL_SETTINGS,
          });
        });
    }
  };

  // search or filter function
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer end

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    // reset customrangepicker & customstatusfilter state using jquery//
    setResetDate(!resetdate);
    // $("#defaultstatus")[0].selectedIndex = 0;
  };

  /********** MULTI SELECT >>>>>>********* */
  useEffect(() => {
    if (selectedRecords.length === datalength) {
      selectAllCheckbox.current.indeterminate = false;
      selectAllCheckbox.current.checked = true;
    } else if (selectedRecords.length) {
      selectAllCheckbox.current.indeterminate = true;
    } else {
      selectAllCheckbox.current.indeterminate = false;
    }
  }, [selectedRecords]);
  const selectRecord = (userData, check) => {
    if (check) {
      setSelectedRecords((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedRecords((previousState) =>
        previousState.filter((item) => item !== userData._id)
      );
    }
  };
  const handleSelectAll = (check) => {
    if (check) {
      setSelectedRecords(selectedAllData);
    } else {
      setSelectedRecords([]);
    }
  };
  const isSelectedRecords = (data) => {
    return selectedRecords.filter((item) => data?._id === item).length > 0;
  };
  /********** MULTI SELECT <<<<<<<<<********* */

  const goToDuplicate = (row) => {
    navigate(`/admin/event-management/events/add`, {state: row});
  };

  const livePreview = (row) => {
    window.open(`/event-preview/${row.slug}`);
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {/* table section */}
      <div className="animation_fade">
        <div className="card custom-card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="main-content-label">EVENTS</h6>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0 me-3">
                  <div className="form-group mb-0 rangepicker_container filter_design">
                    <i className="fa fa-search calender_icon"></i>
                    <input
                      type="search"
                      className="form-control"
                      value={globalsearch}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="me-3">
                  <CustomRangepicker
                    GetDateRange={(e) => {
                      prepareSearch("createdAt", e);
                    }}
                    resetdate={resetdate}
                  />
                </div>
                <button
                  type="reset"
                  value="Reset"
                  onClick={resetFilter}
                  className="btn btn-warning float-right mr-2"
                >
                  Reset Filter
                </button>
                {hasPermission('/admin/event-management/events/button') ?

                <button
                  className="btn ripple btn-main-primary signbtn"
                  onClick={() => navigate(`/admin/event-management/events/add`)}
                >
                  Add New
                </button>
                :null}
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                <thead>
                  <tr>
                    <th className={"position-relative select_head " + (hasPermission('/admin/event-management/events/multi-select') ? '': "d-none")}>
                      <div className="select-check-container">
                      <label htmlFor="selectAllCheck" style={{lineHeight:'unset'}}
                          className="ckbox  cp">
                          <input
                          ref={selectAllCheckbox}
                          id="selectAllCheck"
                          checked={
                            datalength && datalength === selectedRecords.length
                          }
                          onChange={(e) => handleSelectAll(e.target.checked)} type="checkbox"/>
                          <span></span>
                        </label>
                      </div>
                    </th>
                    <th>
                      <div className="sorting_column">
                        <span>Title</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="title"
                        />
                      </div>
                    </th>
                    <th>Image</th>
                    <th>Slug</th>
                    <th>
                      <div className="sorting_column">
                        <span>Start Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          defaultSorting={defaultSorting}
                          column="start_date"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="sorting_column">
                        <span>End Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          defaultSorting={defaultSorting}
                          column="end_date"
                        />
                      </div>
                    </th>
                    <th>Pay Status</th>
                    <th>
                      <div className="sorting_column">
                        <span>Created Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          defaultSorting={defaultSorting}
                          column="createdAt"
                        />
                      </div>
                    </th>
                    
                    <th>Created By</th>
                    <th>Updated By</th>
               
                    <th className="action_head">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={11}>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {list.length ? (
                        list.map((row, i) => {
                          return (
                            <tr className={isSelectedRecords(row) ? "selected" : ""} key={i} >
                              <td className={"position-relative " + (hasPermission('/admin/event-management/events/multi-select') ? '': "d-none")}>
                                <div className="select-check-container">
                                <label htmlFor={row?._id + "input"} style={{ lineHeight: 'unset' }}
                                    className="ckbox cp">
                                    <input
                                      id={row?._id + "input"}
                                      checked={isSelectedRecords(row)}
                                      onChange={(e) =>
                                        selectRecord(row, e?.target?.checked)
                                      }
                                      type="checkbox"
                                      className="form-check select-check cp" />
                                    <span></span>
                                  </label>
                                </div>
                              </td>
                         
                              <td>{row?.title ? row?.title : "N/A"}</td>
                              <td>
                                {row?.image ? (
                                  <div className="row_image">
                                    <img className="rounded" src={row?.image} alt={row?.title} />
                                  </div>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>{row?.slug ? row?.slug : "N/A"}</td>
                              
                              <td>
                              {row?.start_date
                                  ? formatDateFromTime(row?.start_date)
                                  : "N/A"}
                              </td>
                              <td>
                              {row?.end_date
                                  ? formatDateFromTime(row?.end_date)
                                  : "N/A"}
                              </td>
                              
                              
                              <td>
                              {row?.event_quota_data?.price === 0 ? <span className="badge badge-success">Free</span> : <span className="badge badge-danger">Paid</span>}
                              </td>
                              <td>
                                {row?.createdAt
                                  ? formatDateFromTime(row.createdAt)
                                  : "N/A"}
                              </td>
                              <td>{row?.createdBy?.name ? <Link to={`/admin/staff/view/${row.createdBy._id}`}>{row.createdBy.name}</Link>: "N/A"}</td>
                              <td>{row?.updatedBy?.name ? <Link to={`/admin/staff/view/${row.updatedBy._id}`}>{row.updatedBy.name}</Link>: "N/A"}</td>
                                <td>
                                <div className="d-flex">
                               
                                  <button
                                    className="btn ripple btn-main-primary signbtn"
                                    onClick={() => {
                                      viewfunction(row);
                                    }}
                                  >
                                    View
                                  </button>
                                  <button
                                    className="btn ripple btn-success mlAction"
                                    onClick={() => {
                                      goToEdit(row);
                                    }}
                                  >
                                    Edit
                                  </button>
                                  {hasPermission('/admin/event-management/events/delete') ?
                                  <button
                                    className="btn ripple btn-secondary mlAction"
                                    onClick={() => {
                                      Deletefunction(row?._id);
                                    }}
                                  >
                                    Delete
                                  </button>
                                  :null}
                                  <div className="btn-group mlAction">
                                  {hasPermission('/admin/event-management/events/more') ?
                                    <button
                                        type="button"
                                        className="btn btn-dark dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        More
                                    </button>
                                    :null}
                                    <ul className="dropdown-menu">
                                        <li>
                                            <button
                                            type="button"
                                            className="dropdown-item"
                                            onClick={() => goToDuplicate(row)}
                                            >
                                            Duplicate
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                            type="button"
                                            className="dropdown-item"
                                            onClick={() => livePreview(row)}
                                            >
                                            Live preview
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={11} className="text-center">
                            No records
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              id="example1_info"
              role="status"
              aria-live="polite"
            >
              <b>Total Records : {datalength ? datalength : "0"}</b>
            </div>
            {datalength && datalength > 0 ? (
              <CustomPagination
                datalength={datalength}
                itemperpage={itemperpage}
                currentPage={page}
                setPage={setPage}
                pageRoute={[
                  {
                    name: "Events",
                    path: "/admin/event-management/events/list",
                  },
                ]}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <Modal size={"lg"} open={open} onClose={() => setOpen(false)}>
          <Modal.Header className="mb-3">
            <Modal.Title>Select users</Modal.Title>
            <div className="form-group w-50 mt-4">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                onChange={searchUsersForInvite}
              />
            </div>
            <TagGroup>
              {selectedUser &&
                selectedUser.map((item, i) => (
                  <Tag key={i} closable onClose={() => selectUser(item, false)}>
                    {item?.name}
                  </Tag>
                ))}
            </TagGroup>
          </Modal.Header>
          <div className="clearfix"></div>
          <div className="w-100">
            <table className="table table-hover cp table-bordered border-t0 key-buttons text-nowrap w-100">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {userData &&
                  userData.map((item, i) => (
                    <tr
                      key={i}
                      onClick={() => selectRow(item)}
                      className={isSelected(item) ? "selected" : ""}
                    >
                      <td>
                        <label
                          id={item?._id + "label"}
                          className="d-none"
                          htmlFor={item?._id + "input"}
                        ></label>
                        <input
                          id={item?._id + "input"}
                          checked={isSelected(item)}
                          onChange={(e) => selectUser(item, e?.target?.checked)}
                          type="checkbox"
                          className="form-check"
                        />
                      </td>
                      <td className="text-capitalize">{item?.name}</td>
                      <td>{item?.email}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <Modal.Footer>
            <Button onClick={() => setOpen(false)} appearance="subtle">
              Cancel
            </Button>
            <Button
              disabled={selectedUser.length === 0}
              onClick={() => onOkayClick()}
              appearance="primary"
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size={"lg"}
          open={selectedModal}
          onClose={() => {
            setSelectedModal(false);
            document.getElementById("reader_id").reset();
            setSlug("");
            setId("");
            setPreviewTemplate(false);
          }}
        >
          <Modal.Header className="mb-3">
            <Modal.Title>Selected users: </Modal.Title>
            <TagGroup className="mt-2">
              {selectedUser &&
                selectedUser.map((item, i) => (
                  <Tag key={i} closable onClose={() => selectUser(item, false)}>
                    {item?.email}
                  </Tag>
                ))}
            </TagGroup>
            <div className="col-lg-6 text-center form-group ps-0 mt-3">
              <label className="text-left d-flex">Select Email Template:</label>
              <div className="form-group select-down-arrow">
                <select
                  className="form-control select2"
                  onChange={(e) => {
                    setSlug(e.target.value);
                    setId(e.target.options[e.target.options.selectedIndex].getAttribute("data-id"));
                    // console.log("e.target.options[e.target.options.selectedIndex].getAttribute",e.target.options[e.target.options.selectedIndex].getAttribute("data-id"));
                  }}
                >
                  <option value="" label="Select">
                    Select{" "}
                  </option>
                  {emailOptions &&
                    emailOptions.length > 0 &&
                    emailOptions.map((option, i) => {
                      return (
                        <option key={i} data-id={option?._id} value={option.slug}>
                          {option.title}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {previewTemplate && slug && id ? (
              <div className="border p-4 rounded">
                <h5 className="mb-3">{previewData && previewData.subject}</h5>
                <div dangerouslySetInnerHTML={{__html: previewData && previewData.description ? previewData.description : "N/A"}}></div>
              </div>
            ): (
              <></>
            )}
          </Modal.Header>
          <div className="w-100"></div>
          <Modal.Footer>
            <Button
              onClick={() => {
                setSelectedModal(false);
                document.getElementById("reader_id").reset();
                setSlug("");
                setId("");
                setPreviewTemplate(false);
              }}
              appearance="subtle"
            >
              Cancel
            </Button>
            <Button
              disabled={selectedUser.length === 0}
              onClick={() => {previewEmailTemplate()}}
              appearance="primary"
            >
              Preview
            </Button>
            <Button
              disabled={selectedUser.length === 0}
              onClick={() => onSendClick()}
              appearance="primary"
            >
              Send Email
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <form id="reader_id">
        <input
          style={{ display: "none" }}
          type={"file"}
          id="file_reader"
          onChange={readExcelFile}
          accept=".xls,.xlsx,.csv"
        />
      </form>
      {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
      {selectedRecords.length ? (
        <div className="bulk_actions">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ fontSize: "23px", color: "#35b159" }}
              ></i>
              <h6 className="ms-2 mt-1 mb-0">
                Selected {selectedRecords.length}{" "}
                {selectedRecords.length > 1 ? "records" : "record"} of{" "}
                {datalength}
              </h6>
            </div>
            <div>
              <ul>
                {/* <li>
                  <button
                    className="btn ripple btn-main-primary signbtn"
                    onClick={() => {
                      ChangeStatus(selectedRecords, 1);
                    }}
                  >
                    Active
                  </button>
                </li>
                <li>
                  <button
                    className="btn ripple btn-secondary"
                    onClick={() => {
                      ChangeStatus(selectedRecords, 0);
                    }}
                  >
                    Inactive
                  </button>
                </li> */}
                {hasPermission('/admin/event-management/events/delete') ?

                <li>
                  <button
                    className="btn ripple btn-secondary"
                    onClick={() => {
                      Deletefunction(selectedRecords);
                    }}
                  >
                    Delete
                  </button>
                </li>
                :null}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/***********MULTI SELECTION OPTIONS <<<<<<*************/}
    </>
  );
};

export default EventsTable;
